$chart-neutral: hsla(202, 46%, 96%, 1);
$font-size-label: 1.8rem;
$font-size-data: 1.8rem;
$font-color-label: black;
$font-color-data: hsla(0, 0%, 45%, 1);
$font-weight-label: 400;
$font-weight-data: 300;
$font-text-decoration-link: underline;
$color-zebra: hsl(204, 50%, 96%);
$padding-header-cell: 0.8rem 1.6rem;
$padding-table-cell: 0.8rem 1.6rem;

@mixin base-analytic-table {
  font-family: $font-family-base;
  font-size: 10pt;
  cursor: default;
  margin: 0;
  background: transparent !important;
  z-index: 1 !important;
  border-color: transparent;
}

//disabled for now
@mixin table-row-hover {
  -webkit-box-shadow: inset 0px 0px 2px 1px rgba(53, 158, 196, 1);
  -moz-box-shadow: inset 0px 0px 2px 1px rgba(53, 158, 196, 1);
  box-shadow: inset 0px 0px 2px 1px rgba(53, 158, 196, 1);
}

@mixin header-styles {
  background-color: $color-zebra; //white;
  border-bottom: 1px solid #eeeeee;
  border-right-color: $color-zebra !important;
  white-space: nowrap;
}

@mixin table-cell-global {
  color: $text-global;
  font-size: $font-size-data !important;
  padding: $padding-table-cell !important;
  max-height: 3rem;
  max-width: 0;
  text-align: right;
  border-color: transparent;
  font-weight: $font-weight-data;
}

@mixin table-cell-global--overview-1st-child {
  color: $text-global;
  font-size: $font-size-data !important;
  padding: $padding-table-cell !important;
  max-height: 3rem;
  text-align: left;

  border-color: transparent !important;
  font-weight: $font-weight-data;
}

.chart {
  &__table-row {
    border-left: 8px solid transparent;
  }

  &__oddTableRow {
    background-color: rgba($color-zebra, 0.6);
  }

  &__headerCell {
    padding: $padding-header-cell !important;
    font-weight: $font-weight-label;
    font-size: $font-size-label;
    color: $font-color-label;
    text-align: right;
    @include header-styles;

    &:first-of-type {
      text-align: left;
      width: 33.33333333%;
    }

    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(7) {
      display: none;
    }

    &--overview {
      padding: 1.4rem !important;
      font-size: $font-size-label;
      text-align: right;
      width: 25%;
      background-color: $chart-neutral;
      color: $font-color-label;
      font-weight: $font-weight-label;
      @include header-styles;

      &:first-of-type {
        &.sort-ascending,
        &.sort-descending {
          > span.google-visualization-table-sortind:after {
            content: "";
          }
        }
      }

      &:first-of-type,
      &:nth-child(2),
      &:nth-child(3) {
        width: 25%;
      }
    }
  }

  &__tableCell {
    @include table-cell-global;
    border-color: transparent !important;
    //descriptor column
    &:nth-child(1) {
      cursor: pointer;

      text-align: left;
      font-size: $font-size-label;
      font-weight: $font-weight-label;
      text-decoration: $font-text-decoration-link;
    }

    &:nth-child(2) {
      //background: rgba(#A0CDDE, .2);
    }

    &:nth-child(4) {
      //background: rgba(#25627A, .2);
    }

    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(7) {
      display: none;
    }

    &--overview {
      @include table-cell-global--overview-1st-child;
      text-align: right;

      &:nth-child(1) {
        font-size: $font-size-label;
        color: $font-color-data;
        font-weight: $font-weight-label;
        text-decoration: underline;
        text-align: left !important;
        cursor: pointer;
      }
    }
  }

  &__rowNumberCell {
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wrap {
  white-space: normal;
}

.sub-header__title-chart {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > i {
    padding-right: var(--measure-1x);
  }

  .sub-header-svg {
    margin-right: var(--measure-1x);
    width: 2.4rem;
    height: 2.4rem;
    fill: #656565;
  }
}

.control-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.table-height {
  &__2-row {
    min-height: 1px !important;
    max-height: 120px !important;
  }

  &__3-row {
    min-height: 1px !important;
    max-height: 200px !important;
  }

  &__4-row {
    min-height: 1px !important;
    max-height: 240px !important;
  }

  &__5-row {
    min-height: 1px !important;
    max-height: 475px !important;
  }

  &__full {
    min-height: 475px !important;
    height: auto !important;
    width: 100% !important;
    max-height: 100% !important;
  }
}

.google-visualization-table-table {
  @include base-analytic-table();
}
.table {
  &--dealer-analytics {
    @include base-analytic-table();
    width: 100%;
    &__header-cell {
      @include header-styles;
      padding: $padding-header-cell !important;
      font-weight: $font-weight-label;
      font-size: $font-size-label !important;
      color: $font-color-label;
      text-align: right;
    }
    &__data-cell {
      @include table-cell-global;
      font-weight: var(--font-weight-regular);
      &:nth-child(1) {
        text-align: left;
      }
      & .table-border-left {
        border-left: 3px solid #000 !important;
        background-color: blue;
      }
    }
    tr {
      &:nth-child(odd) {
        background-color: rgba($color-zebra, 0.6);
      }
    }
    &--all-dmp {
      &__data-cell:nth-child(1) {
        text-align: right;
      }
    }
    &__secondary-text {
      font-size: 1.25rem;
      font-style: italic;
      color: $font-color-label;
    }
  }
}

@media screen and (max-width: 767px) {
  .table-responsive--dealer-analytics {
    border: 1px solid #eeeeee;
    margin-bottom: 15.75px;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    transition: all 0.4s ease-out;
  }

  .table-responsive--dealer-analytics > .table--dealer-analytics {
    margin-bottom: 0;
  }

  .table-responsive .table--dealer-analytics__header-cell,
  .table-responsive > .table--dealer-analytics > tbody > tr > th,
  .table-responsive > .table--dealer-analytics > tfoot > tr > th,
  .table-responsive > .table--dealer-analytics > thead > tr > td,
  .table-responsive > .table--dealer-analytics > tbody > tr > td,
  .table-responsive > .table--dealer-analytics > tfoot > tr > td {
    white-space: nowrap;
  }
}

.dashboard-table-border-left {
  border-left: 4px double $global-border;

  &:nth-of-type(odd) {
    background-color: #f7f9fc;
  }
  &--no-color {
    border-left: 4px double $global-border;
  }
}
table {
  &#top-ten {
    tr:nth-child(even) {
      background-color: #f7f9fc;
    }
  }
}
