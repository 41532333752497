.delete-header {
  color: var(--color-danger-500) !important;
}

.confirm-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1.6rem;
}

.confirm-body {
  margin-bottom: 1.6rem;
}

.confirm-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.confirm-footer > * {
  margin-left: 0.8rem;
}

.confirm-button {
  min-width: 8rem;
}

.confirm-overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: var(--measure-3x);
  border-radius: inherit;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2;
}

.confirm-overlay__container {
  display: flex;
  align-items: center;
  width: 40rem;
  height: 100%;
  max-height: 80rem;
}

.confirm-overlay__container--delete {
  display: flex;
  align-items: center;
  width: 30rem;
  height: 100%;
  max-height: 80rem;
}

.confirm-overlay__content {
  width: 100%;
}
