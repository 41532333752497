@mixin login-panel-attrs($opacity: 0.8) {
  background-color: rgba(255, 255, 255, $opacity);
  border: 1px solid #e6e6e6;
}

@media (max-width: 480px) {
  .width-based-on-browser {
    width: 90%;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }
}

@media (min-width: 481px) {
  .width-based-on-browser {
    width: 440px;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }
}

.panel {
  &.panel-login {
    @include login-panel-attrs;
    margin: 0 0.8rem;
  }
}

.modal {
  &.forgot-password-modal {
    .modal-content {
      @include login-panel-attrs(0.9);
    }
  }
}

@keyframes panBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 50% 60%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.panel-login {
  animation: fadeInUp 0.2s ease-out;
}

.copyright {
  animation: fadeInUp 0.2s ease-out;
  color: #ddd;
}
