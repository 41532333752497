/* Box Shadow Inset */
@mixin box-shadow-inset {
  @include hacks();
  @include prefixed(transition-duration, $mediumDuration);
  @include prefixed(transition-property, box-shadow);

  box-shadow: inset 0 0 0 $shadowColor, 0 0 1px rgba(0, 0, 0, 0); /* Hack to improve aliasing on mobile/tablet devices */

  &:hover,
  &:focus,
  &:active {
    box-shadow: inset 2px 2px 2px $shadowColor, 0 0 1px rgba(0, 0, 0, 0); /* Hack to improve aliasing on mobile/tablet devices */
  }
}
