//Bootstrap print 3.x-1.1 for Twitter Bootstrap v3.

/**
 *  Bootstrap print 3.x-1.1 for Twitter Bootstrap v3.
 *
 *  To mange grids using Twitter Bootstrap v3 for print media.
 *  This will help controlling grid columns size on printed pages.
 *  https://github.com/Natshah/bootstrap-print
 *
 */

@media print {
  .col-p-1,
  .col-p-2,
  .col-p-3,
  .col-p-4,
  .col-p-5,
  .col-p-6,
  .col-p-7,
  .col-p-8,
  .col-p-9,
  .col-p-10,
  .col-p-11 {
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .col-p-12 {
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
  .col-p-11 {
    width: 91.66666667%;
  }
  .col-p-10 {
    width: 83.33333333%;
  }
  .col-p-9 {
    width: 75%;
  }
  .col-p-8 {
    width: 66.66666667%;
  }
  .col-p-7 {
    width: 58.33333333%;
  }
  .col-p-6 {
    width: 50%;
  }
  .col-p-5 {
    width: 41.66666667%;
  }
  .col-p-4 {
    width: 33.33333333%;
  }
  .col-p-3 {
    width: 25%;
  }
  .col-p-2 {
    width: 16.66666667%;
  }
  .col-p-1 {
    width: 8.33333333%;
  }
  .col-p-pull-12 {
    right: 100%;
  }
  .col-p-pull-11 {
    right: 91.66666667%;
  }
  .col-p-pull-10 {
    right: 83.33333333%;
  }
  .col-p-pull-9 {
    right: 75%;
  }
  .col-p-pull-8 {
    right: 66.66666667%;
  }
  .col-p-pull-7 {
    right: 58.33333333%;
  }
  .col-p-pull-6 {
    right: 50%;
  }
  .col-p-pull-5 {
    right: 41.66666667%;
  }
  .col-p-pull-4 {
    right: 33.33333333%;
  }
  .col-p-pull-3 {
    right: 25%;
  }
  .col-p-pull-2 {
    right: 16.66666667%;
  }
  .col-p-pull-1 {
    right: 8.33333333%;
  }
  .col-p-pull-0 {
    right: auto;
  }
  .col-p-push-12 {
    left: 100%;
  }
  .col-p-push-11 {
    left: 91.66666667%;
  }
  .col-p-push-10 {
    left: 83.33333333%;
  }
  .col-p-push-9 {
    left: 75%;
  }
  .col-p-push-8 {
    left: 66.66666667%;
  }
  .col-p-push-7 {
    left: 58.33333333%;
  }
  .col-p-push-6 {
    left: 50%;
  }
  .col-p-push-5 {
    left: 41.66666667%;
  }
  .col-p-push-4 {
    left: 33.33333333%;
  }
  .col-p-push-3 {
    left: 25%;
  }
  .col-p-push-2 {
    left: 16.66666667%;
  }
  .col-p-push-1 {
    left: 8.33333333%;
  }
  .col-p-push-0 {
    left: auto;
  }
  .col-p-offset-12 {
    margin-left: 100%;
  }
  .col-p-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-p-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-p-offset-9 {
    margin-left: 75%;
  }
  .col-p-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-p-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-p-offset-6 {
    margin-left: 50%;
  }
  .col-p-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-p-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-p-offset-3 {
    margin-left: 25%;
  }
  .col-p-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-p-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-p-offset-0 {
    margin-left: 0%;
  }
  /* Always insert a page break after the element */
  .always-page-break-after {
    page-break-after: always;
  }
  /* Avoid page break after the element (if possible) */
  .avoid-page-break-after {
    page-break-after: avoid;
  }
  /* Insert page breaks after the element so that the next page is formatted as a left page */
  .left-page-break-after {
    page-break-after: left;
  }
  /* Insert page breaks after the element so that the next page is formatted as a right page */
  .right-page-break-after {
    page-break-after: right;
  }
  /* Always insert a page break before the element */
  .always-page-break-before {
    page-break-before: always;
  }
  /* Avoid page break before the element (if possible) */
  .avoid-page-break-before {
    page-break-before: avoid;
  }
  /* Insert page breaks before the element so that the next page is formatted as a left page */
  .left-page-break-before {
    page-break-before: left;
  }
  /* Insert page breaks before the element so that the next page is formatted as a right page */
  .right-page-break-before {
    page-break-before: right;
  }
  /* Avoid page break inside the element (if possible) */
  .avoid-page-break-inside {
    page-break-inside: avoid;
  }
  body {
    font-family: arial, sans-serif;
    background: inherit;
    color: black;
    font-size: 0.8em;
  }
  #page {
    width: 100%;
    margin: 0;
    padding: 0;
    background: inherit;
  }
  #bs-example-navbar-collapse-1,
  div.sidebar,
  footer {
    display: none;
  }
  .entry a:after {
    content: " [" attr(href) "] ";
  }

  .no-padding-print {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  aside.aside {
    display: none;
  }
}

@media all {
  .customer-data-printed {
    border-bottom: 1px solid $dmp-border-color;
    padding: $padding-base-vertical;
  }

  span {
    &.field-title {
      font-size: 1em;
      font-weight: bolder;
      white-space: nowrap;
    }
    &.printed-data {
      text-decoration: underline;
    }
  }

  .printed-concept-heading {
    border-bottom: 1px solid $dmp-border-color;
    h4 {
      font-size: 1.2em;
      margin-top: 5px;
      margin-bottom: 1px;
    }
  }
  .print-logo {
    width: 120px;
    height: auto;
  }

  .print-page-header {
    padding: 5px 0;
    margin-bottom: $padding-base-vertical;
    border-bottom: 1px solid rgb(178, 178, 178);
  }
}
