.equal-heights-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "sub-header-col-1" "col-1" "sub-header-col-2" "col-2";
  grid-row-gap: 0;
  @media (min-width: $screen-md) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "sub-header-col-1 sub-header-col-2" "col-1 col-2";
    grid-row-gap: 0;
    grid-column-gap: var(--measure-3x);
  }

  &__sub-header-col-1 {
    grid-area: sub-header-col-1;
  }

  &__sub-header-col-2 {
    grid-area: sub-header-col-2;
  }

  &__col-1 {
    grid-area: col-1;
  }

  &__col-2 {
    grid-area: col-2;
  }
}
