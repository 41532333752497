$chart-tooltip-bg: #232323;
$chart-tooltip-color: #453d3f;
.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 5em;
  padding: 0.5em;
  background: $chart-tooltip-bg;
  color: $chart-tooltip-color;
  font-family: Oxygen, Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 1000;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -15px;
    border: 15px solid transparent;
    border-top-color: $chart-tooltip-bg;
  }
  &.tooltip-show {
    opacity: 0.8;
  }
}
