/* Overline From Center */
@mixin overline-from-center {
  $duration: $mediumDuration;

  @include hacks();
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    right: 50%;
    top: 0;
    background: $activeColor;
    height: 4px;
    @include prefixed(transition-property, "left, right");
    @include prefixed(transition-duration, $duration);
    @include prefixed(transition-timing-function, ease-out);
  }

  &:hover,
  &:focus,
  &:active {
    &:before {
      left: 0;
      right: 0;
    }
  }
}
