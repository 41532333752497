//
// Tables
// --------------------------------------------------

table {
  background-color: $table-bg;
}

caption {
  padding-top: $table-cell-padding;
  padding-bottom: $table-cell-padding;
  color: $table-text;
  text-align: left;
}

th,
td {
  &.text-center {
    text-align: center !important;
  }
}

// Baseline styles

table.table {
  //table-layout: fixed;
  width: 100%;
  max-width: 100%;
  margin-bottom: $line-height-computed;

  &.table-ellipsis {
    table-layout: auto;
    @media (min-width: $screen-sm) {
      table-layout: fixed;
      width: 100%;
      > thead,
      > tbody {
        > tr {
          > td {
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            transition: all 0.1s linear;
          }

          > th {
            white-space: nowrap;
            padding: 8px 24px;
          }
        }
      }
    }
  }

  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > td {
        padding: 0.8rem 1.6rem;
        line-height: $line-height-base;
        vertical-align: top;
        border-top: 0px solid $table-border-color;
        white-space: nowrap;
        color: darken($dmp-text-standard, 20%);
        overflow: hidden;
        text-overflow: ellipsis;

        /* &:hover {
                    overflow: visible;
                    background-color: inherit;
                    z-index: 20;
                    position: absolute;
                    width: auto;
                    transform-origin: left;
                }*/
      }
    }
  }
  table.table thead th,
  table.table tbody tr td {
    white-space: nowrap;
    padding: 0.8rem 1.6rem;
  }
  table.table tbody tr td {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > tfoot {
    > tr {
      > th {
        padding: $table-cell-padding;
        line-height: $line-height-base;
        vertical-align: top;
        //border-top: 0px solid $table-border-color;

        color: darken($dmp-text-standard, 20%);
      }
    }
  }

  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid $table-border-color;
  }

  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }

  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid $table-border-color;
  }

  // Nesting
  .table {
    background-color: $table-bg;
  }
}

// Condensed table w/ half padding

.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $table-condensed-cell-padding;
      }
    }
  }
}

// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  border: 1px solid $table-border-color;

  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid $table-border-color;
      }
    }
  }

  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  > tbody > tr:nth-child(odd) {
    background-color: $table-bg-accent;
  }
  > tbody > tr:nth-child(even) {
    background-color: white;
  }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  > tbody > tr:hover {
    background-color: $table-bg-hover;
  }
}

// Table cell sizing
//
// Reset default table behavior

table col[class*="col-"] {
  position: static; // Prevent border hiding in Firefox and IE9/10 (see https://github.com/twbs/bootstrap/issues/11623)
  float: none;
  display: table-column;
}

table {
  td,
  th {
    &[class*="col-"] {
      position: static; // Prevent border hiding in Firefox and IE9/10 (see https://github.com/twbs/bootstrap/issues/11623)
      float: none;
      display: table-cell;
    }
  }
}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// Generate the contextual variants
@include table-row-variant("active", $table-bg-active);
@include table-row-variant("success", $state-success-bg);
@include table-row-variant("info", $state-info-bg);
@include table-row-variant("warning", $state-warning-bg);
@include table-row-variant("danger", $state-danger-bg);

// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  overflow-x: auto;

  overflow-y: hidden;

  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

  @media screen and (max-width: $screen-xs-max) {
    width: 100%;
    margin-bottom: ($line-height-computed * 0.75);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid $table-border-color;

    &:hover {
    }

    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }

          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
.table-responsive-x-only {
  overflow-x: auto;

  overflow-y: visible;

  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

  @media screen and (max-width: $screen-xs-max) {
    width: 100%;
    margin-bottom: ($line-height-computed * 0.75);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid $table-border-color;

    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
table {
  background-color: $table-bg;
}

caption {
  color: #909fa7;
  padding-bottom: 8px;
  padding-top: 8px;
  text-align: left;
}

th {
  text-align: left;
}

.table {
  margin-bottom: 21px;
  max-width: 100%;
  width: 100%;
  position: relative;
}

.table > thead > tr > th {
  border-bottom: 2px solid $dmp-dark-gray;
  vertical-align: bottom;
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.table > tbody + tbody {
  border-top: 2px solid $dmp-dark-gray;
}

.table .table {
  background-color: #f5f7fa;
}

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 8px;
}

.table-bordered {
  border: 1px solid $table-border-color;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid $table-border-color;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-child(odd) {
  background-color: $table-bg-accent;
}

.table-hover > tbody > tr:hover {
  background-color: $table-bg-hover;
}

table col[class*="col-"] {
  display: table-column;
  float: none;
  position: static;
}

table td[class*="col-"],
table th[class*="col-"] {
  display: table-cell;
  float: none;
  position: static;
}

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #fafbfc;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: $table-bg-hover;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: $table-bg-hover;
}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: $table-bg-hover;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: $table-bg-hover;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: $table-bg-hover;
}

@media screen and (max-width: 767px) {
  .table-responsive {
    border: 1px solid #eeeeee;
    margin-bottom: 15.75px;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    transition: all 0.4s ease-out;

    /*     &:hover:before {
            position: absolute;
            top: 48%;
            left: var(--measure-2x);
            font-family: dmp_icon_font;
            content: '\e97c';
            font-size: var(--measure-font-60);
            opacity: .5;
            //transform: translateY(-50%);
        }*/
  }

  .table-responsive > .table {
    margin-bottom: 0;
  }

  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }

  .table-responsive > .table-bordered {
    border: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }

  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }

  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

.table > tbody > tr > td {
  // padding: 8px;
  line-height: 1.52857;
  vertical-align: top;
  //border-top: 1px solid #eee;
  font-size: 14px;
  font-weight: 400; //600;
  color: $text-global;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
th {
  vertical-align: text-top;
  font-weight: 300;
  font-size: 1rem;
  text-align: left;
  @media screen and (min-width: $screen-sm) {
    font-size: 1.4rem;
  }
}
.customer-info-cell,
.system-info-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: inherit;
  &--emphasized {
    font-weight: 600;
    font-size: 1.6rem;
  }
  &--deemphasized {
    display: block;
    color: lighten($text-global, 25%);
  }
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  vertical-align: middle;
}

.table {
  &-emphasis {
    font-size: 1.5rem;
    font-weight: 600;

    &--secondary {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  &-deemphasis {
    color: $color-deemphasis;
    font-size: 1.2rem;
  }
}
td.ellipsis-cell {
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table > thead > tr > th {
  padding: $table-header-cell-padding;
  white-space: nowrap;
  color: black;
}

// invoice tables
.pdf-file-actions {
  width: 80px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

table.table.table-ellipsis--hover > tbody > tr > td:hover {
  @media (min-width: $screen-sm) {
    overflow: visible;
    background-color: inherit;
    z-index: 1;
    position: absolute;
    width: auto;
    height: 2.8rem;
  }
}
.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 0.8rem 1.6rem;
}
