div {
  &#CustomerDataTable_filter.dataTables_filter > label > input {
    height: 1.8em !important;
    font-size: 12px !important;
  }

  &#CustomerDataTable_paginate.dataTable > ul.pagination > a {
    margin-left: 0px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    padding: 0.5em;
  }
}

.pagination > li > a {
  margin-left: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  padding: 0.5em;
}

.dataTables_length {
  label {
    text-align: center;

    select {
      margin-right: 0;
    }
  }
}

table.dataTable.dtr-inline.collapsed tbody {
  td:first-child::before,
  th:first-child::before {
    /*Library override*/
    top: 1px !important;
    left: 1px !important;
    height: 14px !important;
    width: 14px !important;
    display: block;
    position: absolute;
    color: $dmp-white-inverse;
    border: 2px solid $dmp-white-inverse;
    border-radius: 4px !important;
    text-align: center;
    line-height: 14px;
    box-shadow: 0px 0px 3px #444;
    box-sizing: content-box;
    content: "+" !important;
    background-color: $state-primary !important;
  }

  tr.parent {
    td:first-child::before,
    th:first-child::before {
      /*Library override*/
      content: "-" !important;
      background-color: $dmp-red !important;
      -ms-transform: rotate(360deg);
      /* IE 9 */
      -webkit-transform: rotate(360deg);
      /* Chrome, Safari, Opera */
      transform: rotate(360deg);
      transition-duration: 0.1s;
    }
  }
}
.top {
  padding: 2.4rem;
}
.bottom {
  padding: var(--measure-3x);
  display: flex;
  justify-content: space-between;

  &.view-controls {
    margin-top: 0;
    clear: both;
  }
}

.link-csv {
  position: relative;
  top: 1.333em;
}

@media only screen and (max-width: 768px) {
  .customer-table_form-wrapper {
    margin-top: 0.5em;
    margin-right: 0.33333333333333em;
    width: 90%;

    > form > {
      div.form-group.table-select {
        /*Library override*/
        margin-top: 0.5em;
      }

      .form-group > {
        select,
        button {
          /*Library override*/
          margin-top: 0.5em;
        }

        select
          .customer-table_form-wrapper
          > form
          > div.form-group.table-select {
          padding-top: 5px;
        }
      }
    }
  }

  /*#CustomerDataTable .btn.btn-default, #CustomerDataTable .ColVis .ColVis_Button {

      margin: 18px 10px 0;
    }*/
  button.ColVis_MasterButton {
    margin: 18px 10px 15px 20px;
  }

  .dataTables_info {
    display: inline-block;
  }

  .pagination {
    display: inline-block;
    padding-left: 0px;
    margin: 0px 0px;
    margin-top: 0.5rem;
    border-radius: 4px;
  }

  .ColVis_collectionBackground {
    display: block;
  }

  #CustomerDataTable_length {
    margin-top: -2.93333333333333333em;
  }

  #CustomerDataTable_filter > label > input.form-control {
    height: 2.83333333333333em;
    margin-right: 1em;
  }

  ul.ColVis_collection {
    width: 150px;
    padding: 8px 8px 4px;
    margin: 0px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    z-index: 2002;
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  }

  td {
    &.align-right-table {
      padding-right: 2.5em !important;
      text-align: right;
    }
  }

  div.table-responsive-y-control {
    overflow-x: auto;
    overflow-y: hidden;
  }

  @media only screen and (min-width: $container-desktop) {
    .table > thead > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > th,
    .table > tbody > tr > td,
    .table > tfoot > tr > th,
    .table > tfoot > tr > td {
      padding: 3px;
      line-height: 1.42857;
      vertical-align: top;
      border-top: 1px solid #eee;
      font-size: 14px;
      color: black;
    }
  }
}

.data-table {
  table {
    thead {
      tr {
        font-weight: 200;
      }
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    padding-top: var(--measure-3x);
    padding-right: var(--measure-3x);
    padding-left: var(--measure-3x);
    padding-bottom: var(--measure-12);
    justify-content: space-evenly;
    align-items: center;

    &--with-padding {
      padding: var(--measure-2x);
    }

    @media screen and (min-width: $screen-sm) {
      flex-direction: row;
      justify-content: space-between;
    }

    > input.form-control[data-role="table-search"] {
      @media screen and (max-width: $screen-sm) {
        max-width: 32rem;
        min-height: 3.5rem;
        margin-bottom: var(--measure-2x);
        flex: 1;
        text-align: center;
        font-size: 1.6rem;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        -ms-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
      }

      &:focus {
        text-align: left;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        -ms-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;

        ::-webkit-input-placeholder {
          color: $input-color-placeholder;
        }
      }

      margin-right: var(--measure-1x);
      @media screen and (min-width: $screen-sm) {
        font-size: 1.4rem;
        line-height: 1;
        width: 25rem;
        text-align: left;
        margin-bottom: 0;
      }
    }

    > .data-table__btn {
      width: 100%;
    }

    > .dataTables_filter {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    // Used for start/end date filters
    .dataTables_dateFilter {
      display: inline-block;
      margin-left: 10px;
      width: 140px;
      @media (max-width: $screen-sm) {
        margin: 0 0 5px 0;
        display: block;
        width: auto;
      }

      // Used to highlight invalid date string
      .invalid {
        color: red;
      }

      .dateFormatHint {
        display: inline;
        position: absolute;
        font-size: 7pt;
        margin: 33px 0 0 -129px; // this is ugly
        color: red;
        @media (max-width: $screen-sm) {
          display: block;
          position: inherit;
          margin-left: 10px;
          margin-top: 2px;
        }
      }
    }

    &--right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__bottom {
    clear: both;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    padding: var(--measure-1x);
    //margin-bottom: var(--measure-2x);
    // margin-top: var(--measure-2x);

    &--with-padding {
      padding: var(--measure-2x);
      margin-bottom: var(--measure-2x);
    }

    &--modal {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;

      @media (min-width: $screen-sm) {
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
      }
    }

    @media (min-width: $screen-sm) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: var(--measure-3x);
    }

    &--left,
    &--right,
    &--center {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: center;
      flex: 0 0 1;
      margin-bottom: var(--measure-half);
      //margin-bottom: var(--measure-1x);
      @media (min-width: $screen-sm) {
        justify-content: flex-start;
        align-items: stretch;
        margin-bottom: 0;
      }
    }

    &--right {
      .form-inline .form-group {
        text-align: center;
        margin-bottom: 0;
      }
    }

    p.system-metric-display {
      //margin-bottom: var(--measure-1x);
    }

    dir-pagination-controls ul.pagination {
      margin: 0;
    }
  }

  &__btn {
    @media (max-width: 48em) {
      width: 100%;
    }
  }

  &__btn-dashboard {
    margin-left: -1px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.no-b-radius-rt {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.dataTables_filter input {
  margin-left: 0;
}

div.dataTables_info {
  //padding-top: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}

.dataTables_length {
  > label {
    margin-bottom: 0;
  }
}

.dataTables_paginate {
  display: flex;
}

input {
  &.input-center {
    @media (max-width: 48em) {
      text-align: center;

      &:focus {
        text-align: start;
      }
    }
  }
}

.dataTables_filter input[type="search"] {
  width: 32rem;
  //border-radius: 3rem;
  @media only screen and (min-width: $screen-sm) {
    width: 25rem;
  }
}

.dataTables_info {
  padding-top: 0.8rem;
}

table#CustomerSummarySystemDataTable {
  .visible-1090 {
    display: none;
    @media screen and (min-width: 1090px) {
      display: table-cell;
    }
  }

  .not-visible-1090 {
    display: block;
    @media screen and (min-width: 1090px) {
      display: none !important;
    }
  }
}

th.login-button,
td.login-button {
  display: none;
  @media screen and (min-width: 1090px) {
    display: table-cell;
  }
}

.table-fixed {
  width: 100%;
  table-layout: fixed;
  &--not-mobile {
    @media (min-width: $screen-sm) {
      width: 100%;
      table-layout: fixed;
    }
  }
}

.locked-account-indicator {
  &:before {
    font-family: dmp_icon_font;
    content: "\e90b";
    color: $state-danger;
  }
}

.login-visibility {
  display: block;
  @media screen and (min-width: 1090px) {
    display: none;
  }
}

table#CustomerSummarySystemDataTable {
  .visible-1090 {
    display: none;
    @media screen and (min-width: 1090px) {
      display: table-cell;
    }
  }

  .not-visible-1090 {
    display: block;
    @media screen and (min-width: 1090px) {
      display: none !important;
    }
  }

  .login-visibility {
    display: block;
    @media screen and (min-width: 1090px) {
      display: none;
    }
  }
}

th.login-button,
td.login-button {
  display: none;
  @media screen and (min-width: 1090px) {
    display: table-cell;
  }
}

.table-fixed {
  width: 100%;
  table-layout: fixed;
  &--not-mobile {
    @media (min-width: $screen-sm) {
      width: 100%;
      table-layout: fixed;
    }
  }
}

table#CustomerSummarySystemDataTable {
  .visible-1090 {
    display: none;
    @media screen and (min-width: 1090px) {
      display: table-cell;
    }
  }

  .not-visible-1090 {
    display: block;
    @media screen and (min-width: 1090px) {
      display: none !important;
    }
  }

  .login-visibility {
    display: block;
    @media screen and (min-width: 1090px) {
      display: none;
    }
  }
}

th.login-button,
td.login-button {
  display: none;
  @media screen and (min-width: 1090px) {
    display: table-cell;
  }
}

.table-fixed {
  width: 100%;
  table-layout: fixed;
  &--not-mobile {
    @media (min-width: $screen-sm) {
      width: 100%;
      table-layout: fixed;
    }
  }
}

.locked-account-indicator {
  &:before {
    font-family: dmp_icon_font;
    content: "\e90b";
    color: $state-danger;
  }
}

/*
Accounting reports table 
*/

/* Row hover style */
table#PaymentsDataTable tbody tr:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}

/* Error icon column */
table#PaymentsDataTable .error-col {
  text-overflow: clip; /* Hide ellipsis */
  padding: 1.2rem 5px 1.2rem 15px; /* Trim down padding */
}

/* Error icon */
table#PaymentsDataTable i {
  color: #d60000;
}
