//tags with links

a.label {
  font-size: 75%;
  transition: all 0.3s ease;
}

a.label:hover {
  font-size: 80%;
  padding: 0.15em 0.55em 0.25em;
  transition: all 0.3s ease;
  position: relative;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
}

a.label.filteron:before {
  content: "\f057\00a0";
  font-family: FontAwesome;

  font-size: 100%;
  transition: all 0.3s ease;
}
