.customer-referral {
  &__header {
    &__title {
      font-size: var(--measure-font-20);
    }

    &__date {
      font-size: var(--measure-font-14);
    }
  }

  &__detail-table {
    &__line-item {
      @include flex-config(column, nowrap, flex-start, flex-start);
      margin-bottom: var(--measure-1x);
      width: 100%;
      @media (min-width: $screen-sm) {
        @include flex-config(row, nowrap, space-between, center);
        max-width: 60%;
      }

      &__label {
        font-weight: var(--font-weight-thin);
        font-size: var(--measure-font-12);

        &--msg {
          margin-bottom: var(--measure-1x);
          border-bottom: 0.1rem solid $global-border;
        }
      }

      &__data {
        font-weight: var(--font-weight-semibold);

        &--message {
          font-weight: var(--font-weight-semibold);
          display: flex;
          flex-flow: row wrap;
        }
      }
    }
  }

  &__detail-grid {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 5rem repeat(7, 2.8rem) 1fr 3.2rem;
    grid-template-areas: "name name" "date date" "label-type label-type" "data-type data-type" "label-email label-email" "data-email data-email" "label-phone label-phone" "data-phone data-phone" "label-referred label-referred" "data-referred data-referred" "data-message data-message" "action-button action-button";

    padding: var(--measure-2x);
    @media (min-width: $screen-sm) {
      grid-template-columns: 0.5fr 1fr 0.5fr;
      grid-template-rows: 5rem repeat(4, 3.2rem) 1fr 3.2rem;
      grid-template-areas: "name name date" "label-type data-type data-type" "label-email data-email data-email" "label-phone data-phone data-phone" "label-referred data-referred data-referred" "data-message data-message data-message" "action-button action-button action-button";
    }

    &--name {
      grid-area: name;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      margin-bottom: var(--measure-1x);
    }

    &--date {
      grid-area: date;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      margin-bottom: var(--measure-1x);
      @media (min-width: $screen-sm) {
        justify-content: flex-end;
      }
    }

    &--label-type {
      grid-area: label-type;
      padding-top: var(--measure-2x);
      @media (min-width: $screen-sm) {
        padding-top: 0;
      }
    }

    &--data-type {
      grid-area: data-type;
    }

    &--label-email {
      grid-area: label-email;
      padding-top: var(--measure-2x);
      @media (min-width: $screen-sm) {
        padding-top: 0;
      }
    }

    &--data-email {
      grid-area: data-email;
    }

    &--label-phone {
      grid-area: label-phone;
      padding-top: var(--measure-2x);
      @media (min-width: $screen-sm) {
        padding-top: 0;
      }
    }

    &--data-phone {
      grid-area: data-phone;
    }

    &--label-referred {
      grid-area: label-referred;
      padding-top: var(--measure-2x);
      @media (min-width: $screen-sm) {
        padding-top: 0;
      }
    }

    &--data-referred {
      grid-area: data-referred;
    }

    &--data-message {
      grid-area: data-message;
      margin-bottom: var(--measure-1x);
      padding-top: var(--measure-2x);
    }

    &--action-button {
      grid-area: action-button;

      display: flex;
      justify-content: flex-end;
      align-items: end;
    }
  }
}
