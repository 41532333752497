/**
*  The following styles were copied from the Edison
*  project for use with the video verification page.
************************************************************/

div.video-container.embed-responsive.embed-responsive-4by3 {
  background-size: contain;
  background: $dmp-white-inverse url("/assets/img/ajax-loader.gif") no-repeat
    center center;
}

.busy-wrapper.busy {
  /* display a spinner */
  min-width: 90px;
  background: black url("/assets/img/loading_small.gif") no-repeat center;
}

.busy-wrapper-large.busy {
  /* display a spinner */
  background: black url("/assets/img/loading_small.gif") no-repeat center;
}

.range {
  &.vertical {
    @include prefix(
      (
        transform: rotate(-90deg),
      ),
      webkit moz ms o
    );

    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    &.reversed {
      @include prefix(
        (
          transform: rotate(90deg),
        ),
        webkit moz ms o
      );
    }
  }
  &.wack {
    @include prefix(
      (
        transform: rotate(0deg),
      ),
      webkit moz ms o
    );
  }
}

/*This corrects a problem with 4.3 and sliders.  Don't know how it fixes it, but it does*/

.video-play {
  position: absolute;
  top: 0px;
  left: 10%;
  width: 90%;
  height: 60%;
  background: none;
  border: none;
}

.video-paused .video-play span::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid white;
  display: block;
  margin: 18px 0 0 29px;
}

.video-play span {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  border: medium white dotted;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 45%;
  margin: -40px 0 0 -40px;
  text-align: center;
  /*display: none;*/
}

.modal {
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  left: 0;
}

//List for rotation classes
$rotations: 90, 180, 270;

@each $rotation in $rotations {
  .rotate-image-#{$rotation} {
    @include prefix(
      (
        transform: rotate($rotation + deg),
      ),
      webkit ms
    );
  }
}

.hotspot {
  z-index: 5;
  position: absolute;
  border: 1px solid white;
  border-radius: 32px;
  background-color: black;
  opacity: 0.6;
  padding-top: 4px;
  .icon {
    color: #98a3f0;
    font-size: 30px;
  }
}

.hotspot-device {
  z-index: 10;
  position: absolute;
  left: 0px;
  top: 0px;
}

.item-toggle .toggle.toggle-row {
  position: relative;
  top: 0px;
  right: 0px;
}

video.video {
  position: relative;
  background: black;
  border: 1px solid #ff0000;
}

.camera-title {
  padding: 5px;
  background: rgba(255, 0, 0, 0.5) none repeat scroll 0% 0%;
  width: 20%;
  position: absolute;
  left: 0;
  color: $dmp-white-inverse;
  border-bottom-right-radius: 4px;
  z-index: 1;
}

input[type="range"] {
  &.camera-control-pan {
    background: rgba(255, 255, 255, 0) none repeat scroll 0% 0%;
    margin-left: 11px;
    width: 80%;
    position: relative;
    bottom: 2px;
  }
  &.camera-control-pan--ie {
    background: rgba(255, 255, 255, 0) none repeat scroll 0% 0%;
    margin-left: 11px;
    width: 80%;
    position: relative;
    bottom: 17px;
  }
}

.camera-control-tilt--ie {
  background: rgba(255, 255, 255, 0) none repeat scroll 0% center;
  position: relative;
  top: -17px;
}

input[type="range"][orient="vertical"] {
  writing-mode: bt-lr;
  /* IE */
  -webkit-appearance: slider-vertical;
  /* WebKit */
  width: 4px;
  height: 90%;
  padding: 9px 10px;
}

.camera-control-tilt {
  background: rgba(255, 255, 255, 0) none repeat scroll 0% center;
  position: relative;
  top: -3px;
}

.tilt-control-wrapper {
  position: relative;
}

input[type="range"] {
  &::-ms-fill-upper,
  &::-ms-fill-lower {
    background-color: $dmp-white-inverse;
  }
}

.light-control-wrapper {
  position: absolute;
  padding: 0.4166667em 0.25em;
  bottom: 0px;
  left: 7px;
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  &:hover {
    position: absolute;
    padding: 0.4166667em 0.25em;
    bottom: 0px;
    left: 7px;
    z-index: 2;
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      rgba(241, 218, 54, 1) 0%,
      rgba(248, 237, 155, 0) 56%,
      rgba(254, 252, 234, 0) 100%
    );
    /* FF3.6+ */
    background: -webkit-gradient(
      radial,
      center center,
      0px,
      center center,
      100%,
      color-stop(0%, rgba(241, 218, 54, 1)),
      color-stop(56%, rgba(248, 237, 155, 0)),
      color-stop(100%, rgba(254, 252, 234, 0))
    );
    /* Chrome,Safari4+ */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      rgba(241, 218, 54, 1) 0%,
      rgba(248, 237, 155, 0) 56%,
      rgba(254, 252, 234, 0) 100%
    );
    /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(
      center,
      ellipse cover,
      rgba(241, 218, 54, 1) 0%,
      rgba(248, 237, 155, 0) 56%,
      rgba(254, 252, 234, 0) 100%
    );
    /* Opera 12+ */
    background: -ms-radial-gradient(
      center,
      ellipse cover,
      rgba(241, 218, 54, 1) 0%,
      rgba(248, 237, 155, 0) 56%,
      rgba(254, 252, 234, 0) 100%
    );
    /* IE10+ */
    background: radial-gradient(
      ellipse at center,
      rgba(241, 218, 54, 1) 0%,
      rgba(248, 237, 155, 0) 56%,
      rgba(254, 252, 234, 0) 100%
    );
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1da36', endColorstr='#00fefcea', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
}

/*End of additions from edison  project*/

.btn-camera-control {
  opacity: 0.5;
  &:hover {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
}

.btn-pt-up,
.btn-pt-down {
  position: absolute;
  z-index: 1;
}

.btn-pt-up {
  top: -24px;
  left: 25px;
}

.btn-pt-down {
  top: 2px;
  left: 24px;
}

div {
  &.camera-controls {
    position: relative;
    z-index: 1;
  }
  &.camera-control-wrapper {
    cursor: pointer;
    position: absolute;
    bottom: 40px;
    left: -54px;
    padding: 2.5em 0.5em 2.5em 0.5em;
    z-index: 1;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
    border-radius: 4px;
    -webkit-animation-delay: 2s;
    /* Chrome, Safari, Opera */
    animation-delay: 2s;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    /*bottom: 8px;
        left: 2px;*/
  }
  &.video_controls_pan-wrapper {
    position: absolute;
    background: #000 none repeat scroll 0% 0%;
    bottom: -13px;
    left: 0;
    right: 0;
    margin: 0 auto 5%;
    width: 98%;
    height: 22px;
    padding: 0.5em 5%;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    opacity: 0.5;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s;
      -o-transition: opacity 0.3s;
      -ms-transition: opacity 0.3s;
      transition: opacity 0.3s;
    }
  }
  &.video_controls_tilt-wrapper {
    position: absolute;
    background: #000;
    bottom: 22px;
    left: 0;
    margin: 0 0 5% 1%;
    width: 50%;
    height: 22px;
    padding: 0.5em 5%;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    opacity: 0.5;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    -ms-transition: opacity 0.3s;
    transition: opacity 0.3s;
    transform: rotate(-90deg);
    transform-origin: 0 0;
    &:hover {
      opacity: 0.8;
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s;
      -o-transition: opacity 0.3s;
      -ms-transition: opacity 0.3s;
      transition: opacity 0.3s;
    }
  }
  &.camera-control-wrapper:hover {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    background: rgba(11, 10, 10, 0.33);
    opacity: 1;
    padding: 2.5em 0.5em 2.5em 0.5em;
    left: 2px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
}

a.button.btn-light > span.icon-Camera_Light_on {
  color: #dedede;
  text-decoration: none !important;
  position: absolute;
  top: -1px;
  font-size: 2.2em;
}

#light_control.active {
  color: rgb(255, 254, 0);
}

.grow {
  height: 100px;
  /* Origional height */
  width: 22%;
  /* Origional width */
  margin: 5px 1% 5px 1%;
  /* Just for presentation (Not required) */
  float: left;
  /* Just for presentation (Not required) */
  position: relative;
  /* Just for presentation (Not required) */
  transition: height 0.5s;
  /* Animation time */
  -webkit-transition: height 0.5s;
  /* For Safari */
  &:hover {
    height: 115px;
    /* This is the height on hover */
  }
}

table.video-verification_table > tbody > tr > td {
  padding: 3px 8px;
}

.video-verification_.nav > li > a {
  position: relative;
  display: block;
  padding: 0.5em 0.5em 0;
  > span {
    position: relative;
    left: 5px;
    bottom: 5px;
  }
}

:active,
:focus {
  outline: 0;
}

button.record-video-btn {
  display: block;
  width: 15px;
  height: 15px;
  background-color: #841313;
  margin-right: 0.5em;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  margin-top: 1px;
  &:hover,
  &:active,
  &[aria-pressed="true"],
  &.active {
    background-color: rgb(226, 0, 0);
    box-shadow: inset 0px 1px 0px 0px rgba(250, 250, 250, 0.5),
      0px 0px 3px 2px rgba(226, 0, 0, 0.5);
    -moz-box-shadow: inset 0px 1px 0px 0px rgba(250, 250, 250, 0.5),
      0px 0px 3px 2px rgba(226, 0, 0, 0.5);
    -webkit-box-shadow: inset 0px 1px 0px 0px rgba(250, 250, 250, 0.5),
      0px 0px 3px 2px rgba(226, 0, 0, 0.5);
  }
}

.video-title {
  padding-left: 0.5em;
  color: #000;
}

button.record-video-btn:target {
  box-shadow: inset 0px 1px 0px 0px rgba(250, 250, 250, 0.5),
    0px 0px 3px 2px rgba(135, 187, 83, 0.5);
  background-color: rgb(135, 187, 83);
}

.fa-spin {
  -webkit-filter: blur(0);
}

.video-verification_.embed-responsive .embed-responsive-item {
  border: 1px solid $global-border;
  border-top: 0 none;
  background-size: cover;
}

.embed-responsive {
  iframe,
  embed,
  object {
    border: 1px solid $global-border;
    border-top: 0px none;
    background-size: cover;
  }
}

@media only screen and (min-width: 768px) {
  /*  label.input.label-acct-num-width, input.label-acct-num-width {
        width: 100%;
        max-width: 212px;
      }*/
}

.video-cell {
  max-width: 100%;
  height: auto;
}

div#default_clip_player {
  //border: 1px solid $global-border;
  background-color: $bleached-white;
  border-radius: 0.4rem;
  margin-bottom: var(--measure-2x);
}

[class^="icon-"],
[class*=" icon-"] {
  font-size: 1.5em;
}

.clip-list {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  &__item {
    display: grid;
    width: 100%;
    grid-template-columns: min-content 1fr 1fr 1fr;
    grid-template-rows: min-content;
    grid-template-areas: "play-icon  camera-name time detected-object";
    grid-gap: 0.8rem;
    margin: 0;
    padding: 0.8rem;
    list-style: none;
    align-content: center;
    border-top: 1px solid #eee;
    &:last-child {
      border-bottom: 0 none;
    }
    &:hover {
      background-color: $table-bg-hover;
    }
  }
  &__play-icon {
    grid-area: play-icon;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    padding: 0.8rem;
    text-align: center;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &__time {
    grid-area: time;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__camera-name {
    grid-area: camera-name;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__detected-object {
    grid-area: detected-object;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.online {

 &__info { 
  position: absolute;
  z-index: 500;
  top: 0;
  right: 0;
  padding: .2rem .4rem;
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0% 0%;
  color: $dmp-white-inverse;
  border-bottom-left-radius: 4px;
  z-index: 1;
font-size: 1rem
}
}