.salesperson {
  &__content-layout-grid {
    display: grid;
    grid-template-columns: minmax(31rem, 100vw);
    grid-template-rows: repeat(4, minmax(0, max-content));
    grid-template-areas: "dealer-list-title" "dealer-list" "audit-table-title" "audit-table";

    @media (min-width: $screen-sm) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat (2, minmax(0, max-content));
      grid-column-gap: 1.6rem;
      grid-template-areas:
        "dealer-list-title audit-table-title"
        "dealer-list audit-table";
    }

    @media (min-width: $screen-lg) {
    }

    &__dealer-list-title {
      grid-area: dealer-list-title;
    }

    &__dealer-list-row {
      grid-area: dealer-list;
    }
    &__audit-table-row-title {
      grid-area: audit-table-title;
    }
    &__audit-table-row {
      grid-area: audit-table;
    }
  }

  &__dealer-list {
    background-color: white;
    padding: 0;

    max-height: 30vh;

    &__item {
      display: block;
      margin: 0 -0.8rem 0.8rem -0.8rem;

      &__territory-name {
        padding: var(--measure-1x);
        font-size: 1.6rem;
        font-weight: var(--font-weight-semibold);
        background-color: var(--color-primary);
        color: white;
        display: block;
        width: 100%;
      }
      &__dealer-name-list {
        padding-left: 0;
      }
      &__dealer-name {
        padding: var(--measure-1x) var(--measure-3x);
        margin-left: 0;
        font-size: var(--measure-font-14);
        font-weight: var(--font-weight-regular);
        background-color: white;
        color: #3d3e3e;
        border-bottom: 1px solid var(--color-neutral-300);
        &:nth-child(even) {
          background-color: #f5f7f9;
        }
        @at-root li:first-of-type {
          .salesperson__dealer-list__item__dealer-name {
            border-top-left-radius: 0.4rem;
            border-top-right-radius: 0.4rem;
          }
        }
        @at-root li:last-of-type {
          .salesperson__dealer-list__item__dealer-name {
            border-bottom-left-radius: 0.4rem;
            border-bottom-right-radius: 0.4rem;
          }
        }
      }
    }
  }
}
