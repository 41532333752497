// alert-component.scss
.custom-alert {
  background-color: #cce7ff;
  border: 1px solid #35728d;
  border-radius: 6px;
  padding: 20px;
  color: #35728d;
  display: grid;
  grid-template-rows: auto auto;
  gap: 15px;
  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto; // Title & report link in the first row, count in second row
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    @media (max-width: 991px) {
      // Bootstrap 3 mobile view
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
    }
  }
  &__title {
    grid-column: 1 / 2; // Title stays in the first column
    font-size: 18px;
    justify-self: start;
    @media (max-width: 991px) {
      justify-self: center;
      text-align: center;
    }
  }
  &__link {
    grid-column: 2 / 3; // Aligns to the right
    grid-row: 1 / 2; // Ensures it's in the first row
    color: #35728d;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    justify-self: end;
    text-align: right;
    margin-bottom: 0.8rem;
    &--underlined {
      text-decoration: underline;
    }
    &:hover {
      text-decoration: underline;
    }
    @media (max-width: 991px) {
      text-align: center;
      justify-self: center;
    }
  }
  &__count {
    grid-column: 1 / 2; // Stays under the title
    grid-row: 2 / 3; // Moves to the second row
    font-size: 18px;
    font-weight: bold;
    color: #35728d;
    padding-left: 3.2rem;
    justify-self: start;
    margin-top: 5px;
    @media (max-width: 991px) {
      justify-self: center;
      text-align: center;
    }
  }
  &__body {
    display: grid;
    grid-template-rows: auto auto auto;
    padding-left: 3.2rem;
    @media (max-width: 991px) {
      padding-left: 0;
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    gap: 5px;
    align-items: flex-start;

    // Aligns links to the left on desktop
    @media (max-width: 991px) {
      justify-items: center; // Centers links on mobile
    }
  }
}
