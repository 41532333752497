/* Ripple In */
@include keyframes(#{$nameSpace}-ripple-in) {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}

@mixin ripple-in {
  $outerBorderWidth: 4px;
  $innerBorderWidth: 4px;

  @include hacks();
  position: relative;

  &:before {
    content: "";
    position: absolute;
    border: $primaryColor solid $outerBorderWidth;
    top: -(6px + 6px);
    right: -(6px + 6px);
    bottom: -(6px + 6px);
    left: -(6px + 6px);
    opacity: 0;
    @include prefixed(animation-duration, 1s);
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    @include prefixed(animation-name, #{$nameSpace}-ripple-in);
  }
}
