.btn-override > span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-override {
  font-size: 1.2em;
  vertical-align: middle;
  line-height: 1;
  margin-right: var(--measure-half);
}
