@mixin required-marker {
  content: "*";
  white-space: no-wrap;
  color: $dmp-red;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

@media screen and (max-width: $screen-sm-max) {
  .no-gutter-less-than-sm {
    padding-left: 0px;
    padding-right: 0px;
  }
}

input.display-table-cell {
  display: table-cell !important;
  vertical-align: top;
}

.add-border-right {
  border-right: 1px solid $dmp-dark-gray;
}

.margin-right-inline {
  margin-right: 1em;
}

.sm-neg-margin-left-inline {
  margin-left: -1.25em;
}

.truncate {
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-120 {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-95 {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hide-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.truncate-name {
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 768px) {
  .truncate-name {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.word-break-letter {
  word-wrap: break-word;
}

.pad {
  padding: 25px;
}

.mr10 {
  margin-right: 10px !important;
}

.small-indent {
  margin-left: 5em;
}

.very-small-indent {
  margin-left: 2.1em;
}

// margins for mobile

.show-hand {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.fixed-position {
  position: fixed;
}

.relative-position {
  position: relative;
}

.width25 {
  width: 25%;
}

.width50 {
  width: 50%;
}

.width75 {
  width: 75%;
}

.width100 {
  width: 100%;
}

.table-wrapper {
  max-height: 225px;
  overflow-y: scroll;
}

.mv0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.pv0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.x-hide {
  overflow-x: hidden;
  // margins for mobile
}

.vert-scroll {
  overflow-y: auto;
}

@media only screen and (max-width: 357px) and (min-width: 0) {
  a.btn.btn-sm.btn-alert.pull-right.mt-lg-xs-view {
    margin-top: 1.8em !important;
  }
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.vertical-align {
  display: inline-flex;
  flex-direction: row;
}

.vertical-align > [class^="col-"],
.vertical-align > [class*=" col-"] {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Optional, to align inner items
                                 horizontally inside the column */
}

button.btn.btn-sm.btn-default.mt0-mobile {
  margin-top: 0 !important;
}

.alert-info-dmp {
  @include alert-variant($state-primary, $alert-info-border, $alert-info-text);
}

.mouse-over-pointer {
  cursor: pointer;
}

.minimum-width-75 {
  min-width: 75px;
}

@media only screen and (max-width: 768px) and (min-width: 0) {
  .text-align-center-mb {
    text-align: center;
  }
}

.show-hand {
  cursor: pointer;
  cursor: hand;
}

.text-bolder {
  font-weight: bolder;
}

.has-error-header {
  border: 1px solid $dmp-red !important;
  color: $dmp-red !important;
}

.form-group.required {
  .control-label:after,
  .tiny-label:after {
    @include required-marker();
  }
}
span.required {
  &:after {
    @include required-marker();
  }
}

.text {
  &-primary-color,
  &_color-primary {
    color: $astral;
  }

  &-warning-color,
  &_color-warning {
    color: $warning;
  }

  &-danger-color,
  &_danger-warning {
    color: $brand-danger;
  }
}
