@mixin dealer-level-titles {
  grid-area: title;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  font-weight: var(--font-weight-semibold);
}
.dealer-level {
  &__grid {
    display: grid;
    width: 100%;
    padding: 2.4rem 1.6rem 1.6rem 1.6rem;
    grid-template-columns: max-content;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas: "title" "d1" "d2" "d3" "d4" "d5" "d6";
    grid-gap: var(--measure-2x);
    justify-content: center;
    align-items: top;
    @media screen and (min-width: 40em) {
      grid-template-areas:
        "title title"
        "d1 d2"
        "d3 d4"
        "d5 d6";
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: repeat(2, 50%);
    }
    @media screen and (min-width: $screen-sm) {
      grid-template-areas:
        "d1 d2 d3"
        "d4 d5 d6"
        "title title title";
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(3, 33.333%);
    }
    @media screen and (min-width: 1300px) {
      grid-template-areas:
        "d1 d2 d3 d4 d5 d6"
        "title title title title title title";
      grid-template-rows: 1fr 1fr;
      grid-template-columns: repeat(6, 1fr);
    }
  }

  &__rating {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    &:nth-of-type(1) {
      grid-area: d1;
    }
    &:nth-of-type(2) {
      grid-area: d2;
    }
    &:nth-of-type(3) {
      grid-area: d3;
    }
    &:nth-of-type(4) {
      grid-area: d4;
    }
    &:nth-of-type(5) {
      grid-area: d5;
    }
    &:nth-of-type(6) {
      grid-area: d6;
    }
    &--under {
      background-color: var(--color-state-danger);
    }
    &--met {
      background-color: var(--color-state-info);
    }
    &--exceeds {
      background-color: var(--color-state-success);
    }
    &__current {
      border-radius: 9999px;
      color: white;
      padding: 0 1.5rem;
      font-size: var(--measure-font-12);
      font-weight: var(--font-weight-bold);
      line-height: 2.5rem;
      white-space: nowrap;
    }

    &__title {
      @include dealer-level-titles();
      font-size: var(--measure-font-16);

      &--extra-info {
        font-weight: var(--font-weight-normal);
      }

      &--sub {
        @include dealer-level-titles();
        font-size: var(--measure-font-14);
      }
    }

    &__range {
      font-size: var(--measure-font-18);
      display: flex;
      flex-flow: row nowrap;
      white-space: nowrap;
    }
  }
}
.dealer-table-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-template-areas: "first-col" "second-col";
  margin: 0 -1.5rem;
  @media screen and (min-width: $screen-lg) {
    grid-template-columns: 0.5fr 0.5fr;
    grid-template-rows: max-content;
    grid-template-areas: "first-col second-col";
    grid-column-gap: 1.6rem;
  }
  &__first-col {
    grid-area: first-col;
  }

  &__second-col {
    grid-area: second-col;
  }
}

.table-responsive--dealer-dashboard {
  overflow-x: auto;

  overflow-y: hidden;

  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

  @media screen and (max-width: $screen-xs-max) {
    width: 100%;
    margin-bottom: ($line-height-computed * 0.75);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid $table-border-color;

    // Tighten up spacing
    > .table--dealer-dashboard {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }

          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
