/* ========================================================================
       2.3  Component: sidebar
     ========================================================================== */

$font-size-nav: 1.3rem;
$nav-icon-size: 1.8rem;
$icon-controller: icon-control_down;

@mixin sidebar-svg-base {
  height: 2rem;
  width: 2rem;
  position: relative;
}
@mixin collapse-animation-transition {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@mixin background-subnav {
  background-color: $background-subnav;
  border-top: 1px solid rgba($accent-blue, 0.1);
}

.sidebar .nav > li.is-active-state > a {
  i.icon-user {
    color: $bleached-white;
  }
  color: $navbar-is-active-color;
}

.sidebar .nav > li.is-active-account > a {
  i.icon-user {
    color: $bleached-white;
  }

  color: $navbar-is-active-color;
}

aside.aside {
  border-left: solid 1px $dmp-layout-border-darker;
  background-color: white;
  height: 100%;
  width: 24rem;
}

.sidebar {
  @include collapse-animation-transition;

  &__menu-revealer {
    position: absolute;
    right: 0.4rem;
    transform-origin: 1rem 1rem;
  }
  .nav-heading {
    color: $nav-heading-color;
    > li {
      > a {
        &:after {
          font-family: FontAwesome;
          float: right;
          content: "\f0d9";
        }
      }
    }
  }
  > .logo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 0;
  }

  &__dealer-logo {
    max-width: 23rem;
    max-height: 16rem;
    justify-content: center;
    height: auto;
    background-size: contain;
    object-fit: contain;
  }
}

ul.sidebar {
  > li {
    position: static;

    > a {
      > em {
        color: inherit;
      }
    }
    .nav {
      > li {
        .active {
          > a {
            > em {
              color: $brand-info;
            }
          }
        }
        .open {
          > a {
            > em {
              color: $brand-info;
            }
          }
        }
        > nav.item {
          > em {
            color: inherit;
          }
        }
      }
    }
  }
}

.sidebar > .nav > li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $font-size-nav;
  position: relative;
  padding-left: 0;

  &:focus,
  > .nav-item:focus,
  &:hover,
  > .nav-item:hover {
    text-decoration: none;
    outline: medium none;
    color: $text-color;
  }
}

.sidebar-subnav > li {
  > {
    a,
    .nav-item,
    a:focus,
    .nav-item:focus,
    a:hover,
    .nav-item:hover {
      color: $text-color;
    }
  }
  &.active > {
    a,
    .nav-item {
      color: $text-color;
    }
    a:after,
    .nav-item:after {
      border-color: #eeeeee;
      /*background-color: $brand-info; */
    }
  }
}

.sidebar-subnav {
  background-color: $body-bg;
  > li {
    > a {
    }
  }
  > .sidebar-subnav-header {
    color: $text-color;
  }
}

.topnavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*
ul > li {
	padding-left: 10px;
}
*/

.customer-table_form-wrapper {
  margin-top: 0.58333333333333em;
  margin-right: 0.33333333333333em;
  padding-top: 7px;
  display: inline-block;
  width: 100%;
}

.customer-table_form-wrapper > form > div.form-group.table-select {
  margin-top: 0px;
}

@media only screen and (min-width: 700px) {
  .customer-table_form-wrapper {
    margin-top: 0.5em;
    margin-right: 0.33333333333333em;
    display: inline-block;
    float: none;
    width: 90%;
    > form > {
      div.form-group.table-select {
        /*Library override*/
        float: right;
      }
      .form-group > {
        select,
        button {
          /*Library override*/
          float: right;
        }
      }
      div.form-group.table-select {
        padding-top: 5px;
      }
    }
  }
}

.row-sidebar {
  height: 100%;
  margin-left: 0;
}

.sidebar.nav li > a > span.span__i__fa-search-move {
  top: 0;
  left: 0.25em;
  padding-right: 1.5em;
}

.menu {
  width: 44px;
  height: 25px;

  &:hover {
    cursor: pointer;
  }

  &.open {
    .menu-item {
      background: #656565;
      width: 45px;
    }
  }
}

.menu-item {
  display: block;
  width: 40px;
  height: 5px;
  margin: 0 0 5px;
  background: #656565;
}

.navbar-toggle {
  /*  position:                 absolute;
      top:                      30%;
      right:                    5.2rem;*/
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: translate(-50% -50%);
  -moz-transform: translate(-50% -50%);
  -ms-transform: translate(-50% -50%);
  -o-transform: translate(-50% -50%);
  transform: translate(-50% -50%);
  float: none;
  margin: 0;
  padding: 0;

  margin-bottom: 0;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.sidebar > .nav > li > .nav-item:focus {
  color: $text-color;
}

li a i {
  width: 1.8em;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  color: $sidebar-icon-color;
  padding-right: 1em;
}

.sidebar__logo-area {
  display: inline-block;
  width: 84%;
  height: 44px;
  margin: 0 1rem 1rem 1rem;
  background: url("/assets/img/DealerAdmin_Color.svg");
  background-size: 100% 100%;
  @include collapse-animation-transition;
}

i[class^="sidebar__icon icon-"] {
  font-size: $nav-icon-size;

  opacity: 0;
  top: -15%;
  transform: translateY(15%);
  -webkit-transition: transform 0.4s 0.2s cubic-bezier(0.3, 0, 1, 2.12);
  -moz-transition: transform 0.4s 0.2s cubic-bezier(0.3, 0, 1, 2.12);
  -ms-transition: transform 0.4s 0.2s cubic-bezier(0.3, 0, 1, 2.12);
  -o-transition: transform 0.4s 0.2s cubic-bezier(0.3, 0, 1, 2.12);
  transition: transform 0.4s 0.2s cubic-bezier(0.3, 0, 1, 2.12);
  -webkit-animation: to-end-state 0.2s 0.4s ease-out forwards;
  -o-animation: to-end-state 0.2s 0.4s ease-out forwards;
  animation: to-end-state 0.2s 0.4s ease-out forwards;
}

li.sidebar {
  &__nav-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    border-left: 0.4rem solid transparentize($navbar-is-active-bg, 1);
    transition: all 0.5s ease;
    color: $navbar-default-color;

    &:hover {
      color: $navbar-is-active-color;
      border-left: 0.4rem solid opacify($navbar-is-active-bg, 1);

      transition: all 0.3s ease;
    }

    &--new {
      .sidebar__nav-item-text::after {
        content: "New";
        display: inline-block;
        margin-left: var(--measure-half);
        padding: 0 var(--measure-half);
        min-width: 3rem;
        border-radius: 9999px;
        white-space: nowrap;
        line-height: 2;
        font-size: 0.8rem;
        font-weight: 600;
        background-color: var(--color-info-900);
        color: white;
        text-transform: uppercase;
        vertical-align: middle;
        text-align: center;
      }

      &.is-active-state .sidebar__nav-item-text::after {
        background-color: $background-subnav;
        color: $navbar-is-active-bg;
      }

      &.is-active-account .sidebar__nav-item-text::after {
        background-color: $background-subnav;
        color: $navbar-is-active-bg;
      }
    }
  }
}

svg.sidebar__icon {
  &--svg {
    @include sidebar-svg-base;
    fill: black;
    margin-right: 1rem;

    &--arrow {
      @include sidebar-svg-base;
      fill: #777;
      margin-right: 0.8rem;
      transform: scale(0.5);
    }
    &--splash {
      height: 10vh;
      width: 10vh;
    }
  }
}

.sidebar .nav > li > a.sidebar__link,
.sidebar__link--external {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: var(--measure-2x) var(--measure-1x);
  font-weight: 400;
  border-bottom: 1px solid #f5f2f0;

  &.sub-item {
    font-weight: normal;
  }
  &--external {
    width: 100%;
    padding: var(--measure-2x) var(--measure-1x);
    font-weight: 400;
    &:focus {
      color: $sidebar-item-color;
    }
    &--svg {
      display: flex;
      justify-content: flex-start;
      align-content: center;
    }
  }
}

.sidebar__title--account {
  display: block;
}

.nav.sidebar__nav {
  //width:   100%;
  height: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &--subnav-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

li {
  &.sidebar {
    &__nav-item {
      width: 240px;
      font-weight: 400;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      &--line-break {
        width: 100%;
        height: 2px;
        border-top: 2px solid #f5f2f0;
        flex-shrink: 0;
        flex-grow: 0;
        cursor: auto;
      }
      &--list-header {
        width: 100%;
        height: 2rem;
        background-color: var(--color-neutral-400);
        color: var(--black);
        margin-top: -2px;
        flex-shrink: 0;
        flex-grow: 0;
        cursor: auto;
      }
      &--spacer {
        width: 24rem;
        height: 8rem;
        flex-shrink: 0;
        flex-grow: 0;
        cursor: auto;
      }
      div {
        &.recent-systems-menu-item {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          color: $primary;
        }
        span {
          &.system-name {
            font-size: 13px;
            font-weight: 800;
          }
          &.customer-name {
            font-size: 11px;
            font-weight: bold;
          }
        }
      }
      i {
        &.#{$icon-controller} {
          font-size: 1rem;
          position: relative;

          right: var(--measure-2x);
        }
      }
      &.customer-menu-item {
        padding-left: 4.4rem;
        font-size: 1.1rem;
      }

      &.control-system-menu-item,
      &.personnel-menu-item {
        > a {
          width: 100%;
          padding: 0.5rem 0 0.5rem 4.4rem;
          font-size: 1.2rem;
        }
      }
      &.account-menu-item {
        > a {
          width: 100%;
          padding: 0.5rem 0 0.5rem 4.4rem;
          font-size: 1.2rem;
        }
      }

      &--subnav {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        border-left: 0;
        transition: all 0.5s ease;
        padding-left: 5rem;
        padding-top: 0.5rem;
        font-weight: normal;

        &:hover {
          color: $navbar-is-active-color;
          border-left: 0.4rem solid $navbar-is-active-bg;

          transition: all 0.3s ease;
        }
      }
    }
  }
}

.sidebar {
  &__breadcrumb-list {
    width: 100%;
    //height: 120%
  }
  &__breadcrumb-item {
    &_title {
      display: flex;
      vertical-align: middle;
      width: 100%;
      border-left: 0;
      transition: all 0.5s ease;
      padding: 0.5rem 0 0.9rem 5rem;
    }
  }
}

.control-system-menu-item {
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-right: 10px solid $accent-blue;
}

li {
  > ul.nav {
    @include background-subnav;
  }
}

.search-box {
  width: 95%;
  height: auto;
  padding: 1rem;
  @include background-subnav;
}

@keyframes to-end-state {
  100% {
    opacity: 1;
  }
}

.sidebar:after {
  content: "";
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  width: 0;
  -webkit-transform: translateZ(0px);
}

.layout-fixed .wrapper > .aside {
  width: 236px;
}

.sidebar {
  overflow-x: hidden;
}

.wrapper > .aside {
  padding-top: 0;
}
.badge-active {
  color: transparent;
}
