.grid {
  width: 500px;
}
.CellComSL_Guide {
  width: 100%;
  height: 33.3%;
}
.Zone_Header {
  width: 100%;
  height: 33.3%;
}
.Zone_Data {
  width: 100%;
  height: auto;
}
.cellComGridStyle {
  border: 1px solid rgb(212, 212, 212);
  max-width: 100%;
  width: 100%;
}

.cellComGridStyle.cellComContent {
  height: 210px;
  margin-top: -20px;
}
.ui-grid-header-cell {
  position: relative;
  box-sizing: border-box;
  background-color: inherit;
  border-right: 1px solid #d4d4d4;
  border-color: #d4d4d4;
  display: table-cell;
  -moz-user-select: none;
  width: 30px;
}
.vertical30x {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: left bottom 0;
  -moz-transform-origin: left bottom 0;
  -ms-transform-origin: left bottom 0;
  -o-transform-origin: left bottom 0;
  transform-origin: left bottom 0px;
  top: 135px;
  position: relative;
  height: 31px;
  width: 170px;
  left: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 6px;
  text-align: left;
}
.vertical80 {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: left bottom 0;
  -moz-transform-origin: left bottom 0;
  -ms-transform-origin: left bottom 0;
  -o-transform-origin: left bottom 0;
  transform-origin: left bottom 0px;
  top: 135px;
  position: relative;
  height: 31px;
  width: 170px;
  left: 55px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 6px;
  text-align: left;
}
.uicenter {
  text-align: center;
}
.ngcenter.gheader {
  position: absolute;
  bottom: 4px;
  left: 7px;
}
.ui-grid-cell.uiGridDarkGray {
  color: #6a6a6a !important;
  background-color: #6a6a6a !important;
  margin: 1px;
}
.vertical {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: left bottom 0;
  -moz-transform-origin: left bottom 0;
  -ms-transform-origin: left bottom 0;
  -o-transform-origin: left bottom 0;
  transform-origin: left bottom 0px;
  position: absolute;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  bottom: 4px;
}
.vertical.v25 {
  left: 22px;
}
.vertical.v30 {
  left: 25px;
}
.vertical.v50 {
  left: 35px;
}
.vertical.v80 {
  left: 50px;
}
.vert-col-height {
  height: 150px !important;
}

.linked {
  color: #003670;
  text-decoration: underline;

  -webkit-transition: all 0.33s ease;
  -moz-transition: all 0.33s ease;
  -o-transition: all 0.33s ease;
  transition: all 0.33s ease;

  &:hover {
    font-weight: bold;
    color: #145770;
    background: #000;

    cursor: pointer;
    text-decoration: none;
    font-size: 1.1em;
  }
}

ui-select-wrap {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

table thead tr th.rotate {
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;
}

table thead tr th.rotate > div > span {
  transform: rotate(-90deg);
}
td.not-applicable {
  background-color: #b8b8b8 !important;
}

span.not-applicable-legend {
  height: 1em;
  width: 1em;
  padding: 0 1em;
  background-color: #b8b8b8;
  border: 1px solid black;
  border-radius: 4px;
}
.table-striped-column > tbody > tr td:nth-of-type(odd) {
  background-color: $dmp-light-gray;
}

thead th div.vertical-header {
  -moz-transform-origin: top left;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  position: relative;

  -moz-transform: rotate(-90deg); /* FF3.5+ */
  -o-transform: rotate(-90deg); /* Opera 10.5 */
  -webkit-transform: rotate(-90deg); /* Saf3.1+, Chrome */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); /* IE6,IE7 */
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)"; /* IE8 */
  vertical-align: top;
  padding: 15px 0;
}
