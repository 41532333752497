/* Icon Grow */
@mixin icon-grow {
  @include hacks();
  position: relative;
  padding-right: #{$spaceBetweenTextAndArrows};
  @include prefixed(transition-duration, $mediumDuration);

  &:before {
    content: "\f118";
    position: absolute;
    right: 1em;
    padding: 0 1px;
    font-family: FontAwesome;
    @include prefixed(transform, translateZ(0));
    @include prefixed(transition-duration, $mediumDuration);
    @include prefixed(transition-property, transform);
    @include prefixed(transition-timing-function, ease-out);
  }

  &:hover,
  &:focus,
  &:active {
    &:before {
      @include prefixed(transform, scale(1.3) translateZ(0));
    }
  }
}
