.new-feature-banner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: white;
  max-width: 90rem;
  padding: var(--measure-2x);
  margin: 0 auto;
  border: 2px solid var(--color-primary-500);
  border-radius: 6px;

  @media screen and (min-width: 550px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & .new-feature-banner__button {
      margin-left: var(--measure-2x);
    }
  }
}

.new-feature-banner__title {
  margin: 0;
  color: var(--color-primary-500);
  font-weight: bold;
}

.new-feature-banner__description {
  @media screen and (min-width: 550px) {
    margin-bottom: 0;
  }
}
