// a unified view header for all pages
@mixin flex-display-standard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > *:not(:last-child) {
    margin-bottom: var(--measure-1x);
    margin-right: 0;
  }
  @media (min-width: $screen-sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    //margin-top: var(--measure-half);
    > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: var(--measure-1x);
    }
  }
}

@mixin flex-display-confirm-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > *:not(:last-child) {
    margin-bottom: var(--measure-1x);
    margin-right: 0;
  }
  @media (min-width: $screen-sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    //margin-top: var(--measure-half);
    > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}

//page-header
.page-header {
  @include flex-display-standard();
  margin-top: 0;
  padding: 1.6rem 0;
  border-bottom: 1px solid #cccccc;
  margin-bottom: var(--measure-3x);
  &__left {
    @include flex-display-standard();
    flex: 0 0 1;
  }

  &__right {
    @include flex-display-standard();
    flex: 0 0 1;

    &--confirm-button {
      @include flex-display-confirm-button();
    }
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: var(--measure-3x);
    line-height: 1;
    > *:first-child {
      padding-right: var(--measure-1x);
    }
    @media print {
      padding-left: var(--measure-3x);
    }
  }
}

%row-display {
  content: "";
  display: table;
}

.view-header {
  &:before {
    @extend %row-display;
  }

  &:after {
    clear: both;
    @extend %row-display;
    margin-bottom: var(--measure-2x);
  }

  &__col {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    border-bottom: 1px solid #cccccc;
  }

  &__title {
    @at-root h3.view-header__title {
      font-size: var(--measure-3x);
      line-height: var(--measure-3x);
      display: block;
      text-align: center;
      @media (min-width: $screen-sm) {
        text-align: left;
      }
    }
  }
}

.sub-header {
  font-size: 1.6rem;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--measure-2x) 0;

  @media screen and (min-width: $screen-sm) {
    flex-flow: row wrap;
    font-size: 2rem;
    line-height: var(--measure-3x);
  }
  &--underlined {
    border-bottom: 1px solid #cccccc;
    padding-bottom: var(--measure-2x);
    margin-bottom: var(--measure-3x);
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--measure-1x);

    @media screen and (min-width: $screen-sm) {
      flex-direction: row;
      margin-bottom: 1rem;
    }

    & *:not(:last-child) {
      @media screen and (min-width: $screen-sm) {
        margin-right: var(--measure-1x);
      }
    }

    > i:first-of-type {
      font-size: inherit;
    }
  }

  &__title {
    margin-bottom: var(--measure-1x);
    &--inside-panel {
      display: flex;
      justify-content: flex-start;
      flex: 0 0 max-content;
      align-items: center;
      color: var(--color-neutral-900);
      font-size: var(--measure-font-18);
      font-weight: var(--font-weight-regular);
      line-height: 1.33333;
      padding: var(--measure-2x) 0;
      margin-bottom: var(--measure-2x);
      border-bottom: 0.1rem solid rgba($global-border, 0.5);
    }
    @media screen and (min-width: $screen-sm) {
      margin-bottom: 0;
    }
  }

  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & *:not(:last-child) {
      @media screen and (min-width: $screen-sm) {
        margin-right: var(--measure-1x);
      }
    }
  }
}
