$current-color: currentColor;

a {
  color: black;
  text-decoration: none;

  &.link {
    cursor: pointer;
    border: none;
    padding: 0;
    background-color: transparent;
    text-decoration: none;
    color: currentColor;

    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.2s ease-out;

    &--flex {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }

    &.link-primary {
      color: $primary;
      font-weight: bold;

      &--emphasis {
        color: $accent-blue-link;
        font-size: 1.6rem;

        &-bold {
          color: $accent-blue-link;
          font-size: 1.6rem;
          font-weight: 500;
        }
      }
      &.disabled-link {
        pointer-events: none;
        cursor: default;
        color: gray;
        cursor: not-allowed;
      }
    }

    &.link-danger {
      color: $dmp-red;

      &:hover {
        background-color: transparent;
        text-decoration: none;
        color: darken($dmp-red, 50%);
      }
    }
  }
}

button.link {
  border: none;
  padding: 0;
  background-color: transparent;
  border-radius: 3px;
  text-decoration: underline;
  /*$text-color*/
  color: $text-color;
}

a.link:hover,
a.link-primary.link:hover,
button.link:hover {
  background-color: transparent;
  text-decoration: none;
  color: darken($primary, 50%);
}

a.link-primary:hover {
  text-decoration: underline;
}

a.link-danger.link:hover {
  background-color: transparent;
  text-decoration: none;
  color: darken($dmp-red, 50%);
}

body,
.wrapper > section {
  background-color: $body-bg;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

h4.panel-title {
  font-size: 1em;
}

/*Cell Signal Page
----------------------------------*/
hr.page_cell-signal {
  margin-top: 0;
}

.legend {
  background: $legend-background-color;
  border: 0px none;
  color: #3a3f51;
  display: block;
  font-size: 21px;
  line-height: inherit;
  margin: 2px 0px 0px;
  padding: 0.5em;
  width: 100%;
}

legend,
div.legend {
  display: block;
  width: 100%;
  padding: 0.5em 0.5em 0.5em 0.5em;
  margin: 2px 0 0 0;
  font-size: 21px;
  line-height: inherit;
  color: #3a3f51;
  background: $legend-background-color;
  border: 0px none;
}

.page-header {
  padding: 0 0 0 0.16666666666667em;
  background: $legend-background-color;
  border: 0px none;
}

/*Multi-panels
------------------------------------*/
ul.profile-list {
  background: #eaeaea;
}

li.group-list-item {
  margin-left: 2.3em;
}

i.toggler {
  position: relative;

  bottom: -0.6em;
}

legend {
  display: block;
  width: 100%;
  padding: 0.5em 0.5em 0.5em 0.5em;
  margin: 2px 0 0 0;
  font-size: 21px;
  line-height: inherit;
  color: #3a3f51;
  background: none repeat scroll 0% 0% $legend-background-color;
  border: 0px none;
}

fieldset > legend {
  margin-left: 0.2em;
  padding-right: 2px;
  border: none;
  background-color: $dmp-white-inverse;
  font-size: 1.2em;
  width: auto;
}

@media screen and (min-width: 767px) {
  .constrain-width-300 {
    max-width: 300px;
  }
  .constrain-width-235 {
    max-width: 235px;
  }
}

a.no-underline {
  color: $accent-blue-link;
  transition: color 0.5s ease;

  &:hover {
    text-decoration: none;
    color: $accent-blue-link-hover;
    transition: color 0.5s ease;
  }
}

a:focus {
  outline: 0;
}

/* Make select element behave like other inputs in your form
------------------------------------------------------------- */

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -appearance: none;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("/assets/img/dropdown.svg") !important;
  background-repeat: no-repeat !important;
  background-attachment: scroll !important;
  background-size: 20px 13px !important;
  background-position: 99% center !important;
  border: 1px solid #ccc;
  overflow: hidden;
  padding: 6px 20px 6px 16px !important;
  width: auto;
}

select[disabled] {
  background-color: $gray-lighter !important;
}

/* Setting Select placeholder to same styles as other inputs
------------------------------------------------------------- */

select option[data-default] {
  color: #999;

  /* You can add whatever styles you want here */
}

.input-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn,
.ColVis .input-group-sm > .input-group-btn > .ColVis_Button,
.form-group-sm .form-control {
  padding: 0 1rem;
}

.cell-upgrade-img-preview {
  max-width: 80%;
  height: auto;
  max-height: 40rem;
  transition: all 0.2s linear;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;
  }
}
