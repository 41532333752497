$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);

.img-responsive--limit-height {
  width: auto;

  height: 100%;
  max-height: 30rem;
  margin: 0 auto;
}

.img-frame {
  width: 100%;
  height: auto;
  padding: $padding-base;
  margin-bottom: $margin-base;
  text-align: center;
  overflow: hidden;
  border: 1px solid $border-color;
  -webkit-border-radius: $border-radius-base;
  -moz-border-radius: $border-radius-base;
  border-radius: $border-radius-base;
}

//state colors for svg only
svg {
  /**
     * ----------------------------------------
     * state-colors
     * ----------------------------------------
     */
  &.svg-info {
    fill: $state-primary !important;
  }

  &.svg-danger {
    fill: $state-danger !important;
  }

  &.svg-success {
    fill: $state-success !important;
  }

  &.svg-warning {
    fill: $state-warning !important;
  }
  &.svg-default {
    fill: $text-global !important;
  }

  /**
     * ----------------------------------------
     * busy-icon
     * ----------------------------------------
     */

  &#busy {
    will-change: transform, opacity, fill;
    backface-visibility: none;
    fill: #4fb6d6;
    // transform: translateY($pull-to-right) rotate($degree-of-spin);
    // animation:  rotate-in-left $roll-in-time cubic-bezier(0,1.34,.55,.99) forwards;

    #dot-lt {
      -webkit-animation: pulsate-fwd 1s 0.1s ease-in-out infinite both;
      animation: pulsate-fwd 1s 0.1s ease-in-out infinite both;

      transform-origin: 30% 50%;
    }
    #dot-md {
      -webkit-animation: pulsate-fwd 1s 0.4s ease-in-out infinite both;
      animation: pulsate-fwd 1s 0.4s ease-in-out infinite both;

      transform-origin: 50% 50%;
    }
    #dot-rt {
      -webkit-animation: pulsate-fwd 1s 0.6s ease-in-out infinite both;
      animation: pulsate-fwd 1s 0.6s ease-in-out infinite both;

      transform-origin: 70% 50%;
    }
  }

  /**
     * ----------------------------------------
     * positive-icon
     * ----------------------------------------
     */
  &#positive {
    will-change: transform;
    backface-visibility: none;
    fill: #0f700f;

    #ring {
      transform-origin: 50% 50%;
    }

    #checkmark {
      transform-origin: 50% 50%;
      opacity: 0;
      -webkit-animation: change-color-rotate-in-negative 1s 0.2s
        cubic-bezier(0.62, 0.26, 0.24, 1.53) forwards;

      animation: change-color-rotate-in-negative 1s 0.2s
        cubic-bezier(0.62, 0.26, 0.24, 1.53) forwards;
    }
  }

  /**
     * ----------------------------------------
     * negative-icon
     * ----------------------------------------
     */
  &#negative {
    will-change: transform, opacity, fill;
    backface-visibility: none;
    fill: #4fb6d6;
    // transform: translateY($pull-to-right) rotate($degree-of-spin);
    // animation:  rotate-in-left $roll-in-time cubic-bezier(0,1.34,.55,.99) forwards;
    #ring {
      -webkit-animation: change-color-rotate-in-negative 0.5s ease-in-out
        forwards;
      animation: change-color-rotate-in-negative 0.5s ease-in-out forwards;

      transform-origin: 50% 50%;
    }
    #x-spot {
      opacity: 0;
      -webkit-animation: change-color-rotate-in-negative 1s 0.2s
        cubic-bezier(0.62, 0.26, 0.24, 1.53) forwards;

      animation: change-color-rotate-in-negative 1s 0.2s
        cubic-bezier(0.62, 0.26, 0.24, 1.53) forwards;

      transform-origin: 50% 50%;
    }
  }
}

/**
 * ----------------------------------------
 * ANIMATIONS FOR NG_IF SVG'S
 * ----------------------------------------
 */

.status-icon-animate-busy.ng-leave {
  animation: 600ms rotateMeNgLeave $easeOutCubic;
}

.status-icon-animate-positive.ng-enter {
  animation: rotateMeNgEnter 600ms $easeOutCubic;
}

.status-icon-animate-positive.ng-leave {
  animation: rotateMeNgLeave 600ms $easeInCubic;
}

@keyframes rotateMeNgEnter {
  from {
    transform: rotateY(-90deg);
  }
  50% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  to {
    transform: rotateY(0);
  }
}

@keyframes rotateMeNgLeave {
  from {
    opacity: 0.2;
    transform: rotateY(70deg);
  }
  50% {
  }
  to {
    opacity: 0;
    transform: rotateY(90deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-left
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-left {
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}

@keyframes rotate-in-left {
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0%,
  80%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@keyframes pulsate-fwd {
  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.2;
  }
  50% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation change-color-rotate-in-positive
 * ----------------------------------------
 */

@keyframes change-color-rotate-in-positive {
  0%,
  20% {
    fill: #4fb6d6;
    transform: scale(0.8) rotate(275deg);
    opacity: 0;
  }
  30% {
    fill: #4fb6d6;
    transform: scale(1.01) rotate(10deg);
    opacity: 0;
  }
  100% {
    fill: #0f700f;
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation change-color-rotate-in-negative
 * ----------------------------------------
 */

@keyframes change-color-rotate-in-negative {
  0%,
  20% {
    fill: #4fb6d6;
    transform: scale(0) rotate(360deg);
    opacity: 0;
  }
  30% {
    fill: #e82022;
    opacity: 0.5;
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}
