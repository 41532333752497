/*----------------------------------------/
-- @component - push-down-alert
--  @desc - alert area that pushes down
--          over the header area.
 ----------------------------------------*/
@mixin push-down-alert-primary_color-scheme {
  background-color: $color-primary;
  border-bottom: var(--color-primary-300);
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2),
    0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
  color: white;
}

@mixin alert-animation {
  transform-origin: top;
  transform: translateY(-10rem) scaleY(1);
  animation: fadeInFromTop 600ms 1s cubic-bezier(0.28, 0.14, 1, 1.28) forwards;
}

.push-down-alert {
  align-items: center;
  @include alert-animation;
  background-color: var(--color-state-info-hover);
  border-bottom: 0.1rem solid var(--color-state-info-darken-20);
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2),
    0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
  color: var(--color-state-info-darken-20);
  cursor: pointer;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  left: 0;
  padding: var(--measure-1x) var(--measure-2x);
  position: fixed;
  right: 0;
  z-index: 1100;
  max-width: 50%;
  margin: 0 auto;
  border-radius: 0 0 0.5rem 0.5rem;

  &--news {
    background-color: #3d85a3;
    border-bottom: #3d85a3;
  }

  &.show-as-warning {
    background-color: var(--color-secondary-hover);
    color: var(--color-secondary-darken-20);
    border-bottom: 0.1rem solid var(--color-secondary-darken-20);
  }

  &__svg-icon-box {
    fill: currentColor;
    height: var(--measure-2x);
    width: var(--measure-2x);

    &:before {
    }
  }

  &__type {
    font-size: 1.8rem;
    line-height: 1.8rem;
    vertical-align: middle;
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: var(--measure-2x);
    font-weight: 400;
    color: darken($dmp-blue, 30);

    .push-down-alert__svg-icon-box {
      margin-right: var(--measure-1x);
    }

    &--news {
      color: #fafafa;
    }
  }

  &__closer {
    z-index: 1200;
    height: 2rem;
    width: 2rem;

    &--news {
      color: #fafafa;
    }
  }
}

.push-down-alert--custom {
  @include push-down-alert-primary_color-scheme;
  @include alert-animation;
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  left: -2.4rem;
  right: -2.4rem;
  top: -0.5rem;
  padding: var(--measure-1x) var(--measure-2x);
  position: fixed;
  z-index: 1141;
  @media (min-width: $screen-sm) {
    flex-flow: row wrap;
    justify-content: space-between;
  }
  @media (min-width: $screen-sm) {
    top: 1.6rem;
    left: -1.6rem;
    right: -1.6rem;
  }
  @media (min-width: $screen-md) {
    top: 1.6rem;
    left: -1.6rem;
    right: -1.6rem;
  }
  @media (min-width: $screen-lg) {
    top: 0;
    left: 0;
    right: 0;
  }

  &__svg-icon-box {
    display: none;

    @media (min-width: $screen-sm) {
      display: inline-block;
      justify-content: stretch;

      fill: currentColor;
      height: var(--measure-2x);
      width: var(--measure-2x);
      margin-right: var(--measure-1x);
    }
  }

  &__type {
    font-size: 1.8rem;
    line-height: 1.8rem;
    vertical-align: middle;
  }

  &__title {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    font-size: var(--measure-2x);
    color: var(--color-primary-700);
    font-weight: 400;
    @media (min-width: $screen-sm) {
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }

    .push-down-alert__svg-icon-box {
      margin-right: var(--measure-1x);
    }
  }

  &__closer {
    position: absolute;
    top: 1.2rem;
    right: 1.6rem;
    height: 1rem;
    width: 1rem;

    @media (min-width: $screen-sm) {
      margin-top: 0;
      z-index: 1200;
      height: 2rem;
      width: 2rem;
    }
  }

  &__content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    @media (min-width: $screen-sm) {
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }

    > a {
      margin-top: var(--measure-1x);
      width: 100%;
      @media (min-width: $screen-sm) {
        margin-top: 0;
        width: min-content;
      }
    }
  }
}

@keyframes fadeInFromTop {
  95% {
    transform: scaleY(1.2) translateY(-0.4rem);
  }

  100% {
    transform: none;
  }
}
