select {
  &.clean-select {
    padding: 0.5rem;
    border: 0;
    background: url("/assets/img/control_down.svg") 110% center no-repeat;
    background-size: 2rem auto;
    color: $brand-primary;
    font-size: 1.4rem;
    width: 100%;
  }
}
