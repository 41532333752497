.list-container {
  width: 100%;
  height: auto;
  display: block;
  padding: var(--measure-2x);
  margin-top: var(--measure-1x);
  border: 0.1rem solid $global-border;
  border-radius: 0.4rem;
  clear: both;

  &__line-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: var(--measure-1x);
    font-size: 1.4rem;
  }

  &__delete-button {
    width: 1.6rem;
    height: 1.6rem;
    fill: var(--color-state-danger);
    margin-right: var(--measure-1x);
  }
}

.line-item-fade-in.ng-move,
.line-item-fade-in.ng-enter,
.line-item-fade-in.ng-leave {
  -webkit-transition: all linear 0.5s;
  transition: all linear 0.5s;
}

.line-item-fade-in.ng-leave.ng-leave-active,
.line-item-fade-in.ng-move,
.line-item-fade-in.ng-enter {
  opacity: 0;
}

.line-item-fade-in.ng-leave,
.line-item-fade-in.ng-move.ng-move-active,
.line-item-fade-in.ng-enter.ng-enter-active {
  opacity: 1;
}
