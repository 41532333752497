/* ========================================================================
     Component: typo
 ========================================================================== */

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,400italic");

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  color: $dmp-text-standard;
  font-size: 1.2rem;
  line-height: 1.52857143;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  font-weight: 400;
}

h1,
.h1 {
  font-size: 2.8rem;
}

h2,
.h2 {
  font-size: 2.4rem;
}

h3,
.h3 {
  font-size: 2rem;
}

h4,
.h4 {
  font-size: 1.8rem;
}

h5,
.h5 {
  font-size: 1.4rem;
}

h6,
.h6 {
  font-size: 1.2rem;
}

.has-error {
  @include form-control-validation(
    $color-danger-500,
    $color-danger-500,
    $color-danger-500
  );
  &--align-right {
    text-align: right;
  }
  &--sm {
    font-size: var(--measure-font-10);
  }
}
