.switch-overrides {
  line-height: 1;
}

.btn-override > span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-override {
  font-size: 1em;
  vertical-align: middle;
  line-height: 1;
  margin-right: var(--measure-half);
}

@media (max-width: 767px) {
  /* Using inversed to implicity default back to previous display */
  .switch-overrides {
    display: block;
  }
}

@media (min-width: 600px) {
  .form-sections {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
  }

  .form-section {
    flex: 1 0 auto;
    width: calc(50% - var(--measure-12));
    max-width: calc(50% - var(--measure-12));
    padding: 0 var(--measure-1x);
  }
}

@media (min-width: 768px) {
  .switch-overrides {
    line-height: 1;
    display: block;
  }
}

@media (min-width: 1024px) {
  .door-label {
    padding-left: var(--measure-1x);
  }

  .door-field {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .door-field > :nth-child(1) {
    flex: 1 0 38%;
    max-width: 38%;
    text-align: right;
    padding: 0 var(--measure-20);
  }
}
