// Main Core

// Create the row
.row-flex {
  @include make-row-flex();
}

// Default grid
@include make-grid-flex-columns();

// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

@include make-aligment(xs);
@include make-grid-flex(xs);

// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: $screen-sm-min) {
  @include make-aligment(sm);
  @include make-grid-flex(sm);
}

// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: $screen-md-min) {
  @include make-aligment(md);
  @include make-grid-flex(md);
}

// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: $screen-lg-min) {
  @include make-aligment(lg);
  @include make-grid-flex(lg);
}
