@mixin preview-img-style {
  display: inline-block;
  height: 100%;
}

.image-uploader {
  &.image-display-area {
    border: 0.2rem dashed $global-border;
    padding: 1.5rem;
    border-radius: 1.2rem;
    text-align: center;
    vertical-align: middle;
    height: 10rem;
  }
  &.dark {
    background-color: $tinted-black;
  }
  &.light {
    background-color: inherit;
  }

  &__preview-image {
    &--light {
      @include preview-img-style;
    }

    &--dark {
      @include preview-img-style;
    }
  }
}

.dark-bg-preview-area {
  background: transparent url("/assets/img/vkmobile@2x.png") no-repeat center
    top;
  background-size: contain;
  min-height: 700px;
  position: relative;

  .dark-bg-logo {
    position: absolute;
    display: inline-block;
    top: 4.8rem;
    right: 14rem;
    width: 17rem;
  }
}

.light-bg-preview-area {
  background: transparent url("/assets/img/vk-desktop@2x.png") no-repeat center
    top;
  background-size: contain;
  min-height: 700px;
  position: relative;

  .light-bg-logo {
    position: absolute;
    display: inline-block;
    top: 1.5rem;
    left: 11.2rem;
    width: 15rem;
    height: auto;
  }
}
