@media only screen and (max-width: $mq-tablet) {
  div[class^="col-"].no-padding-mobile {
    @include no-padding-lr;
  }
}

.h3--span--reposition {
  position: relative;
  bottom: -2px;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}
li.test .panel-heading {
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 25, 102, 0.8);
  animation: pulse 2s;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 25, 102, 0.8);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 25, 102, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 25, 102, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 25, 102, 0.8);
    box-shadow: 0 0 0 0 rgba(0, 25, 102, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(0, 25, 102, 0);
    box-shadow: 0 0 0 10px rgba(0, 25, 102, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 25, 102, 0);
    box-shadow: 0 0 0 0 rgba(0, 25, 102, 0);
  }
}

.wrapper > footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid #ccc;
  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 1);
  z-index: 109;
}
