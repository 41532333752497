@mixin closed-state($opacity: 1) {
  &--closed {
    background-color: var(--color-neutral-200);
    opacity: $opacity;
  }
}

.service-request {
  position: relative;
  width: 100%;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
      0,
      1fr
    ) max-content;
  grid-template-areas: "one one" "two two" "three three" "four four" "five five";

  padding-bottom: var(--measure-1x);
  margin-bottom: var(--measure-3x);
  background-color: white;
  @include global-border;

  @media screen and (min-width: $screen-sm) {
    width: 100%;
    grid-template-areas: "one one one" "two two two" "three four five";

    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 0;
    justify-content: flex-start;
    align-items: center;
  }

  @media screen and (min-width: $screen-lg) {
    width: 100%;
    grid-template-areas: "one two three four five";
    grid-template-columns: 20rem minmax(20px, 1fr) minmax(20px, 1fr) minmax(
        20px,
        1fr
      ) 1fr;
    grid-template-rows: 1fr;
    grid-gap: var(--measure-2x);
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 0;
    margin-bottom: var(--measure-2x);
  }

  &__modal {
    &__read-only {
      background-color: var(--color-neutral-100);
      border: 0.1rem solid var(--color-neutral-300);
      border-radius: 0.4rem;
      padding: var(--measure-1x);
      margin-bottom: var(--measure-1x);
      display: grid;
      grid-row-gap: var(--measure-1x);
      grid-template-areas: "top-left top-middle right" "bottom-left bottom-middle right";
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      font-size: var(--measure-font-14);
      align-items: start;

      &__area-one {
        grid-area: top-left;
      }

      &__area-two {
        grid-area: top-middle;
      }

      &__area-three {
        grid-area: bottom-left;
      }

      &__area-four {
        grid-area: bottom-middle;
      }

      &__area-five {
        grid-area: right;
      }
    }
  }

  @include closed-state;

  &__item-divider {
    font-size: var(--measure-font-12);
    font-weight: var(--font-weight-medium);
    color: white;
    padding: var(--measure-1x) var(--measure-2x);
    margin-bottom: var(--measure-1x);
    background-color: var(--color-primary-500);
    border: 0.1rem solid var(--color-primary-300);
    border-radius: 0.4rem;
  }

  &__area-one,
  &__area-two,
  &__area-three,
  &__area-four,
  &__area-five {
    padding: var(--measure-half) var(--measure-2x);
  }

  &__area-one {
    grid-area: one;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: var(--measure-1x) var(--measure-1x);

    background-color: var(--color-neutral-200);
    //min-width: 14rem;
    align-self: start;

    &--closed {
      background-color: var(--color-neutral-200);
      opacity: 0.5;
      padding: var(--measure-1x) 0 !important;
    }

    @media screen and (min-width: $screen-md) {
      flex-direction: column;
      justify-content: center;
    }
    @media screen and (min-width: $screen-lg) {
      flex-direction: column;
      justify-content: center;
      padding: var(--measure-2x) 0;
    }

    &-srv-type {
      font-size: var(--measure-font-10);
      font-weight: var(--font-weight-regular);
      color: var(--color-neutral-800);
    }

    &-ref-date {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    &-icon {
      height: 2.6rem;
      width: 2.6rem;
      display: block;
      fill: var(--color-neutral-800);

      .service-progress__icon {
        width: 2.6rem;
        height: 2.6rem;
      }

      &-type {
        grid-area: icon-group;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: start;
        //min-width: 6rem;

        @media screen and (min-width: $screen-md) {
          margin-right: 0;
        }
      }

      &-srv-type {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-right: var(--measure-1x);
        @media screen and (min-width: $screen-md) {
          margin-right: 0;
        }
      }
    }

    &-date {
      grid-area: service-date;
      font-size: var(--measure-font-24);
      font-weight: var(--font-weight-thin);
      line-height: 1;
      align-self: center;

      //justify-self: center;

      @media screen and (min-width: $screen-md) {
        margin-right: 0;
        font-size: var(--measure-font-24);
        margin-left: 0;
      }

      &--label {
        font-size: var(--measure-font-10);
      }
    }

    &__subgrid {
      display: grid;
      grid-template-rows: max-content;
      grid-template-columns: 10rem max-content;
      grid-column: 1 / span 2;
      justify-content: start;
      align-content: start;
      grid-template-areas: "icon-group service-date";
      align-self: start;
      grid-column-gap: 1.6rem;

      &__request-id {
        font-size: var(--measure-font-14);
        font-weight: var(--font-weight-regular);
        line-height: 1;
        grid-area: request-id;
        align-self: start;
      }
    }
  }

  &__area-two {
    grid-area: two;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    flex-wrap: wrap;
    @include closed-state(0.5);
  }

  &__area-three {
    grid-area: three;
    @include closed-state(0.5);
  }

  &__area-four {
    grid-area: four;
    @include closed-state(0.5);
  }

  &__area-five {
    grid-area: five;
    justify-self: end;
    @media (min-width: $screen-sm) {
      // background-color: var(--color-neutral-200);
    }

    &__flex-column {
      position: absolute;
      top: 0.8rem;
      right: 1.6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      @media screen and (min-width: $screen-lg) {
        position: relative;
        display: flex;
        top: 0;
        right: 0;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
      }
      display: flex;
    }
  }

  &__personnel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @include truncate;
    font-size: var(--measure-font-14);
    @media screen and (min-width: $screen-md) {
      font-size: var(--measure-font-14);
    }

    &-pic {
      height: var(--measure-3x);
      width: var(--measure-3x);

      position: relative;
      margin-bottom: var(--measure-1x);
      margin-right: var(--measure-2x);

      > img {
        position: relative;
        width: var(--measure-3x);
        height: var(--measure-3x);

        border-radius: 3rem;
        -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 3px #999;
        -moz-box-shadow: 0 0 0 2px #fff, 0 0 0 3px #999;
        box-shadow: 0 0 0 2px #fff, 0 0 0 3px #999;
      }
    }

    &-name {
      margin-top: var(--measure-1x);
      @media screen and (min-width: $screen-lg) {
        margin-top: 0;
      }
    }

    &-email {
      font-size: var(--measure-font-10);
    }
  }

  &__customer-info {
    display: flex;
    flex-direction: column;
    font-size: var(--measure-font-12);
    @include truncate;

    &-item {
      //@include truncate;

      &-emphasis {
        font-size: var(--measure-font-12);
        font-weight: var(--font-weight-semibold);
        @media screen and (min-width: $screen-lg) {
        }
      }
    }
  }

  &__system-info,
  &__system-info-location {
    font-size: var(--measure-font-12);
    // padding: var(--measure-1x) var(--measure-2x);

    &-name {
      display: block;
      //max-width: 90%;
      font-weight: var(--font-weight-semibold);

      @media (min-width: $screen-lg) {
        @include truncate;
      }
    }

    &-model {
      @include truncate;
    }
  }

  &__system-info-location {
    @media (min-width: $screen-md) and (max-width: $screen-lg-min) {
      display: none;
    }
  }

  &__action-area {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    top: 1.6rem;
    // transform: translateY(-50%);
    right: var(--measure-2x);
    @media screen and (min-width: $screen-lg) {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      top: 50%;
      transform: translateY(-50%);
    }

    &-dropdown-container {
      transform: translate(-100%, -50%);
    }
  }

  &__created-by {
    @media screen and (min-width: $screen-lg) {
      margin-bottom: var(--measure-half);
    }
  }

  &-label,
  .created-byline {
    font-size: var(--measure-font-10);
    font-weight: var(--font-weight-thin);
    white-space: nowrap;
  }

  &-data {
    font-size: var(--measure-font-12);
    font-weight: var(--font-weight-regular);

    &-emphasis {
      font-size: var(--measure-font-12);
      font-weight: var(--font-weight-semibold);
      @include truncate;
    }
  }

  &__note {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: var(--measure-2x);

    &__id-line {
      font-size: var(--measure-font-14);

      &-name {
        font-weight: var(--font-weight-bold);
        color: var(--color-primary);
      }

      &-date {
        font-weight: var(--font-weight-medium);
        color: var(--color-neutral-700);
      }
    }

    &__body {
      font-size: var(--measure-font-14);
    }
  }

  &__notes-well {
    width: 100%;
    height: auto;
    min-height: 30rem;
    max-height: 12rem;
    overflow-y: auto;
    background-color: var(--color-neutral-100);
    border: 1px solid var(--color-neutral-400);
    color: var(--color-neutral-900);
    border-radius: 4px;
    padding: var(--measure-1x);
    -webkit-box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    font-weight: var(--font-weight-medium);
  }
}

.add-note-btn {
  position: relative;
  top: 0.5rem;
  right: -0.5rem;
  //transform: translateY(-50%);
}

.flex-row {
  display: flex;
  &--center--center {
    justify-content: center;
    align-items: center;
  }
  &-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &--responsive {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      @media (min-width: $screen-md) {
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  &-flex-end {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-evenly;
    align-items: center;

    > * {
      &:not(:last-child) {
        display: block;
        margin-bottom: var(--measure-2x);
      }
    }

    @media (min-width: $screen-sm) {
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;
      > * {
        &:not(:last-child) {
          display: inline;
          margin-bottom: 0;
          margin-right: var(--measure-1x);
        }
      }
    }
  }
  &-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &--responsive {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      > * {
        margin-bottom: var(--measure-1x);
        width: 100%;
      }

      @media (min-width: $screen-sm) {
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
      }
      @media (min-width: $screen-md) {
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}

.audit-table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-gap: var(--measure-1x);

  &__col-header {
  }

  &_col-data {
  }

  &__col-1 {
  }

  &__col-3 {
  }

  &__col-3 {
  }
}

@mixin generate-truncate() {
  @for $int from 1 through 10 {
    $value: $int * 10;
    $width-percent: $value * 1%;

    .truncate-text-#{$value} {
      width: $width-percent;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .truncate-text-#{$value + 120}-px {
      width: $value + 120+0px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

@include generate-truncate();
