/* Buzz Out */
@include keyframes(#{$nameSpace}-buzz-out) {
  10% {
    @include prefixed(transform, translateX(3px) rotate(2deg));
  }

  20% {
    @include prefixed(transform, translateX(-3px) rotate(-2deg));
  }

  30% {
    @include prefixed(transform, translateX(3px) rotate(2deg));
  }

  40% {
    @include prefixed(transform, translateX(-3px) rotate(-2deg));
  }

  50% {
    @include prefixed(transform, translateX(2px) rotate(1deg));
  }

  60% {
    @include prefixed(transform, translateX(-2px) rotate(-1deg));
  }

  70% {
    @include prefixed(transform, translateX(2px) rotate(1deg));
  }

  80% {
    @include prefixed(transform, translateX(-2px) rotate(-1deg));
  }

  90% {
    @include prefixed(transform, translateX(1px) rotate(0));
  }

  100% {
    @include prefixed(transform, translateX(-1px) rotate(0));
  }
}

@mixin buzz-out {
  @include hacks();

  &:hover,
  &:focus,
  &:active {
    @include prefixed(animation-name, #{$nameSpace}-buzz-out);
    @include prefixed(animation-duration, 0.75s);
    @include prefixed(animation-timing-function, linear);
    @include prefixed(animation-iteration-count, 1);
  }
}
