.container {
  width: 100%;
  overflow: hidden;
}

.screen {
  width: 100%;
}

.actions {
  width: 100%;
  padding: var(--measure-2x);
  text-align: right;
}
