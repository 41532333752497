.user-code-table-data {
  @media screen and (max-width: 768px) {
    &:before {
      content: attr(aria-label);
      font-weight: bold;
      width: 120px;
      min-width: 120px;
      text-align: left !important;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.user-code-table-row {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.user-code-table-head {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.user-code-table-icons {
  @media screen and (max-width: 768px) {
    text-align: right !important;
    display: inline-block;
  }
}

.user-code-checkmark {
  @media screen and (max-width: 768px) {
    display: block;
    margin: 0.6rem;
    text-align: left !important;
  }
  @media screen and (min-width: 769px) {
    display: inline;
  }
}

.user-code-mobile-icon {
  @media screen and (max-width: 768px) {
    margin-right: 0 !important;
  }
}
