.dropdown-select-btn {
  position: relative;
  border: 0.1rem solid $color-neutral-500;
  padding: var(--measure-1x) var(--measure-2x);
  display: inline-block;
  text-align: left;
  border-radius: 0.4rem;
  &:after {
    font-family: "dmp_icon_font";
    position: absolute;
    content: "\e981";
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
  }
}
