@mixin full-prog-header-base {
  margin-bottom: var(--measure-2x);
  z-index: 1000000;
}

.full-programming-header {
  @include full-prog-header-base();

  &--app {
    @include full-prog-header-base();
  }

  &__title {
    font-size: 1.6rem;
    line-height: var(--measure-3x);
    @media screen and (min-width: $screen-sm) {
      margin-right: 0.8rem;
      font-size: var(--measure-3x);
    }
  }

  &__select {
    display: flex;

    cursor: pointer;
    font-size: 1.4rem;
    color: $primary-color;
    padding: 0.4rem 0.8rem;
    border: 1px solid $primary-color;
    @media screen and (min-width: $screen-sm) {
      margin-left: 0.8rem;
    }
  }
}

.full-programming-header__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--measure-2x) 0;
  background-color: $body-bg;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  .full-programming-header & {
    top: 7.5rem;
  }

  @media screen and (min-width: $screen-lg) {
    flex-direction: row;
  }
}
