.input-group-addon {
  background: $dmp-light-gray;
}

.time-edit-grid {
  div[class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}

.concept-dropdown-area {
  p.h3 {
    cursor: pointer;
  }
}

@media only screen and (max-width: 991px) {
  .time-edit-grid {
    div[class*="col-"] {
      .input-group {
        margin-bottom: 1em;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .time-edit-grid {
    div[class*="col-"]:nth-child(odd) {
      padding-right: $padding-large;
    }
  }
}

.input-group-addon {
  min-width: 75px;
  text-align: left;
}

select.select-width-limit {
  width: 25% !important;
}

.button-header-div {
  padding: 0.5rem;
}

.panel-heading.panel-heading-border-bottom {
  border-bottom: 1px solid #e4eaec;
}

@media screen and (min-width: 1150px) {
  span.h3.pr-lg.divider-right {
    border-right: 2px solid #656565;
  }
}

.accordion-panel {
  margin-bottom: 0;
}

.multi-panel-list {
  max-height: 200px;
  overflow-y: scroll;
}

.btn-inline-schedule {
  padding-left: 6px;
  padding-right: 6px;
}
.input-group-addon.input-group-addon-narrow {
  padding: 0 6px;
  min-width: 53px;
}
.dropdown-menu.dropdown-menu-schedule {
  left: 15px;
  padding: 6px;
}
