/* ========================================================================
     Component: typo
 ========================================================================== */

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,400italic");

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  color: #656565;
  font-size: 12px;
  line-height: 1.52857143;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
}
