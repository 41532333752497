.text-danger-dmp {
  color: $dmp-red !important;
}

.text-danger-ada {
  color: $dmp-red-ada !important;
}

.text-success-ada {
  color: $dmp-green-ada !important;
}

.text-primary-ada {
  color: $dmp-blue-ada !important;
}

.text-black {
  color: black !important;
}

.bg-primary-dmp {
  background: $astral;
  color: $dmp-white-inverse;
}

.bg-dk-gray {
  background-color: $dmp-dark-gray;
}
.is-active-state {
  background: $navbar-is-active-bg;
  color: $navbar-is-active-color;
  transition: all 1s ease;
  > a {
    > svg {
      fill: $navbar-is-active-color;
    }
  }
}
.is-active-account {
  background: $navbar-is-active-bg;
  color: $navbar-is-active-color;
  transition: all 1s ease;

  > a {
    > svg {
      fill: $navbar-is-active-color;
    }
  }
}

a.no-underline {
  text-decoration: none;
  cursor: pointer;
}
.text-info {
  background: $state-primary;
  color: $dmp-white-inverse;
}
.text-info-foreground {
  background: inherit;
  color: $state-primary;
}
.text-blue {
  color: $state-primary;
}

.text-highlighted {
  vertical-align: middle;
  color: $accent-blue-link;
  font-size: 14px;
  font-weight: bold;
}

.text-strong {
  font-size: 14px;
  font-weight: bold;
}
.table > tbody > tr > td.text-table-disabled {
  color: $gray-table-disabled;
}
.table > tbody > tr.text-table-enabled > td {
  color: inherit;
  font-weight: var(--font-weight-semibold) !important;
  font-style: normal !important;
}

.table > tbody > tr.text-table-disabled-add > td,
.table > tbody > tr.text-table-disabled-remove > td {
  -webkit-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  -moz-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  -o-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
}

.table > tbody > tr.text-table-disabled > td,
.table > tbody > tr.text-table-disabled-active > td {
  color: $gray-table-disabled;
  font-style: oblique;
}

.table
  > tbody
  > tr.text-table-disabled-remove.text-table-disabled-remove-active
  > td {
  color: black;
}

span.table-header {
  font-weight: var(--font-weight-thin);
  font-size: var(--measure-font-14);
}

.text-strong {
  font-size: 20px;
  font-weight: bold;
}

.progress {
  background-color: #b3b3b3;
}
