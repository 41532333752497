.system-image-gallery {
  display: grid;
  place-items: center stretch;

  padding: var(--measure-2x);

  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: repeat (2, minmax(min-content, 165px));
  grid-auto-flow: dense;
  grid-gap: var(--measure-2x);

  justify-items: center;

  &__image {
    width: 100%;
    height: auto;
    max-height: 165px;
    //margin:           8px;
    box-shadow: 2px 2px 45px -10px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    object-fit: cover;
    background-color: #404040;
    &:hover {
      -webkit-transform: scale(1.02);
      -moz-transform: scale(1.02);
      -ms-transform: scale(1.02);
      -o-transform: scale(1.02);
      transform: scale(1.02);
      transform-origin: center;
      box-shadow: 4px 4px 50px -20px rgba(0, 0, 0, 0.4);
      box-shadow: 2px 2px 45px -10px rgba(0, 0, 0, 0.4);
    }
  }
}
