/* Round Corners */
@mixin round-corners {
  @include hacks();
  @include prefixed(transition-duration, $mediumDuration);
  @include prefixed(transition-property, border-radius);

  &:hover,
  &:focus,
  &:active {
    border-radius: 1em;
  }
}
