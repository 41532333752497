/**
 * ==============================================
 * Dot Elastic
 * ==============================================
 */
@mixin dot {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  background-color: var(--color-neutral-500);
}
.dot-elastic {
  position: relative;
  margin-left: 1rem;
  @include dot;

  animation: dotElastic 1s infinite linear;
  &:before,
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &:before {
    left: -1rem;
    @include dot;
    animation: dotElasticBefore 1s infinite linear;
  }

  &:after {
    left: 1rem;
    @include dot;
    animation: dotElasticAfter 1s infinite linear;
  }
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
    background-color: var(--color-neutral-500);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
    background-color: var(--color-primary-500);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
    background-color: var(--color-neutral-500);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
    background-color: var(--color-primary-500);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
    background-color: var(--color-neutral-500);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
    background-color: var(--color-primary-500);
  }
  100% {
    transform: scale(1, 1);
  }
}
