@mixin badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.4rem 0.8rem;
  font-size: 1.2rem;
  font-weight: var(--font-weight-medium);
  line-height: 1;
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 100px;
  //border: .2rem solid white;
  position: relative;
  top: -10px;
  right: -8px;
  //@include box_shadow(1);
}

.badge {
  &-primary {
    @include badge;
    position: relative;
    background-color: var(--color-primary-300);
    border: 0.1rem solid darken($color-primary-300, 5%);
    color: white;
    z-index: 3;

    &--with-pulse {
    }

    &:after {
      display: flex;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      //background-color: var(--color-primary-200);
      border-radius: 100px;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all 0.4s;
      opacity: 1;
      animation: pulse 2s 1s forwards;
      animation-iteration-count: 2;
      box-shadow: 0 0 0 rgba(95, 195, 255, 0.4);
    }
  }

  &-info {
    @include badge;
    position: relative;
    background-color: var(--color-info-300);
    border: 0.1rem solid darken($color-info-300, 5%);
    color: white;
    z-index: 3;

    &--with-pulse {
    }

    &:after {
      display: flex;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      //background-color: var(--color-primary-200);
      border-radius: 100px;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all 0.4s;
      opacity: 1;
      animation: pulse 2s 1s forwards;
      animation-iteration-count: 2;
      box-shadow: 0 0 0 rgba(130, 205, 228, 0.4);
    }
  }

  &-success {
    @include badge;
    position: relative;
    background-color: var(--color-success-300);
    border: 0.1rem solid darken($color-success-300, 5%);
    color: white;
    z-index: 3;

    &--with-pulse {
    }

    &:after {
      display: flex;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      //background-color: var(--color-primary-200);
      border-radius: 100px;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all 0.4s;
      opacity: 1;
      animation: pulse 2s 1s forwards;
      animation-iteration-count: 2;
      box-shadow: 0 0 0 rgba(77, 184, 161, 0.4);
    }
  }
  &-neutral {
    @include badge;
    position: relative;
    background-color: var(--color-neutral-200);
    border: 0.1rem solid var(--color-neutral-300);
    color: var(--color-neutral);
    z-index: 3;
    position: relative;
    top: 0;
    right: 0;
    padding: 0.8rem 1.6rem;
  }
  &-warning {
    @include badge;
    position: relative;
    background-color: var(--color-warning-300);
    border: 0.1rem solid darken($color-warning-300, 5%);
    color: darken($color-warning-900, 20%);
    z-index: 3;

    &--with-pulse {
    }

    &:after {
      display: flex;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      //background-color: var(--color-primary-200);
      border-radius: 100px;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all 0.4s;
      opacity: 1;
      animation: pulse 2s 1s forwards;
      animation-iteration-count: 2;
      box-shadow: 0 0 0 darken($color-warning-900, 20%);
    }
  }

  &-danger {
    @include badge;
    position: relative;
    background-color: var(--color-danger-300);
    border: 0.1rem solid darken($color-danger-300, 5%);
    color: white;
    z-index: 3;

    &--with-pulse {
    }

    &:after {
      display: flex;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      //background-color: var(--color-primary-200);
      border-radius: 100px;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all 0.4s;
      opacity: 1;
      animation: pulse 2s 1s forwards;
      animation-iteration-count: 2;
      box-shadow: 0 0 0 rgba(246, 130, 149, 0.4);
    }
  }
  &-sidebar {
    position: relative;
    top: -0.1rem;
  }

  // [converter] extracted a& to a.label

  // Empty labels collapse automatically (not available in IE8)
  &:empty {
    display: none;
  }

  // Quick fix for labels in buttons
  .btn & {
    position: relative;
    top: -1px;
  }
}

@keyframes pulse {
  0%,
  50% {
    transform: none;
    opacity: 1;
  }
  25%,
  100% {
    -webkit-transform: scale(3);
    -moz-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(95, 195, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(95, 195, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(95, 195, 255, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(95, 195, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(95, 195, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(95, 195, 255, 0);
    box-shadow: 0 0 0 10px rgba(95, 195, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(95, 195, 255, 0);
    box-shadow: 0 0 0 0 rgba(95, 195, 255, 0);
  }
}
