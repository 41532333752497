/* Outline In */
@mixin outline-in {
  $outerBorderWidth: 4px;
  $innerBorderWidth: 4px;

  @include hacks();
  position: relative;

  &:before {
    pointer-events: none;
    content: "";
    position: absolute;
    border: $primaryColor solid $outerBorderWidth;
    top: -($outerBorderWidth + $innerBorderWidth) * 2;
    right: -($outerBorderWidth + $innerBorderWidth) * 2;
    bottom: -($outerBorderWidth + $innerBorderWidth) * 2;
    left: -($outerBorderWidth + $innerBorderWidth) * 2;
    opacity: 0;
    @include prefixed(transition-duration, 0.3s);
    @include prefixed(transition-property, "top, right, bottom, left");
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    top: -($outerBorderWidth + $innerBorderWidth);
    right: -($outerBorderWidth + $innerBorderWidth);
    bottom: -($outerBorderWidth + $innerBorderWidth);
    left: -($outerBorderWidth + $innerBorderWidth);
    opacity: 1;
  }
}
