//my custom
// Spinning Icons
// --------------------------

.#{$icon-css-prefix}__spin {
  -webkit-animation: spinning 2s infinite linear;
  animation: spinning 2s infinite linear;
}

.#{$icon-css-prefix}__pulse {
  -webkit-animation: spinning 1s infinite steps(8);
  animation: spinning 1s infinite steps(8);
}

@-webkit-keyframes spinning {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spinning {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
