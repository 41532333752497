//Includes styles for customer summary top section and modal.

@mixin email-style {
  font-size: 1.5rem;
  font-weight: 600;
}

//for customer summary only
.cell-ellipsis {
  @include ellipsis-cell(100%);

  &--10 {
    @include ellipsis-cell(10%);
  }

  &--20 {
    @include ellipsis-cell(20%);
  }

  &--30 {
    @include ellipsis-cell(30%);
  }

  &--40 {
    @include ellipsis-cell(40%);
  }

  &--50 {
    @include ellipsis-cell(50%);
  }

  &--60 {
    @include ellipsis-cell(60%);
  }

  &--70 {
    @include ellipsis-cell(70%);
  }

  &--80 {
    @include ellipsis-cell(80%);
  }

  &--90 {
    @include ellipsis-cell(90%);
  }
}

.color {
  &--below-avg {
    color: $color-below-avg;
  }

  &--average {
    color: $color-average;
  }

  &--good {
    color: $color-good;
  }

  &--great {
    color: $color-great;
  }

  &--excellent {
    color: $color-excellent;
  }
}

.customer-card {
  &__panel {
    width: 100%;
    background-color: white;
    border-radius: 0.4rem;
    border: 0.1rem solid $global-border;
    margin-bottom: var(--measure-3x);

    &-header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: var(--measure-font-18);
      padding: var(--measure-2x) var(--measure-3x);
      background: #f5f7f9;
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
      @media screen and (min-width: $screen-sm) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-body {
      padding: 0.8rem 0.8rem var(--measure-2x);
    }

    &-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: $screen-sm) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }

      > .feedback-notifier {
        margin: var(--measure-1x);
        @media screen and (min-width: $screen-sm) {
          margin: 0 0 0 var(--measure-1x);
        }
      }
    }
  }

  &__detail {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
    flex-grow: 0;
    min-width: 0;
    line-height: 1.6rem;
    font-size: var(--measure-font-14);

    > * {
      &:last-child {
        border-right: 0.1rem solid transparent;
        border-bottom: 0.1rem solid transparent;
      }
    }

    @media screen and (min-width: $screen-sm) {
      grid-template-columns: repeat(auto-fill, minmax(1%, 275px));
      grid-template-rows: 1fr;
      justify-content: flex-start;
      line-height: 1.5;
      margin-top: var(--measure-1x);
    }

    &-field-group {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: var(--measure-1x);
      @media screen and (min-width: $screen-sm) {
        align-items: flex-start;
      }
    }

    &-left,
    &-center,
    &-right,
    &-far-right,
    &-customer-score {
      flex-grow: 0;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: var(--measure-1x);
      border-bottom: 1px solid $divider-color;

      min-height: 12rem;
      @media screen and (min-width: $screen-sm) {
        justify-content: flex-start;
        align-items: flex-start;
        border-right: 1px solid $divider-color;
        border-bottom: 0;
        padding: var(--measure-2x);
        min-height: 12rem;
      }
      &__list {
        display: flex;
        flex-flow: column nowrap;
        &__list-item {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          min-width: 26rem;
        }
      }
    }

    &-customer-score {
      &__widget {
        // scale: 80%;
        display: grid;
        grid-template-rows: min-content min-content min-content;
        grid-template-columns: max-content;
        grid-template-areas:
          "score-title" "score-progress-bar"
          /**/ "score-rating";
        justify-items: center;

        // background-color: var(--color-primary-100);

        border-radius: var(--measure-1x);

        &__radial-progress {
          grid-area: score-progress-bar;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: var;
        }

        &__title {
          grid-area: score-title;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          font-size: var(--measure-font-16);
          font-weight: var(--font-weight-thin);

          &:first-child {
            margin-right: var(--measure-1x);
          }
        }

        &__rating {
          grid-area: score-rating;
        }
      }
    }

    &-label {
      font-weight: 800;
      color: var(--dark-gray);
      font-size: var(--measure-font-14);
      margin-right: var(--measure-1x);

      &--big {
        font-weight: var(--font-weight-semibold);
      }

      &-icon {
        font-size: 1.4rem;
        vertical-align: center;
        cursor: pointer;
        color: var(--color-primary-600);
      }
    }

    &-pill {
      font-size: 12px;
      padding: 0.5em 1em;
      margin: 0.25em;
      border-radius: 2em;
      border: none;
      outline: none;
      background: hsl(0, 0%, 90%);
      cursor: default;
    }
  }

  &__name {
    font-size: 1.8rem;
    justify-content: space-between;
    align-items: center;
    display: flex;
    font-weight: 700;
    margin-bottom: var(--measure-1x);
  }

  &__address,
  &__communication-paths,
  &__options {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    line-height: 1.6;
    margin-right: var(--measure-1x);

    &-subheader {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: 200;
      margin-bottom: 0.4rem;
      color: lighten($text-global, 20%);
    }
  }
}

.app-user-cell {
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-basis: 0;
  max-width: 250px;

  &__sub-row {
    //display: flex;
    //flex-direction: row;
    //justify-content: flex-start;
    //align-items: flex-start;
    color: $color-deemphasis;
  }

  &__email {
    @include email-style;
  }
}

.status-indicator {
  &--locked {
    color: $brand-danger;
  }
}

.customer-card__detail-score--list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr min-content;
  grid-column-gap: var(--measure-3x);
  grid-row-gap: var(--measure-2x);
  width: 100%;
  padding-left: 0;
  grid-template-areas: "col-1 col-2" "subnote subnote";
  &__col-1,
  &__col-2 {
    display: grid;
    grid-template-rows: repeat(4, minmax(var(--measure-2x), 7rem));

    grid-template-columns: 1fr;
    grid-row-gap: var(--measure-2x);
  }

  &__col-1 {
    grid-area: col-1;
  }

  &__col-2 {
    grid-area: col-2;
  }

  .customer-card__detail-score--list-item {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, min-max(2rem, max-content));
    grid-template-areas: "score-item-title" "score-item-description";
    grid-row-gap: var(--measure-half);

    &-icon {
      font-size: var(--measure-font-12);
      display: flex;
      align-items: center;
      margin-left: 0.8rem;
      color: $color-good;
    }

    &-title {
      grid-area: score-item-title;
      font-weight: var(--font-weight-semibold);
      font-size: var(--measure-font-16);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: 1;

      > i {
        margin-left: 0.8rem;
      }
    }

    &-description {
      grid-area: score-item-description;
      font-size: var(--measure-font-14);
      line-height: 1.4;
      display: flex;
      align-items: flex-start;
    }

    &--subnote-line {
      grid-area: subnote;
      color: var(--color-neutral-700);
      display: flex;
      font-size: (--measure-font-12);
      justify-content: space-between;
      align-items: center;
    }
  }
}
.customer-card__detail-far-right {
  min-width: 26rem;
}

.text-disabled {
  color: var(--dark-gray);
}

customer-card__detail-far-right .personnel__email {
  @include email-style;
}
