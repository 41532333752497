/* enable absolute positioning */
.inner-addon {
  position: relative;
}

/* style glyph */
.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

/* align glyph */
.left-addon .glyphicon {
  left: 0px;
}

.right-addon .glyphicon {
  right: 0px;
}

/* add padding  */
.left-addon input {
  padding-left: 30px;
}

.right-addon input {
  padding-right: 30px;
}

.needsclick.ib-label-for-checkbox {
  display: inline-block;
  padding-left: 2.5rem;
}

@media screen and (min-width: 768px) {
  .needsclick.ib-label-for-checkbox {
    display: inline-block;
    padding-left: 1rem;
  }
}

.z-depth-0 {
  box-shadow: none !important;
}

.z-depth-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.z-depth-1-half {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.z-depth-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.z-depth-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

.hoverable {
  -webkit-transition: box-shadow 0.55s;
  transition: box-shadow 0.55s;
  box-shadow: 0;
  cursor: pointer;
}

.hoverable:hover {
  -webkit-transition: box-shadow 0.45s;
  transition: box-shadow 0.45s;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.view {
  overflow: hidden;
  position: relative;
  cursor: default;
}

.view .mask {
  background-attachment: fixed;
}

.view .mask,
.view .full-bg-img {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}

.view img,
.view video {
  display: block;
  position: relative;
}

.no-wrap {
  white-space: nowrap;
}

.no-m-block-end {
  margin-block-end: 0 !important;
}

//flex-box

.flex {
  display: flex;

  &--between,
  &__between {
    display: flex;
    justify-content: space-between;

    &--center {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > *:not(:last-child) {
        margin-right: var(--measure-1x);
      }
    }
  }

  &--start,
  &__start {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &--center {
      display: flex;
      align-items: center;
    }
    &--baseline {
      align-items: baseline;
    }
    &--wrap {
      flex-wrap: wrap;
    }
  }

  &--around,
  &__around {
    display: flex;
    justify-content: space-around;
    align-items: center;

    &--center {
      justify-content: space-around;
      align-items: center;
      display: flex;
      align-items: center;
    }
  }
  &--center--center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.no-break {
  white-space: nowrap;
}

.show-flex-start-baseline {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.no-wrap {
  white-space: nowrap;
}

.vert-align {
  &-top {
  }
  &-middle {
  }
}

.mb-24 {
  margin-bottom: var(--measure-3x);
}

@include spacing-classes;

footer.mar-h-15-neg {
  margin: 0 -1.5rem !important;
}

@include create-colors-classes;

.text {
  &-info {
    color: var(--color-info-500) !important;
  }

  &-muted {
    color: var($dark-gray);
  }

  &-success {
    color: var(--color-success-500) !important;
  }

  &-warning {
    color: var(--color-warning-500) !important;
  }

  &-warning-high-contrast {
    color: var(--color-state-warning) !important;
  }

  &-danger {
    color: var(--color-danger-500) !important;
  }
}

.flex,
.flex-error,
.flex-checkbox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
@mixin flex-config(
  $main-axis: row,
  $wrap-style: nowrap,
  $justify: flex-start,
  $align: center
) {
  display: flex;
  flex-flow: $main-axis $wrap-style;
  flex-direction: $main-axis;
  justify-content: $justify;
  align-items: $align;
}

.flex {
  &--row {
    &--start {
      @include flex-config(row, nowrap, flex-start, stretch);
      &--baseline {
        @include flex-config(row, nowrap, flex-start, baseline);
        &--wrap {
          @include flex-config(row, wrap, flex-start, baseline);
        }
      }
      &--center {
        @include flex-config(row, nowrap, flex-start, center);
        &--wrap {
          @include flex-config(row, wrap, flex-start, center);
        }
      }
    }

    &--center {
      @include flex-config(row, nowrap, center, stretch);
      &--center {
        @include flex-config(row, nowrap, center, center);
        &--wrap {
          @include flex-config(row, wrap, center, center);
        }
        &--baseline {
          @include flex-config(row, nowrap, center, baseline);
        }
      }
    }
    &--sp-between {
      &--center {
        @include flex-config(row, nowrap, space-between, center);
      }
    }
    &--between {
      &--center {
        @include flex-config(row, nowrap, space-between, center);

        &--mobile {
          @include flex-config(column, nowrap, space-evenly, center);

          @media (min-width: $screen-sm) {
            @include flex-config(row, nowrap, space-between, center);
          }
        }
      }
      &--top {
        @include flex-config(row, nowrap, space-between, first baseline);
        &--mobile {
          @include flex-config(column-reverse, nowrap, space-evenly, center);

          @media (min-width: $screen-sm) {
            @include flex-config(row, nowrap, space-between, first baseline);
          }
        }
      }
    }

    --center-both {
      @include flex-config(row, nowrap, center, center);
    }

    &--end {
      @include flex-config(row, nowrap, flex-end, center);
    }
  }
  &--col {
    &--start {
      @include flex-config(column, nowrap, flex-start, flex-start);
      &--center {
        @include flex-config(column, nowrap, flex-start, center);
        &--wrap {
          @include flex-config(column, wrap, flex-start, center);
        }
      }
    }
  }
}

//positioning
.z-i-base {
  z-index: 1;
}
.pos-rel {
  position: relative !important;
}

.pos-abs {
  position: absolute !important;
}
.pos-fixed {
  position: fixed !important;
}

.no-padding {
  padding: 0 !important;
}

.glyphicon.glyphicon-remove {
  display: none;
}
.height-max-500 {
  max-height: 50rem;
}
@include spacing-classes;

@include percent-widths;

@include create-colors-classes;

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0px, 0px, 0px, 0px);
  margin: -1px;
  padding: 0px;
  overflow: hidden;
}

.no-spin::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.display {
  &--b {
    display: block !important;
  }
  &--ib {
    display: inline-block !important;
  }
}
// remove animation from disapearing form fields to prevent doubling
input,
select {
  &.no-animate.ng-leave.ng-leave-active {
    display: none !important;
  }
}
