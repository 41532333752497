$status-colors: (
  success: #28a745,
  // Green
  fail: #dc3545,
  // Red
  info: #17a2b8,
  // Blue
  default: #6c757d // Gray,,,,,
);
.status-pill {
  display: inline-flex;
  align-items: center;
  padding: 0.2rem 0.4rem;
  border-radius: 3rem;
  font-size: 1rem;
  font-weight: var(--font-weight-normal);
  color: white;
  i {
    margin-right: 5px;
  }
  @each $state, $color in $status-colors {
    &.pill-#{$state} {
      background-color: $color;
    }
  }
}
