/* Back Pulse */
@include keyframes(#{$nameSpace}-back-pulse) {
  50% {
    background-color: rgba(32, 152, 209, 0.75);
  }
}

@mixin back-pulse {
  @include hacks();
  overflow: hidden;
  @include prefixed(transition-duration, $slowDuration);
  @include prefixed(transition-property, "color, background-color");

  &:hover,
  &:focus,
  &:active {
    @include prefixed(animation-name, #{$nameSpace}-back-pulse);
    @include prefixed(animation-duration, 1s);
    @include prefixed(animation-delay, $slowDuration);
    @include prefixed(animation-timing-function, linear);
    @include prefixed(animation-iteration-count, infinite);
    background-color: $activeColor;
    background-color: rgba(32, 152, 209, 1);
    color: white;
  }
}
