.camera-details {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.securecom-add-button {
  padding: 0 12px;
}

.header {
  padding: var(--measure-12) var(--measure-3x);
}

.camera-status {
  font-weight: bold;
}

.grid {
  display: flex;
  flex-direction: column;
}

.grid-content-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem;
  margin-bottom: var(--measure-2x);
}

.video-device-header {
  display: flex;
  padding: 0 var(--measure-12);
  justify-content: space-between;
  align-items: center;
}

.video-device-titles {
  display: flex;
  flex-direction: column;
}

.video-device-title {
  padding: 2px 4px;
}

.video-device-title-value {
  margin-left: 4px;
  font-weight: bold;
}

@media (min-width: 768px) {
  .table-padding {
    padding: 0 var(--measure-3x);
  }

  .video-device-titles {
    flex-direction: row;
    flex: 1 0 75%;
    max-width: 75%;
    justify-content: space-between;
  }

  .video-device-title-value {
    font-weight: normal;
  }
}
