$avatar-size: rem(32px);
$avatar-bg-size: $avatar-size + rem(5px);

@mixin base-google-button {
  position: absolute;
  padding: var(--measure-1x);
  width: max-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 0.2rem solid white;
  border-radius: 0.3rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  text-align: center;
  color: hsl(0, 0%, 10%);
  font-family: $font-family-base;
  font-size: var(--measure-font-16);
  z-index: 1;
}

.tech-track {
  &__recenter-control {
    @include base-google-button;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    bottom: 9.6rem;
    right: 5.9rem;
    transform: translateY(4rem);
    opacity: 0;
    animation: to-end-state 0.4s forwards;
  }

  &__route-control {
    @include base-google-button;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    bottom: 2.4rem;
    right: 5.9rem;
    transform: translateY(4rem);
    animation: to-end-state 0.4s forwards;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    grid-gap: var(--measure-2x);
    grid-template-areas: "map" "list";
    @media (min-width: $screen-sm) {
      grid-template-columns: 0.6666667fr 0.333333fr;
      grid-template-rows: 1fr;
      grid-gap: var(--measure-2x);
      grid-template-areas: "map list";
    }

    &__map-area {
      grid-area: map;
    }

    &__list-area {
      grid-area: list;
    }

    &__technician {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr min-content;
      grid-template-areas: "top" "tech-actions";
      justify-items: start;
      align-items: center;
      grid-gap: var(--measure-1x);
      border-bottom: 0.1rem solid var(--color-neutral-300);
      border-top: 0.1rem solid var(--color-neutral-300);
      padding: var(--measure-1x) var(--measure-1x);
      margin-right: -0.8rem;
      cursor: pointer;

      transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
      border-left: 8px solid transparent;

      &:hover,
      &.selected {
        border-left: 8px solid $color-primary;
      }

      &__top {
        grid-area: top;
        display: inline-grid;
        width: 100%;
        grid-template-columns: min-content 1fr;
        grid-template-rows: minmax(0, min-content);
        grid-template-areas: "tech-pic tech-info";
        grid-gap: var(--measure-1x);
        transform-origin: left center;
        transition: all 0.2s ease-in;
        cursor: pointer;
      }

      &__pic {
        width: $avatar-bg-size;
        height: $avatar-bg-size;
        display: block;
        border: 0.5rem solid white !important;
        border-radius: 50%;
        //margin: var(--measure-5x);
        background-color: white;

        padding: 0.1rem !important;
        //clip-path: circle(100% at 50% 50%);
        -webkit-box-shadow: 0px 8px 10px -4px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 8px 10px -4px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 8px 10px -4px rgba(0, 0, 0, 0.75);
        object-position: center top;
        //border: 4px solid var(--color-neutral-400);
        > img {
          position: relative;
          display: block;
          background-position: center;
          background-size: $avatar-size $avatar-size;
          object-position: center top;
          object-fit: cover;
          width: $avatar-size;
          height: $avatar-size;
          //padding: rem(1px);
          // margin: rem(-1px);
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          vertical-align: middle;
          white-space: nowrap;
          overflow: hidden;
          left: -4px;
          top: -4px;

          transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
        }
      }

      &__info {
        grid-area: tech-info;
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;
        font-weight: var(--font-weight-semibold);
        font-size: var(--measure-font-16);
      }

      &__name {
        font-weight: var(--font-weight-semibold);
        font-size: var(--measure-font-16);
      }

      &__cancel-btn-area {
        grid-area: close;
        position: relative;
        top: -0.6rem;
        right: -0.2rem;
      }

      &__cancel-btn {
        position: relative;
        height: rem(32px);
        width: rem(32px);
        top: 0;
        right: 0;
        background: $color-neutral-500;
        border-radius: 50%;
        border: rem(2px) solid white;

        &:before {
          position: absolute;
          font-family: dmp_icon_font;
          content: "\e979";
          font-size: var(--measure-font-14);
          color: white;
          //transform-origin: center;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &__actions {
        grid-area: tech-actions;
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        padding: var(--measure-1x);
      }

      &__stop-list-area {
        width: 100%;

        &__stop-list {
          &__title {
            font-weight: var(--font-weight-bold);
            padding-bottom: var(--measure-1x);
          }

          &__single-stop {
            display: grid;
            grid-template-columns: minmax(0, min-content) 1fr;
            grid-template-rows: repeat(2, minmax(0, min-content));
            grid-template-areas:
              "index-area time-area"
              "index-area customer-area";
            grid-column-gap: var(--measure-1x);
            grid-row-gap: var(--measure-half);

            border-bottom: 0.1rem solid $global-border;
            padding: var(--measure-1x) 0;
            transition: all 0.2s;

            &:first-of-type {
              border-top: 0.1rem solid $global-border;
            }

            &:hover,
            &.is-selected {
              color: $color-primary;
              border-left: 0.6rem solid $color-primary;
              padding-left: var(--measure-1x);
            }

            &__index-area {
              grid-area: index-area;
            }

            &__time-area {
              grid-area: time-area;
            }

            &__customer-area {
              grid-area: customer-area;
              font-weight: var(--font-weight-bold);
            }
          }
        }
      }
    }
  }
}

.tech,
.system {
  &-info {
    &__heading {
      font-size: var(--measure-font-14);
      font-weight: var(--font-weight-medium);
      line-height: normal;
      color: #333;
    }

    &__list {
      display: flex;
      flex-flow: column nowrap;

      &__item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        line-height: 1.5;
        &--column {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: flex-start;
          line-height: 1.5;
        }

        &--label {
          font-weight: var(--font-weight-thin);
          margin-right: var(--measure-1x);
        }

        &--data {
          font-size: rem(13px);
          font-weight: var(--font-weight-regular);
          color: black;

          &--column {
            font-size: rem(13px);
            font-weight: var(--font-weight-regular);
            color: black;
            display: block;
          }
        }
      }
    }
  }
}

input.gmap-search-filter {
  position: absolute;
  right: 5.8rem;
  top: 1.6rem;
  z-index: 4;
  max-height: 13.2rem;
}

.extra-info-icon {
  font-size: 1.4rem;
  vertical-align: center;
  cursor: pointer;
  color: var(--color-primary-600);
}

.fade-repeat.ng-enter {
  animation: 2s appear;
}
.fade-repeat.ng-enter-stagger {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.fade-repeat.ng-leave {
  animation: 1s disappear;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
