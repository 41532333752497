.camera-map-view {
  input[type="search"] {
    width: 100%;
    margin-bottom: var(--measure-1x);
    flex: 1;
    text-align: center;
    margin-right: var(--measure-1x);
    @media (min-width: $screen-sm) {
      width: 25rem;
      text-align: left;
    }
  }
  .panel {
    .panel-body {
      @media (min-width: $screen-sm) {
        overflow-x: hidden;
      }
    }
  }

  &__table {
    th {
      &:nth-child(1) {
        width: 27rem;
        padding-right: var(--measure-2x);
      }

      &:nth-child(2) {
        width: 15rem;
      }
    }
    td {
      &:nth-child(2) {
      }
    }

    @media (min-width: $screen-sm) {
      overflow-x: hidden;
    }
    td,
    tr {
      //white-space: nowrap;
    }

    tr {
      &.imCheckedTrue {
        opacity: 0.3;
      }

      padding-bottom: 2rem;
    }

    .ui-select-container {
      max-width: 80%;
      min-width: 300px;

      > .ui-select-choices {
        position: relative;
        top: auto;
        left: auto;
        width: 0;
        padding: 8px;
        z-index: 1000;
        opacity: 0;
        transform-origin: top center;
        transform: scale(1, 0.1);
        animation: to-fully-opaque 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55)
          forwards;
      }
    }

    .ui-select-match-item {
      opacity: 0;
      transform: scale(0.1);
      animation: to-fully-opaque 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275)
        forwards;
    }
  }

  .ui-select-bootstrap .ui-select-choices-row > span:hover,
  .ui-select-bootstrap .ui-select-choices-row > span:focus {
    text-decoration: none;
    color: $bleached-white;
    background-color: $dmp-blue;
  }
}

@keyframes to-fully-opaque {
  100% {
    transform: none;
    opacity: 1;
  }
}

.animate-repeat-me.ng-move,
.animate-repeat-me.ng-enter,
.animate-repeat-me.ng-leave {
  transition: all linear 400ms;
}

.animate-repeat-me.ng-leave.ng-leave-active,
.animate-repeat-me.ng-move,
.animate-repeat-me.ng-enter {
  opacity: 0;
  max-height: 0;
}

.animate-repeat-me.ng-leave,
.animate-repeat-me.ng-move.ng-move-active,
.animate-repeat-me.ng-enter.ng-enter-active {
  opacity: 1;
  max-height: 30px;
}
