$icon-batch-loader-width: var(--measure-4x);

//The Batch-uploader object
.batch-uploader {
  width: 100%;
  //the top title section
  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    flex-wrap: nowrap;
    &__left {
      width: 50%;
    }
    &__right {
      position: relative;

      > * {
        position: absolute !important;
        right: 0;
        top: 0;
      }
    }
  }

  &__status-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--measure-3x);
    margin-bottom: var(--measure-1x);
    height: var(--measure-4x);
    position: relative;

    &__title {
      font-size: var(--measure-2x);
    }

    &__icon {
      width: $icon-batch-loader-width;
    }
  }

  //The uploader target area
  &__drag-target {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border: 0.2rem dashed transparent;
    -webkit-border-radius: 0.4rem;
    -moz-border-radius: 0.4rem;
    border-radius: 0.4rem;
    padding: var(--measure-2x);
    font-size: var(--measure-2x);
    width: 100%;

    &.bordered {
      border: 0.2rem dashed #cccccc;
    }

    &.drag-target-hit {
      background-color: rgba($astral, 0.2);
      box-shadow: inset -5px 5px 5px -3px #cccccc,
        inset 5px -5px 5px -3px #cccccc;
      transition: all 0.2s ease-out;
    }

    &--instructions {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }
    &--progress {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      height: var(--measure-4x);
      margin: var(--measure-2x);

      -webkit-animation: to-end 0.4s ease-out forwards;
      -o-animation: to-end 0.4s ease-out forwards;
      animation: to-end 0.4s ease-out forwards;
      & > .progress-bar-area {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-items: start;
        width: 85%;
      }

      &__icon {
        width: $icon-batch-loader-width;
        height: $icon-batch-loader-width;
      }

      &-completed {
        > h6 {
          margin-bottom: var(--measure-1x);
          margin-top: 0;
          max-height: 0rem;
          animation: expand-the-area 0.4s 0.2s ease-out forwards;
        }

        &-detail {
          overflow-y: auto;
          width: 100%;
          border: 0.1rem solid #cccccc;
          -webkit-border-radius: 0.4rem;
          -moz-border-radius: 0.4rem;
          border-radius: 0.4rem;
          max-height: 0rem;
          animation: expand-the-area 0.4s 0.4s ease-out forwards;
        }
      }

      .progress {
        height: 1.4rem;
        width: 100%;
        margin-bottom: 0;
        -webkit-border-radius: 1rem;
        -moz-border-radius: 1rem;
        border-radius: 1rem;
        .progress-bar-percent {
          padding-left: 0.5em;
          white-space: nowrap;
          font-size: 1.2rem;
          position: relative;
          top: -0.5rem;
        }
      }
    }
  }

  &__file-title,
  &__file-status-metric {
    font-size: var(--measure-2x);
  }
}

.animate-switch.ng-animate {
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.animate-switch.ng-leave.ng-leave-active,
.animate-switch.ng-enter {
  opacity: 0;
}

.animate-switch.ng-leave,
.animate-switch.ng-enter.ng-enter-active {
  opacity: 1;
}

@keyframes expand-the-area {
  100% {
    max-height: 20rem;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    opacity: 1;
  }
}
