/* ========================================================================
     Component: layout
 ========================================================================== */

//
// Main layout
// This file controls the presentation of all components
//  .wrapper
//    > section
//      > .content-wrapper
//    > .aside
//
// If modified or removed make sure to check the variable
// shared with other components
// -------------------------------------------------------------
@mixin collapse-animation-transition {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

$aside-wd: 240px;
$aside-wd-collapsed: 70px;
$aside-wd-icons-only: 7rem;
$aside-bg: $bleached-white;

$content-bg: $athens-gray;
$content-heading-bg: #fafbfc;
$content-heading-border: #cfdbe2;
$content-padding: 20px;

$footer-hg: 60px;

$navbar-hg: $navbar-height;

$boxed-max-width: 1160px;
$z-index-main-section: 111;

.wrapper > .aside {
  padding-top: 5.5rem;
}

html {
  /* $replace rtl */
  direction: ltr;
  height: 100%;
  // http://updates.html5rocks.com/2013/12/300ms-tap-delay-gone-away
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

html,
body {
  // overflow-x: hidden;
  height: 100%;
}
.wrapper {
  > .aside {
    padding-top: 7.5rem;
    z-index: $z-index-main-section + 5;
  }
}

// Main wrapper
// -----------------------------

.wrapper {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  // padding-bottom: $footer-hg !important; // ensure a space for the footer
  @include transition(all 0.3s cubic-bezier(0.23, 1, 0.32, 1));

  // Contains the main sidebar
  > .aside {
    // visibility: hidden;
    position: absolute;
    padding-top: 8rem;
    width: $aside-wd;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: $z-index-main-section - 1; // Hides behind the main section
    @include collapse-animation-transition;
    @include backface-visibility(hidden);
    background-color: $aside-bg;

    .aside-inner {
      height: 100%;
      width: $aside-wd;

      position: relative;
    }

    > .nav-floating {
      // position: handled from directive
      left: inherit;
      margin-left: $aside-wd-collapsed;
      z-index: $z-index-main-section + 5;

      min-width: 190px;
      overflow: auto;
    }
  }

  // Contains the main content
  > section {
    position: relative;
    height: 100%;
    // overflow: hidden;
    // z-index: $z-index-main-section;
    background-color: $content-bg;
    margin-bottom: $footer-hg !important; // ensure a space for the footer
  }

  // Page Footer
  > footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid $global-border;
    padding: $content-padding;
    background: rgba(255, 255, 255, 0.5);
    z-index: $z-index-main-section - 2;

    > p {
      margin: 0;
    }
  }
}

// Page main content
// -----------------------------
.content-wrapper {
  padding: 15px;
  width: 100%;

  margin-top: 78px;

  > .unwrap {
    margin: -15px; // expand over wrapper padding
    @media only screen and (min-width: $mq-tablet) {
      margin: -20px; // expand over wrapper padding
    }
  }

  > h3 {
    color: #929292;
    margin: -15px;
    margin-bottom: 20px;
    padding: 15px;
    font-weight: normal;
    background-color: $content-heading-bg;
    border-bottom: 1px solid $content-heading-border;
    > small {
      display: block;
      font-size: 12px;
      color: $text-muted;
    }
  }
  > button,
  > .btn {
    margin: 10px 10px 0 0;
  }

  .container,
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  @media only screen and (min-width: $mq-tablet) {
    padding: $content-padding;
    //overflow: auto; // required for portlets
    > h3,
    > .content-heading {
      margin: -$content-padding;
      margin-bottom: $content-padding;
      padding: $content-padding;
    }
    > button,
    > .btn {
      margin: 0;
    }
  }
}

// Desktop layout
// -----------------------------

@media only screen and (min-width: $mq-up-to-desktop-lg) {
  body {
    min-height: 100%;
  }

  .wrapper {
    > .aside {
      padding-top: 7.5rem;
      z-index: $z-index-main-section + 5;
    }

    > section,
    > footer {
      margin-right: 0;
      margin-left: $aside-wd;
    }

    //altered for menu on right
    > section {
      // with just a margin we can make the
      // right sidebar always visible
      &.has-sidebar-right {
        margin-left: 0px;
        + .offsidebar {
          z-index: 1;
        }
      }
    }
  }
}

.content-wrapper {
  padding: 15px;
  width: 100%;
  position: relative;
}

// Aside toggled layout
// On mobile acts like offcanvas
// -----------------------------

@media only screen and (max-width: $mq-up-to-desktop-lg) {
  .wrapper {
    > .aside {
      margin-right: -$aside-wd;
    }
  }
  .aside-toggled {
    .wrapper {
      > section {
        margin-left: 0;
        margin-right: 7rem;
      }
      > footer {
        margin-right: 7rem;
      }

      > .aside {
        margin-left: 0;
      }
    }
  }
  .aside-icon-view {
    .wrapper {
      > section {
        margin-left: 0;
        margin-right: $aside-wd;
      }
      > footer {
        margin-right: $aside-wd;
      }

      > .aside {
        margin-left: 0;
      }
    }
  }
  .csstransforms3d {
    .wrapper {
      @include backface-visibility(hidden);
      > section,
      > footer {
        margin-right: 0;
        @include translate3d(0, 0, 0);
        @include transition-transform(0.3s ease);
      }
      > .aside {
        margin-left: 0;
        @include translate3d(-$aside-wd, 0, 0);
        @include transition-transform(0.3s ease);
      }
    }

    .aside-toggled {
      .wrapper {
        > section,
        > footer {
          @include translate3d($aside-wd, 0, 0);
        }
        > .aside {
          @include translate3d(0, 0, 0);
        }
      }
    }
  }
}

// Aside toggled layout
// On desktop acts like condensed
// -------------------------------

@media only screen and (min-width: $mq-up-to-desktop-lg) {
  // Aside status toggled via JS
  .aside-collapsed {
    overflow-y: auto;
    .wrapper {
      > .aside {
        &,
        > .aside-inner {
          width: $aside-wd-icons-only;
          transition: all 0.25s ease-out;
        }
        > .nav-floating {
          &,
          .collapse {
            height: auto !important;
            display: block !important;
            visibility: visible !important;
          }
        }
      }

      > section,
      > footer {
        margin-right: $aside-wd-collapsed;
      }
    }
  }
}

// mixin to remove transformations
@mixin disable-transform() {
  -webkit-transform: none;
  -moz-transform: none;
  -opera-transform: none;
  -ms-transform: none;
  transform: none;
}

// Fixed layout
// -----------------------------
.layout-fixed {
  @media (max-width: $boxed-max-width) {
    &.aside-toggled,
    &.offsidebar-open {
      overflow-y: hidden;
    }
  }

  .wrapper {
    .topnavbar-wrapper {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: $z-index-main-section + 10;
    }
    > aside {
      position: fixed;
    }
    /* only applied to sidebar */
    > .aside {
      /* safari fix */
      height: 1px;
      min-height: 100%;
    }
    > section {
      margin-top: ($navbar-hg + 2.5);
      @media only screen and (min-width: $mq-tablet) {
        margin-top: $navbar-hg;
      }
    }
  }
}

// Boxed layout
// -----------------------------
.layout-boxed {
  overflow: auto !important;
  .wrapper {
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);

    .offsidebar {
      position: absolute !important;
    }

    > .aside {
      left: inherit;
    }

    @media only screen and (min-width: $mq-desktop) {
      &,
      & .topnavbar-wrapper {
        width: 970px;
      }
    }

    @media only screen and (min-width: $mq-desktop-lg) {
      &,
      & .topnavbar-wrapper {
        width: $boxed-max-width;
      }
    }
  }
}
