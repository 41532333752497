.vertical-tabset {
  &__tablist {
    padding-left: 0;
    &__list-item {
      position: relative;
      padding: $padding-small-vertical;
      vertical-align: middle;
      width: 100%;

      transition: all 0.5s ease;
      color: $navbar-default-color;

      &:hover {
        background-color: rgba($tab-active-bg-color, 0.5);
        color: $tab-active-color;
        text-decoration: none;

        transition: all 0.3s ease;

        > a {
          color: $tab-active-color;
          text-decoration: none;
        }
      }

      &.active {
        background-color: $tab-active-bg-color;
        color: $tab-active-color;
        text-decoration: none;

        transition: all 0.3s ease;
        > a {
          color: $tab-active-color;
          text-decoration: none;
        }
        &:after {
          content: "";
          position: absolute;
          top: 32%;
          right: -1%;
          width: 0;
          height: 0;
          border-top: 0.5rem solid transparent;
          border-right: 1rem solid $bleached-white;
          border-bottom: 0.5rem solid transparent;
        }
      }

      &__link {
      }
    }
  }
}

.flexbox-container {
  > .flex-col {
    &.link-list {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      border-right: 0.1rem solid $global-border;
    }
    &.detail-area {
      padding-top: 0;
      height: 42rem;
      overflow-y: scroll;

      .checkbox {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
