//dmp icon font v1.5.2
$icomoon-font-family: "dmp_icon_font" !default;
$icomoon-font-path: "fonts" !default;
$icon-css-prefix: icon !default;

$icon-arming_shield: "\e900";
$icon-armed_shield_solid: "\e901";
$icon-home: "\e902";
$icon-sleep: "\e903";
$icon-away: "\e904";
$icon-all: "\e905";
$icon-perimeter: "\e906";
$icon-burglary: "\e907";
$icon-fire: "\e908";
$icon-police: "\e909";
$icon-lock_unlocked: "\e90a";
$icon-lock_locked: "\e90b";
$icon-door_chime: "\e90c";
$icon-door: "\e90d";
$icon-light: "\e90e";
$icon-light_solid: "\e90f";
$icon-therm_auto: "\e910";
$icon-therm_cool: "\e911";
$icon-therm_heat: "\e912";
$icon-fan: "\e913";
$icon-question: "\e914";
$icon-exclamation: "\e915";
$icon-secura_unlocked: "\e916";
$icon-secura_locked: "\e917";
$icon-bell_chime: "\e918";
$icon-temperature: "\e919";
$icon-favorite: "\e91a";
$icon-edit: "\e91b";
$icon-sign: "\e91c";
$icon-key: "\e91d";
$icon-garage_closed: "\e91e";
$icon-garage_1: "\e91f";
$icon-garage_2: "\e920";
$icon-garage_3: "\e921";
$icon-garage_4: "\e922";
$icon-garage_open: "\e923";
$icon-outlet_output: "\e924";
$icon-rooms: "\e925";
$icon-attention: "\e926";
$icon-exit: "\e927";
$icon-user: "\e928";
$icon-users: "\e929";
$icon-headset_monitoring: "\e92a";
$icon-fingerprint: "\e92b";
$icon-like: "\e92c";
$icon-automation: "\e92d";
$icon-sort_vertical: "\e92e";
$icon-sort_horizontal: "\e92f";
$icon-id_card_profiles: "\e930";
$icon-id_badge_profiles: "\e931";
$icon-date: "\e932";
$icon-date_time: "\e933";
$icon-timer: "\e934";
$icon-box_checked: "\e935";
$icon-box_unchecked: "\e936";
$icon-trash: "\e937";
$icon-credit_card_front: "\e938";
$icon-credit_card_back: "\e939";
$icon-wifi: "\e93a";
$icon-id_cards_profiles: "\e93b";
$icon-location: "\e93c";
$icon-location_solid: "\e93d";
$icon-pointer: "\e93e";
$icon-pointer_solid: "\e93f";
$icon-graph_line: "\e940";
$icon-play: "\e941";
$icon-mail: "\e942";
$icon-magnify_right: "\e943";
$icon-magnify_left: "\e944";
$icon-settings: "\e945";
$icon-gauge: "\e946";
$icon-download: "\e947";
$icon-history: "\e948";
$icon-reset: "\e949";
$icon-power: "\e94a";
$icon-brightness_dim: "\e94b";
$icon-target: "\e94c";
$icon-search_right: "\e94d";
$icon-search_left: "\e94e";
$icon-brightness: "\e94f";
$icon-radial_alert: "\e950";
$icon-radial_question: "\e951";
$icon-radial_check: "\e952";
$icon-radial_ban: "\e953";
$icon-radial_empty: "\e954";
$icon-radial_info: "\e955";
$icon-pie_chart: "\e956";
$icon-cycle: "\e957";
$icon-radial_button: "\e958";
$icon-radial_solid: "\e959";
$icon-radial_add: "\e95a";
$icon-radial_subtract: "\e95b";
$icon-radial_warning: "\e95c";
$icon-time_clock: "\e95d";
$icon-more: "\e95e";
$icon-menu_list: "\e95f";
$icon-radial_mail: "\e960";
$icon-radial_facebook: "\e961";
$icon-radial_twitter: "\e962";
$icon-world: "\e963";
$icon-diagnostics_1: "\e964";
$icon-diagnostics_2: "\e965";
$icon-screen_resize: "\e966";
$icon-personnel: "\e967";
$icon-action: "\e968";
$icon-sd_card: "\e969";
$icon-camera: "\e96a";
$icon-zwave: "\e96b";
$icon-installer: "\e96c";
$icon-plug: "\e96d";
$icon-menu: "\e96e";
$icon-menu_2: "\e96f";
$icon-resize: "\e970";
$icon-switch: "\e971";
$icon-cleaning: "\e972";
$icon-download2: "\e973";
$icon-device: "\e974";
$icon-bar_graph: "\e975";
$icon-line_graph: "\e976";
$icon-delivery: "\e977";
$icon-checkmark: "\e978";
$icon-close_cancel: "\e979";
$icon-add: "\e97a";
$icon-subtract: "\e97b";
$icon-arrow_back: "\e97c";
$icon-arrow_forward: "\e97d";
$icon-control_right: "\e97e";
$icon-control_left: "\e97f";
$icon-control_up: "\e980";
$icon-control_down: "\e981";
$icon-battery_full_horiz: "\e982";
$icon-battery_3_horiz: "\e983";
$icon-battery_4_horiz: "\e984";
$icon-battery_low_horiz: "\e985";
$icon-battery_empty_horiz: "\e986";
$icon-battery_full_vert: "\e987";
$icon-battery_3_vert: "\e988";
$icon-battery_4_vert: "\e989";
$icon-battery_low_vert: "\e98a";
$icon-battery_empty_vert: "\e98b";
$icon-folder: "\e98c";
$icon-doc: "\e98d";
$icon-doc_pdf: "\e98e";
$icon-doc_file: "\e98f";
$icon-news: "\e990";
$icon-manual: "\e991";
$icon-camera2: "\e992";
$icon-cart: "\e993";
$icon-cloud_download: "\e994";
$icon-cloud_upload: "\e995";
$icon-sunrise_sunset: "\e996";
$icon-sunny: "\e997";
$icon-night: "\e998";
$icon-foggy: "\e999";
$icon-partly_cloudy: "\e99a";
$icon-night_partly_cloudy: "\e99b";
$icon-rainy: "\e99c";
$icon-storm: "\e99d";
$icon-snow: "\e99e";
$icon-desk: "\e99f";
$icon-dinner: "\e9a0";
$icon-leaf: "\e9a1";
$icon-christmas_tree: "\e9a2";
$icon-arming_favorite: "\e9a3";
$icon-child: "\e9a4";
$icon-travel: "\e9a5";
$icon-game: "\e9a6";
$icon-mood: "\e9a7";
$icon-music: "\e9a8";
$icon-clip: "\e9a9";
$icon-christmas_bulb: "\e9aa";
$icon-movie_night: "\e9ab";
$icon-box_cancel: "\e9ac";
$icon-box_small_cancel: "\e9ad";
$icon-circle_cancel: "\e9ae";
$icon-bolt: "\e9af";
$icon-menu_sliders: "\e9b0";
$icon-exterior_light: "\e9b1";
$icon-speaker_full: "\e9b2";
$icon-speaker_2: "\e9b3";
$icon-speaker_1: "\e9b4";
$icon-speaker_off: "\e9b5";
$icon-speaker_aligned_right: "\e9b6";
$icon-edit_2: "\e9c0";
$icon-troubleshooting: "\e9b7";
$icon-bubble_question: "\e9b8";
$icon-bubble_exclamation: "\e9b9";
$icon-bubble_blank: "\e9ba";
$icon-doorbell: "\e9bb";
$icon-shipping_box: "\e9bc";
$icon-microphone: "\e9bd";
$icon-microphone_mute: "\e9be";
$icon-phone: "\e9bf";
$icon-logger: "\e9cc";
$icon-city: "\e9c1";
$icon-server_1: "\e9c2";
$icon-server_2: "\e9c3";
$icon-chime: "\e9c4";
$icon-keyfob_1: "\e9c5";
$icon-keyfob_2: "\e9c6";
$icon-circle_arrow_left: "\e9c7";
$icon-circle_arrow_right: "\e9c8";
$icon-circle_arrow_up: "\e9c9";
$icon-circle_arrow_down: "\e9ca";
$icon-circle_pointer_right: "\e9cb";
$icon-circle_pointer_left: "\e9cd";
$icon-circle_pointer_up: "\e9ce";
$icon-circle_pointer_down: "\e9cf";
$icon-bypass: "\e9d0";
$icon-government: "\e9d1";
$icon-bank: "\e9d5";
$icon-jewelry_store: "\e9d2";
$icon-residential: "\e9d3";
$icon-phone_x: "\e9d4";
$icon-graphics_keypad: "\e9dc";
$icon-secura_keypad: "\e9d6";
$icon-company_store_keypad: "\e9d7";
$icon-thinline_keypad: "\e9d8";
$icon-security_command_keypad: "\e9d9";
$icon-keypad_screen: "\e9da";
$icon-security_panel: "\e9db";
$icon-arming_shield_new_outline: "\e9dd";
$icon-cell_signal-full: "\e9de";
$icon-cell_signal_4: "\e9df";
$icon-cell_signal_5: "\e9e0";
$icon-cell_signal_6: "\e9e1";
$icon-cell_signal_7: "\e9e2";
$icon-favorite_anim_1: "\e9e3";
$icon-favorite_anim_2: "\e9e4";
$icon-favorite_anim_3: "\e9e5";
$icon-favorite_anim_4: "\e9e6";
$icon-map: "\e9e7";
$icon-filter: "\e9e8";
$icon-skip_up: "\e9e9";
$icon-skip_down: "\e9ea";
$icon-power_off: "\e9eb";
$icon-globe_with_pointer: "\e9ec";
$icon-computer_pointer: "\e9ed";
$icon-computer_w_pointer: "\e9ee";
$icon-installer_vertical: "\e9ef";
$icon-cloud: "\e9f0";
$icon-globe_lined: "\e9f1";
$icon-quick_exit: "\e9f2";
$icon-fast_start_program: "\e9f3";
$icon-sales_team: "\e9f4";
$icon-HR: "\e9f5";
$icon-cycle_clockwise: "\e9f6";
$icon-fitness: "\e9f7";
$icon-cell_tower: "\e9f8";
$icon-spotlight: "\e9f9";
$icon-training_v2: "\e9fa";
$icon-arm_inactivity: "\e9fb";
$icon-security_gateway: "\e9fc";
$icon-voice_integration: "\e9fd";
$icon-network: "\ea01";
$icon-phone_hang_up: "\ea02";
$icon-small_biz: "\ea03";
$icon-speaker_mute: "\ea04";
$icon-no_cell_signal: "\ea05";
$icon-xtl_plus: "\ea06";
$icon-share_1: "\ea07";
$icon-share_2: "\ea08";
$icon-dmp_guide: "\ea09";
$icon-install_guide: "\ea0a";
$icon-programming-guide: "\ea0b";
$icon-security_keypad: "\ea2e";
$icon-security_siren: "\ea2f";
$icon-security_sensor: "\ea30";
$icon-visibility: "\ea0c";
$icon-man_with_tie: "\ea2d";
$icon-map_dealer_base: "\ea0d";
$icon-sensors_1106: "\ea0e";
$icon-low_cell_signal: "\ea0f";
$icon-rotate_left: "\ea10";
$icon-rotate_right: "\ea11";
$icon-play_circle: "\ea12";
$icon-pause_circle: "\ea13";
$icon-play_icon: "\ea14";
$icon-pause_icon: "\ea15";
$icon-value_feature: "\ea16";
$icon-check_in_protection: "\ea17";
$icon-central_station: "\ea18";
$icon-central_station_communication: "\ea1f";
$icon-panel_communication: "\ea20";
$icon-communication_tower_right: "\ea21";
$icon-communication_tower_left: "\ea22";
$icon-laptop: "\ea23";
$icon-user_codes_finger: "\ea24";
$icon-arming_area: "\ea25";
$icon-megaphone: "\ea26";
$icon-instant_arming: "\ea27";
$icon-picture_placeholder: "\ea28";
$icon-user_photo_placeholder: "\ea29";
$icon-brochure: "\ea2a";
$icon-invoice_stuffer: "\ea2b";
$icon-upload: "\ea2c";
$icon-power_appliance: "\e9fe";
$icon-modem: "\e9ff";
$icon-cd: "\ea00";
$icon-wireless: "\ea19";
$icon-encrypted_wireless: "\ea1a";
$icon-motion: "\ea1b";
$icon-critical_thinking: "\ea1c";
$icon-interview: "\ea1d";
$icon-tool_hammer: "\ea1e";
$icon-dmpmoji_smile: "\ea31";
$icon-binoculars: "\ea32";
$icon-award_ribbon: "\ea33";
$icon-dollar_bill: "\ea34";
$icon-public_speaker: "\ea35";
$icon-handshake: "\ea36";
$icon-apartment: "\ea37";
$icon-measurement_yard: "\ea38";
$icon-security_sign: "\ea39";
$icon-playbook: "\ea3a";
$icon-camera_flip: "\ea3b";
$icon-stop: "\ea3c";
$icon-reorder: "\ea3d";
$icon-service_request: "\ea3e";
$icon-new_install: "\ea3f";
$icon-new_tab: "\ea40";
$icon-ez_connect: "\ea41";
$icon-ez_connect_with_cell: "\ea42";
$icon-flash_flood: "\ea43";
$icon-tornado: "\ea44";
$icon-brain: "\ea45";
$icon-alarm_bell_2: "\ea46";
$icon-alarm_bell_1: "\ea47";
$icon-dmp_building: "\ea48";
$icon-tshirt: "\ea49";
$icon-wildfire_warning: "\ea4a";
$icon-residential_lockdown: "\ea4b";
$icon-remote_update: "\ea4c";
$icon-door_lockdown-path1: "\ea4d";
$icon-door_lockdown-path2: "\ea4e";
$icon-door_lockdown-path3: "\ea4f";
$icon-door_lockdown-path4: "\ea50";
$icon-poe_power_over_ethernet: "\ea51";
$icon-4_wire: "\ea52";
$icon-confluence_home: "\ea53";
$icon-confluence_index: "\ea54";
$icon-handshake_dealer_admin: "\ea55";
$icon-appliances_off: "\ea56";
$icon-appliances_on: "\ea57";
$icon-school: "\ea58";
$icon-office_building: "\ea59";
$icon-345_truck: "\ea5a";
$icon-346_crosshairs: "\ea5b";
$icon-output_options: "\ea5c";
$icon-output_groups: "\ea5e";
$icon-visibility_off: "\ea5d";
$icon-all_solid: "\ea4e";
$icon-vault: "\ea4f";
$icon-syringe: "\ea50";
$icon-wash_hands: "\ea5f";
$icon-no_touching: "\ea60";
$icon-stay_home: "\ea61";
$icon-sneeze: "\ea62";
$icon-dispose_trash: "\ea63";
$icon-hand_sanitizer: "\ea64";
$icon-no_dishes_cutlery: "\ea65";
$icon-hands_free_open_door: "\ea66";
$icon-paper_towel_dispenser: "\ea67";
$icon-brightness_volume: "\ea68";
$icon-wifi_setting_0_secure: "\ea69";
$icon-wifi_setting_1_secure: "\ea6a";
$icon-wifi_setting_2_secure: "\ea6b";
$icon-wifi_setting_3_secure: "\ea6c";
$icon-wifi_setting_4_secure: "\ea6d";
$icon-wifi_setting_5_secure: "\ea6e";
$icon-wifi_setting_1: "\ea6f";
$icon-wifi_setting_2: "\ea70";
$icon-wifi_setting_3: "\ea71";
$icon-wifi_setting_4: "\ea72";
$icon-move_system: "\ea73";
$icon-wifi_disconnected: "\ea74";
$icon-university: "\ea75";
$icon-heart_checkmark: "\ea76";
$icon-xtltouch: "\ea77";
$icon-xtltouch_wall: "\ea78";
$icon-cyber_security: "\ea79";
$icon-automation_software: "\ea7a";
$icon-insurance: "\ea7b";
$icon-distributor: "\ea7c";
$icon-compliance_services: "\ea7d";
$icon-authority_stamp: "\ea7e";
$icon-legal_services: "\ea7f";
$icon-pers: "\ea80";
$icon-shield_clean: "\ea81";
$icon-clips_with_play: "\ea82";
$icon-back_to_camera: "\ea83";
$icon-back_to_list: "\ea84";
$icon-honeywell_panel: "\ea85";
$icon-card_reader_1: "\ea86";
$icon-card_reader_2: "\ea87";
$icon-usa: "\ea88";
$icon-support: "\ea89";
$icon-church: "\ea8a";
$icon-family: "\ea8b";
$icon-hotspot: "\ea8c";
$icon-military: "\ea8d";
$icon-takeover: "\ea8e";
$icon-door_closed: "\ea8f";
$icon-overview: "\ea90";
$icon-sales: "\ea91";
$icon-top_ten_ribbon: "\ea92";
$icon-top_ten_circle: "\ea93";
$icon-new_products__services: "\ea94";
$icon-dealer_information: "\ea95";
$icon-securecom: "\ea96";
$icon-success: "\ea97";
$icon-skills: "\ea98";
$icon-behavior: "\ea99";
$icon-performance: "\ea9a";
$icon-goals: "\ea9b";
$icon-carousel: "\ea9c";
$icon-sa_jacket_reward: "\ea9f";
$icon-sa_backpack_reward: "\ea9d";
$icon-sa_coin_reward: "\eaa0";
$icon-sa_card_holder_reward: "\eaa2";
$icon-sa_watch_reward: "\eaa1";
$icon-sa_briefcase_reward: "\eaa3";
$icon-sa_notepad_reward: "\ea9e";
$icon-mobile_credentials: "\eaa4";
$icon-credential_reader: "\eaa5";

