$avatar-size-nearest-tech: rem(48px);
$avatar-bg-size-nearest-tech: $avatar-size-nearest-tech + rem(6px);

.nearest-tech {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: repeat(2, minmax(0, min-content));
  grid-template-areas: "pic name" "pic email";
  grid-column-gap: var(--measure-1x);
  justify-items: start;
  align-items: center;

  &__pic-area {
    grid-area: pic;
    &__pic {
      width: $avatar-bg-size-nearest-tech;
      height: $avatar-bg-size-nearest-tech;
      display: block;
      border: 0.5rem solid white !important;
      border-radius: 50%;
      //margin: var(--measure-5x);
      background-color: white;

      padding: 0.1rem !important;
      //clip-path: circle(100% at 50% 50%);
      -webkit-box-shadow: 0px 8px 10px -4px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 8px 10px -4px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 8px 10px -4px rgba(0, 0, 0, 0.75);
      object-position: center top;
      //border: 4px solid var(--color-neutral-400);
      > img {
        position: relative;
        display: block;
        background-position: center;
        background-size: $avatar-size $avatar-size;
        object-position: center top;
        object-fit: cover;
        width: $avatar-size-nearest-tech;
        height: $avatar-size-nearest-tech;
        //padding: rem(1px);
        // margin: rem(-1px);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        vertical-align: middle;
        white-space: nowrap;
        overflow: hidden;
        top: -0.3rem;
        left: -0.3rem;
        transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
      }
    }
  }

  &__name-area {
    grid-area: name;
    font-size: var(--measure-font-18);
    font-weight: var(--font-weight-medium);
  }

  &__email-area {
    grid-area: email;
  }
}
