.pill-buttons {
  display: flex;
  padding: var(--measure-1x) 0;
}

.pill-button {
  margin-left: var(--measure-1x);
}

.pill-button__text {
  margin-left: var(--measure-half);
}

.pill-button:first-child {
  margin-left: 0;
}
