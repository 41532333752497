@mixin video-device-headers {
  display: flex;
  align-items: center;
  font-size: var(--measure-2x);
  > * {
    margin: 2px;
  }
}

.video-device {
  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2.4rem;
    padding: var(--measure-3x) var(--measure-3x) 0;
    margin-bottom: var(--measure-2x);

    > *:first-child {
      font-size: inherit;
      margin-right: var(--measure-1x);
    }
    &:not(:first-child) {
      // margin-top: var(--measure-4x);
    }

    &--camera {
      @include video-device-headers();
      justify-content: flex-start;
      //border-bottom:   .1rem solid rgba($global-border, .4);
      margin-bottom: var(--measure-3x);
      padding-bottom: var(--measure-half);

      > *:first-child {
        font-size: inherit;
        margin-right: var(--measure-1x);
      }
    }
    &--nvr {
      @include video-device-headers();
      font-size: 1.2rem;
      justify-content: space-between;
      margin-bottom: var(--measure-1x);
      padding: 0 var(--measure-3x);
      &__button-area {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        > * {
          margin: 2px;
        }
        @media (min-width: $screen-sm) {
          flex-direction: row;
          justify-content: space-evenly;
        }
      }
      &:nth-child(n + 1) {
        margin-top: var(--measure-3x);
      }

      // border-bottom:   .1rem solid rgba($global-border, .4);

      &-title {
        font-size: inherit;
        @include video-device-headers();
        justify-content: flex-start;
        > *:first-child {
          font-size: inherit;
          margin-right: var(--measure-1x);
        }
      }
      &-data {
        font-weight: 500;
      }
    }
    &--hikvision {
      @include video-device-headers();
      font-size: 1.2rem;
      justify-content: flex-start;
      padding: 0 var(--measure-3x);
      margin-bottom: var(--measure-1x);
      &:nth-child(n + 1) {
        margin-top: var(--measure-3x);
      }
      @media screen and (min-width: $screen-sm) {
        font-size: 1.2rem;
        > *:first-child {
          margin-right: var(--measure-5x);
        }
      }
      // border-bottom:   .1rem solid rgba($global-border, .4);

      &-title {
        font-size: inherit;
        @include video-device-headers();
        justify-content: flex-start;
        > *:first-child {
          font-size: inherit;
          margin-right: var(--measure-1x);
        }
      }
      &-data {
        font-weight: 500;
      }
    }
  }
}

table {
  thead {
    th.subdued {
      font-weight: 200;
      color: $text-global;
    }
  }
}
.nvr-title {
  > .nvr-data {
    font-weight: 500;
  }
}
