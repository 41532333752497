// DEFAULT OPTIONS

// Prefix for Hover class names
$nameSpace: "hvr" !default;

// Durations
$fastDuration: 0.1s !default;
$mediumDuration: 0.3s !default;
$slowDuration: 0.5s !default;

// Colors
$primaryColor: #e1e1e1 !default;
$secondaryColor: #666 !default;
$highlightColor: #ccc !default;
$activeColor: #2098d1 !default;
$shadowColor: rgba(0, 0, 0, 0.6) !default;

// Speech bubbles options
$tipWidth: 10px !default; //the width of the speech bubble tip
$tipHeight: 10px !default; //the height of the speech bubble tip
$tipColor: $primaryColor !default;

// Arrows options
$spaceBetweenTextAndArrows: 2.2em !default;

// Curl dimensions
$curlWidth: 25px !default;
$curlHeight: 25px !default;

// Curl colors
$revealAreaColor: white !default; // reveal area behind the curl
$curlLineColor: #aaa !default; // crease line
$curlTransitionColor: #ccc !default; // color transitioning from crease line to final color
$curlLastColor: white !default; // final color of the back of the curl

// Browser Prefixes - Which CSS prefixes should be used?
$webkit: true !default;
$moz: false !default;
$ms: false !default;
$o: false !default;

// Compile with or without classes
$includeClasses: true !default;
