.area-arming {
  &__all-buttons {
    display: flex;
    align-items: center;
    //padding: 0 2.4rem;
    -webkit-box-align: center;
    margin: 0.8rem 0 2rem;

    > * {
      flex: 1;
    }
  }

  &__faulted-zones-container,
  &__armed-status-container {
    background: var(--color-primary-300);
    padding: var(--measure-3x);

    &__header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 2rem;
      padding: 0;
      color: white;

      &:first-child {
        margin-right: var(--measure-1x);
      }
    }
  }

  &__armed-status-container {
    position: relative;
    top: 0;
    right: 0;

    &--unknown {
      background: var(--color-warning-200);
      padding: var(--measure-3x);
      position: absolute;
      top: 0;
      left: 0;
      &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 2rem;
        padding: 0;
        color: darken($color-warning-200, 50%);

        &:first-child {
          margin-right: var(--measure-1x);
        }
      }
    }

    &--attempting {
      position: absolute;
      top: 0;
      left: 0;
      background: var(--color-primary-300);
      padding: var(--measure-3x);

      &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 2rem;
        padding: 0;
        color: white;

        &:first-child {
          margin-right: var(--measure-1x);
        }

        &:first-child {
          margin-right: var(--measure-1x);
        }
      }
    }
  }

  &__areas-container,
  &__faulted-zones--list {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: 0 -24px;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-width: 0.1rem;
    border-style: solid;
    border-color: rgb(224, 224, 224);
    border-image: initial;
    background: white;
    border-radius: 0;
    min-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;

    &-row {
      font-size: var(--measure-font-16);
      font-weight: var(--font-weight-medium);
      color: white;
      line-height: 2.5;
      border-bottom: 0.1rem solid rgba(255, 255, 255, 0.4);

      &:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &--list {
      &-row {
        margin: 0;
        list-style: none;
        padding: 0.8rem 1.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: var(--measure-font-16);
        font-weight: var(--font-weight-medium);

        &:nth-child(odd) {
          background: $table-bg-accent;
        }
      }
    }
  }

  &__faulted-zones--list {
    background: var(--color-primary-300);
    border: none;
  }
}

.flip-animate-if.ng-leave.ng-leave-active {
  -webkit-animation: flip-horizontal-bottom 0.8s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
  animation: flip-horizontal-bottom 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    forwards;
}

.flip-animate-if.ng-enter.ng-enter-active {
  -webkit-animation: flip-horizontal-top 0.8s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
  animation: flip-horizontal-top 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    forwards;
}

/**
 * ----------------------------------------
 * animation flip-horizontal-bottom
 * ----------------------------------------
 */
@-webkit-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0) scaleY(1);
    transform: rotateX(0) scaleY(1);
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(-90deg) scaleY(0);
    transform: rotateX(-90deg) scaleY(0);
  }
}
@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0) scaleY(1);
    transform: rotateX(0) scaleY(1);
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(-90deg) scaleY(0);
    transform: rotateX(-90deg) scaleY(0);
  }
}

.flip-horizontal-top {
  -webkit-animation: flip-horizontal-top 0.4s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-horizontal-top 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
}

/**
 * ----------------------------------------
 * animation flip-horizontal-top
 * ----------------------------------------
 */
@-webkit-keyframes flip-horizontal-top {
  0% {
    opacity: 0;
    -webkit-transform: rotateX(90deg) scaleY(0);
    transform: rotateX(90deg) scaleY(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes flip-horizontal-top {
  0% {
    opacity: 0;
    -webkit-transform: rotateX(90deg) scaleY(0);
    transform: rotateX(90deg) scaleY(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
