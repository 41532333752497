@mixin use-icon-font {
  font-family: dmp_icon_font;
  margin-right: var(--measure-1x);
}
.user-feedback {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: inherit;
  &__primary {
    color: var(--color-primary);
    &:before {
      @include use-icon-font();
      content: "\e955 ";
    }
  }
  &__info {
    color: var(--color-state-info);
    &:before {
      @include use-icon-font();
      content: "\e955";
    }
  }
  &__success {
    color: var(--color-state-success);
    &:before {
      @include use-icon-font();
      content: "\e952 ";
    }
  }
  &__warning {
    color: var(--color-state-warning);
    &:before {
      @include use-icon-font();
      content: "\e951";
    }
  }
  &__danger {
    color: var(--color-state-danger);
    &:before {
      @include use-icon-font();
      content: "\e950 ";
    }
  }
  &__neutral {
    color: var(--color-neutral-900);
    &:before {
      @include use-icon-font();
      content: "\e950 ";
    }
  }
}

$icon-radial_alert: \e950;
$icon-radial_question: \e951;
$icon-radial_check: \e952;
$icon-radial_ban: \e953;
$icon-radial_empty: \e954;
$icon-radial_info: \e955;
