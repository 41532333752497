.remote-update {
  &__grid {
    display: grid;
    grid-template-rows: 5rem 1fr 1fr 5rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "panel-name-area notification-area"
      "current-status-area current-status-area"
      "new-status-area new-status-area"
      "action-area action-area";
    @media (min-width: $screen-sm) {
      grid-template-rows: 5rem 1fr 5rem;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "panel-name notification-area"
        "current-status-area new-status-area"
        "action-area action-area";

      &__panel-name-area {
        grid-area: panel-name;
      }
      &__notification-area {
        grid-area: notification-area;
      }

      &__current-status-area {
        grid-area: current-status-area;
      }

      &__new-status-area {
        grid-area: new-status-area;
      }

      &__action-area {
        grid-area: action-area;
      }
    }
  }
}
