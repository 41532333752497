[slim-scroll],
[data-slim-scroll] {
  position: relative;
  overflow: hidden;
  > div {
    position: absolute !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    left: 0;
    top: 0 !important;
    bottom: 0 !important;
    &.unselectable {
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: -moz-none;
      -o-user-select: none;
      user-select: none;
    }
    + div {
      position: absolute !important;
      top: 0 !important;
      bottom: 0 !important;
      right: 0;
      left: auto;
      width: 5px;
      cursor: pointer !important;
      padding-right: 0 !important;
      > div {
        position: absolute !important;
        background-color: #999;
        top: 0;
        left: 0;
        right: 0;
      }
    }
  }
  &.mac-style {
    > div {
      + div {
        background-color: transparent;
        > div {
          border-radius: 10px;
        }
        &.animate > div {
          transition: 2s opacity ease;
          -ms-transition: 2s opacity ease;
          opacity: 0;
        }
        &:hover > div {
          opacity: 0.5;
          transition: none;
          -ms-transition: none;
        }
      }
    }
  }
}

div[slim-scroll].one-88 {
  min-height: 20rem;
}

div[slim-scroll].four-00 {
  min-height: 40rem;
}
div[slim-scroll].auto {
  height: auto;
}
div.slim-scroll-wrapper {
  padding-right: 15px !important;
  margin-right: 0;
  background: none;
  transition: background-image 1s ease-out;

  &:hover {
    //border-top: 1px solid rgba($global-border, 20%);
    // border-right: 1px solid darken($global-border, 20%);
    // -webkit-box-shadow: inset 0px 2px 4px 0px rgba(163,163,163,1);
    //   -moz-box-shadow: inset 0px 2px 4px 0px rgba(163,163,163,1);
    //   box-shadow: inset 0px 2px 4px 0px rgba(163,163,163,1);
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cccccc+0,fcfcfc+100&0.1+1,0.04+44,0+89,0.2+93 */
    //   background: -moz-linear-gradient(-45deg, rgba(204,204,204,0.1) 0%, rgba(204,204,204,0.1) 1%, rgba(225,225,225,0.04) 44%, rgba(247,247,247,0) 89%, rgba(249,249,249,0.2) 93%, rgba(252,252,252,0.2) 100%); /* FF3.6-15 */
    //     background: -webkit-linear-gradient(-45deg, rgba(204,204,204,0.1) 0%,rgba(204,204,204,0.1) 1%,rgba(225,225,225,0.04) 44%,rgba(247,247,247,0) 89%,rgba(249,249,249,0.2) 93%,rgba(252,252,252,0.2) 100%); /* Chrome10-25,Safari5.1-6 */
    //     background: linear-gradient(135deg, rgba(204,204,204,0.1) 0%,rgba(204,204,204,0.1) 1%,rgba(225,225,225,0.04) 44%,rgba(247,247,247,0) 89%,rgba(249,249,249,0.2) 93%,rgba(252,252,252,0.2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1acccccc', endColorstr='#33fcfcfc',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
}
