//
// Variables
// --------------------------------------------------

//== Colors
//
//## Gray and brand colors for use across Bootstrap.
// new colors
$dark-gray: #676a6c;
$black: black;
$primary: hsl(198, 47, 38);
$text-color: $black;
$text-muted-color: $dark-gray;

//DMP Colors
$catskill-white: hsl(221, 28%, 97%); //hsl(198, 51%, 96%);
$alabaster: #fafbfc;

$athens-gray: #edf0f5;
$astral: $primary;
$candlelight: #fcd316;
$gainsboro: #dddddd;
$bleached-white: #ffffff;
$less-than-white: #eeeeee;
$text-global: $black;
$background-subnav: #f8f8f8;

$dmp-light-gray: #fafafa;
$dmp-dark-gray: #efecea;
$dmp-red: #f24965;
$dmp-red-lighter: lighten($dmp-red, 10%);
$dmp-blue: #347490;
$dmp-blue-hover: #054a6e;
$dmp-white-inverse: $bleached-white;
$dmp-border-color: #ccc;
$dmp-layout-border-darker: #ddd;
$nav-heading-color: var(--black);
$dmp-progress-bar-color: #06498d;
$dmp-text-standard: black;
$dmp-text-light: $bleached-white;
$dmp-keyfob-highlight: #6686a9;

// Redefined Gray colors for theme
$gray-darker: #232735;
$gray: #dde6e9;
$gray-light: $dark-gray;
$gray-lighter: #edf1f2;
$gray-table-disabled: #b3b3b3;
$global-border: $dmp-border-color;
$gray-table-enabled: #000;
$table-row-hover: #f9f7f7;
$brownish-grey: #efecea;
$panel-step-active: $dmp-blue-lighter;
$panel-step-inactive: #a7b1c5;
$collapse-icon: #c2c3c4;
$warning: #ff7833;
$color-deemphasis: black;

$divider-color: rgba($global-border, 0.2);
$color-below-avg: rgb(226, 87, 104);
$color-average: rgb(245, 173, 86);
$color-good: rgb(89, 202, 144);
$color-great: rgb(87, 195, 125);
$color-excellent: rgb(71, 155, 127);

//color-neutral
$color-neutral: hsl(198, 45%, 47%);
$color-neutral-50: hsl(0, 0%, 98%);
$color-neutral-100: hsl(180, 17%, 98%);
$color-neutral-200: hsl(180, 23%, 95%);
$color-neutral-300: hsl(186, 25%, 92%);
$color-neutral-400: hsl(186, 18%, 90%);
$color-neutral-500: hsl(198, 16%, 80%);
$color-neutral-600: hsl(199, 8%, 70%);
$color-neutral-700: hsl(198, 14%, 60%);
$color-neutral-800: hsl(198, 8%, 50%);
$color-neutral-900: hsl(198, 0%, 40%);
$color-neutral-1000: black;

$neutral-colors: (
  "neutral-base": $color-neutral,
  "neutral-50": $color-neutral-50,
  "neutral-100": $color-neutral-100,
  "neutral-200": $color-neutral-200,
  "neutral-300": $color-neutral-300,
  "neutral-400": $color-neutral-400,
  "neutral-500": $color-neutral-500,
  "neutral-600": $color-neutral-600,
  "neutral-700": $color-neutral-700,
  "neutral-800": $color-neutral-800,
  "neutral-900": $color-neutral-900,
  "neutral-1000": $color-neutral-1000,
);

//color-primary
$color-primary: hsl(198, 47%, 38%);
$color-primary-100: hsl(198, 45%, 95%);
$color-primary-50: hsl(198, 39%, 94%);
$color-primary-200: hsl(198, 45%, 84%);
$color-primary-300: hsl(198, 45%, 68%);
$color-primary-400: hsl(198, 45%, 58%);
$color-primary-500: hsl(198, 47%, 38%);
$color-primary-600: hsl(198, 45%, 38%);
$color-primary-700: hsl(198, 45%, 28%);
$color-primary-800: hsl(198, 45%, 19%);
$color-primary-900: hsl(198, 45%, 9%);
$color-primary-A100: hsl(204, 100%, 87%);
$color-primary-A200: hsl(205, 100%, 77%);
$color-primary-A400: rgb(89, 188, 255);
$color-primary-A700: hsl(205, 100%, 63%);

$primary-colors: (
  "primary-base": $color-primary,
  "primary-50": $color-primary-50,
  "primary-100": $color-primary-100,
  "primary-200": $color-primary-200,
  "primary-300": $color-primary-300,
  "primary-400": $color-primary-400,
  "primary-500": $color-primary-500,
  "primary-600": $color-primary-600,
  "primary-700": $color-primary-700,
  "primary-800": $color-primary-800,
  "primary-900": $color-primary-900,
  "primary-A100": $color-primary-A100,
  "primary-A200": $color-primary-A200,
  "primary-A400": $color-primary-A400,
  "primary-A700": $color-primary-A700,
);

//color state-info
$color-info: rgb(233, 246, 250), rgb(200, 233, 243), rgb(164, 219, 235),
  rgb(128, 204, 227), rgb(100, 193, 221), rgb(73, 182, 215), rgb(66, 175, 211),
  rgb(57, 166, 205), rgb(49, 158, 199), rgb(33, 142, 190), rgb(247, 253, 255),
  rgb(196, 236, 255), rgb(145, 220, 255), rgb(120, 211, 255);

$color-info-50: nth($color-info, 1);
$color-info-100: nth($color-info, 2);
$color-info-200: nth($color-info, 3);
$color-info-300: nth($color-info, 4);
$color-info-400: nth($color-info, 5);
$color-info-500: nth($color-info, 6);
$color-info-600: nth($color-info, 7);
$color-info-700: nth($color-info, 8);
$color-info-800: nth($color-info, 9);
$color-info-900: nth($color-info, 10);
$color-info-A100: nth($color-info, 11);
$color-info-A200: nth($color-info, 12);
$color-info-A400: nth($color-info, 13);
$color-info-A700: nth($color-info, 14);

$info-colors: (
  "info-50": $color-info-50,
  "info-100": $color-info-100,
  "info-200": $color-info-200,
  "info-300": $color-info-300,
  "info-400": $color-info-400,
  "info-500": $color-info-500,
  "info-600": $color-info-600,
  "info-700": $color-info-700,
  "info-800": $color-info-800,
  "info-900": $color-info-900,
  "info-A100": $color-info-A100,
  "info-A200": $color-info-A200,
  "info-A400": $color-info-A400,
  "info-A700": $color-info-A700,
);

//color state success
$color-success: rgb(225, 243, 239), rgb(179, 226, 216), rgb(129, 207, 190),
  rgb(78, 188, 164), rgb(40, 173, 145), rgb(2, 159, 125), rgb(2, 151, 117),
  rgb(1, 141, 106), rgb(1, 131, 96), rgb(1, 114, 77), rgb(161, 255, 219),
  rgb(110, 255, 199), rgb(59, 255, 180), rgb(33, 255, 170);
$color-success-50: nth($color-success, 1);
$color-success-100: nth($color-success, 2);
$color-success-200: nth($color-success, 3);
$color-success-300: nth($color-success, 4);
$color-success-400: nth($color-success, 5);
$color-success-500: nth($color-success, 6);
$color-success-600: nth($color-success, 7);
$color-success-700: nth($color-success, 8);
$color-success-800: nth($color-success, 9);
$color-success-900: nth($color-success, 10);
$color-success-A100: nth($color-success, 11);
$color-success-A200: nth($color-success, 12);
$color-success-A400: nth($color-success, 13);
$color-success-A700: nth($color-success, 14);

$success-colors: (
  "success-50": $color-success-50,
  "success-100": $color-success-100,
  "success-200": $color-success-200,
  "success-300": $color-success-300,
  "success-400": $color-success-400,
  "success-500": $color-success-500,
  "success-600": $color-success-600,
  "success-700": $color-success-700,
  "success-800": $color-success-800,
  "success-900": $color-success-900,
  "success-A100": $color-success-A100,
  "success-A200": $color-success-A200,
  "success-A400": $color-success-A400,
  "success-A700": $color-success-A700,
);

//color state-warning / secondary

/* For use in _color-definitions.scss */
$color-warning: rgb(255, 250, 227), rgb(254, 242, 185), rgb(254, 233, 139),
  rgb(253, 224, 92), rgb(252, 218, 57), rgb(252, 211, 22), rgb(252, 206, 19),
  rgb(251, 200, 16), rgb(251, 194, 12), rgb(250, 183, 6), rgb(255, 255, 255),
  rgb(255, 250, 238), rgb(255, 234, 187), rgb(255, 227, 162);

$color-warning-50: nth($color-warning, 1);
$color-warning-100: nth($color-warning, 2);
$color-warning-200: nth($color-warning, 3);
$color-warning-300: nth($color-warning, 4);
$color-warning-400: nth($color-warning, 5);
$color-warning-500: nth($color-warning, 6);
$color-warning-600: nth($color-warning, 7);
$color-warning-700: nth($color-warning, 8);
$color-warning-800: nth($color-warning, 9);
$color-warning-900: nth($color-warning, 10);
$color-warning-A100: nth($color-warning, 11);
$color-warning-A200: nth($color-warning, 12);
$color-warning-A400: nth($color-warning, 13);
$color-warning-A700: nth($color-warning, 14);

$color-secondary-50: nth($color-warning, 1);
$color-secondary-100: nth($color-warning, 2);
$color-secondary-200: nth($color-warning, 3);
$color-secondary-300: nth($color-warning, 4);
$color-secondary-400: nth($color-warning, 5);
$color-secondary-500: nth($color-warning, 6);
$color-secondary-600: nth($color-warning, 7);
$color-secondary-700: nth($color-warning, 8);
$color-secondary-800: nth($color-warning, 9);
$color-secondary-900: nth($color-warning, 10);
$color-secondary-A100: nth($color-warning, 11);
$color-secondary-A200: nth($color-warning, 12);
$color-secondary-A400: nth($color-warning, 13);
$color-secondary-A700: nth($color-warning, 14);

$warning-colors: (
  "warning-50": $color-warning-50,
  "warning-100": $color-warning-100,
  "warning-200": $color-warning-200,
  "warning-300": $color-warning-300,
  "warning-400": $color-warning-400,
  "warning-500": $color-warning-500,
  "warning-600": $color-warning-600,
  "warning-700": $color-warning-700,
  "warning-800": $color-warning-800,
  "warning-900": $color-warning-900,
  "warning-A100": $color-warning-A100,
  "warning-A200": $color-warning-A200,
  "warning-A400": $color-warning-A400,
  "warning-A700": $color-warning-A700,
);

$secondary-colors: (
  "secondary-50": $color-secondary-50,
  "secondary-100": $color-secondary-100,
  "secondary-200": $color-secondary-200,
  "secondary-300": $color-secondary-300,
  "secondary-400": $color-secondary-400,
  "secondary-500": $color-secondary-500,
  "secondary-600": $color-secondary-600,
  "secondary-700": $color-secondary-700,
  "secondary-800": $color-secondary-800,
  "secondary-900": $color-secondary-900,
  "secondary-A100": $color-secondary-A100,
  "secondary-A200": $color-secondary-A200,
  "secondary-A400": $color-secondary-A400,
  "secondary-A700": $color-secondary-A700,
);

//color-state--danger
$color-danger: rgb(253, 233, 237), rgb(251, 200, 209), rgb(249, 164, 178),
  rgb(246, 128, 147), rgb(244, 100, 124), rgb(242, 73, 101), rgb(240, 66, 93),
  rgb(238, 57, 83), rgb(236, 49, 73), rgb(232, 33, 55), rgb(255, 255, 255),
  rgb(255, 237, 239), rgb(255, 186, 193), rgb(255, 161, 170);

$color-danger-50: nth($color-danger, 1);
$color-danger-100: nth($color-danger, 2);
$color-danger-200: nth($color-danger, 3);
$color-danger-300: nth($color-danger, 4);
$color-danger-400: nth($color-danger, 5);
$color-danger-500: nth($color-danger, 6);
$color-danger-600: nth($color-danger, 7);
$color-danger-700: nth($color-danger, 8);
$color-danger-800: nth($color-danger, 9);
$color-danger-900: nth($color-danger, 10);
$color-danger-A100: nth($color-danger, 11);
$color-danger-A200: nth($color-danger, 12);
$color-danger-A400: nth($color-danger, 13);
$color-danger-A700: nth($color-danger, 14);

$danger-colors: (
  "danger-50": $color-danger-50,
  "danger-100": $color-danger-100,
  "danger-200": $color-danger-200,
  "danger-300": $color-danger-300,
  "danger-400": $color-danger-400,
  "danger-500": $color-danger-500,
  "danger-600": $color-danger-600,
  "danger-700": $color-danger-700,
  "danger-800": $color-danger-800,
  "danger-900": $color-danger-900,
  "danger-a100": $color-danger-A100,
  "danger-a200": $color-danger-A200,
  "danger-a400": $color-danger-A400,
  "danger-a700": $color-danger-A700,
);

////////////////////////////////
//CSS VARIABLES
:root {
  --black: ($black);
  --dark-gray: ($dark-gray);
  --wd-aside: 24rem;

  --color-secondary: #fcd316;
  --color-secondary-hover: #fef4c5;
  --color-secondary-darken-20: #fcd316;

  //states
  --color-state-info: #347490;
  --color-state-info-darken-20: #347490;
  --color-state-info-hover: #c5deeb;
  --color-state-warning: #ff7833;
  --color-state-success: #029f7d;
  /* --color-state-warning-hover:*/
  --color-state-danger: #f24965;
  //text default
  --color-text-default: #{$dmp-text-standard};
  --color-brand-vk: #8f98ef;

  --color-global-border: #ccc;

  --color-neutral-zebra-dark: hsl(210, 25%, 96.9%);
  --color-text-muted: hsl(231, 90% 77%);

  //color-neutral
  --color-neutral-50: #{$color-neutral-50};
  --color-neutral-100: #{$color-neutral-100};
  --color-neutral-200: #{$color-neutral-200};
  --color-neutral-300: #{$color-neutral-300};
  --color-neutral-400: #{$color-neutral-400};
  --color-neutral-500: #{$color-neutral-500};
  --color-neutral-600: #{$color-neutral-600};
  --color-neutral-700: #{$color-neutral-700};
  --color-neutral-800: #{$color-neutral-800};
  --color-neutral-900: #{$color-neutral-900};
  --color-neutral-1000: #{$color-neutral-1000};

  //color-primary
  --color-primary: #{$color-primary};
  --color-primary-50: #{$color-primary-50};
  --color-primary-100: #{$color-primary-100};
  --color-primary-200: #{$color-primary-200};
  --color-primary-300: #{$color-primary-300};
  --color-primary-400: #{$color-primary-400};
  --color-primary-500: #{$color-primary-500};
  --color-primary-600: #{$color-primary-600};
  --color-primary-700: #{$color-primary-700};
  --color-primary-800: #{$color-primary-800};
  --color-primary-900: #{$color-primary-900};
  --color-primary-A100: #{$color-primary-A100};
  --color-primary-A200: #{$color-primary-A200};
  --color-primary-A400: #{$color-primary-A400};
  --color-primary-A700: #{$color-primary-A700};

  //color-secondary
  --color-secondary-50: #{$color-secondary-50};
  --color-secondary-100: #{$color-secondary-100};
  --color-secondary-200: #{$color-secondary-200};
  --color-secondary-300: #{$color-secondary-300};
  --color-secondary-400: #{$color-secondary-400};
  --color-secondary-500: #{$color-secondary-500};
  --color-secondary-600: #{$color-secondary-600};
  --color-secondary-700: #{$color-secondary-700};
  --color-secondary-800: #{$color-secondary-800};
  --color-secondary-900: #{$color-secondary-900};
  --color-secondary-A100: #{$color-secondary-A100};
  --color-secondary-A200: #{$color-secondary-A200};
  --color-secondary-A400: #{$color-secondary-A400};
  --color-secondary-A700: #{$color-secondary-A700};

  //color-success
  --color-success-50: #{$color-success-50};
  --color-success-100: #{$color-success-100};
  --color-success-200: #{$color-success-200};
  --color-success-300: #{$color-success-300};
  --color-success-400: #{$color-success-400};
  --color-success-500: #{$color-success-500};
  --color-success-600: #{$color-success-600};
  --color-success-700: #{$color-success-700};
  --color-success-800: #{$color-success-800};
  --color-success-900: #{$color-success-900};
  --color-success-A100: #{$color-success-A100};
  --color-success-A200: #{$color-success-A200};
  --color-success-A400: #{$color-success-A400};
  --color-success-A700: #{$color-success-A700};

  //color-info
  --color-info-50: #{$color-info-50};
  --color-info-100: #{$color-info-100};
  --color-info-200: #{$color-info-200};
  --color-info-300: #{$color-info-300};
  --color-info-400: #{$color-info-400};
  --color-info-500: #{$color-info-500};
  --color-info-600: #{$color-info-600};
  --color-info-700: #{$color-info-700};
  --color-info-800: #{$color-info-800};
  --color-info-900: #{$color-info-900};
  --color-info-A100: #{$color-info-A100};
  --color-info-A200: #{$color-info-A200};
  --color-info-A400: #{$color-info-A400};
  --color-info-A700: #{$color-info-A700};

  //color-warning
  --color-warning-50: #{$color-warning-50};
  --color-warning-100: #{$color-warning-100};
  --color-warning-200: #{$color-warning-200};
  --color-warning-300: #{$color-warning-300};
  --color-warning-400: #{$color-warning-400};
  --color-warning-500: #{$color-warning-500};
  --color-warning-600: #{$color-warning-600};
  --color-warning-700: #{$color-warning-700};
  --color-warning-800: #{$color-warning-800};
  --color-warning-900: #{$color-warning-900};
  --color-warning-A100: #{$color-warning-A100};
  --color-warning-A200: #{$color-warning-A200};
  --color-warning-A400: #{$color-warning-A400};
  --color-warning-A700: #{$color-warning-A700};

  //color-danger
  --color-danger-50: #{$color-danger-50};
  --color-danger-100: #{$color-danger-100};
  --color-danger-200: #{$color-danger-200};
  --color-danger-300: #{$color-danger-300};
  --color-danger-400: #{$color-danger-400};
  --color-danger-500: #{$color-danger-500};
  --color-danger-600: #{$color-danger-600};
  --color-danger-700: #{$color-danger-700};
  --color-danger-800: #{$color-danger-800};
  --color-danger-900: #{$color-danger-900};
  --color-danger-a100: #{$color-danger-A100};
  --color-danger-a200: #{$color-danger-A200};
  --color-danger-a400: #{$color-danger-A400};
  --color-danger-a700: #{$color-danger-A700};

  //measure
  --measure-half: 0.4rem;
  --measure-1x: 0.8rem;
  --measure-12: 1.2rem;
  --measure-2x: 1.6rem;
  --measure-20: 2rem;
  --measure-3x: 2.4rem;
  --measure-4x: 3.2rem;
  --measure-5x: 4rem;
  --measure-6x: 4.8rem;
  --measure-7x: 5.6rem;
  --measure-8x: 6.4rem;
  --measure-9x: 7.2rem;
  --measure-10x: 8rem;

  //fonts
  --measure-font-10: 1rem;
  --measure-font-12: 1.2rem;
  --measure-font-14: 1.4rem;
  --measure-font-16: 1.6rem;
  --measure-font-18: 1.8rem;
  --measure-font-20: 2rem;
  --measure-font-24: 2.4rem;
  --measure-font-30: 3rem;
  --measure-font-32: 3.2rem;
  --measure-font-36: 3.6rem;
  --measure-font-48: 4.8rem;
  --measure-font-60: 6rem;
  --measure-font-72: 7.2rem;
  //font-weight
  --font-weight-black: 900;
  --font-weight-heavy: 800;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
  --font-weight-thin: 300;
  --font-weight-ultralight: 200;
  //icon-family
  --font-family-icon: "dmp_icon_font", sans-serif;
}

//colors
$color_regal_blue_80_approx_tooltip: $astral;

// Technician colors
$accent-blue: $astral;
$accent-yellow: $candlelight;
$primary-color: $accent-blue;

//$accent-blue link color
$accent-blue-link: $astral;
$accent-blue-link-hover: darken($astral, 15%);
$accent-blue-link-focus: darken($astral, 30%);
$accent-blue-link-disabled: lighten($astral, 30%);

// State Colors
$state-neutral: $black;
$state-neutral-hover: rgba($black, 0.1);
$state-primary: $primary;
$state-primary-hover: darken($state-primary, 20%);
$state-primary-action-button-hover: lighten($state-primary, 5%);
$state-primary-focus: darken($state-primary-hover, 20%);
$state-primary-focus-text: darken($state-primary, 30%);
$state-primary-disabled: lighten($state-primary, 30%);

$state-danger: $dmp-red;
$state-danger-hover: darken($dmp-red, 10%);
$state-danger-focus: darken(rgba($dmp-red, 0.2), 20%);
$state-primary-focus-text: darken($dmp-red, 30%);
$state-danger-disabled: lighten($dmp-red, 30%);

$gray-sidebar: $dmp-light-gray;

// Ada compliant colors
$dmp-red-ada: #c32626;
$dmp-green-ada: #3f8d3f;
$dmp-blue-ada: #218ebe;

// Red Danger Buttons
$btn-danger-ada-bg: $dmp-red-ada;
$btn-danger-ada-border: darken($btn-danger-ada-bg, 1%);

// Blue Primary Buttons
$btn-primary-ada-bg: $dmp-blue-ada;
$btn-primary-ada-border: darken($btn-primary-ada-bg, 1%);
$btn-primary-ada-hover: darken($dmp-dark-gray, 2.5%);

// Classic brand colors

$brand-primary: $dmp-blue;
$brand-success: #029f7d;
$brand-info: $dmp-blue;
$brand-warning: #fcd316;
$brand-danger: $dmp-red;
$brand-neutral: $black;

// New brand colors

$brand-inverse: #131e26;
$brand-green: #37bc9b;
$brand-green-dmp: #0f700f;
$brand-pink: #f532e5;
$brand-purple: #7266ba;
$brand-dark: #3a3f51;
$brand-yellow: #fad732;

// Brand color Variants

$amountOfLight: 10%;

$brand-primary-light: lighten($brand-primary, $amountOfLight);
$brand-success-light: lighten($brand-success, $amountOfLight);
$brand-info-light: lighten($brand-info, $amountOfLight);
$brand-warning-light: lighten($brand-warning, $amountOfLight);
$brand-danger-light: lighten($brand-danger, $amountOfLight);
$brand-inverse-light: lighten($brand-inverse, $amountOfLight);
$brand-green-light: lighten($brand-green, $amountOfLight);
$brand-pink-light: lighten($brand-pink, $amountOfLight);
$brand-purple-light: lighten($brand-purple, $amountOfLight);
$brand-dark-light: lighten($brand-dark, $amountOfLight);
$brand-yellow-light: lighten($brand-yellow, $amountOfLight);

$brand-primary-dark: darken($brand-primary, $amountOfLight);
$brand-success-dark: darken($brand-success, $amountOfLight);
$brand-info-dark: darken($brand-info, $amountOfLight);
$brand-warning-dark: darken($brand-warning, $amountOfLight);
$brand-danger-dark: darken($brand-danger, $amountOfLight);
$brand-inverse-dark: darken($brand-inverse, $amountOfLight);
$brand-green-dark: darken($brand-green, $amountOfLight);
$brand-pink-dark: darken($brand-pink, $amountOfLight);
$brand-purple-dark: darken($brand-purple, $amountOfLight);
$brand-dark-dark: darken($brand-dark, $amountOfLight);
$brand-yellow-dark: darken($brand-yellow, $amountOfLight);
$dmp-light-gray-darker: darken($dmp-light-gray, 10);

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg: $athens-gray;
$content-bg: $bleached-white;
//** Global text color on `<body>`.

$text-color-inverse: $bleached-white;

//** Global textual link color.
$link-color: $brand-primary;
//** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%);
$link-hover-color-light: lighten($link-color, 15%);
//** Link hover decoration.
$link-hover-decoration: underline;
//== Base Margins and Padding Values
$margin-small: 5px;
$margin-base: 10px;
$margin-large: 15px;
$margin-em: 1em;

$padding-small: 5px;
$padding-base: 10px;
$padding-large: 15px;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base: $font-family-sans-serif;
$font-family-icon: "dmp_icon_font", sans-serif;

$font-size-base: 14px;
$font-size-large: ceil(($font-size-base * 1.25)); // ~18px
$font-size-small: ceil(($font-size-base * 0.85)); // ~12px

$font-size-h1: floor(($font-size-base * 2.6)); // ~36px
$font-size-h2: floor(($font-size-base * 2.15)); // ~30px
$font-size-h3: ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5: $font-size-base;
$font-size-h6: ceil(($font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.528571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.1;
$headings-color: inherit;

//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.
$icon-font-path: "/assets/fonts/";
//** File name for all font files.
$icon-font-name: "glyphicons-halflings-regular";
//** Element ID within SVG icon file.
$icon-font-svg-id: "glyphicons_halflingsregular";

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical: 6px;
$padding-base-horizontal: 16px;

$padding-large-vertical: 10px;
$padding-large-horizontal: 20px;

$padding-small-vertical: 5px;
$padding-small-horizontal: 10px;

$padding-xs-vertical: 1px;
$padding-xs-horizontal: 5px;

$line-height-large: 1.33;
$line-height-small: 1.5;

$border-radius-base: 4px;
$border-radius-large: 6px;
$border-radius-small: 3px;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color: $dmp-white-inverse;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $brand-primary;

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base: 4px;
//** Carets increase slightly in size for larger components.
$caret-width-large: 5px;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding: 0.8rem 1.6rem;

$table-header-cell-padding: 0.8rem 1.6rem 1.8rem;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding: 5px;

$table-text: $black;

//** Default background color used for all tables.
$table-bg: $bleached-white;
//** Background color used for `.table-striped`.
$table-bg-accent: $catskill-white;
//** Background color used for `.table-hover`.
$table-bg-hover: darken($table-bg-accent, 5%);

$table-bg-active: darken($table-bg-accent, 10%);

//** Border color for table and cell borders.
$table-border-color: $less-than-white;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight: var(--font-weight-regular);

$btn-default-color: black;
$btn-default-bg: $dmp-white-inverse;
$btn-default-border: black;

$btn-primary-color: $state-primary;
$btn-primary-bg: transparent;
$btn-primary-border: $state-primary;

$btn-success-color: $dmp-white-inverse;
$btn-success-bg: $brand-success;
$btn-success-border: darken($btn-success-bg, 1%);

$btn-info-color: $dmp-white-inverse;
$btn-info-bg: $brand-info;
$btn-info-border: darken($btn-info-bg, 1%);

$btn-warning-color: $dmp-white-inverse;
$btn-warning-bg: $brand-warning;
$btn-warning-border: darken($btn-warning-bg, 1%);

$btn-danger-color: $dmp-white-inverse;
$btn-danger-bg: $brand-danger;
$btn-danger-border: darken($btn-danger-bg, 1%);

$btn-link-disabled-color: $gray-dark;

//== Forms
//
//##

//** `<input>` background color
$input-bg: $dmp-white-inverse;
//** `<input disabled>` background color
$input-bg-disabled: $gray-lighter;

//** Text color for `<input>`s
$input-color: black;
//** `<input>` border color
$input-border: $gray;
// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
$input-border-radius: $border-radius-base;
//** Large `.form-control` border radius
$input-border-radius-large: $border-radius-large;
//** Small `.form-control` border radius
$input-border-radius-small: $border-radius-small;

//** Border color for inputs on focus
$input-border-focus: #66afe9;

//** Placeholder text color
$input-color-placeholder: $dark-gray;

//** Default `.form-control` height
$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 2);
//** Large `.form-control` height
$input-height-large: (
  ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) +
    2
);
//** Small `.form-control` height
$input-height-small: (
  floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) +
    2
);

$legend-color: $gray-dark;
$legend-border-color: $athens-gray;
$legend-background-color: $athens-gray;

//** Background color for textual input addons
$input-group-addon-bg: $gray-lighter;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border;

//** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed;

//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg: $dmp-white-inverse;
//** Dropdown menu `border-color`.
$dropdown-border: #e1e1e1;
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border: #cccccc;
//** Divider color for between dropdown items.
$dropdown-divider-bg: #e5e5e5;

//** Dropdown link text color.
$dropdown-link-color: #5f5f5f;
//** Hover color for dropdown links.
$dropdown-link-hover-color: darken($gray-dark, 5%);
//** Hover background for dropdown links.
$dropdown-link-hover-bg: #fafbfc;

//** Active dropdown menu item text color.
$dropdown-link-active-color: $component-active-color;
//** Active dropdown menu item background color.
$dropdown-link-active-bg: $component-active-bg;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-light;

//** Text color for headers within dropdown menus.
$dropdown-header-color: $gray-light;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color: #000000;

//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal-background: 1040;
$zindex-modal: 1050;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: ((720px + $grid-gutter-width));
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet;

// Medium screen / desktop
$container-desktop: ((940px + $grid-gutter-width));
//** For `$screen-md-min` and up.
$container-md: $container-desktop;

// Large screen / wide desktop
$container-large-desktop: ((1140px + $grid-gutter-width));
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;

//== Navbar
//
//##

// Basics of a navbar
$navbar-height: 55px;
$navbar-margin-bottom: $line-height-computed;
$navbar-border-radius: $border-radius-base;
$navbar-padding-horizontal: floor(($grid-gutter-width / 2));
$navbar-padding-vertical: (($navbar-height - $line-height-computed) / 2);
$navbar-collapse-max-height: 340px;

$navbar-default-color: #777777;
$navbar-default-bg: #f8f8f8;
$navbar-default-border: darken($navbar-default-bg, 6.5%);

// Navbar links
$navbar-default-link-color: $dmp-white-inverse;
$navbar-default-link-hover-color: $dmp-layout-border-darker;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: $dmp-layout-border-darker;
$navbar-default-link-active-bg: darken($navbar-default-bg, 6.5%);
$navbar-default-link-disabled-color: #cccccc;
$navbar-default-link-disabled-bg: transparent;
$navbar-is-active-bg: $primary-color;
$navbar-is-hovered-bg: lighten($navbar-is-active-bg, 30%);
$navbar-is-active-color: $bleached-white;

// Navbar brand label
$navbar-default-brand-color: $navbar-default-link-color;
$navbar-default-brand-hover-color: darken($navbar-default-brand-color, 10%);
$navbar-default-brand-hover-bg: transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg: $dmp-layout-border-darker;
$navbar-default-toggle-icon-bar-bg: #888888;
$navbar-default-toggle-border-color: $dmp-layout-border-darker;

// Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: $gray-light;
$navbar-inverse-bg: #222222;
$navbar-inverse-border: darken($navbar-inverse-bg, 10%);

// Inverted navbar links
$navbar-inverse-link-color: $gray-light;
$navbar-inverse-link-hover-color: $dmp-white-inverse;
$navbar-inverse-link-hover-bg: transparent;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg: darken($navbar-inverse-bg, 10%);
$navbar-inverse-link-disabled-color: #444444;
$navbar-inverse-link-disabled-bg: transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color: $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color: $dmp-white-inverse;
$navbar-inverse-brand-hover-bg: transparent;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg: #333333;
$navbar-inverse-toggle-icon-bar-bg: $dmp-white-inverse;
$navbar-inverse-toggle-border-color: #333333;

//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding: 10px 15px;
$nav-link-hover-bg: transparent;

$nav-disabled-link-color: $gray-light;
$nav-disabled-link-hover-color: $gray-light;

$nav-open-link-hover-color: $dmp-white-inverse;

//== Tabs
$nav-tabs-border-color: $dmp-layout-border-darker;

$nav-tabs-link-hover-border-color: $dmp-layout-border-darker;
$nav-tabs-inactive: $gainsboro;
$nav-tabs-active-link-hover-bg: $dmp-white-inverse;
$nav-tabs-active-link-hover-color: $gray;
$nav-tabs-active-link-hover-border-color: $dmp-layout-border-darker;

$nav-tabs-justified-link-border-color: $dmp-layout-border-darker;
$nav-tabs-justified-active-link-border-color: $body-bg;

$tab-active-bg-color: $brand-primary;
$tab-active-color: $bleached-white;

//== Pills
$nav-pills-border-radius: $border-radius-base;
$nav-pills-active-link-hover-bg: $component-active-bg;
$nav-pills-active-link-hover-color: $component-active-color;

//== Pagination
//
//##

$pagination-color: $text-color;
$pagination-bg: $dmp-white-inverse;
$pagination-border: #eeeeee;

$pagination-hover-color: white;
$pagination-hover-bg: $dmp-light-gray;
$pagination-hover-border: #eeeeee;

$pagination-active-color: $dmp-white-inverse;
$pagination-active-bg: $brand-primary;
$pagination-active-border: $brand-primary;

$pagination-disabled-color: $gray-light;
$pagination-disabled-bg: $dmp-white-inverse;
$pagination-disabled-border: #eeeeee;

//== Pager
//
//##

$pager-bg: $pagination-bg;
$pager-border: $pagination-border;
$pager-border-radius: 15px;

$pager-hover-bg: $pagination-hover-bg;

$pager-active-bg: $pagination-active-bg;
$pager-active-color: $pagination-active-color;

$pager-disabled-color: $pagination-disabled-color;

//== Jumbotron
//
//##

$jumbotron-padding: 30px;
$jumbotron-color: inherit;
$jumbotron-bg: #fcfcfc;
$jumbotron-heading-color: inherit;
$jumbotron-font-size: ceil(($font-size-base * 1.5));

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text: $brand-success;
$state-success-bg: #dff0d8;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%);

$state-info-text: $state-info;
$state-info-bg: #d9edf7;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%);

$state-warning-text: $brand-warning;
$state-warning-bg: #fcf8e3;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%);

$state-danger-text: $color-danger-500;
$state-danger-bg: $color-danger-200;
$state-danger-border: $color-danger-900;

//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width: 200px;
//** Tooltip text color
$tooltip-color: $dmp-white-inverse;
//** Tooltip background color
$tooltip-bg: $primary-color;
$tooltip-opacity: 0.9;

//** Tooltip arrow width
$tooltip-arrow-width: 5px;
//** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg;

//== Popovers
//
//##

//** Popover body background color
$popover-bg: $dmp-white-inverse;
//** Popover maximum width
$popover-max-width: 276px;
//** Popover border color
$popover-border-color: #e1e1e1;
//** Popover fallback border color
$popover-fallback-border-color: #cccccc;

//** Popover title background color
$popover-title-bg: $dmp-light-gray;

//** Popover arrow width
$popover-arrow-width: 10px;
//** Popover arrow color
$popover-arrow-color: $dmp-light-gray;

//** Popover outer arrow width
$popover-arrow-outer-width: ($popover-arrow-width + 1);
//** Popover outer arrow color
$popover-arrow-outer-color: $popover-border-color;
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color: darken(
  $popover-fallback-border-color,
  20%
);

//== Labels
//
//##

//** Default label background color
$label-default-bg: $gray-dark;
//** Primary label background color
$label-primary-bg: $brand-primary;
//** Success label background color
$label-success-bg: $brand-success;
//** Info label background color
$label-info-bg: $brand-info;
//** Warning label background color
$label-warning-bg: $brand-warning;
//** Danger label background color
$label-danger-bg: $brand-danger;

//** Default label text color
$label-color: $bleached-white;
//** Default text color of a linked label
$label-link-hover-color: $dmp-white-inverse;

//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding: 15px;

//** Padding applied to the modal title
$modal-title-padding: 1.6rem;
//** Modal title line-height
$modal-title-line-height: $line-height-base;

//** Background color of modal content area
$modal-content-bg: $dmp-white-inverse;
//** Modal content border color
$modal-content-border-color: rgba(0, 0, 0, 0.2);
//** Modal content border color **for IE8**
$modal-content-fallback-border-color: #999999;

//** Modal backdrop background color
$modal-backdrop-bg: #000000;
//** Modal backdrop opacity
$modal-backdrop-opacity: 0.5;
//** Modal header border color
$modal-header-border-color: #e5e5e5;
//** Modal footer border color
$modal-footer-border-color: $modal-header-border-color;

$modal-lg: 900px;
$modal-md: 600px;
$modal-sm: 300px;

//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding: 8px;
$alert-border-radius: $border-radius-small;
$alert-link-font-weight: bold;

$alert-success-bg: $brand-success;
$alert-success-text: $dmp-white-inverse;
$alert-success-border: rgba(0, 0, 0, 0.1);

$alert-info-bg: $brand-info;
$alert-info-text: $dmp-white-inverse;
$alert-info-border: rgba(0, 0, 0, 0.1);

$alert-warning-bg: $brand-warning;
$alert-warning-text: $dmp-white-inverse;
$alert-warning-border: rgba(0, 0, 0, 0.1);

$alert-danger-bg: $brand-danger;
$alert-danger-text: $dmp-white-inverse;
$alert-danger-border: rgba(0, 0, 0, 0.1);

//== Progress bars
//
//##

//** Background color of the whole progress component
$progress-bg: #f1f1f1;
//** Progress bar text color
$progress-bar-color: $dmp-white-inverse;
//** Variable for setting rounded corners on progress bar.
$progress-border-radius: $border-radius-base;

//** Default progress bar color
$progress-bar-bg: $brand-primary;
//** Success progress bar color
$progress-bar-success-bg: $brand-success;
//** Warning progress bar color
$progress-bar-warning-bg: $brand-warning;
//** Danger progress bar color
$progress-bar-danger-bg: $brand-danger;
//** Info progress bar color
$progress-bar-info-bg: $brand-info;

//== List group
//
//##

//** Background color on `.list-group-item`
$list-group-bg: $dmp-white-inverse;
//** `.list-group-item` border color
$list-group-border: $gray-light;
//** List group border radius
$list-group-border-radius: $border-radius-base;

//** Background color of single list items on hover
$list-group-hover-bg: #fafbfc;
//** Text color of active list items
$list-group-active-color: $component-active-color;
//** Background color of active list items
$list-group-active-bg: $component-active-bg;
//** Border color of active list elements
$list-group-active-border: $list-group-active-bg;
//** Text color for content within active list items
$list-group-active-text-color: lighten($list-group-active-bg, 40%);

//** Text color of disabled list items
$list-group-disabled-color: $gray-light;
//** Background color of disabled list items
$list-group-disabled-bg: $gray-lighter;
//** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color;

$list-group-link-color: #555555;
$list-group-link-hover-color: $list-group-link-color;
$list-group-link-heading-color: #333333;

//== Panels
//
//##
$panel-global-border: $global-border;

$panel-bg: $dmp-white-inverse;
$panel-body-padding: 15px;
$panel-heading-padding: 10px 15px;
$panel-footer-padding: $panel-heading-padding;
$panel-border-radius: $border-radius-base;

//** Border color for elements within panels
$panel-inner-border: #eeeeee;

$panel-default-heading-bg-dmp: $dmp-blue;

//** Border color for elements within panels
$panel-inner-border: #eeeeee;

$panel-footer-bg: $dmp-light-gray;

$panel-default-text: #444444;
$panel-default-border: $panel-global-border;
$panel-default-heading-bg: $dmp-white-inverse;

$panel-primary-text: $dmp-white-inverse $dmp-white-inverse;
$panel-primary-border: $panel-global-border;
$panel-primary-heading-bg: $brand-primary;

$panel-success-text: $dmp-white-inverse;
$panel-success-border: $panel-global-border;
$panel-success-heading-bg: $brand-success;

$panel-info-text: $dmp-white-inverse;
$panel-info-border: $panel-global-border;
$panel-info-heading-bg: $brand-info;

$panel-warning-text: $dmp-white-inverse;
$panel-warning-border: $panel-global-border;
$panel-warning-heading-bg: $brand-warning;

$panel-danger-text: $dmp-white-inverse;
$panel-danger-border: $panel-global-border;
$panel-danger-heading-bg: $brand-danger;

//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding: 2px;
//** Thumbnail background color
$thumbnail-bg: #e1e2e3;
//** Thumbnail border color
$thumbnail-border: #e1e2e3;
//** Thumbnail border radius
$thumbnail-border-radius: $border-radius-small;

//** Custom text color for thumbnail captions
$thumbnail-caption-color: $text-color;
//** Padding around the thumbnail caption
$thumbnail-caption-padding: 9px;

//== Wells
//
//##

$well-bg: $dmp-white-inverse;
$well-border: darken($well-bg, 7%);

//== Badges
//
//##

$badge-color: $dmp-white-inverse;
//** Linked badge text color on hover
$badge-link-hover-color: $dmp-white-inverse;
$badge-bg: $gray-light;

//** Badge text color in active nav link
$badge-active-color: $link-color;
//** Badge background color in active nav link
$badge-active-bg: $dmp-white-inverse;

$badge-font-weight: bold;
$badge-line-height: 1;
$badge-border-radius: 10px;

//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical: 12px;
$breadcrumb-padding-horizontal: 15px;
//** Breadcrumb background color
$breadcrumb-bg: $dmp-light-gray;
//** Breadcrumb text color
$breadcrumb-color: #c1c2c3;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color: $text-color;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator: ">";

//== Carousel
//
//##

$carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);

$carousel-control-color: $dmp-white-inverse;
$carousel-control-width: 15%;
$carousel-control-opacity: 0.5;
$carousel-control-font-size: 20px;

$carousel-indicator-active-bg: $dmp-white-inverse;
$carousel-indicator-border-color: $dmp-white-inverse;

$carousel-caption-color: $dmp-white-inverse;

//== Close
//
//##

$close-font-weight: bold;
$close-color: #000000;
$close-text-shadow: 0 1px 0 $dmp-white-inverse;

//== Code
//
//##

$code-color: #c7254e;
$code-bg: #f9f2f4;

$kbd-color: $dmp-white-inverse;
$kbd-bg: #333333;

$pre-bg: #f5f5f5;
$pre-color: $gray-dark;
$pre-border-color: #cccccc;
$pre-scrollable-max-height: 340px;

//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px;
//** Text muted color
$text-muted: $dark-gray;
//** Abbreviations and acronyms border color
$abbr-border-color: $gray-dark;
//** Headings small color
$headings-small-color: $gray-dark;
//** Blockquote small color
$blockquote-small-color: $gray-dark;
//** Blockquote font size
$blockquote-font-size: ($font-size-base * 1.25);
//** Blockquote border color
$blockquote-border-color: $gray-lighter;
//** Page header border color
$page-header-border-color: $gray-lighter;
//** Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal;
//** Horizontal line color.
$hr-border: $gray-lighter;

@mixin global-border {
  border: 0.1rem solid $global-border;
  border-radius: 0.4rem;
}
