.outer-shell {
  display: flex;
  flex-direction: column;
}
.form-row {
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 1.2rem;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.label-container {
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-right: 0.5rem;
  justify-content: center;
  position: relative;
  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
}
.sub-label {
  font-size: 1rem;
  color: $dmp-blue;
  position: absolute;
  top: calc(100% - 0.5rem);
}
.spinner-helper {
  position: absolute;
  top: 1.6rem;
  text-wrap: nowrap;
  color: $dmp-blue;
}

.ui-select-shell {
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.input-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.input-container .decrement-button {
  background-color: white;
  color: black;
  padding: 0.5rem 1.5rem;
  font-size: 1.6rem;
  cursor: pointer;
  border: 0.1rem solid $dmp-border-color;
  height: 3.5rem;
  border-radius: 0.5rem 0 0 0.5rem;
}

.input-container .increment-button {
  background-color: white;
  color: black;
  padding: 0.5rem 1.5rem;
  font-size: 1.6rem;
  cursor: pointer;
  border: 0.1rem solid $dmp-border-color;
  height: 3.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
}

.input-container button:hover {
  background-color: #e6e6e6;
}

.input-container input {
  text-align: center;
  font-size: 1.4rem;
  padding: 0.6rem 1.6rem;
  border: 0.1rem solid $dmp-border-color;
  height: 3.5rem;
  box-sizing: border-box;
  border-left: none;
  border-right: none;
  width: 100%;
}

.add-cred-btn {
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  width: 100%;
  height: 3.5rem;
  border: 0.1rem solid $dmp-border-color;
  font-size: 1.4rem;
  background-color: white;
}
.add-cred-btn:hover {
  background-color: white !important;
}
/* Hide native browser spinners - Cross-browser compatibility */
/* WebKit Browsers */
.input-container input[type="number"]::-webkit-inner-spin-button,
.input-container input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-container input[type="number"] {
  -moz-appearance: textfield;
}

/* All other browsers */
.input-container input[type="number"] {
  appearance: textfield;
}
