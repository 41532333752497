@mixin programming-header {
  position: relative;
  //padding: var(--measure-2x);
  > a {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > h4.panel-title {
      > span {
        > i:before {
          position: absolute;
          top: 0.4rem;
          right: 0.8rem;
        }
      }
    }
  }
}

.accordion-toggle {
  &.keyfob-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.panel-list-oveflow-control {
  max-height: 200px;
  overflow-y: auto;
}

.panel-body.programming {
  //padding: 0 .5em .5em .5em;
  padding: $panel-heading-padding;
}

.panel-normal-border {
  margin-bottom: 21px;
  background-color: $body-bg;
  border: 1px solid $dmp-border-color;
  border-radius: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-heading a.collapsed:after {
  content: "\e080";
}

div.panel-heading.lean-heading {
  padding: 0;
}

.panel {
  &.panel-default {
    //border-top-width: 1px;
  }
  box-shadow: none;
}

.panel-default .panel-heading paneltool > a {
  color: #c1c2c3;
  cursor: pointer;
}

.panel-body {
  & .customer-table {
    padding-top: 1em;
  }
}

.panel-footer {
  &.no-color {
    padding: 10px 15px;
    background-color: inherit;
    border-top: none;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

.panel-heading {
  &.zone-panel-heading {
    //padding: 0 1.9em;
  }
  &.panel-heading-color {
    background-color: $dmp-light-gray;
  }
}

.panel-dmp-info {
  @include panel-variant(
    $panel-default-border,
    $panel-default-text,
    $panel-default-heading-bg-dmp,
    $panel-default-border
  );
}
.well {
  background-color: $dmp-light-gray;
  border: none;
}
.fixed-height-border-scroll-y {
  max-height: 250px;
  overflow-y: scroll;
}

/*Cell Signal Page
----------------------------------*/

div {
  &.page_cell-signal.panel-body,
  &#panelChart2.page_cell-signal.panel,
  &.page_cell-signal.flot-chart-wrapper.panel-body {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

.panel.panel-dashboard {
  .panel-title {
    font-size: 1em !important;

    color: inherit;
    line-height: 0px;
    padding: var(--measure-3x);
  }
  .panel-heading {
    //padding-bottom: 0;
    .panel-title {
      hr {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }
  }
  a.list-group-item,
  a {
    border: 0;
  }
}

/* Video
---------------------------------*/
.channel-placeholder {
  height: 13.4em;
  width: 100%;
}
.isgreyedout {
  background: #efefef;
}

/* Secura Settings
---------------------------------*/
.custom-parts .panel-group:before {
  content: none;
}
.custom-parts .panel-group {
  margin-top: 5px;
}

.panel-dropup-friendly {
  border: 1px solid $gray;

  border-radius: 4px;
  margin-bottom: 15px;
}
.panel-dropup-friendly-header {
  border-bottom: 0;
  background-color: $dmp-light-gray;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: $border-radius-base;
  padding: 10px 15px;
  .title {
    padding: 0;
    border-bottom: 1px solid transparent;
    //Instead of the line below you could use @include border-top-right-radius($radius)
    border-top-right-radius: 3px;
    //Instead of the line below you could use @include border-top-left-radius($radius)
    border-top-left-radius: 3px;
    font-size: 1.5em;
  }
}
.panel-dropup-friendly-body {
  padding: 15px;
}

@keyframes step-active-pulse {
  0%,
  100% {
    color: $dmp-text-light;
  }

  50% {
    color: #85b0cc;
  }
}

.panel {
  border: 1px solid $panel-global-border;
}

.panel {
  box-shadow: none;
}

.panel {
  margin-bottom: var(--measure-3x);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;

  .panel-title {
    padding: var(--measure-3x) var(--measure-3x) 1.2rem;
  }
  h4 {
    &.panel-title {
      padding: 0;
    }
  }
  .panel-body {
    padding: $padding-large;
  }
}

.panel-heading {
  padding: var(--measure-1x) var(--measure-2x);
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  //background-color: rgba(114, 126, 144, 0.31);

  &-programming {
    @include programming-header;
  }

  &--co-store,
  &--defaults {
    @include programming-header;
  }
  // programming-cards - header with no delete button
  // needed min-height to match items with delete button
  &-programming-cards {
    @include programming-header;
    min-height: 5.2rem;
  }

  &-keyfob {
    > div {
      > span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: var(--measure-1x);
        margin-bottom: var(--measure-1x);
      }
    }
  }
}

.panel {
  padding: var(--measure-2x);
  &--no-padding,
  &-table {
    padding: 0;
  }
  .panel-body {
    padding: 0;
  }
  &--address-panel {
    margin-top: 3.7rem;
    margin-bottom: var(--measure-2x);
  }
  &--address-panel {
    margin-top: 3.7rem;
    margin-bottom: var(--measure-2x);
  }

  &--primary-custom {
    background: var(--color-primary-200);
    color: var(--color-primary-600);
    border: 1px solid var(--color-primary-600);
  }
  &--primary-dashboard {
    background: var(--color-warning-900);
    color: black;
    border: 1px solid black;
  }
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: var(--measure-font-18);
  padding: var(--measure-3x);
  color: inherit;
}
