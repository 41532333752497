/* ========================================================================
     Component: user-block
 ========================================================================== */

// for use as navbar item
.has-user-block {
  display: block;
  overflow: hidden;
  border: 0 !important;
}

.user-block {
  /* position: relative;
  padding: 25px 30px 0;
  cursor: pointer;
  @include clearfix();*/

  > .user-block-picture {
    position: relative;
    width: 60px;
    margin: 0 auto;
    > img {
      max-width: 100%;
      height: auto;
    }
  }

  .user-block-info {
    padding-top: 15px;
    text-align: center;
    .user-block-name,
    .user-block-role {
      display: block;
    }

    .user-block-name {
      color: #7d848f;
    }
    .user-block-role {
      font-size: 12px;
      color: #aaa;
    }
  }
}

.user-block-status {
  position: relative;
  > .circle {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid $dmp-white-inverse;
  }
}

@media only screen and (min-width: $mq-tablet) {
  .aside-collapsed {
    .user-block {
      padding: 10px 0 0;
      margin: 0;
      text-align: center;
      transition: all 0.3s ease-out;

      > .user-block-picture {
        float: none;
        margin: 0 auto;
        width: 50px;
        > .user-block-status {
          display: block;
        }
      }
      .user-block-role,
      .user-block-status,
      .user-block-name {
        display: none;
      }
    }
  }
}
