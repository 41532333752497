/*
*   Buttons-custom.scss
----------------------------------- */

// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

@mixin btn-add-base-styles {
  background: $state-primary;
  color: $bleached-white;
  border-color: $state-primary;
  text-decoration: none;
  transition: all 10ms linear;
  margin-bottom: var(--measure-1x);
  position: relative;
  z-index: 1;
  border-radius: 40rem;
  font-size: 1.4rem;
  padding: var(--measure-half) var(--measure-1x);
  &:hover {
    background: $state-primary-action-button-hover;
    color: $bleached-white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    -webkit-transform: translateY(-0.01rem);
    -moz-transform: translateY(-0.01rem);
    -ms-transform: translateY(-0.01rem);
    -o-transform: translateY(-0.01rem);
    transform: translateY(-0.01rem);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  &:focus,
  .focus {
    background: darken($state-primary-action-button-hover, 20%);
    color: $bleached-white;
    box-shadow: none;
    -webkit-transform: translateY(0.01rem);
    -moz-transform: translateY(0.01rem);
    -ms-transform: translateY(0.01rem);
    -o-transform: translateY(0.01rem);
    transform: translateY(0.01rem);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  @media (min-width: $screen-sm) {
    margin-bottom: 0;
  }
}

@mixin btn-add-outline-base-styles {
  background: white;
  color: $state-primary;
  border-color: $state-primary;
  text-decoration: none;
  margin-bottom: var(--measure-1x);
  position: relative;
  z-index: 1;
  border-radius: 40rem;
  font-size: 1.4rem;
  padding: var(--measure-half) var(--measure-1x);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    background: rgba($state-primary, 0.2);
    color: darken($state-primary, 20%);
    border-color: darken($state-primary, 20%);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -webkit-transform: translateY(-0.01rem);
    -moz-transform: translateY(-0.01rem);
    -ms-transform: translateY(-0.01rem);
    -o-transform: translateY(-0.01rem);
    transform: translateY(-0.01rem);
  }
  &:focus,
  .focus {
    background: darken($state-primary-focus, 20%);
    color: $state-primary-focus;
    box-shadow: none;
    -webkit-transform: translateY(0.01rem);
    -moz-transform: translateY(0.01rem);
    -ms-transform: translateY(0.01rem);
    -o-transform: translateY(0.01rem);
    transform: translateY(0.01rem);
  }

  @media (min-width: $screen-sm) {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  text-align: center;
  vertical-align: middle;
  font-weight: $btn-font-weight;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 0.1rem solid transparent;
  white-space: nowrap;
  @include button-size(
    $padding-base-vertical,
    $padding-base-horizontal,
    $font-size-base,
    $line-height-base,
    $border-radius-base
  );
  @include user-select(none);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    pointer-events: none; // Future-proof disabling of clicks
    @include opacity(0.4);
    @include box-shadow(none);
  }

  &.btn-danger {
    &.disabled,
    &[disabled] {
      cursor: $cursor-disabled;
      pointer-events: none; // Future-proof disabling of clicks
      color: desaturate($state-danger, 40%) !important;
      background-color: $bleached-white !important;
      border: 1px solid desaturate($state-danger, 40%) !important;
    }
  }
}

// Alternate buttons
// --------------------------------------------------

.btn-default {
  @include button-variant(black, $btn-default-bg, black);
}

.btn-primary {
  @include button-variant(white, $btn-primary-color, $btn-primary-border);

  &--solid {
    @include button-variant(white, $btn-primary-color, $btn-primary-border);
  }
}

.btn-primary-ada {
  @include button-variant(white, $btn-primary-ada-bg, $btn-primary-ada-border);
}

// Success appears as green
.btn-success {
  @include button-variant(
    $btn-success-color,
    $btn-success-bg,
    $btn-success-border
  );
}

// Info appears as blue-green
.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

// Warning appears as orange
.btn-warning {
  @include button-variant(
    $btn-warning-color,
    $btn-warning-bg,
    $btn-warning-border
  );
}

// Danger and error appear as red
.btn-danger {
  @include button-variant(
    $btn-danger-color,
    $btn-danger-bg,
    $btn-danger-border
  );

  &--solid {
    @include button-variant(white, $btn-danger-bg, $btn-danger-border);
  }
}

// Danger ADA Compliant
.btn-danger-ada {
  @include button-variant(
    $btn-danger-color,
    $btn-danger-ada-bg,
    $btn-danger-ada-border
  );
}

// Danger ADA Compliant
.btn-dmp-ada {
  @include button-variant(
    $btn-primary-ada-bg,
    $bleached-white,
    $btn-primary-ada-bg
  );
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: $link-color;
  font-weight: normal;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    @include box-shadow(none);
  }

  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: underline;
    background-color: transparent;
  }

  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size(
    $padding-large-vertical,
    $padding-large-horizontal,
    $font-size-large,
    $line-height-large,
    $border-radius-large
  );
}

.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size(
    $padding-small-vertical,
    $padding-small-horizontal,
    $font-size-small,
    $line-height-small,
    $border-radius-small
  );
}

.btn-xs {
  @include button-size(
    $padding-xs-vertical,
    $padding-xs-horizontal,
    $font-size-small,
    $line-height-small,
    $border-radius-small
  );
}

// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

//-------------------------------

.btn-alert,
.btn-danger {
  background: $state-danger;
  color: $bleached-white;
  border-color: $state-danger;
  text-decoration: none;

  &:hover {
    background: $state-danger-hover;
    transition: all 0.3s ease-out;
    color: $bleached-white;
  }

  &:focus {
    border-color: darken($state-danger, 10%);
    color: darken($state-danger, 30%);
    box-shadow: none;
    background: rgba(darken($state-danger, 20%), 0.2);
    transition: all 0.3s ease-out;
  }
}

.btn-dmp,
.btn-info {
  background: $primary;
  color: white;
  border-color: $primary;
  text-decoration: none;
  transition: all 0.3s ease-out;

  &--input-group-btn {
    border-color: $gray;
  }

  &:hover,
  &:focus,
  .focus {
    background: $state-primary-hover;
    transition: all 0.3s ease-out;
  }

  &.btn-dmp--inverse {
    background: $state-primary;
    color: $bleached-white;
    border-color: $state-primary;

    &:hover,
    &:focus,
    .focus {
      background: $state-primary-hover;
      transition: all 0.3s ease-out;
    }
  }
}
.btn-dmp--dark {
  background: $bleached-white;
  color: var(--color-primary-700);
  border-color: var(--color-primary-700);
  text-decoration: none;
  transition: all 0.3s ease-out;

  &:hover,
  &:focus,
  .focus {
    background: var(--color-primary-200);
    transition: all 0.3s ease-out;
  }
}
.btn-dmp-ada {
  &:hover {
    border-color: $btn-primary-ada-border;
    color: darken($dmp-blue-ada, 10%);
    box-shadow: none;
    background: rgba($dmp-blue-ada, 0.2);
    transition: all 0.3s ease-out;
  }
}
.btn-add--outline {
  @include btn-add-outline-base-styles;
  &:before {
    font-family: dmp_icon_font;
    content: "\e97a";
    font-size: 1.2rem;

    // margin-right: var(--measure-1x);
  }
}

.btn-add {
  @include btn-add-base-styles;

  &--svg {
    @include btn-add-base-styles;
  }

  &--no-icon {
    @include btn-add-base-styles;
    padding: 0 var(--measure-1x);

    &.btn-padding {
      padding: var(--measure-half) var(--measure-1x);
    }
  }
  &.btn-slim {
    padding: 0 var(--measure-1x);
    &--with-pulse {
      &:after {
        display: flex;
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        //background-color: var(--color-primary-200);
        border-radius: 4px;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all 0.4s;
        opacity: 1;
        animation: pulse 2s 1s forwards;
        animation-iteration-count: 6;
        box-shadow: 0 0 0 rgba(95, 195, 255, 0.4);
      }
    }
  }

  &--no-text {
    position: relative;
    @include btn-add-base-styles;
    height: var(--measure-4x);
    width: var(--measure-4x);
    border-radius: 50%;

    &:after {
      content: "" !important;
    }

    &:before {
      position: absolute;
      font-family: dmp_icon_font;
      content: "\e97a";
      margin-right: 0 !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:before {
    font-family: dmp_icon_font;
    content: "\e97a";
    // margin-right: var(--measure-1x);
  }

  /* &:after {
        content: "Add";
    }*/

  &:hover {
    background: $state-primary-action-button-hover;
    color: $bleached-white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    -webkit-transform: translateY(-0.01rem);
    -moz-transform: translateY(-0.01rem);
    -ms-transform: translateY(-0.01rem);
    -o-transform: translateY(-0.01rem);
    transform: translateY(-0.01rem);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:focus,
    .focus {
      background: darken($state-primary-action-button-hover, 20%);
      color: $bleached-white;
      box-shadow: none;
      -webkit-transform: translateY(0.01rem);
      -moz-transform: translateY(0.01rem);
      -ms-transform: translateY(0.01rem);
      -o-transform: translateY(0.01rem);
      transform: translateY(0.01rem);
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
  }

  &-custom,
  &--custom {
    &:after {
      content: "";

      &--no-icon {
        &:before {
          content: "" !important;
        }
      }
    }
  }
}
.btn-go-vk {
  position: relative;
  padding-bottom: 0.22rem;
  &:after {
    font-family: dmp_icon_font;
    content: unicode($icon-control_right);
    margin-right: var(--measure-1x);
    position: relative;
    right: -1.2rem;
    font-size: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.btn-dmp:hover,
.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info:active,
.btn-info.active,
.open > .btn-info.dropdown-toggle {
  color: white;
  background-color: $state-primary-hover;
  border-color: $state-primary-hover;

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.btn-dmp:focus {
  border-color: darken($state-primary, 0.2%);
  color: white;
  box-shadow: none;
  background: darken($state-primary, 0.2%);
  transition: all 0.3s ease-out;
}

.btn-gray {
  color: $text-color;
  background: #d7d4d2;

  &:hover,
  &:focus,
  .focus {
    color: $text-color;
    background: $dmp-dark-gray;
    text-decoration: none;
  }
}

.datatable-tools-btn {
  font-size: 13px;
  padding: 3px 8px;
}

.btn-xs-xs {
  padding: 2px 3px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-success {
  color: var(--color-state-success);
  border-color: var(--color-state-success);
  background: $dmp-white-inverse;

  &:hover {
    color: $brand-success;
    background-color: rgba($brand-success, 0.2);
    border-color: $brand-success;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}

// zone buttons for detail view and delete
button.btn-inline-accordion {
  width: 94%;
  margin-left: 0%;
  margin-right: 6%;
  height: 2.8em;
  margin-top: 0.5em;

  &.btn-2-line {
    width: 100%;
    margin-left: -3%;
    height: 2.8em;
    margin-top: 0.5em;
    line-height: 0.8em;
  }
}

@media screen and (min-width: $mq-tablet) {
  button.btn-inline-accordion {
    width: 100%;
    height: 2.8em;
    margin-top: 2.1em;

    &.btn-2-line {
      width: 100%;
      height: 2.8em;
      margin-top: 2.2em;
      line-height: 0.8em;
    }
  }
}

//  Help button

em[class^="btn-help"] {
  &.icon-question {
    &.btn-help-concept {
      font-size: 1em;
      cursor: pointer;
      position: relative;
      top: 4px;
      @include prefix(
        (
          transition: all(0.1s ease-in),
        ),
        webkit ms o moz
      );
      //noinspection CssInvalidFunction
      &:hover {
        @include prefix(
          (
            transform: scale(1.1, 1.1),
          ),
          webkit ms o moz
        );
        @include prefix(
          (
            transition: all(0.1s ease-in),
          ),
          webkit ms o moz
        );
        color: #000;
      }
    }
  }
}

/*button.btn.btn-default.ng-binding,
button.btn.btn-default.dropdown-toggle,
button.btn.btn-default {
  margin-top: 0;
}*/

#delete-btn {
  transition: 1s;
}

svg:hover #delete-btn {
  fill: darkslateblue;
}

.one,
.two {
  position: absolute;
  border-radius: 4px;
  background: #000;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 400ms all ease-out;
  transition: 400ms all ease-out;
}

.one {
  top: 32.5px;
  left: 10px;
  right: 10px;
  height: 10px;
}

.two {
  top: 10px;
  left: 32.5px;
  bottom: 10px;
  width: 10px;
}

.icon {
  position: relative;
  width: 75px;
  height: 75px;
  background: $dmp-white-inverse;

  &:hover {
    cursor: pointer;

    .two {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
}

button.btn-custom-toggle {
  border-color: #dde6e9;
  background: $dmp-white-inverse;
}

button.btn-custom-toggle:hover,
button.btn-custom-toggle:focus {
  background-position: 0 -15px;
}

button.btn-custom-toggle:active,
button.btn-custom-toggle.active {
  background-color: #e0e0e0;
}

fieldset[disabled] btn.btn-custom-toggle,
button.btn-custom-toggle.disabled:hover,
button.btn-custom-toggle[disabled]:hover,
fieldset[disabled] btn.btn-custom-toggle:hover,
button.btn-custom-toggle.disabled:focus,
button.btn-custom-toggle[disabled]:focus,
fieldset[disabled] btn.btn-custom-toggle:focus,
button.btn-custom-toggle.disabled.focus,
button.btn-custom-toggle[disabled].focus,
fieldset[disabled] btn.btn-custom-toggle.focus,
button.btn-custom-toggle.disabled:active,
button.btn-custom-toggle[disabled]:active,
fieldset[disabled] button.btn-custom-toggle:active,
button.btn-custom-toggle.disabled.active,
button.btn-custom-toggle[disabled].active,
fieldset[disabled] button.btn-custom-toggle.active {
  background-color: #efefef;
  background-image: none;
}

.include-item-icon-display {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
}

button.btn-custom-toggle {
  border-color: #dde6e9;
  background: $dmp-white-inverse;
}

button.btn-custom-toggle:hover,
button.btn-custom-toggle:focus {
  background-position: 0 -15px;
}

button.btn-custom-toggle:active,
button.btn-custom-toggle.active {
  background-color: #e0e0e0;
}

.btn-border-inverse {
  border: 1px solid $dmp-white-inverse;
}

fieldset[disabled] btn.btn-custom-toggle,
button.btn-custom-toggle.disabled:hover,
button.btn-custom-toggle[disabled]:hover,
fieldset[disabled] btn.btn-custom-toggle:hover,
button.btn-custom-toggle.disabled:focus,
button.btn-custom-toggle[disabled]:focus,
fieldset[disabled] btn.btn-custom-toggle:focus,
button.btn-custom-toggle.disabled.focus,
button.btn-custom-toggle[disabled].focus,
fieldset[disabled] btn.btn-custom-toggle.focus,
button.btn-custom-toggle.disabled:active,
button.btn-custom-toggle[disabled]:active,
fieldset[disabled] button.btn-custom-toggle:active,
button.btn-custom-toggle.disabled.active,
button.btn-custom-toggle[disabled].active,
fieldset[disabled] button.btn-custom-toggle.active {
  background-color: #efefef;
  background-image: none;
}

.include-item-icon-display {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
}

button.btn-block {
  &.btn-block-300 {
    max-width: 90%;
    margin-left: 30px;
  }
}

@media screen and (min-width: 768px) {
  button.btn-block {
    &.btn-block-300 {
      max-width: 300px;
      margin-left: 5px;
    }
  }
}

.btn-font-large {
  font-size: 1.5em;
  font-weight: lighter;

  i[class^="icon-"] {
    position: relative;
    top: 4px;
  }
}

.btn.btn-outline {
  background: none;

  /*primary*/
  &.btn-primary {
    border: 0.2rem solid $btn-primary-border;
    color: $dmp-blue;

    &:hover {
      border-color: darken($btn-primary-border, 10%);
      color: darken($dmp-blue, 10%);
      box-shadow: none;
      background: rgba($dmp-blue, 0.2);
      transition: all 0.3s ease-out;
    }

    &:active {
      border-color: darken($btn-primary-border, 10%);
      color: darken($dmp-blue, 30%);
      box-shadow: none;
      background: rgba(darken($dmp-blue, 20%), 0.2);
      transition: all 0.3s ease-out;
    }
  }

  /*success*/
  &.btn-success {
    border: 0.2rem solid $btn-primary-border;
    color: $dmp-blue;

    &:hover {
      border-color: darken($btn-primary-border, 10%);
      color: darken($dmp-blue, 10%);
      box-shadow: none;
      background: rgba($dmp-blue, 0.2);
      transition: all 0.3s ease-out;
    }

    &:active {
      border-color: darken($btn-primary-border, 10%);
      color: darken($dmp-blue, 30%);
      box-shadow: none;
      background: rgba(darken($dmp-blue, 20%), 0.2);
      transition: all 0.3s ease-out;
    }
  }

  /*info*/
  &.btn-info {
    border: 0.2rem solid $btn-primary-border;
    color: $dmp-blue;

    &:hover {
      border-color: darken($btn-primary-border, 10%);
      color: darken($dmp-blue, 10%);
      box-shadow: none;
      background: rgba($dmp-blue, 0.2);
      transition: all 0.3s ease-out;
    }

    &:active {
      border-color: darken($btn-primary-border, 10%);
      color: darken($dmp-blue, 30%);
      box-shadow: none;
      background: rgba(darken($dmp-blue, 20%), 0.2);
      transition: all 0.3s ease-out;
    }
  }

  /*warning*/
  &.btn-warning {
    border: 0.2rem solid $btn-primary-border;
    color: $dmp-blue;

    &:hover {
      border-color: darken($btn-primary-border, 10%);
      color: darken($dmp-blue, 10%);
      box-shadow: none;
      background: rgba($dmp-blue, 0.2);
      transition: all 0.3s ease-out;
    }

    &:active {
      border-color: darken($btn-primary-border, 10%);
      color: darken($dmp-blue, 30%);
      box-shadow: none;
      background: rgba(darken($dmp-blue, 20%), 0.2);
      transition: all 0.3s ease-out;
    }
  }

  /*danger*/
  &.btn-danger {
    border: 0.2rem solid $state-danger;
    color: $state-danger;

    &:hover {
      border-color: darken($state-danger, 10%);
      color: darken($state-danger, 10%);
      box-shadow: none;
      background: rgba($state-danger, 0.2);
      transition: all 0.3s ease-out;
    }

    &:active {
      border-color: darken($state-danger, 10%);
      color: darken($state-danger, 30%);
      box-shadow: none;
      background: rgba(darken($state-danger, 20%), 0.2);
    }
  }
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger:active,
.btn-danger.active,
.open > .btn-danger.dropdown-toggle {
  border-color: darken($state-danger, 10%);
  color: darken($state-danger, 30%);
  box-shadow: none;
  background: rgba(darken($state-danger, 20%), 0.2);
}

/*
.input-group {
	.btn {
		&.btn-dmp,
		&.btn-primary {
			font-size: 1.4rem;
			border-color: $state-primary;
		}

	}

}

.toast {
	background-color: #FFF!important;
}


.custom-info {
	color: $toast-success-text !important;
	font-size: 1rem;
	background-color: $toast-success-bg !important;
	border: $toast-success-border !important;

}*/

.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  color: darken($dmp-blue, 10%);
  background-color: rgba($dmp-blue, 0.2);
  border-color: $dmp-blue;
}
.btn-round {
  border-radius: 50%;
  // border-width: .2rem;
}

.btn-group.btn-group-xs.btn-group {
  &--flex {
    display: flex;
  }
}

.btn--replace-panel {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  position: relative;
  left: -1px;
  width: 100%;
}

.btn-dropdown--border {
  border-bottom-right-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  @media screen and (min-width: $mq-tablet) {
    border-bottom-right-radius: 0rem;
    border-top-right-radius: 0rem;
  }
}

.btn-no-border {
  border-color: transparent;
  background-color: transparent;
  &:hover {
    border-color: transparent;
    background-color: transparent;
  }
}
