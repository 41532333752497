/* ========================================================================
     Component: form-elements
 ========================================================================== */

/**
 * Custom form elements
 *    - Checkbox 
 *    - Radios
 *    - Switch
 *    - Rounded inputs
 */

//
// CHECKBOX AND RADIOS
//

$ui-fg-color: $state-primary;
$ui-fg-color-disabled: #ecf1f2;
$ui-bg-color: $dmp-layout-border-darker;

@mixin switch-variant($wd, $hg) {
  & span {
    width: $wd;
    height: $hg;
  }

  & span:after {
    height: $hg - 2;
    width: $hg - 2;
  }
}

.c-checkbox,
.c-radio {
  margin-right: 4px;
  * {
    cursor: pointer;
  }
  input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important;
  }

  // the element that replaces the input
  span {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-left: -20px;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid $dmp-border-color;
    margin-right: 5px;
    text-align: center;
    &:before {
      margin-left: 1px; // fa align trick
    }
  }

  &:hover span {
    border-color: $ui-fg-color;
  }

  .form-inline & span {
    margin-left: 0;
  }

  &.c-checkbox-rounded,
  &.c-radio-rounded {
    span {
      border-radius: 500px;
    }
  }
}

/* override for radio */
.c-radio span {
  border-radius: 500px;
}

/* the icon */
.c-checkbox,
.c-radio {
  span:before {
    //content: "\2713"; /* comment content to use custom icon classes */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    text-align: center !important;
    font-size: 12px;
    line-height: 18px;
    vertical-align: middle;
  }
}

/* Checked state */
.c-checkbox,
.c-radio {
  input[type="checkbox"]:checked + span:before,
  input[type="radio"]:checked + span:before {
    color: darken($state-primary, 20%);
    opacity: 1;
    transition: color 0.3s ease-out;
  }

  input[type="checkbox"]:checked + span,
  input[type="radio"]:checked + span {
    border-color: $ui-fg-color;
    background-color: $ui-fg-color;
  }

  /* override for radio */
  input[type="radio"]:checked + span {
    background-color: $dmp-white-inverse;
    &:before {
      color: $ui-fg-color;
    }
  }

  /* Disable state */

  input[type="checkbox"]:disabled + span,
  input[type="radio"]:disabled + span {
    border-color: $ui-bg-color !important;
    background-color: $ui-bg-color !important;
  }
  /* override for radio */
  input[type="radio"]:disabled + span {
    background-color: $dmp-white-inverse !important;
    &:before {
      color: $ui-bg-color;
    }
  }
}

//
// Form Rounded
//

.form-control-rounded {
  border-radius: 100px;
}

//
// Form SWITCH
// Supports radio and checkbox
//

$switch-wd: 40px;
$switch-hg: $switch-wd/2;

$switch-lg-wd: 50px;
$switch-lg-hg: $switch-lg-wd/2;

$switch-sm-wd: 30px;
$switch-sm-hg: $switch-sm-wd/2;

fieldset[disabled] {
  .switch *,
  .styled-button *,
  .styled-switch * {
    cursor: not-allowed;
  }

  .styled-button * {
    pointer-events: none;
  }
}

.switch {
  .form-control {
    padding-top: 7px;
    margin-bottom: 0;
  }
}

.switch * {
  cursor: pointer;
}

.switch input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.switch {
  & span {
    position: relative;
    display: inline-block;
    width: $switch-wd;
    height: $switch-hg;
    background-color: $dmp-white-inverse;
    border: 1px solid $ui-bg-color;
    border-radius: 100px;
    transition: all 0.5s;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1) inset;
  }

  & span:after {
    content: "";
    position: absolute;
    background-color: $dmp-white-inverse;
    top: 0;
    left: 0;
    height: $switch-hg - 2;
    width: $switch-hg - 2;
    border: 1px solid $dmp-layout-border-darker;
    border-radius: 400px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.2s;
  }

  &.switch-lg {
    @include switch-variant($switch-lg-wd, $switch-lg-hg);
  }
  &.switch-sm {
    @include switch-variant($switch-sm-wd, $switch-sm-hg);
  }
}

.switch input:disabled + span {
  background-color: $ui-fg-color-disabled;
  border-color: $ui-fg-color-disabled;
  transition: all 0.5s;
}

.switch input:checked + span {
  background-color: $state-primary-toggled;
  border-color: $state-primary-toggled;
  transition: all 0.5s;
}

.switch input:checked + span:after {
  left: 50%;
  transition: all 0.2s;
  border-color: $state-primary-toggled;
}

// form feedback variants

.form-control-feedback {
  // top: 10px;
  left: $padding-base-horizontal;
  right: auto;

  .form-control + &,
  .form-control + .parsley-errors-list + & {
    right: 0;
    left: auto;
    top: 10px;
  }

  .input-sm + &,
  .input-sm + .parsley-errors-list + & {
    top: 0;
  }

  label + .form-control + &,
  label + .form-control + .parsley-errors-list + & {
    top: 36px;
  }
}

.input-md {
  width: 260px;
}

@media screen and (min-width: 767px) {
  input.form-control.form-control-235 {
    width: 235px;
  }

  select.form-control.form-control-300,
  input.form-control.form-control-300,
  div.form-control.form-control-300 {
    max-width: 300px;
  }

  input.form-control.form-control-480 {
    max-width: 480px;
  }
}
