/* Underline From Right */
@mixin underline-from-right {
  $duration: $mediumDuration;

  @include hacks();
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 100%;
    right: 0;
    bottom: 0;
    background: $activeColor;
    height: 4px;
    @include prefixed(transition-property, "left");
    @include prefixed(transition-duration, $duration);
    @include prefixed(transition-timing-function, ease-out);
  }

  &:hover,
  &:focus,
  &:active {
    &:before {
      left: 0;
    }
  }
}
