/* Outline Out */
@mixin outline-out {
  $outerBorderWidth: 4px;
  $innerBorderWidth: 4px;

  @include hacks();
  position: relative;

  &:before {
    content: "";
    position: absolute;
    border: $primaryColor solid $outerBorderWidth;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include prefixed(transition-duration, 0.3s);
    @include prefixed(transition-property, "top, right, bottom, left");
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    top: -($outerBorderWidth + $innerBorderWidth);
    right: -($outerBorderWidth + $innerBorderWidth);
    bottom: -($outerBorderWidth + $innerBorderWidth);
    left: -($outerBorderWidth + $innerBorderWidth);
  }
}
