.area-slider {
  flex: 1 1 0%;
  min-width: 135px;
  font-size: 12px;
}

.arming-slider {
  &__wrapper {
    height: 2.5rem;
    font-size: 1.3rem;
  }

  &__action-area {
    display: block;
    position: relative;
    cursor: pointer;
    border-radius: 1.25rem;
  }

  &__track {
    height: 2.5rem;
    z-index: 2;
    box-shadow: rgb(224, 224, 224) 0 0 0 0.1rem;
    border-radius: 1.25rem;
    // background: rgba(66, 141, 174, 0.2);
    transition: background 0.35s ease 0s;

    &-inner {
      position: relative;
      height: 2.5rem;
      border-radius: 1.25rem;
      overflow: hidden;

      &:before,
      &:after {
        background-repeat: no-repeat;
        box-sizing: inherit;
      }
    }
  }

  &__label {
    &.arming-slider__off-label {
      position: absolute;
      display: flex;
      -moz-box-align: center;
      align-items: center;

      height: 2.5rem;
      width: 100%;
      justify-content: flex-start;
      margin: 0;
      padding: 0 1.25rem 0 3.25rem;
      font-weight: 600;
      text-align: left;
      text-transform: uppercase;
      white-space: pre;
      color: rgb(127, 131, 134);
      z-index: 0;
      opacity: 1;
      transition: opacity 0.35s ease 0s;
    }

    &.arming-slider__on-label {
      display: flex;
      -moz-box-align: center;
      align-items: center;
      top: 0px;
      height: 2.5em;
      width: 100%;
      margin: 0;
      padding: 0 3.25rem 0 1.25rem;
      font-weight: 600;
      text-align: left;
      text-transform: uppercase;
      white-space: pre;
      position: absolute;
      left: 0;
      overflow: hidden;
      color: white;
      transform: translateX(calc(-100% + 1.5rem));
      transition: transform 0.4s cubic-bezier(0.63, 0.64, 0.3, 1) 0s;
      z-index: 1;
    }

    &.arming-slider__on-label-text {
      display: block;
    }
  }

  &__handle {
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    position: absolute;
    top: -0.25rem;
    left: -0.25em;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 1px solid rgb(224, 224, 224);
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 6px;
    background: white none repeat scroll 0% 0%;
    transition: left 0.4s cubic-bezier(0.63, 0.64, 0.3, 1) 0s;
    z-index: 3;

    &-icon {
      color: rgb(153, 153, 153);
      font-size: 1.5rem;
      line-height: 1;
    }
  }

  &__visually-hidden {
    position: absolute;
    width: 0.1rem;
    height: 0.1rem;
    clip: rect(0, 0, 0, 0);
    margin: -0.1rem;
    padding: 0;
    overflow: hidden;
  }

  &__svg-icon {
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    fill: currentcolor;
    font-size: 1rem;
    line-height: 0;
    overflow: visible;
  }
}

input:checked + i.arming-slider__handle {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

//
// Form SWITCH
// Supports radio and checkbox
//

$switch-arming-wd: 50px;
$switch-arming-hg: $switch-arming-wd/2;

.switch {
  &.switch-arming-slider {
    & span {
      position: relative;
      display: inline-block;
      min-width: 135px;
      height: $switch-arming-hg;
      background-color: $dmp-white-inverse;
      border: 1px solid $ui-bg-color;
      border-radius: 100px;
      transition: all 0.5s;
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1) inset;
    }

    & span:after {
      font-family: dmp_icon_font;
      content: "\e901";
      color: $color-neutral-400;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: $dmp-white-inverse;
      top: -3px;
      left: -6px;
      height: 30px;
      width: 30px;
      border: 1px solid $dmp-layout-border-darker;
      border-radius: 400px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
      -webkit-transition: all 0.2s;
    }

    input:disabled + span {
      background-color: $ui-fg-color-disabled;
      border-color: $ui-fg-color-disabled;
      transition: all 0.5s;
    }
  }
}

.switch {
  &.switch-arming-slider {
    margin-bottom: 0;

    input:checked {
      + span {
        background-color: $state-primary-toggled;
        border-color: $state-primary-toggled;
        transition: all 0.5s;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;

        &:after {
          font-family: dmp_icon_font;
          content: "\e901";
          color: $color-primary;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 84%;
          transition: all 0.2s;
          border-color: $state-primary-toggled;
        }
      }
    }
  }
}

.switch-text-middle > span {
  vertical-align: middle;
  margin-left: 0.5em;
}

.armed-state-text {
  font-size: var(--measure-font-10);
  font-style: italic;
  align-self: center;
  vertical-align: center;
  margin: 3px 0;
}

.toggle-row {
  min-width: 50%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
}

.area-name-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.8rem;
}

.busy-spinner-arming-sm {
  width: 1rem;
  height: auto;
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-out-center {
  -webkit-animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
