@mixin base-theme-toggle {
  box-sizing: border-box;
  display: flex;
  opacity: 1;
  position: absolute;
  top: 16rem;
  right: 1rem;
  z-index: 100;
  width: var(--map-button-size);
  height: var(--map-button-size);

  background: $bleached-white;
  border-radius: 0.2rem;
  transform-origin: top right;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  font-size: 1rem;
  padding: 3px 1rem;
  transition: all 0.5s cubic-bezier(0, 0.98, 0.26, 1.16);
  justify-content: center;
  cursor: pointer;
}
:root {
  --map-button-size: 4rem;
  --map-button-icon-ratio: 0.769230769;
  --map-button-icon-size: calc(
    var(--map-button-size) * var(--map-button-icon-ratio)
  );
}

.dashboard__map,
.cell-map {
  &__theme-toggle {
    @include base-theme-toggle;
    &--cell-coverage-map {
      @include base-theme-toggle;
      top: 9rem;
      right: 3.5rem;
    }

    &--icon {
      display: flex;
      font-size: var(--map-button-icon-size);
      transition: color 0.5s cubic-bezier(0, 0.98, 0.26, 1.16);
      justify-items: center;
      align-items: center;

      &.icon-light {
        color: #fabd2b;
      }
    }
  }

  &__legend {
    -webkit-transform: translateY(6.3rem) scale(0.6);
    -ms-transform: translateY(6.3rem) scale(0.6);
    -o-transform: translateY(6.3rem) scale(0.6);
    transform: translateY(6.3rem) scale(0.6);

    transform-origin: bottom left;
    opacity: 0;
    visibility: hidden;
    position: absolute;

    bottom: 0;
    left: 0;
    z-index: 100;

    background: $bleached-white;
    border-radius: 0.2rem;

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    font-size: 1rem;
    padding: 3px 1rem;
    transition: all 0.5s cubic-bezier(0, 0.98, 0.26, 1.16);
    cursor: pointer;

    &.is-active {
      opacity: 1;
      visibility: visible;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none;

      &.radar {
        height: auto;
        min-width: 400px;
        max-width: 400px;
        width: 50%;
      }

      &.stormcells {
        height: auto;
        min-width: 320px;
        max-width: 400px;
        width: 50%;
        min-height: 88px;
      }

      &.stormreports {
        height: auto;
        min-width: 340px;
        max-width: 400px;
        width: 50%;
      }
    }

    &.is-collapsed {
      -webkit-transform: translateY(6.3rem);
      -moz-transform: translateY(6.3rem);
      -ms-transform: translateY(6.3rem);
      -o-transform: translateY(6.3rem);
      transform: translateY(6.3rem);
    }

    & h5.legend-title {
      margin-top: 0;
      color: $brand-primary;
    }

    &-closer {
      position: absolute;
      right: 6px;
      top: 6px;
      width: 1.2rem;
      height: 1.2rem;
      font-size: 1.2rem;
    }

    &__range-bar {
      display: inline-block;
      width: 70%;
      height: auto;

      &-title {
        display: inline-block;
        margin: 0;
      }
    }

    &__stormcells {
      width: 100%;
      height: auto;
    }

    &__aeris-logo {
      position: absolute;
      right: 6px;
      bottom: 6px;
      width: 5rem;
      height: auto;
    }

    &-line-item {
      display: flex;
      justify-content: space-between;
    }
  }
}

.dashboard__map__weather-dropdown,
.dashboard__map__cell-coverage-dropdown {
  &--closer {
    font-size: 1rem;
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
  }

  &--drop-down-icon {
    position: absolute;
    height: var(--map-button-size);
    width: var(--map-button-size);
    background-color: $bleached-white;
    top: 50%;
    right: 1rem;
    z-index: 1050;
    border-radius: 0.2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    hover:before & {
      -webkit-transform: scale(2);
      -moz-transform: scale(2);
      -ms-transform: scale(2);
      -o-transform: scale(2);
      transform: scale(2);
    }

    &:before {
      font-family: "dmp_icon_font";
      font-size: var(--map-button-icon-size);
      content: "\e99a";
      color: $brand-primary;
      z-index: 1050;
      position: relative;
      left: 0.5rem;
      transform-origin: 0 0;
    }
  }

  &--content-area {
    opacity: 1;
    position: absolute;
    top: 6rem;
    right: 1rem;
    z-index: 100;
    width: var(--map-button-size);
    height: var(--map-button-size);

    background: $bleached-white;
    border-radius: 0.2rem;
    transform-origin: top right;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    font-size: 1rem;
    padding: 3px 1rem;
    transition: all 0.5s cubic-bezier(0, 0.98, 0.26, 1.16);
    cursor: pointer;

    &--cell {
      top: 11rem;
      font-size: 1.5rem;
    }

    &::hover {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12), 0 2px 3px rgba(0, 0, 0, 0.24);
    }

    .hide-collapsed {
      opacity: 0;
      visibility: hidden;
    }

    .dashboard__map__weather-dropdown--content-area--radio,
    .dashboard__map__cell-coverage-dropdown--content-area--radio {
      visibility: hidden;
    }

    .dashboard__map__weather-dropdown--content-area--title,
    .dashboard__map__cell-coverage-dropdown--content-area--title {
      .text {
        display: none;
      }

      i {
        transition: all 0.5s cubic-bezier(0, 0.98, 0.26, 1.16);
        transform: translate(0.3rem, -0.5rem) scale(1.46);

        &:hover {
          color: $primary-color;

          -webkit-transform-origin: center;
          -moz-transform-origin: center;
          -ms-transform-origin: center;
          -o-transform-origin: center;
          transform-origin: center;
        }
      }
    }

    &.activated {
      opacity: 1;
      transform: none;
      right: 6rem;
      width: 18rem;
      height: 18rem;

      z-index: 101;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

      &--cell {
        height: 12.6rem;
      }

      .hide-collapsed {
        opacity: 0;
      }

      .dashboard__map__weather-dropdown--content-area--title,
      .dashboard__map__cell-coverage-dropdown--content-area--title {
        .text {
          display: inline;
        }

        i {
          transform: translate(0, 0) scale(1);
        }

        .hide-collapsed {
          opacity: 1;
          visibility: visible;
        }

        color: $primary-color;
        margin-top: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        -webkit-transform: translate3d(4rem, 0, 0) scale(0.1, 0);
        -moz-transform: translate3d(4rem, 0, 0) scale(0.1, 0);
        -ms-transform: translate3d(4rem, 0, 0) scale(0.1, 0);
        -o-transform: translate3d(4rem, 0, 0) scale(0.1, 0);
        transform: translate3d(4rem, 0, 0) scale(0.1, 0);
        transform-origin: top right;
        -webkit-animation: the-end 0.3s 0.2s cubic-bezier(0, 0.98, 0.26, 1.16)
          forwards;
        -o-animation: the-end 0.3s 0.2s cubic-bezier(0, 0.98, 0.26, 1.16)
          forwards;
        animation: the-end 0.3s 0.2s cubic-bezier(0, 0.98, 0.26, 1.16) forwards;
      }

      .dashboard__map__weather-dropdown--content-area--radio,
      .dashboard__map__cell-coverage-dropdown--content-area--radio {
        visibility: visible;

        -webkit-transform: translateY(-2rem);
        -moz-transform: translateY(-2rem);
        -ms-transform: translateY(-2rem);
        -o-transform: translateY(-2rem);
        transform: translateY(-2rem);

        -webkit-animation: the-end 0.8s cubic-bezier(0, 0.98, 0.26, 1.16)
          forwards;
        -o-animation: the-end 0.8s cubic-bezier(0, 0.98, 0.26, 1.16) forwards;
        animation: the-end 0.8s cubic-bezier(0, 0.98, 0.26, 1.16) forwards;

        &.first {
          -webkit-animation-delay: 0.2s;
          -moz-animation-delay: 0.2s;
          -o-animation-delay: 0.2s;
          animation-delay: 0.2s;
        }

        &.second {
          -webkit-animation-delay: 0.4s;
          -moz-animation-delay: 0.4s;
          -o-animation-delay: 0.4s;
          animation-delay: 0.4s;
        }

        &.third {
          -webkit-animation-delay: 0.6s;
          -moz-animation-delay: 0.6s;
          -o-animation-delay: 0.6s;
          animation-delay: 0.6s;
        }

        &.fourth {
          -webkit-animation-delay: 0.8s;
          -moz-animation-delay: 0.8s;
          -o-animation-delay: 0.8s;
          animation-delay: 0.8s;
        }
      }

      &--link {
      }
    }
  }
}

@-webkit-keyframes the-end {
  100% {
    opacity: 1;
    transform: none;
  }
}

@-o-keyframes the-end {
  100% {
    opacity: 1;
    transform: none;
  }
}

@-moz-keyframes the-end {
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes the-end {
  100% {
    opacity: 1;
    transform: none;
  }
}

.radar-content {
  display: flex;
  flex-direction: row;
}

.radar-title-block {
  display: flex;
  flex-direction: column;
}

.dashboard__map__legend__range-bar-title {
  display: block;
  padding: 0.3rem;
}
