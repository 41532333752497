.grid {
  display: grid;
  width: 100%;

  &--filter-grid {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 1fr;
    grid-row-gap: var(--measure-1x);
    grid-column-gap: 0;
    grid-template-areas: "one" "two" "three";

    @media (min-width: $screen-sm) {
      grid-template-rows: min-content min-content;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: var(--measure-1x);
      grid-row-gap: var(--measure-1x);
      grid-template-areas: "one two" "three .";
    }
    @media (min-width: $screen-md) {
      grid-template-rows: 1fr;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: var(--measure-1x);
      grid-row-gap: 0;
      grid-template-areas: "one two three";
    }
  }

  &__area {
    &--model {
      grid-area: one;
    }

    &--communication {
      grid-area: two;
    }

    &--firmware {
      grid-area: three;
    }
  }
}

.table-filters {
  background-color: var(--color-neutral-200);
  border-top: 0.1rem solid var(--color-neutral-400);
  border-bottom: 0.1rem solid var(--color-neutral-400);
}
