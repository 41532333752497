.video-icon {
  height: 110%;
  width: auto;
}

.securecom-add-button {
  padding: 0 12px;
}

.video-device-empty-message {
  padding: 0 var(--measure-3x);
}
