.tag-view {
  display: flex;
  flex-flow: row wrap;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: var(--measure-1x) 0;

  &__title {
    position: relative;
    padding: var(--measure-2x) var(--measure-2x) 0 0;
    font-size: var(--measure-font-14);
    font-weight: var(--font-weight-medium);
    border-bottom: 0.1rem solid $global-border;
  }

  &__item {
    padding: var(--measure-half) var(--measure-1x);
    margin: 0 var(--measure-half) var(--measure-half) 0;
    @include global-border;
    border-radius: 0.5rem;
    width: auto;
    display: flex;
    flex-flow: row nowrap;
    font-weight: var(--font-weight-semibold);

    &--no-padding {
      padding: 0;
      margin: 0 var(--measure-1x) var(--measure-half) var(--measure-half);
      background-color: inherit;
    }
    &--no-border {
      @include global-list-parent-styles();
      border: none;
    }
    &--not-bold {
      font-weight: var(--font-weight-regular);
      font-size: var(--measure-font-14);
    }

    &--btn {
      border-color: $brand-info;
    }
    &--date {
      border-radius: 0.4rem;
      justify-content: space-between;
      font-size: 1.4rem;
      padding: 0.4rem 0.8rem;
    }
  }
}
