.test-modal {
  &__header {
    background-color: white;
    border: 1px solid var(--color-neutral-200);
    padding: 1.5rem 2rem;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  &__title {
    font-size: 2rem;
    font-weight: var(--font-weight-bold);
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    border-radius: 0.5rem 0.5rem 0 0;
    &-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__close {
    cursor: pointer;
    font-size: 2rem;
    color: var(--color-neutral-500);

    &:hover {
      color: var(--color-neutral-600);
    }
  }

  &__connection-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    font-weight: var(--font-weight-semibold);

    &-label {
      font-size: 1.2rem;
      font-weight: var(--font-weight-thin);
      color: var(--color-text-default);
      margin-bottom: 0.2rem;
    }
  }

  &__data {
    font-size: 1.6rem;
    font-weight: var(--font-weight-normal);
    color: var(--color-text-default);

    &-label {
      font-size: 1.8rem;
      font-weight: var(--font-weight-normal);
      color: var(--color-text-default);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 2rem;

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;
      transition: all 0.2s ease-in-out;

      &-subtitle {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: 1px solid var(--color-neutral-300);
        margin-bottom: 2rem;
      }
    }
  }

  &__error {
    display: flex;
    justify-content: flex-start;
    color: white;
    background-color: var(--color-error-500);
    font-size: 1.6rem;
    font-weight: var(--font-weight-normal);
    padding: 0 2rem;
  }

  &__recent-connections {
    padding: 2rem 0;

    &-subtitle {
      //sticky header
      //position: sticky;
      // top: 0;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0.5rem;
      font-size: 1.6rem;

      font-weight: var(--font-weight-semibold);
      width: 100%;
    }

    &-list {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      border: 1px solid var(--color-neutral-500);
      border-radius: 0.4rem;
      max-height: 44rem;
      overflow-y: auto;
      //add a dropshadow at the top of the list that is inset: this will make it look like the list is inset into the background;
      box-shadow: inset 0 0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.2);

      &-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        transition: all 0.2s ease-in-out;
        width: 100%;
        padding: 1rem;
        //zebra striping
        &:nth-child(even) {
          background-color: var(--color-neutral-100);
        }
        // children but not last child gets margin-bottom
        &:not(:last-child) {
          margin-bottom: 2rem;
        }

        &-item {
          display: block;
          font-size: 1.4rem;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          &-grid {
            display: grid;
            grid-template-columns: min-content max-content 1fr;
            grid-template-rows: min-content 1fr;
            grid-template-areas: "icon date message" "icon time details";
            grid-row-gap: 0.2rem;
            width: 100%;
            justify-items: center;
            &-item {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              font-size: 1.4rem;
              &-label {
                font-size: 1.2rem;
                font-weight: var(--font-weight-thin);
                color: var(--color-text-default);
                margin-bottom: 0.2rem;
              }
            }
          }
          &-icon {
            grid-area: icon;
            display: flex;
            justify-items: flex-start;
            padding-top: 0.5rem;
          }
          &-date {
            grid-area: date;
            font-weight: var(--font-weight-bold);
            font-size: 1.4rem;
            display: flex;
            align-items: center;
          }
          &-time {
            grid-area: time;
            display: flex;
            justify-self: flex-end;
          }
          &-message {
            grid-area: message;
            font-weight: var(--font-weight-bold);
            display: flex;
            justify-self: flex-end;
          }
          &-details {
            grid-area: details;
            display: flex;
            justify-self: flex-end;
          }
        }
      }
    }
  }
}
