$margin-right-collapsed: -17rem;
$collapsed-icon-size: 2.4rem;

section,
footer {
  @include collapse-animation-transition;
}

// Aside status toggled via JS
.aside-collapsed {
  overflow-y: auto;
  & .sidebar {
  }
  &__hover-trigger {
    position: relative;

    + li.display-float-collapsed {
      padding: 0;
      opacity: 0;
      display: none;
      position: absolute;
      float: left;
      left: -230px;
      transform: translateY(-40px);

      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;

      background-color: $bleached-white;
      min-width: 240px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 999999;
      overflow: visible;

      //noinspection SassScssResolvedByNameOnly
      & .search-box.search-box-float {
        width: 100%;
        background-color: $bleached-white !important;
        height: auto;
        padding: 1rem;
        border-top: none;
      }

      > nav,
      > li > a > span {
        display: inline-block;
      }
    }

    &:hover {
    }
  }

  .aside-collapsed__hover-trigger:hover + li.display-float-collapsed,
  li.display-float-collapsed:hover {
    display: inline-block;
  }

  &__sub-menu {
    &-content {
      display: none;
      position: absolute;
      background-color: $bleached-white;
      min-width: 24rem;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      z-index: 2;
      &:hover {
        display: block;
      }
    }
  }
  overflow-y: auto;

  .wrapper {
    > section {
      margin-left: 0;
      margin-right: $margin-right-collapsed;
    }
    > footer {
      margin-right: $margin-right-collapsed;
    }

    > .aside {
      margin-left: 0;
    }
  }

  .sidebar__logo-area {
    display: inline-block;
    width: 100%;
    height: 4.4rem;

    margin: 0 0 0 -0.2rem;
    background: url(/assets/img/DealerAdmin_Color--collapsed.svg);
    background-size: 100% 100%;
    -webkit-animation: spinner 1.5s linear;
    -o-animation: spinner 1.5s linear;
    animation: spinner 1.5s linear;
  }
  .wrapper {
    > .aside {
      &,
      > .aside-inner {
        width: $aside-wd-icons-only;
      }
      > .nav-floating {
        &,
        .collapse {
          height: auto !important;
          display: block !important;
          visibility: visible !important;
        }
      }
    }

    > section,
    > footer {
      transition: all 0.25s ease-out;
      margin-right: $aside-wd-collapsed;
    }
  }
  .sidebar {
    > .nav {
      > li {
        opacity: 0;
        animation: to-end 0.6s forwards;

        > a.sidebar__link {
          display: inline-block;
          i[class*="sidebar__icon icon-"] {
            top: 0;
            transform: scale(1.3);
            -webkit-transform-origin: 50% 50%;
            -moz-transform-origin: 50% 50%;
            -ms-transform-origin: 50% 50%;
            -o-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            padding-left: 0.3rem;
          }
        }
        > a.sidebar__link--external {
          display: inline-block;
          i[class^="sidebar__icon icon-"] {
            top: 0;
            transform: scale(1.3);
            -webkit-transform-origin: 50% 50%;
            -moz-transform-origin: 50% 50%;
            -ms-transform-origin: 50% 50%;
            -o-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            padding-left: 0.3rem;
          }
        }

        > .not-visible-collapsed {
          display: none;
        }
      }
    }
  }
}

@keyframes fadeInSlowly {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }

  80% {
    opacity: 0;
  }
  85% {
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes bounce {
  0%,
  50% {
    -moz-transform: scale(0);
    opacity: 0;
  }
  50% {
    -moz-transform: scale(1.3);
    opacity: 0.4;
  }
  75% {
    -moz-transform: scale(0.9);
    opacity: 0.7;
  }
  100% {
    -moz-transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes bounce {
  0%,
  50% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1.3);
    opacity: 0.4;
  }
  75% {
    -webkit-transform: scale(0.9);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
  0%,
  40% {
    opacity: 0;
    -webkit-transform: rotateY(0deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateY(-360deg);
  }
}

/* all other browsers */
@keyframes spinner {
  0%,
  40% {
    opacity: 0;
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    opacity: 1;
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}

h5.title-subnav {
  font-weight: 400;
  text-align: left;
  padding-left: 1rem;
}

@keyframes to-end {
  100% {
    opacity: 1;
  }
}
