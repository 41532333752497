//Styles for Nested styleguide inside App.Styleguide
// i Global
// 0.0 Elements
//   0.1 Anchors
//   0.5 Grid examples
//   0.7 DA Icon-grid
// 1.0 Colors
// 2.0 Typography
// 3.0
// 4.0 Prism code highlighter
//   4.1 Code view accordion
// 5.0 Directives

/**
 * i  Global
 ************/

.dev-note {
  font-style: italic;
  color: $brand-green-dmp;
  &::before {
    font-family: FontAwesome;
    font-weight: bolder;
    font-style: italic;
    content: "\f0a4 \00a0 TODO:";
    padding-right: $padding-base;
  }
}

.info-note {
  font-style: italic;
  color: $state-primary;
  &::before {
    font-family: FontAwesome;
    font-weight: bolder;
    font-style: italic;
    content: "\f05a \00a0 TIP:";
    padding-right: $padding-base;
  }
}
/**
 *0.0 Elements View
 ************/

//0.1 Anchor tags for styleguide
section.s-guide {
  position: relative;
  z-index: 1; /* needed for setting pseudo-element z-index */
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  a {
    font-style: bold;
    position: relative;
    display: inline-block;
    outline: none;
    color: $state-primary;
    vertical-align: bottom;
    text-decoration: none;
    white-space: nowrap;
    padding: 2px 2px 0 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
}

section.s-guide a::before,
section.s-guide a::after {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
/* Fade in background */
.link-fadeinbg {
  background: inherit;
}

.link-fadeinbg a {
  border-bottom: 1px solid rgba(5, 57, 110, 0.5);
  font-weight: 500;
  -webkit-transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.link-fadeinbg a:hover,
.link-fadeinbg a:focus {
  border-color: rgba(5, 57, 110, 0);
  background-color: rgba(5, 57, 110, 0.8);
  color: $dmp-white-inverse;
}
/* Slide up */
.link-slideup {
  background: $dmp-white-inverse;
  color: #34495e;
}

.link-slideup a {
  overflow: hidden;
  font-weight: 500;
}

.link-slideup a::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 57, 110, 0.7);
  content: "";
  -webkit-transition: -webkit-transform 0.2s, color 0.2s;
  transition: transform 0.2s, color 0.2s;
  -webkit-transform: translateY(95%);
  transform: translateY(95%);
}
.link-slideup a:hover,
.link-slideup a:focus {
  color: $dmp-white-inverse;
  transition: background-color 0.2s ease;
}

.link-slideup a:hover::before,
.link-slideup a:focus::before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

// 0.5 grid example

.grid-example div[class^="col-"] {
  background-color: #e1e1e1;
  border: 1px solid #737373;
  border-radius: 12px;

  padding: 20px;
  text-align: center;
  vert-align: center;
  font-size: 1em;
}

// 0.7 DA Icon-Grid

.da-icons {
  margin: 0 -10px 20px;
}
.da-icons-list {
  padding-left: 0;
  list-style: none;
}
.da-icons li {
  float: left;
  width: 25%;
  height: 115px;
  margin-left: 2px;
  padding: 10px;
  font-size: 10px;
  line-height: 1.4;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid $dmp-white-inverse;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.da-icons li:hover {
  color: $dmp-white-inverse;
  background-color: $state-primary;
}
.da-icons .grid-icon {
  display: block;
  text-align: center;
  word-wrap: break-word;
}

.da-icons .glyphicon,
.da-icons [class^="icon-"],
.da-icons [class*=" icon-"] {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 3em;
}
@media (min-width: 768px) {
  .ds-icons {
    margin-right: 0;
    margin-left: 0;
  }
  .da-icons li {
    width: 14%;
    font-size: 12px;
  }
}

/**
 *1.0 Colors View
 ************/
.color-chip {
  width: 100%;
  padding: 1em;
  border: 1px solid $border-color;
  -webkit-border-radius: $border-radius-large;
  -moz-border-radius: $border-radius-large;
  border-radius: $border-radius-large;

  margin-bottom: $margin-xl;
}

.dark-text {
  color: #656565;
}
.light-text {
  color: $dmp-white-inverse;
}

/**
* 2.0 Typography View
***********************/

/**
* 4.0 PRISM
***********/

/* http://prismjs.com/download.html?themes=prism&languages=markup+css+clike+javascript */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

code[class*="language-"],
pre[class*="language-"] {
  color: black;
  background: none;
  text-shadow: 0 1px $dmp-white-inverse;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #f5f2f0;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}

.token.punctuation {
  color: #999;
}

.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #a67f59;
  background: hsla(0, 0%, 100%, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}

.token.function {
  color: #dd4a68;
}

.token.regex,
.token.important,
.token.variable {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

/**
* 5.0 DIRECTIVES
*****************/

//Code accordion directive

.code-accordion-wrapper {
  position: relative;

  hr {
    &.code-accordion {
      margin: 0 3px 0 0;
      padding: 0 15px;
    }
  }
  button.btn.btn-default.btn-code-accordion {
    border-bottom: none;
    border-bottom-left-radius: 0;
  }
}
