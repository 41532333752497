@mixin dmp-icon-font {
  font-family: dmp_icon_font;
}

.icon_btn {
  display: inline-block;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  line-height: 1.5;
  @at-root a.icon_btn {
    color: $accent-blue-link;
    &:hover {
      color: $accent-blue-link;
      text-decoration: none !important;
    }
  }

  &:link {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;

    span.icon-edit,
    span.icon-radial_add,
    span.icon-cycle {
      transform: scale(1.2);
    }
  }
  span.icon-edit,
  span.icon-radial_add,
  span.icon-cycle {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    padding-right: 2.5rem;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;

    &:before {
      position: absolute;
      left: 0.5rem;
      top: -0.9rem;
      padding: 0 0.1rem;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      font-size: 1.5rem;
    }
  }
}

a.icon-button:hover,
a.icon-button:focus {
  text-decoration: none !important;
}

.icon-info-superscript {
  font-size: 1.4rem;
  vertical-align: center;
  cursor: pointer;
  color: var(--color-primary-600);
}
