.form-grid-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-auto-rows: minmax(min-content, auto);
  grid-gap: 0.8rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.form-grid-item {
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 0.4rem;
  border: 1px solid var(--color-global-border);
}

.form-grid-item__prefix {
  margin-right: var(--measure-half);
}

.form-grid-item__title {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--measure-font-14);
  font-weight: bold;
  line-height: 1.2;
}

.form-grid-item__subtitle {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-neutral-700);
  font-size: var(--measure-font-12);
}

.form-grid-item__content--clickable {
  display: block;
  position: relative;
  width: 100%;
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.form-grid-item__content--clickable__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.form-grid-item__content--clickable__content {
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding-right: var(--measure-half);
}

a.form-grid-item__content--clickable {
  text-decoration: none;
  color: inherit;
}

button.form-grid-item__content--clickable {
  appearance: none;
  border: none;
  background-color: transparent;
  text-align: left;
}

.form-grid-item__content:only-child,
.form-grid-item__content--clickable:only-child {
  border-radius: inherit;
}

.form-grid-item__content:only-child,
.form-grid-item__content--clickable:only-child,
.form-grid-item__content:not(:only-child):first-child,
.form-grid-item__content--clickable:not(:only-child):first-child {
  padding: var(--measure-2x);
}

.form-grid-item__content:not(:only-child):not(:first-child),
.form-grid-item__content--clickable:not(:only-child):not(:first-child) {
  padding: var(--measure-12) var(--measure-2x);
}

.form-grid-item__content:not(:only-child):not(:last-child),
.form-grid-item__content--clickable:not(:only-child):not(:last-child) {
  border-bottom: 1px solid var(--color-global-border);
}

.form-grid-item__content:not(:only-child):first-child,
.form-grid-item__content--clickable:not(:only-child):first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.form-grid-item__content:not(:only-child):last-child,
.form-grid-item__content--clickable:not(:only-child):last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.form-grid-item__content:not(:only-child):not(:first-child)
  .form-grid-item__title,
.form-grid-item__content--clickable:not(:only-child):not(:first-child)
  .form-grid-item__title {
  font-size: var(--measure-font-12);
}

.form-grid-item__content--clickable:not(:only-child):not(:first-child)
  .form-grid-item__clickable-icon {
  font-size: var(--measure-font-10);
}

.form-grid-item__clickable-icon {
  width: 1.5rem;
  flex-shrink: 0;
}

.form-grid-item__content--clickable:hover {
  background-color: var(--color-neutral-100);
}

.form-grid-item__content--clickable:hover .form-grid-item__title,
.form-grid-item__content--clickable:hover .form-grid-item__clickable-icon {
  color: black;
}
