.property-list {
  width: 100%;
  font-size: inherit;
  @media screen and (min-width: $screen-sm) {
    justify-content: space-evenly;
    padding: var(--measure-1x);
  }
  @media screen and (min-width: $screen-lg) {
  }
  &__wrapper {
    width: 100%;

    display: grid;
    grid-row-gap: rem(32px);
    grid-column-gap: rem(16px);
    justify-content: space-between;
    grid-template-columns: repeat(auto-fit, minmax(max-content, 100%));
    padding: var(--measure-1x);

    @media screen and (min-width: $screen-sm + 100) {
      grid-template-columns: repeat(auto-fit, minmax(max-content, 45%));
      padding: var(--measure-1x);
    }
    @media screen and (min-width: $screen-lg) {
      grid-template-columns: repeat(auto-fit, minmax(max-content, 28%));
      padding: var(--measure-1x);
    }
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
    margin-bottom: var(--measure-2x);
    line-height: 1.5;
    border-bottom: 1px solid rgba($global-border, 0.5);
    &--no-underline {
      @extend .property-list__header;
      border-bottom: 1px solid transparent;
      margin-bottom: 0;
    }

    &-icon {
      fill: var(--color-text-default);
      height: rem(24px);
      width: rem(24px);
      margin-right: var(--measure-1x);
    }
    &-name {
    }
  }
  &__content-area {
    &--bordered {
      border: 1px solid rgba($global-border, 0.5);
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      padding: var(--measure-1x);
      /*.property-list__item:nth-child(odd) {
                 background-color: rgba($global-border, .1);
                 border-top: 1px solid $less-than-white;
                 border-bottom: 1px solid $less-than-white;

                }*/
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    font-size: rem(14px);
    line-height: 1.285714286;
    padding: var(--measure-1x);
    flex-basis: auto; /* default value */
    flex-grow: 1;
    &-label {
      font-weight: 400;
      font-size: inherit;
      margin-right: var(--measure-1x);
    }

    &-data {
      font-weight: 500;
      font-size: inherit;
      max-width: rem(220px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-svg-success {
    }
  }
}

.property-list--detailed {
  &__grid-area {
    display: grid;
    grid-column-gap: var(--measure-2x);
    justify-content: space-between;
    grid-template-columns: repeat(auto-fit, minmax(max-content, 100%));
  }
  &__title {
  }
  &__table {
  }
}
