/* Push */
@include keyframes(#{$nameSpace}-push) {
  50% {
    @include prefixed(transform, scale(0.8));
  }

  100% {
    @include prefixed(transform, scale(1));
  }
}

@mixin push {
  @include hacks();

  &:hover,
  &:focus,
  &:active {
    @include prefixed(animation-name, #{$nameSpace}-push);
    @include prefixed(animation-duration, 0.3s);
    @include prefixed(animation-timing-function, linear);
    @include prefixed(animation-iteration-count, 1);
  }
}
