/* ========================================================================
    - 2.2  Component: top-navbar
 ========================================================================== */
.layout-fixed {
  .topnavbar {
    background-color: $dmp-white-inverse;
    /* fixes chrome jump */
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    margin-bottom: 0;
    padding: var(--measure-1x) var(--measure-2x);
    height: 7.5rem;
    z-index: 1040;
    margin-left: 0px;
    -webkit-backface-visibility: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
    &.open {
      margin-left: $aside-wd + 4px;
    }
    &.collapsed {
      margin-left: 0px;
      @media screen and (min-width: $screen-lg) {
        margin-left: $aside-wd + 4px;
      }
    }
    @media screen and (min-width: $screen-sm) {
      padding: 0 var(--measure-2x);
    }
    @media screen and (min-width: $screen-lg) {
      margin-left: $aside-wd + 4px;
    }

    &__DA-logo {
      display: block;
      width: 20rem;
      height: 4.5rem;
      background: url("/assets/img/DealerAdmin_Color.svg");
      background-repeat: no-repeat;
      -webkit-transform: translateX(-8rem);
      -moz-transform: translateX(-8rem);
      -ms-transform: translateX(-8rem);
      -o-transform: translateX(-8rem);
      transform: translateX(-8rem);
      -webkit-animation: to-end-state 0.6s cubic-bezier(0.33, 0.84, 0.49, 1.18)
        forwards;
      -o-animation: to-end-state 0.6s cubic-bezier(0.33, 0.84, 0.49, 1.18)
        forwards;
      animation: to-end-state 0.6s cubic-bezier(0.33, 0.84, 0.49, 1.18) forwards;

      @media screen and (min-width: $screen-sm) {
        margin-left: 0;
      }
    }
  }
}

.navbar {
  position: relative;
  min-height: 55px;
  margin-bottom: 21px;

  &.topnavbar {
  }
}

div.brand-logo img {
  height: 4.5rem;
  width: auto;
}

@media only screen and (min-width: 768px) {
  .topnavbar .navbar-header {
    background-image: none;
  }
}

div.brand-logo img {
  height: 5.5rem;
  width: auto;
}

.topnavbar .navbar-header {
  position: relative;
  z-index: 11;
}

.topnavbar .navbar-header .navbar-brand {
  padding: 0;
}

.topnavbar .navbar-header .brand-logo {
  display: inline-block;
  padding: 0;
  overflow: hidden;
}

.topnavbar .navbar-header .brand-logo-collapsed {
  display: none;

  padding: 10px;
}

.topnavbar .navbar-nav > li > a,
.topnavbar .navbar-nav > .open > a {
  color: $brand-info;
}

.topnavbar .navbar-nav > li > a:hover,
.topnavbar .navbar-nav > .open > a:hover,
.topnavbar .navbar-nav > li > a:focus,
.topnavbar .navbar-nav > .open > a:focus {
  color: #117391;
}

.topnavbar .navbar-nav > .active > a,
.topnavbar .navbar-nav > .open > a,
.topnavbar .navbar-nav > .active > a:hover,
.topnavbar .navbar-nav > .open > a:hover,
.topnavbar .navbar-nav > .active > a:focus,
.topnavbar .navbar-nav > .open > a:focus {
  background-color: transparent;
}

.topnavbar .navbar-nav > li > [data-toggle="navbar-search"] {
  color: $dmp-white-inverse;
  font-size: 16px;
  line-height: 55px;
  padding-bottom: 0;
  padding-top: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -webkit-transition: color 0.3s ease;
  z-index: 20;
}

@media only screen and (min-width: 768px) {
  .topnavbar .navbar-nav > li > [data-toggle="navbar-search"] {
    color: $dmp-white-inverse;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-toggle {
    color: black !important;
    position: absolute !important;
    right: 0;
    top: 5px;
    z-index: 3001;
  }
}

.nav-wrapper {
  background-color: transparent;
  padding: 0.416667em 1.66667em 0em 0em;
}

.nav-wrapper .nav.navbar-nav {
  float: left;
}

.nav-wrapper .nav.navbar-nav.navbar-right {
  float: right;
}

.nav-wrapper .nav > li {
  float: left;
  position: static;
}

.nav-wrapper .navbar-nav .open .dropdown-menu {
  background-color: $dmp-white-inverse;
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  left: 0px;
  position: absolute;
  right: 0px;
}

.topnavbar .navbar-form {
  border-bottom: 1px solid #e1e2e3;
  height: 55px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: -100%;
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  z-index: 9001;
}

.topnavbar .navbar-form .form-group {
  height: 100%;
  width: 100%;
}

.topnavbar .navbar-form .form-control {
  border: 0;
  border-radius: 0;
  height: 100%;
  width: 100%;
}

.topnavbar .navbar-form.open {
  top: 0;
}

.topnavbar .navbar-form .has-feedback .form-control-feedback {
  color: #c1c2c3;
  cursor: pointer;
  font-size: 1.5em;
  height: 30px;
  line-height: 30px;
  margin-right: 10px;
  margin-top: -15px;
  pointer-events: auto;
  top: 50%;
}

.branding-area {
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  height: 5.5rem;
  background: inherit;
  opacity: 0;
  overflow: hidden;
  -webkit-transform: translateX(-8rem);
  -moz-transform: translateX(-8rem);
  -ms-transform: translateX(-8rem);
  -o-transform: translateX(-8rem);
  transform: translateX(-8rem);
  -webkit-animation: to-end-state 0.6s cubic-bezier(0.33, 0.84, 0.49, 1.18)
    forwards;
  -o-animation: to-end-state 0.6s cubic-bezier(0.33, 0.84, 0.49, 1.18) forwards;
  animation: to-end-state 0.6s cubic-bezier(0.33, 0.84, 0.49, 1.18) forwards;
}

@media only screen and (min-width: 768px) {
  .topnavbar .navbar-form {
    left: 240px;
  }
}

@media only screen and (min-width: 768px) {
  .branding-area {
    position: absolute;
    top: 1rem;
    left: 3rem;
    width: 40%;
    height: 5.5rem;
    background: inherit;
    overflow: hidden;
  }
  .topnavbar {
    background: $dmp-white-inverse;
    border: 0;
    /*
    background-color: $brand-info;
    background-image: -webkit-linear-gradient(left, $brand-info 0%, #51c6ea 100%);
    background-image: -o-linear-gradient(left, $brand-info 0%, #51c6ea 100%);
    background-image: linear-gradient(to right, $brand-info 0%, #51c6ea 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff23b7e5', endColorstr='#ff51c6ea', GradientType=1);
    */
  }

  .topnavbar .navbar-header {
    background-image: none;
    background-repeat: no-repeat;
    filter: none;
  }

  .topnavbar .navbar-nav > a {
    box-shadow: 0 0 0 #000 inset;
    transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
  }

  .topnavbar .navbar-nav > .open > a,
  .topnavbar .navbar-nav > .open > a:hover,
  .topnavbar .navbar-nav > .open > a:focus {
    box-shadow: 0 -3px 0 #5d9cec inset;
    transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
  }

  .topnavbar .navbar-nav > li > a,
  .topnavbar .navbar-nav > .open > a {
    color: $dmp-white-inverse;
  }

  .topnavbar .navbar-nav > li > a:hover,
  .topnavbar .navbar-nav > .open > a:hover,
  .topnavbar .navbar-nav > li > a:focus,
  .topnavbar .navbar-nav > .open > a:focus {
    color: #117391;
  }

  .topnavbar .navbar-nav > li > [data-toggle="navbar-search"] {
    position: static;
  }

  .nav-wrapper {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 10;
  }

  .nav-wrapper:before,
  .nav-wrapper:after {
    //content: " ";
    //display: table;
  }

  .nav-wrapper:after {
    clear: both;
  }

  .nav-wrapper:before,
  .nav-wrapper:after {
    // content: " ";
    //display: table;
  }

  .nav-wrapper:after {
    clear: both;
  }

  .nav-wrapper .navbar-nav .open .dropdown-menu {
    left: auto;
    right: auto;
  }

  .nav-wrapper .navbar-nav.navbar-right .open .dropdown-menu {
    left: auto;
    right: 0;
  }
}

@media only screen and (min-width: 768px) {
  .aside-collapsed .topnavbar .navbar-header .brand-logo {
    display: none;
  }

  .aside-collapsed .topnavbar .navbar-header .brand-logo-collapsed {
    display: block;
    width: 115px;
  }

  .aside-collapsed .topnavbar .navbar-header {
    width: 70px;
  }

  .aside-collapsed .topnavbar .navbar-form {
    left: 70px;
  }
  .aside-collapsed .topnavbar {
    margin-left: 0px;
  }
}

.layout-fixed .topnavbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.topnavbar .navbar-nav > li > a,
.topnavbar .navbar-nav > .open > a {
  background: #d7d4d2;
}

.topnavbar .navbar-nav > li > a,
.topnavbar .navbar-nav > .open > a {
  color: var(--black);
}

.topnavbar .navbar-nav > li > a:hover,
.topnavbar .navbar-nav > .open > a:hover,
.topnavbar .navbar-nav > li > a:focus,
.topnavbar .navbar-nav > .open > a:focus {
  color: $dmp-white-inverse;
}

.topnavbar .navbar-nav > .active > a,
.topnavbar .navbar-nav > .open > a,
.topnavbar .navbar-nav > .active > a:hover,
.topnavbar .navbar-nav > .open > a:hover,
.topnavbar .navbar-nav > .active > a:focus,
.topnavbar .navbar-nav > .open > a:focus {
  background-color: $dmp-white-inverse;
  background-image: none;
}

.topnavbar .navbar-nav > li > [data-toggle="navbar-search"] {
  color: $dmp-white-inverse;
}

.topnavbar .nav-wrapper {
  background-color: $dmp-white-inverse;
  /*border-bottom: 1px solid #cfdbe2;*/
}

.topnavbar {
  margin-bottom: 0px;
  border-radius: 0px;
  background-color: $dmp-white-inverse;
  z-index: 1050;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

@media only screen and (max-width: 768px) {
  .topnavbar .navbar-nav > li > a,
  .topnavbar .navbar-nav > .open > a {
    background: $state-primary;
  }

  .topnavbar .navbar-nav > li > a,
  .topnavbar .navbar-nav > .open > a {
    text-align: right;
    padding-right: 2em;
  }

  .topnavbar .navbar-nav > li > a,
  .topnavbar .navbar-nav > .open > a {
    color: $dmp-white-inverse;
  }

  .topnavbar .navbar-nav > li > a:hover,
  .topnavbar .navbar-nav > .open > a:hover,
  .topnavbar .navbar-nav > li > a:focus,
  .topnavbar .navbar-nav > .open > a:focus {
    background: #5d7fa1;
  }

  .topnavbar .navbar-nav > .active > a,
  .topnavbar .navbar-nav > .open > a,
  .topnavbar .navbar-nav > .active > a:hover,
  .topnavbar .navbar-nav > .open > a:hover,
  .topnavbar .navbar-nav > .active > a:focus,
  .topnavbar .navbar-nav > .open > a:focus {
    background-color: $dmp-white-inverse;
    background-image: none;
  }

  div.brand-logo img {
    height: 4.4rem;
    width: auto;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
}
.search-box {
  width: 95%;
  height: auto;
  padding: 1rem;
}

div.brand-logo {
  max-height: 100%;
  max-width: 100%;

  width: auto;

  overflow-y: hidden;
  margin: 0 0 0.5em 0;
}

div.user-block-info {
  //float: right;
}

img.img-responsive.branding-logo {
  max-height: 44px;
  width: auto;
}

.dmp-admin-ctrl {
  display: none;
  @media screen and (min-width: 500px) {
    display: flex;
  }
}

@keyframes to-end-state {
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    opacity: 1;
  }
}
