// VIDEO VERIFICATION VIEW
@mixin header-item-value-typography {
  font-size: 1.2rem;
  font-weight: 600;
  @media screen and (min-width: $screen-sm) {
    font-size: 1.4rem;
  }
}

@mixin truncate-line-info {
  width: 18rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.video-verification {
  $header-height: 8rem;
  $measure-2x: 1.8rem;

  &__header {
    background-color: $bleached-white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $header-height;
    border-bottom: 2px solid $global-border;
    padding: 2.4rem;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  }

  &__no-cameras-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 2rem;
    font-weight: 700;
    color: var(--color-danger-500);
    padding: 2.4rem;
  }

  &__system-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 300px;
    margin: 0 10px;

    // max-width: 30rem;
  }

  &-customer-name {
    font-size: 44px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-alarm-time {
    color: red;
  }

  &__grid-control {
    @media screen and (min-width: $screen-sm) {
      display: flex;
    }
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 0;

    &__btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      color: $dmp-blue;
      margin: var(--measure-1x);
      transition: all 0.2s ease-out;

      &:hover {
        cursor: pointer;
        color: $dmp-blue-hover;
      }
    }

    &__toggle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 0.8rem;
      font-size: 1.4rem;
    }
  }

  &__body {
    margin-top: $header-height + $measure-2x;
    background: $athens-gray;
  }

  &__preview-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    &-child {
      width: 50%;
      margin: 4px;
    }

    &-child-small-screen {
      width: 50%;
      margin: 4px;
    }
  }

  &__video {
    border: 1px solid $global-border;
    border-radius: 0.4rem;
    margin: var(--measure-half);

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 var(--measure-1x);
      background: $bleached-white;
      height: 3rem;
      border-top-right-radius: 0.4rem;
      border-top-left-radius: 0.4rem;

      &__record-btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  &__clip-player {
    &__video-area {
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: stretch;
      height: inherit;
      width: inherit;
    }

    &__btn-area {
      width: 100%;
      padding: 0 var(--measure-1x) var(--measure-1x) var(--measure-1x);
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      transform-origin: top center;
      transform: scaleY(1);

      > *[not]:last-child {
        margin-right: var(--measure-1x);
      }

      &.ng-hide {
        opacity: 0;
        transform: scaleY(0);
      }

      &.ng-hide-add,
      &.ng-hide-remove {
        transition: all linear 600ms;
      }

      &__btn-area-clip-title {
        display: flex;
        flex-flow: column wrap;
      }
    }

    &__play-btn {
      font-size: 1.8rem;
      padding: 0.8rem;
      color: $dmp-blue;
      transition: all 0.2s ease-out;

      &:hover {
        color: $dmp-blue-hover;
      }
    }

    &__btn {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      color: $dmp-blue;
      transition: all 0.2s ease-out;

      &:hover {
        color: $dmp-blue-hover;
      }
    }

    &__info {
      position: absolute;
      bottom: var(--measure-1x);
      transform-origin: center;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__clip-player-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__clip-list {
    padding-bottom: 0;
    margin-bottom: 0;

    &-table {
      height: 36em;
      overflow-y: auto;
    }
  }

  &__table {
    &__td-wrapper {
      display: flex !important;
      flex-flow: column wrap !important;
      justify-content: flex-start !important;

      &__td-wrapper-btn {
        vertical-align: middle !important;
        width: 4rem !important;
      }
    }
  }

  &__live-stream-control-bar {
    width: 100%;
    height: 48px;

    z-index: 5000;
    display: flex;
    bottom: -33px;
    left: 0;
    right: 0;
    justify-content: stretch;
    align-items: center;

    //transform: translateY(-4rem);
    opacity: 0;
    animation: control-bar-visible 0.2s cubic-bezier(0.25, 0.1, 0.25, 1)
      forwards;

    &:nth-child(2),
    &:nth-child(3) {
      flex-grow: 2;
      width: 100%;
    }

    &__control {
      &:nth-child(2) {
        &:active {
          background-color: red;
          color: white;
        }
      }

      &:not(:last-child) {
        margin-right: var(--measure-half);
      }
    }
  }
}

.header { display: flex; 
  
  &--online-status {
    font-size: 1.0rem;
    font-weight: 600;
    color: inherit;
  }

  &__item {
    text-align: right;
    width: 100%;
    @media screen and (min-width: $screen-sm) {
      display: flex;
      justify-content: space-between;
    }

    &-label {
      display: none;
      font-size: 1.4rem;
      font-weight: 400;
      color: #a6a6a6;
      @media screen and (min-width: $screen-sm) {
        display: inline;
      }
    }

    &-value {
      @include header-item-value-typography;
      @include truncate-line-info;
      color: #656565;

      &--alarm {
        @include header-item-value-typography;
        @include truncate-line-info;
        color: red;
      }
    }

    div.dialog {
      width: 30em;
      padding: 0 4em;
      margin: 4em auto 0 auto;
      border: 1px solid #ccc;
      border-right-color: #999;
      border-bottom-color: #999;
    }

    h1 {
      font-size: 100%;
      color: #f00;
      line-height: 1.5em;
    }

    @keyframes control-bar-visible {
      to {
        opacity: 1;
        transform: none;
      }
    }
  }
}

.panel-title--flex-sbc {
  @include flex-row_spc-btwn-cntr;
  padding: var(--measure-1x);
  font-size: 1.4rem;

  > a {
    @include flex-col-start-center;
    color: $dmp-blue;
    transition: all 0.2s ease-out;
    text-decoration: none;

    &:hover {
      color: $dmp-blue-hover;
      text-decoration: none;
    }
  }
}

body.video-verification {
  background-color: #fff;
  color: #666;
  text-align: center;
  font-family: arial, sans-serif;
}

div.dialog {
  width: 40em;
  background-color: white;
  padding: var(--measure-2x);
  margin: 4em auto 0 auto;
  border: 1px solid $global-border;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.viewing {
  &-alarm-stream {
    background-color: $brand-danger;
    color: white;
    animation: shadow-pulse-danger 1s infinite;
  }

  &-live-stream {
    background-color: $primary-color;
    color: white;
    animation: shadow-pulse-primary 1s infinite;
  }
}

@keyframes shadow-pulse-danger {
  0% {
    box-shadow: 0 0 0 0 rgba($brand-danger, 0.7);
  }
  100% {
    box-shadow: 0 0 0 10px rgba($brand-danger, 0);
  }
}

@keyframes shadow-pulse-primary {
  0% {
    box-shadow: 0 0 0 0 rgba($brand-primary, 0.7);
  }
  100% {
    box-shadow: 0 0 0 10px rgba($brand-primary, 0);
  }
}
