.camera-channel-widget {
  background-color: white;
  border: 1px solid var(--color-neutral-500);
  border-radius: 0.4rem;
  max-width: 95%;
  height: auto;
  padding: 1rem 0;

  margin: 0 0.8rem 1rem 0.8rem;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
  > hr {
    margin-top: 0;
  }

  &__header {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    padding: 0 1rem;

    margin-bottom: 1rem;

    &__title {
      display: flex;
      font-size: 1.4rem;
      font-weight: 400;
      color: var(--color-gray-700);
      margin-bottom: 0.5rem;
    }

    &__email {
      display: flex;
      font-size: 1rem;
      font-weight: 400;
      color: var(--color-gray-500);
    }
  }

  &__channel-body {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
  }

  &__channel {
    display: flex;
    background-color: white;
    flex-flow: row wrap;
    align-items: center;
    justify-content: start;
    /* margin-bottom: 1.2rem; */
    color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 1rem;
    //make every other channel a different  background color
    &:nth-child(odd) {
      background-color: hsl(221, 28%, 97%);
    }
  }

  &__doorbell-channel-line-item {
    display: flex;
    background-color: white;
    flex-flow: column wrap;
    align-items: center;
    justify-content: start;
    /* margin-bottom: 1.2rem; */
    color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 1rem;
    //make every other channel a different  background color
    &:nth-child(odd) {
      background-color: hsl(221, 28%, 97%);
    }

    &--buttons {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 1rem;
    }
  }
}

.deemphasis-text {
  color: $color-deemphasis;
  font-style: italic;
  font-size: 1rem;
}
