.list-group .portlet-list-group {
  height: 217px;
  overflow-y: auto;
}

.panel-widget-icon-area {
  background: $accent-blue;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.panel-title > i {
  color: $accent-blue;
}

.app-key-wrappper {
  background-color: #daedda;
  border: 1px solid #047101;

  vertical-align: middle;
  border-radius: 4px;
  padding: 6px;
  text-size: 2em;
  margin-bottom: 10px;
  margin-left: -8px;
}

.merged-table {
  margin-top: -4.5rem;
  border-top: 0;

  & .panel-heading {
    padding: 1rem 1.5rem 0 1.5rem;
  }
  & .panel-body {
    padding-top: 0;
  }

  &__heading {
    &--title {
      font-size: 2rem;
      font-weight: 400;
      //margin-left: 1.2rem;
      position: relative;
      top: 0.5rem;
      color: inherit;
    }
    &--form {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &--icon-group {
      background: $bleached-white;
      border-width: 0;
      min-width: 1rem;
    }

    &--icon:before {
      position: relative;
      top: 0.4rem;
    }

    &--input {
    }
  }
}

.input-group {
  .form-control {
    &:last-child {
      &.merged-table__heading--input {
        border-bottom-left-radius: 0.3rem;
        border-top-left-radius: 0.3rem;
        font-family: inherit;
        &:-webkit-input-placeholder,
        &:-moz-placeholder,
        &:-ms-input-placeholder {
          text-align: center;
        }
      }
    }
  }
}

.form-control {
  &.chart-select {
    border: none;
  }
}

p.system-metric-display {
  font-size: 1.2rem;
  @media screen and (min-width: $screen-sm) {
    margin-bottom: 0;
  }
}
