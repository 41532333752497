// css 3 custom masonry code for masonry panels

.mortar-row {
  .row {
    -webkit-column-width: 25em;
    -moz-column-width: 25em;
    column-width: 25em;
    -webkit-column-gap: 0.5em;
    -moz-column-gap: 0.5em;
    column-gap: 0.5em;
  }
}

.panel-brick {
  .panel {
    display: inline-block;
    margin: 0.5em;
    padding: 0;
    width: 98%;
  }
}

.grid-item {
  width: 200px;
}
.grid-item--width2 {
  width: 400px;
}
