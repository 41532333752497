.icon-dropdown {
  float: right;
  &__anchor {
    margin: 10px 0 10px 0;
    position: relative;
    padding-left: 0;
    padding-right: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  &__icon {
    border: 1px solid $dmp-blue;
    font-size: rem(24px);
    color: $dmp-blue;
    padding: 6px;
    border-radius: 4px;

    -webkit-transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -moz-transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -ms-transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -o-transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .dropdown-menu {
    top: 56px;
    left: rem(-122px);
    @media screen and (min-width: $screen-sm) {
      left: rem(-122px);
    }

    > li > a {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif;
    }
  }
}

ul .icon-dropdown__anchor.dropdown-toggle[aria-expanded="true"] {
  .icon-dropdown__icon.icon-troubleshooting {
    transform: rotate(90deg) scale(1.2);
  }
}

.dropdown .dropdown-menu.system-dropdown-menu {
  -webkit-transition: all 0.3s 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.3s 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.3s 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.3s 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.3s 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  transform: translateY(-2rem);
  display: block;
  overflow: hidden;
  opacity: 0;
}

.dropdown.open .dropdown-menu.system-dropdown-menu {
  transform: none;
  opacity: 1;
}
