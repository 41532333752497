.progress-work-step {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-top: 0.5em;
}

span.avail-zone-msg {
  &:before {
    content: "\a";
    white-space: pre;
  }
}

.dialog-header-confirm {
  span,
  h4 {
    color: $dmp-white-inverse;
  }
}

div.progress-bar-dmp {
  background-color: $dmp-progress-bar-color;
}

#system-update .modal-footer {
  background-image: url("/assets/img/cell-ant2.gif");
  background-repeat: no-repeat;

  background-size: contain;

  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

#system-update .radial-bar-lg::after,
#system-update .radial-bar-lg > img {
  width: 110px;
  height: 110px;
  margin-left: 20px;
  margin-top: 20px;
  line-height: 70px;
}

#system-update .radial-bar-lg {
  width: 150px;
  height: 150px;
  font-size: 26px;
}

.modal-body {
  &--long-list {
    max-height: 30rem;
    overflow-y: auto;
  }
}

.modal-title {
  &--y-center {
    display: flex;
    align-items: center;
  }
  &--flex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    &__col-1 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: var(--measure-font-20);
    }
    &__col-2 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
.modal-title {
  &--flex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    &--center {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      font-size: var(--measure-font-18);
    }
  }
}

.modal-dialog {
  @media (min-width: $screen-sm) {
    margin: 5.2rem auto;
  }
}

.modal-xl {
  @media (min-width: $screen-sm) {
    width: $modal-xl;
  }
}

.modal-footer-paragraph {
  text-align: left;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #555;
  margin-top: 1rem;
  padding: 1rem 2rem;
  background-color: #f9f9f9;
  border-radius: 0.5rem;
  border: 0.1rem solid #ddd;
}
