/**
*  Dealer-Admin
* all sass variables are located in prep/sass/angle-template/variables
 */

/*@import "helpers/utilities";*/

/*!
 *
 * Angle - Bootstrap Admin App
 *
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: http://support.wrapbootstrap.com/knowledge_base/topics/usage-licenses
 *
 */

/* [Table of contents]

1. Bootstrap reset
  1.1 bootstrap
  1.2 bootstrap-reset
  1.3 bootstrap-print

2. Typography
  2.1 typo

3. Layout
  3.1 layout / .wrapper .content-wrapper
  3.2 top-navbar / .top-navbar
  3.3 sidebar / .sidebar
  3.4 offsidebar / .offsidebar

4. Components
  4.1  button-extra
  4.2  placeholder / .box-placeholder
  4.3  panels
  4.4  progress-extra
  4.5  radial-bar / .radial-bar
  4.6  user-block / .user-block
  4.7  circles / .circle
  4.8  timeline / .timeline
  4.9  dropdown-extra
  4.10 row-extra / .row-table .row-flush
  4.11 half-float / .half-float
  4.12 masonry-grid / .row-masonry
  4.13 widget / .widget
  4.14 typeahead / .typeahead-ctrl
  4.15 animate
  4.16 table-grid
  4.17 todo / .todo
  4.18 nestable

5. Charts
  5.1 chart-flot / .flot-chart

6. Form elements
  6.1 form-elements
  6.2 form-validation
  6.3 form-tags

7. Tables
  7.1 datatable
  7.2 table-extras

8. Plugins
  8.1 plugins
  8.2 slim-scroll / .slimScrollBar
  8.3 alerts / .alerts
  8.4 notifiy / .notifiy
  8.5 calendar / .fc-*
  8.6 spinner / .whirl
  8.7 gmap / .gmap
  8.8 vector-map / vector-map
  8.9 portlets / .portlet

9. Utilities
  9.1 utils

10. Print CSS
  10.1 print

11. Settings
  11.1 settings

12. Documentation
  12.1 docs
 */

// Override bootstrap variables
@import "vendors/angle-template/variables";
@import "vendors/angle-template/utils-definitions";

// Bootstrap
@import "vendors/bootstrap/bootstrap";

//Bootstrap Grid Flexbox
@import "vendors/bootstrap-grid-flexbox/bootstrap-grid-flexbox";

// Global definition of media queries
@import "vendors/angle-template/media-queries";
// Utilities
@import "vendors/angle-template/utils-definitions";

// Bootstrap reset
@import "vendors/angle-template/bootstrap-reset";
@import "vendors/normalize-scss/sass/normalize.scss";

// Typography
@import "vendors/angle-template/typo";

// Layout
@import "vendors/angle-template/layout";
@import "vendors/angle-template/layout-extra";
@import "vendors/angle-template/top-navbar";
@import "vendors/angle-template/sidebar";
@import "vendors/angle-template/offsidebar";

// Components
@import "vendors/angle-template/button-extra";
@import "vendors/angle-template/placeholder";
@import "vendors/angle-template/panels";
@import "vendors/angle-template/progress-extra";
@import "vendors/angle-template/radial-bar";
@import "vendors/angle-template/user-block";
@import "vendors/angle-template/circles";
@import "vendors/angle-template/timeline";
@import "vendors/angle-template/dropdown-extra";
@import "vendors/angle-template/row-extra";
@import "vendors/angle-template/half-float";

@import "vendors/angle-template/widget";

@import "vendors/angle-template/animate";
@import "vendors/angle-template/loading-bar";
@import "vendors/angle-template/table-grid";

@import "vendors/angle-template/ngwig";
@import "vendors/angle-template/ngdialog";

// Charts
@import "vendors/angle-template/chart-flot";

// Form elements
@import "vendors/angle-template/form-elements";

@import "vendors/angle-template/form-validation";

// Tables
@import "vendors/angle-template/datatable";
@import "vendors/angle-template/table-extras";

// Plugins
@import "vendors/angle-template/plugins";
@import "vendors/ng-sim-scroll/simscroll";

@import "vendors/angle-template/alerts";
@import "vendors/angle-template/notify";

@import "vendors/angle-template/spinner";

@import "vendors/angle-template/vector-map";
@import "vendors/angle-template/portlets";
@import "vendors/hover/hover";
@import "components/tablesort";

// Utilities
@import "vendors/angle-template/utils";

// Print CSS
@import "vendors/angle-template/print";

// Settings
@import "vendors/angle-template/settings";

// Documentation

/*
* Icomoon Custom Font Library
*********************************/
//@import "font/icomoon-fonts";
@import "font/font";

/*
* All overrides are located in partial scss files under /partial
*
*/
@import "abstracts/abstracts";
@import "base/base";
@import "overrides/custom";
@import "components/components";
@import "layout/layout";
@import "views/views";
