.filter-tag {
  display: inline-block;
  border: 1px solid $accent-blue;
  background: rgba($accent-blue, 0.2);
  color: $accent-blue;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  position: relative;
  cursor: default;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0.6rem;
  -moz-border-radius: 0.6rem;
  border-radius: 0.6rem;
  transition: all 0.5s ease;
  &__closer {
    font-size: 0.8rem;
    color: $accent-blue-link-hover;
    padding-right: 0.5rem;
  }
  &--clear-all {
    display: inline-block;
    border: 1px solid $accent-blue;
    color: $accent-blue;
    padding: 0.3rem 0.5rem;
    font-size: 1rem;
    position: relative;
    cursor: default;
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 0.6rem;
    -moz-border-radius: 0.6rem;
    border-radius: 0.6rem;
    transition: all 0.5s ease;

    &:hover {
      transition: all 0.5s ease;
      border: 1px solid $accent-blue;
      background: rgba($accent-blue, 0.2);
      color: $accent-blue;
    }
  }
  &:link {
  }

  &:hover {
    transition: all 0.5s ease;
    border: 1px solid $state-primary;
    background-color: $state-primary;
    color: $bleached-white;
    .filter-tag__closer {
      color: $bleached-white;
      font-weight: 600;
    }
  }
}
