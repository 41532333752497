.system-tests {
  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(33.4rem, 1fr);

    &__content-cell {
    }

    &__detail {
      &__header {
        margin-top: var(--measure-2x);
        font-size: var(--measure-font-16);
        font-weight: var(--font-weight-thin);
      }

      &__body {
        margin-top: var(--measure-2x);
        padding-left: var(--measure-5x);
      }
    }
  }

  &__main-header {
    @include flex-config(row, nowrap, space-between, center);
    font-size: var(--measure-font-20);
    margin: var(--measure-1x) 0;
    padding-bottom: var(--measure-1x);
    border-bottom: 0.1rem solid $color-neutral-400;

    &--baseline {
      align-items: baseline;
    }

    &__long-wait-message {
      font-size: var(--measure-font-12);
      color: var(--color-neutral-700);
      display: block;
      padding-right: var(--measure-1x);
    }
  }

  &__communication-test,
  &__walk-test,
  &__wireless-test,
  &__zwave-test,
  &__zwave-opt {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    &__list {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      @media (min-width: $screen-md) {
        max-width: 40%;
      }

      &__item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: var(--measure-1x);
        width: 100%;

        &__name {
          font-size: var(--measure-font-14);
        }

        &__feeback-area {
        }
      }
    }
  }

  &__test-status {
    &--success {
      @include flex-config(row, nowrap, flex-start, center);
      color: var(--color-success-500);
      font-size: var(--measure-font-14);
    }

    &--fail {
      @include flex-config(row, nowrap, flex-start, center);
      color: var(--color-danger-500);
      font-size: var(--measure-font-14);
    }
  }
}

.checkbox-deemphasize {
  opacity: 0.4;
  transition: all 0.4s ease-in;
}

.feedback-icon {
  z-index: 1;
  position: relative;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  animation: rotate-and-scale-icon 0.4s forwards;

  &--success {
    background-color: var(--color-success-400);

    &:after {
      width: 1.8rem;
      height: 1.8rem;
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      transform-origin: center center;
      transform: translate(-50%, -50%);
      z-index: 2;
      font-family: "dmp_icon_font";
      content: "\e978";
      font-size: var(--measure-font-12);
      color: white;
    }
  }

  &--fail {
    background-color: var(--color-danger-400);

    &:after {
      width: 1.8rem;
      height: 1.8rem;
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      transform-origin: center center;
      transform: translate(-50%, -50%);
      z-index: 2;
      font-family: "dmp_icon_font";
      content: "\e979";
      font-size: var(--measure-font-12);
      color: white;
    }
  }
}

@keyframes rotate-and-scale-icon {
  0% {
    opacity: 0;
    transform: scale(0) rotate(1080deg);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
