/** TEMPLATES */

#customer_select {
  .input-group > .ui-select-bootstrap > input.ui-select-search.form-control {
    @media (min-width: $screen-sm) {
      width: 30rem;
    }
  }

  ul.ui-select-choices.ui-select-choices.ui-select-choices-content.ui-select-dropdown.dropdown-menu {
    @media (min-width: $screen-sm) {
      width: 30rem;
    }
  }
}
