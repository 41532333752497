@mixin three-g-base($top-offset: 0) {
  display: flex;
  background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.8),
      rgba(66, 152, 179, 0.8)
    ),
    url(/assets/img/3g-ad.jpeg) left center;
  background-blend-mode: darken;
  background-size: cover;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 1.2rem;
  transform: translateY(-8rem);
  left: 0;
  width: 100%;
  //height: 25rem;
  margin-bottom: var(--measure-1x);
  z-index: 1000000;
  border: 0.1rem;
  padding: var(--measure-2x);
  -webkit-box-shadow: 0px 17px 0px -16px rgba(204, 204, 204, 1);
  -moz-box-shadow: 0px 17px 0px -16px rgba(204, 204, 204, 1);
  box-shadow: 0px 17px 0px -16px rgba(204, 204, 204, 1);
  animation: 1s to-final-position ease-out forwards;
  @media (min-width: 568px) {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
  }
  @media screen and (min-width: $screen-sm) {
    width: 100%;
    top: 3.7rem;
    margin-bottom: 2.5rem;
  }
}
.three-g-promo {
  @include three-g-base(0);

  &__title {
    color: white;
    font-size: 2.8rem;
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--measure-1x);
  }

  /*Sign up now button*/
  &__btn:link,
  &__btn:visited {
    z-index: 2;
    font-size: 1.8rem;
    font-weight: 500;
    background-color: $brand-warning;
    color: black;
    transform-origin: bottom;
    transform: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      background-color: lighten($brand-warning, 5%);
      color: white;

      transform: translateY(-1px);
      box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
    }

    &:active {
      background-color: darken($brand-warning, 10%);
      color: black;
      transform: none;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    @media (min-width: $screen-sm) {
      font-size: 1.8rem;
    }
  }
}

@keyframes to-final-position {
  100% {
    opacity: 1;
    transform: none;
  }
}
