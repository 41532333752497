/*---------------------------------
*  Widgets
-----------------------------------*/

.flex-wrapper {
  width: 100%;
  overflow: hidden;
}

.widget {
  &.cell-signal-widget {
    position: relative;
    width: 175px;
    height: 120px;
    display: inline-block;
    margin: 3px;
    border-right: 1px solid #cbcbcb;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1);
    -webkit-font-smoothing: subpixel-antialiased;
    @include BoxShadow(0);

    &:hover {
      @include BoxShadow(2);
      transform: translateY(-0.2rem);
    }
  }

  &.cell-signal-widget-large {
    position: relative;
    width: 175px;
    height: 280px;
    display: inline-block;
    margin: 16px;
    border-right: 1px solid #cbcbcb;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1);
    -webkit-font-smoothing: subpixel-antialiased;
    @include BoxShadow(0);

    &:hover {
      @include BoxShadow(2);
      transform: translateY(-0.2rem);
    }
  }
}

.orders-dashboard-widget {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  min-width: 14rem;
  padding: var(--measure-2x);
  margin: 0 var(--measure-1x) var(--measure-1x) 0;
  background-color: white;
  @include global-border;
}

.cell-signal-widget-title,
.cell-signal-widget-xtra-info,
.orders-dashboard-widget-title,
.orders-dashboard-widget-xtra-info {
  color: #656565;
}

.cell-signal-widget-title {
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
}

.orders-dashboard-widget-title {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
}

.cell-signal-widget-xtra-info,
.orders-dashboard-widget-xtra-info {
  font-size: 1rem;
  font-weight: normal;
}

.cell-signal-widget-main-info,
.orders-dashboard-widget-main-info {
  font-size: 2rem;
  font-weight: 500;
}

.is-active-view {
  background: #faf9f7;
}

.clickable {
  cursor: pointer;
}

.big-graph-controls {
  font-size: 1.2rem;
  float: right;
  position: relative;
  top: -1px;
}

span.clickable:nth-child(1) {
  margin: 0;
}

.sidebar > ul > li > a {
  padding-left: 10px;
}

.system-analytics-page {
  padding-left: 0em;
}

span.clickable {
  position: relative;
  top: 7px;

  &:nth-child(6),
  &:nth-child(5) {
    font-size: 2.5rem;
  }

  &:nth-child(7) {
    font-size: 2.5rem;
    top: 4px;
    margin-right: 0.833rem;
  }

  &:nth-child(5) {
    position: relative;
    top: 9px;
  }
}

.big-graph-controls > div:nth-child(1) > a:nth-child(5) > strong:nth-child(1) {
  margin-right: 1em;
}

ul.ColVis_collection {
  background-color: rgba(255, 255, 255, 1) !important;
}

.popover.left {
  margin-left: -10px;
  margin-top: 8px;
}

#input-search.body {
  font-size: 0.5em;
}

.sm-input-class {
  height: 30px;
  font-size: 1em;

  &[placeholder] {
    font-size: 1em;
  }
}

a.ng-isolate-scope div.arrow {
  display: none;
}

.cell-widget-wrapper,
.orders-dashboard-widget-wrapper {
  padding: 1em;
  margin: 0.5em;
  display: flex;
}

.cell-widget-wrapper {
  justify-content: center;
  align-items: center;
}

.orders-dashboard-widget-wrapper {
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: $screen-sm) {
    justify-content: flex-start;
    align-items: center;
  }
}

span.clickable {
  &.icon-linegraph:hover,
  &.icon-bargraph:hover,
  &.fa.fa-table:hover {
    color: #141414;
  }
}

.widget-bg-dmp-gray-status {
  color: #656565;
}

.widget-bg-dmp-blue-status {
  color: $state-primary-hover;
}

.widget-bg-dmp-red-status {
  color: $dmp-red;
}

li.paginate_button.active {
  a {
    background-color: $state-primary;
    color: white;
  }

  &:hover a {
    background-color: $state-primary;
    color: white;
    background-color: $state-primary-hover;
    border-color: $state-primary;
    transition: all 0.3s ease-out;
  }
}

.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  color: white;
  background-color: $state-primary;
  border-color: $state-primary;
  transition: all 0.3s ease-out;
}

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  z-index: 2;
  color: white;
  background-color: $state-primary;
  border-color: $state-primary;
  cursor: default;
  transition: all 0.3s ease-out;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: var(--dark-gray);
  cursor: not-allowed;
  background-color: white;
  border-color: #ddd;
}

/* Added to handle sparkline chart main info header values that fall below the specified threshold */

.cell-signal-widget-main-warning {
  font-size: 2rem;
  font-weight: 500;
  color: rgba($dmp-red, 0.8);
}
