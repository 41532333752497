@mixin base-radial-progress-bar-custom {
  position: relative;
  display: inline-block;
  font-size: 14px;
  //width: $radial-size;
  //height: $radial-size;
  border-radius: 50%;
  border: 2px solid $back-color; // remove gradient color
  background-color: transparent; // default 100%
  //margin-bottom: 20px;
  // margin-right: 20px;
  @include box-sizing(content-box);

  // Optional centered circle w/text
  &:after,
  > img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    // width: $radial-size - $radial-bar-size;
    // height: $radial-size - $radial-bar-size;
    border-radius: 50%;
    // margin-left: $radial-bar-size / 2;
    // margin-top: $radial-bar-size / 2;
    text-align: center;
    // line-height: $radial-size - $radial-bar-size;
    font-weight: 200;
    color: #a1a2a3;
  }
  &:after {
    content: attr(data-label);
    background-color: $overlay-bg;
    z-index: 101;
  }
  > img {
    z-index: 102;
  }

  @include radial-progress-size(14px);
}

div.progress-bar-dmp {
  background-color: $dmp-progress-bar-color;
}

#system-update .radial-bar-lg::after,
#system-update .radial-bar-lg > img {
  width: 65px;
  height: 65px;
  margin-left: 8px;
  margin-top: 8px;
  line-height: 62px;
}

#system-update .radial-bar-lg {
  width: 80px;
  height: 80px;
  font-size: 26px;
}

.modal-body .radial-bar-lg::after,
#system-update .radial-bar-lg > img {
  width: 90px !important;
  height: 90px;
  margin-left: 14px;
  margin-top: 14px;
  line-height: 84px;
}

.modal-body .radial-bar-lg {
  width: 120px;
  height: 120px;
  font-size: 28px;
}

script#statusModalContent.html .radial-bar-lg::after,
#system-update .radial-bar-lg > img {
  width: 65px;
  height: 65px;
  margin-left: 8px;
  margin-top: 8px;
  line-height: 55px;
}

script#statusModalContent.html .radial-bar-lg {
  width: 80px;
  height: 80px;
  font-size: 26px;
}

.modal-body .radial-bar-lg::after,
script#statusModalContent.html .radial-bar-lg > img {
  width: 90px !important;
  height: 90px;
  margin-left: 14px;
  margin-top: 14px;
  line-height: 84px;
}

.modal-body .radial-bar-lg {
  width: 120px;
  height: 120px;
  font-size: 28px;
}

/* ========================================================================
     Component: radial-bar
 ========================================================================== */

//
// Pure CSS Radial Progress Bar

// Colors
$bar-color: $brand-primary;
$radial-below-avg: $color-below-avg;
$radial-average: $color-average;
$radial-good: $color-good;
$radial-great: $color-great;
$radial-excellent: $color-excellent;

$radial-primary: $brand-primary;
$radial-success: $brand-success;
$radial-info: $brand-info;
$radial-warning: $brand-warning;
$radial-danger: $brand-danger;

$overlay-bg: $dmp-white-inverse;
$back-color: $dmp-light-gray;

$radial-size-base: 5;

$radial-font-size-lg: 20px;
$radial-font-size: 16px;
$radial-font-size-sm: 10px;
$radial-font-size-xs: 6px;
$radial-font-size--slim: 16px;

// step of % for created classes

$progress-step: 1;

// Mixins
// -----------------------

@mixin radial-progress-size($font-size) {
  $size: $font-size * $radial-size-base;
  $bar-size: $size * 0.3;
  width: $size;
  height: $size;
  font-size: $font-size + 2;
  &:after,
  > img {
    width: $size - $bar-size;
    height: $size - $bar-size;
    margin-left: $bar-size / 2;
    margin-top: $bar-size / 2;
    line-height: $size - $bar-size;
  }
}

@mixin radial-progress-variant($step, $color: $radial-primary) {
  $loops: round(100 / $step);
  $increment: 360 / $loops;
  $half: round($loops / 2);
  @for $i from 0 through $loops {
    &.radial-bar-#{$i*$step} {
      @if $i < $half {
        $nextdeg: 90deg + ($increment * $i);
        background-image: linear-gradient(
            90deg,
            $back-color 50%,
            transparent 50%,
            transparent
          ),
          linear-gradient($nextdeg, $color 50%, $back-color 50%, $back-color);
      } @else {
        $nextdeg: -90deg + ($increment * ($i - $half));
        background-image: linear-gradient(
            $nextdeg,
            $color 50%,
            transparent 50%,
            transparent
          ),
          linear-gradient(270deg, $color 50%, $back-color 50%, $back-color);
      }
    }
  }
}

/* -------------------------------------
 * Bar container
 * ------------------------------------- */
.radial-bar {
  position: relative;
  display: inline-block;
  font-size: $radial-font-size;
  //width: $radial-size;
  //height: $radial-size;
  border-radius: 50%;
  border: 2px solid $back-color; // remove gradient color
  background-color: transparent; // default 100%
  margin-bottom: 20px;
  // margin-right: 20px;
  @include box-sizing(content-box);

  // Optional centered circle w/text
  &:after,
  > img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    // width: $radial-size - $radial-bar-size;
    // height: $radial-size - $radial-bar-size;
    border-radius: 50%;
    // margin-left: $radial-bar-size / 2;
    // margin-top: $radial-bar-size / 2;
    text-align: center;
    // line-height: $radial-size - $radial-bar-size;
    font-weight: 200;
    color: #a1a2a3;
  }
  &:after {
    content: attr(data-label);
    background-color: $overlay-bg;
    z-index: 101;
  }
  > img {
    z-index: 102;
  }

  @include radial-progress-size($radial-font-size);
  @include radial-progress-variant($progress-step, $radial-primary);
}

.radial-bar--slim {
  @include base-radial-progress-bar-custom;

  @include radial-progress-variant($progress-step, $radial-primary);
}
// Variant
// -----------------------
.radial-bar-primary {
  @include radial-progress-variant($progress-step, $brand-primary);
}
.radial-bar-success {
  @include radial-progress-variant($progress-step, $brand-success);
}
.radial-bar-info {
  @include radial-progress-variant($progress-step, $brand-info);
}
.radial-bar-warning {
  @include radial-progress-variant($progress-step, $brand-warning);
}
.radial-bar-danger {
  @include radial-progress-variant($progress-step, $brand-danger);
}

//custom
.radial-bar--below-average {
  @include base-radial-progress-bar-custom;
  @include radial-progress-variant($progress-step, $radial-below-avg);
}

.radial-bar--average {
  @include base-radial-progress-bar-custom;
  @include radial-progress-variant($progress-step, $radial-average);
}

.radial-bar--good {
  @include base-radial-progress-bar-custom;
  @include radial-progress-variant($progress-step, $radial-good);
}

.radial-bar--great {
  @include base-radial-progress-bar-custom;
  @include radial-progress-variant($progress-step, $radial-great);
}

.radial-bar--excellent {
  @include base-radial-progress-bar-custom;
  @include radial-progress-variant($progress-step, $radial-excellent);
}

// Sizes
// -----------------------

.radial-bar-lg {
  @include radial-progress-size($radial-font-size-lg);
}
.radial-bar-slim {
  @include radial-progress-size($radial-font-size--slim);
}

.radial-bar-sm {
  @include radial-progress-size($radial-font-size-sm);
}

.radial-bar-xs {
  @include radial-progress-size($radial-font-size-xs);
}

// hide excess of gradient in opera
x:-o-prefocus,
.radial-bar {
  background-clip: content-box;
}
