@mixin global-list-parent-styles {
  background-color: white;
  //border: 1px solid $global-border;
  border-radius: 0.4rem;
}

@mixin two-col-read-only-styles {
  border-top: 1px solid $global-border;

  padding: var(--measure-2x) 0;
  //border-bottom: 1px solid $global-border;
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: 0.4fr min-content 1fr;
  grid-template-areas: "name subtitle channels";
  transition: all 0.2s ease-out;
  background-color: white;

  &:hover {
    background-color: var(--color-neutral-100);
  }

  &__name {
    grid-area: name;
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-items: flex-start;
    align-items: flex-start;
  }
  &__subtitle {
    grid-area: subtitle;
  }
  &__channels {
    grid-area: channels;
    display: flex;
    flex-flow: row wrap;
    max-width: 80%;
    justify-content: flex-start;
    align-items: flex-start;
    //padding: var(--measure-1x) 0;
  }
}

@mixin list-item-border {
}

.x1-device-list,
.nvr-device-list {
  position: relative;
  background-color: white;
  border-radius: 0.4rem;

  &--bordered {
    border-radius: 0.4rem;
    border: 0.1rem solid $global-border;
  }
  &--no-border {
    @include global-list-parent-styles();
    border: none;
  }

  & table {
    width: 100%;
    background-color: transparent;
  }

  &__list-item {
    transition: all 0.2s ease-out;

    &--clickable {
      cursor: pointer;
    }

    > td {
      padding: var(--measure-2x);
    }

    &:first-child {
      td:first-child {
        border-top-left-radius: 0.4rem;
      }
      td:last-child {
        border-top-right-radius: 0.4rem;
      }
    }

    &:last-child {
      td:first-child {
        border-bottom-left-radius: 0.4rem;
      }
      td:last-child {
        border-bottom-right-radius: 0.4rem;
      }
    }

    &:not(:last-child) {
      border-bottom: 0.1rem solid $global-border;
    }

    &:hover {
      background-color: var(--color-neutral-100);
      color: black;
    }

    &__doors {
      display: flex;
      flex-wrap: wrap;
    }

    &__arrow {
      width: 3rem;
    }

    &--doorbells {
      border-top: 1px solid $global-border;

      display: grid;
      grid-template-rows: min-content;
      grid-template-columns: repeat(3, 1fr) min-content;
      grid-template-areas: "camera firmware serial-number delete";
      transition: all 0.2s ease-out;

      &:hover {
        background-color: var(--color-neutral-100);
      }

      &__camera {
        padding: var(--measure-2x);
        grid-area: camera;
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: flex-start;
      }

      &__firmware {
        padding: var(--measure-2x);
        grid-area: firmware;
        display: flex;
        flex-flow: column wrap;
        max-width: 80%;
        justify-content: flex-start;
        align-items: flex-start;
      }
      &__serial-number {
        padding: var(--measure-2x);
        grid-area: serial-number;
        display: flex;
        flex-flow: column wrap;
        max-width: 80%;
        justify-content: flex-start;
        align-items: flex-start;
      }

      &__controls {
        padding: var(--measure-2x);
        grid-area: controls;
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-end;
        align-items: flex-start;
      }
      &__delete {
        padding: var(--measure-2x);
        grid-area: delete;
        display: flex;
        flex-flow: column wrap;
        max-width: 80%;
        justify-content: flex-start;
        align-items: flex-start;
        @media screen and (min-width: $screen-sm) {
          justify-content: center;
        }
      }
    }

    &--card-formats {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--measure-2x);
      transition: all 0.2s ease-out;
      background-color: white;
      border-radius: 4px;
      border: 1px solid $global-border;

      &:hover {
        color: black;
        background-color: var(--color-neutral-100);
      }

      &__name {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: var(--measure-font-14);
      }
    }

    &--eagle-eye {
      @include two-col-read-only-styles();
    }

    &--open-eye {
      @include two-col-read-only-styles();
    }

    &--digital-watchdog {
      @include two-col-read-only-styles();
    }
  }

  &__list-title {
    font-size: var(--measure-font-14);
    font-weight: bold;
  }
}

div.x1-device-list__list-item--doors:last-of-type {
  border-bottom: none;
}
.card-format-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-auto-rows: minmax(min-content, auto);
  grid-gap: 0.8rem;
}
