@mixin panel_shadow($level) {
  @if $level == 1 {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  } @else if $level == 2 {
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.16), 0 2px 2px rgba(0, 0, 0, 0.23);
  } @else if $level == 3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  } @else if $level == 5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
}

.campaign {
  &__confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: url("/assets/img/campaign-confirm/background.jpg") center;
    -webkit-background-size: cover;
    background-size: cover;

    &--panel {
      @include panel_shadow(5);
      max-width: 90vw;
      transform: translateY(-8rem) scale(0.9);
      opacity: 0;
      animation: to-end-state 0.6s cubic-bezier(0.44, 0, 0.53, 1.52) forwards;
    }

    &--text {
      font-size: var(--measure-2x);
      color: var(--color-neutral-700);
      padding: var(--measure-2x);
      text-align: center;
      @media (min-width: $screen-sm) {
        font-size: var(--measure-3x);
      }
    }

    &--icon {
      font-size: 10rem;
      @media (min-width: $screen-sm) {
        font-size: 16rem;
      }
    }
  }

  &-panel {
    display: grid;
    grid-template-rows: repeat(4, min-content);
    grid-template-columns: 1fr;
    grid-template-areas: "title" "desc" "sent" "buttons";
    grid-row-gap: var(--measure-2x);
    @media (min-width: $screen-sm) {
      grid-row-gap: var(--measure-1x);
      grid-column-gap: var(--measure-1x);
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr minmax(13rem, 14rem);
      grid-template-areas: "title sent buttons" "desc desc desc";
      //@include panel_shadow(1);
    }

    &:last-child {
      margin-bottom: var(--measure-3x);
      @media (min-width: $screen-sm) {
      }
    }

    &__title {
      grid-area: title;
      font-size: var(--measure-font-16);
      text-align: center;
      @media (min-width: $screen-sm) {
        text-align: left;
      }
    }

    &__description {
      grid-area: desc;
      font-size: var(--measure-font-14);
      color: var(--color-neutral-700);
      text-align: center;
      @media (min-width: $screen-sm) {
        text-align: left;
      }
    }

    &__sent {
      grid-area: sent;
      //justify-self: end;
      display: flex;
      flex-flow: row-reverse nowrap;
      justify-content: center;
      align-items: baseline;

      text-align: center;
      @media (min-width: $screen-sm) {
        justify-content: flex-start;
        text-align: left;
        align-items: baseline;
      }

      &__label {
        font-size: var(--measure-font-12);
        font-weight: var(--font-weight-thin);
      }

      &__date {
        font-size: var(--measure-font-16);
        min-width: 8rem;
      }
    }

    &__buttons {
      grid-area: buttons;
      //justify-self: end;
      align-self: start;
      text-align: center;
      @media (min-width: $screen-sm) {
        text-align: left;
        justify-self: end;
      }
    }
  }
}

/*@keyframes to-end-state {

}*/
