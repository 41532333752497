.sidebar-ad {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: var(--measure-4x);
  margin-left: var(--measure-1x);
  margin-right: var(--measure-1x);
  padding: var(--measure-1x);
  width: 90%;
  height: auto;
  border: 1px solid $global-border;
  border-radius: 4px;
  /*Background image and gradient for container*/
  &:before {
    content: "";
    position: absolute;
    margin: 8px auto;
    padding-left: 8px;
    padding-right: 8px;
    top: 0;
    left: 8px;
    width: 90%;
    background: url("/assets/img/VK.com2.0_Mockup_HomeAccess.png") left center;

    background-size: cover;
    height: 12rem;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    height: 12rem;
  }
  &.closed {
    display: none;
  }
  &:link {
    text-decoration: none;
  }

  &__header {
    margin-top: 12.4rem;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: var(--measure-1x);
    text-align: center;
  }

  &__body {
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: var(--measure-1x);
    line-height: 1.2;
  }

  &__closer {
    position: absolute;
    top: var(--measure-1x);
    right: var(--measure-2x);
    width: var(--measure-1x);
    height: var(--measure-1x);
    z-index: 4;
    color: $bleached-white;
    cursor: pointer;
  }

  /*Sign up now button*/
  &__btn,
  &__btn:visited {
    z-index: 2;
    font-size: 1.2rem;
    font-weight: 300;
    background-color: $brand-primary;
    color: white;
    width: 80%;
    transition: all 0.2s;

    &:hover {
      background-color: lighten($brand-primary, 5%);
      color: white;
      transform: translateY(-2px);
      box-shadow: 0 2px 7px rgba(0, 0, 0, 0.4);
    }

    &:active {
      background-color: darken($brand-primary, 10%);
      color: white;
      transform: none;
      box-shadow: none;
    }
  }
}
