//Code for the Marketing Central Splash Modal
.marketing-central-splash {
  position: relative;
  /**
    * icon area that closes the modal
    */
  &__closer {
    position: absolute;
    top: var(--measure-2x);
    right: var(--measure-1x);
    width: var(--measure-3x);
    height: var(--measure-3x);
    z-index: 4;
    /*center icon in the closer*/
    > a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $text-color;
      text-decoration: none;
      @media (min-width: $screen-sm) {
        color: $bleached-white;
      }

      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }

  /*Background image and gradient for container*/
  &:before {
    height: 0;
    transition: all 0.2s ease-in-out;
    @media (min-width: $screen-sm) {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(
          45deg,
          rgba(66, 152, 179, 0.8),
          rgba(66, 152, 179, 0.1)
        ),
        url("/assets/img/MarketingCentral_SignUpHeader.jpg") left center;
      background-blend-mode: screen;
      background-size: cover;
      height: 15.5rem;
    }
  }

  /*Container for Marketing Central logo*/
  &__logo {
    padding: 0 0.8rem;
    min-width: 25rem;
    max-width: 32rem;
    width: 80%;
    height: auto;
    margin-bottom: var(--measure-1x);
    @media (min-width: $screen-sm) {
      max-width: 40rem;
      margin-top: 15rem;
      padding: 0 0.8rem;
      width: 60%;
    }
    @media (min-width: $screen-md) {
      width: 40%;
    }
  }

  &__subheader {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 1.8rem;
    @media (min-width: $screen-sm) {
      font-size: 2rem;
    }
  }

  &__description {
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.2;
    margin-bottom: 1.8rem;
    @media (min-width: $screen-sm) {
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //padding-bottom:  1.8rem;
  }

  &__btn:link,
  &__btn:visited {
    z-index: 2;
    font-size: 1.8rem;
    font-weight: 500;
    background-color: $brand-primary;
    color: white;
    width: 80%;
    transform-origin: bottom;
    transform: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      background-color: lighten($brand-primary, 5%);
      color: white;

      transform: translateY(-1px);
      box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
    }

    &:active {
      background-color: darken($brand-primary, 10%);
      color: white;
      transform: none;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    @media (min-width: $screen-sm) {
      font-size: 1.8rem;
    }
  }

  &__login {
    font-size: 1.4rem;
    padding-top: var(--measure-2x);

  
  }
}
