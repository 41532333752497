/* ========================================================================
     Component: utils
 ========================================================================== */

// Margins
// --------------------

.m0 {
  margin: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.m {
  margin: $margin-base !important;
}

.ml {
  margin-left: $margin-base !important;
}

.mr {
  margin-right: $margin-base !important;
}

.mt {
  margin-top: $margin-base !important;
}

.mb {
  margin-bottom: $margin-base !important;
}

.m-sm {
  margin: $margin-sm !important;
}

.ml-sm {
  margin-left: $margin-sm !important;
}

.mr-sm {
  margin-right: $margin-sm !important;
}

.mt-sm {
  margin-top: $margin-sm !important;
}

.mb-sm {
  margin-bottom: $margin-sm !important;
}

.m-lg {
  margin: $margin-lg !important;
}

.ml-lg {
  margin-left: $margin-lg !important;
}

.mr-lg {
  margin-right: $margin-lg !important;
}

.mt-lg {
  margin-top: $margin-lg !important;
}

.mb-lg {
  margin-bottom: $margin-lg !important;
}

.m-xl {
  margin: $margin-xl !important;
}

.ml-xl {
  margin-left: $margin-xl !important;
}

.mr-xl {
  margin-right: $margin-xl !important;
}

.mt-xl {
  margin-top: $margin-xl !important;
}

.mb-xl {
  margin-bottom: $margin-xl !important;
}

.mt-3xl {
  margin-top: $margin-xl * 3 !important;
}

// Negative margins

.neg-m {
  margin: -$margin-base !important;
}

.neg-ml {
  margin-left: -$margin-base !important;
}

.neg-mr {
  margin-right: -$margin-base !important;
}

.neg-mt {
  margin-top: -$margin-base !important;
}

.neg-mb {
  margin-bottom: -$margin-base !important;
}

.neg-m-em {
  margin: -$margin-em !important;
}

.neg-ml-em {
  margin-left: -$margin-em !important;
}

.neg-mr-em {
  margin-right: -$margin-em !important;
}

.neg-mt-em {
  margin-top: -$margin-em !important;
}

.neg-mb-em {
  margin-bottom: -$margin-em !important;
}

.neg-m-sm {
  margin: -$margin-sm !important;
}

.neg-ml-sm {
  margin-left: -$margin-sm !important;
}

.neg-mr-sm {
  margin-right: -$margin-sm !important;
}

.neg-mt-sm {
  margin-top: -$margin-sm !important;
}

.neg-mb-sm {
  margin-bottom: -$margin-sm !important;
}

.neg-m-lg {
  margin: -$margin-lg !important;
}

.neg-ml-lg {
  margin-left: -$margin-lg !important;
}

.neg-mr-lg {
  margin-right: -$margin-lg !important;
}

.neg-mt-lg {
  margin-top: -$margin-lg !important;
}

.neg-mb-lg {
  margin-bottom: -$margin-lg !important;
}

.neg-m-xl {
  margin: -$margin-xl !important;
}

.neg-ml-xl {
  margin-left: -$margin-xl !important;
}

.neg-mr-xl {
  margin-right: -$margin-xl !important;
}

.neg-mt-xl {
  margin-top: -$margin-xl !important;
}

.neg-mb-xl {
  margin-bottom: -$margin-xl !important;
}

// Margin vertical / horizontal
.mv {
  @extend .mt;
  @extend .mb;
}

.mh {
  @extend .ml;
  @extend .mr;
}

.mv-lg {
  @extend .mt-lg;
  @extend .mb-lg;
}

.mh-lg {
  @extend .ml-lg;
  @extend .mr-lg;
}

.mv-sm {
  @extend .mt-sm;
  @extend .mb-sm;
}

.mh-sm {
  @extend .ml-sm;
  @extend .mr-sm;
}

// Padding - responsive views
@media only screen and (min-width: $mq-tablet) {
  .mt-tablet {
    margin-top: $margin-base;
  }
}

// Paddings
// --------------------

.p0 {
  padding: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pv0 {
  @extend .pt0;
  @extend .pb0;
}

.ph0 {
  @extend .pl0;
  @extend .pr0;
}

.p {
  padding: $padding-base !important;
}

.pl {
  padding-left: $padding-base !important;
}

.pr {
  padding-right: $padding-base !important;
}

.pt {
  padding-top: $padding-base !important;
}

.pb {
  padding-bottom: $padding-base !important;
}

.p-sm {
  padding: $padding-sm !important;
}

.pl-sm {
  padding-left: $padding-sm !important;
}

.pr-sm {
  padding-right: $padding-sm !important;
}

.pt-sm {
  padding-top: $padding-sm !important;
}

.pb-sm {
  padding-bottom: $padding-sm !important;
}

.p-lg {
  padding: $padding-lg !important;
}

.pl-lg {
  padding-left: $padding-lg !important;
}

.pr-lg {
  padding-right: $padding-lg !important;
}

.pt-lg {
  padding-top: $padding-lg !important;
}

.pb-lg {
  padding-bottom: $padding-lg !important;
}

.p-xl {
  padding: $padding-xl !important;
}

.pl-xl {
  padding-left: $padding-xl !important;
}

.pr-xl {
  padding-right: $padding-xl !important;
}

.pt-xl {
  padding-top: $padding-xl !important;
}

.pb-xl {
  padding-bottom: $padding-xl !important;
}
.pb-xxl {
  padding-bottom: $margin-lg-add-5 !important;
}

// Padding vertical / horizontal
.pv {
  @extend .pt;
  @extend .pb;
}

.ph {
  @extend .pl;
  @extend .pr;
}

.pv-xl {
  @extend .pt-xl;
  @extend .pb-xl;
}

.ph-xl {
  @extend .pl-xl;
  @extend .pr-xl;
}

.pv-lg {
  @extend .pt-lg;
  @extend .pb-lg;
}

.ph-lg {
  @extend .pl-lg;
  @extend .pr-lg;
}

.pv-sm {
  @extend .pt-sm;
  @extend .pb-sm;
}

.ph-sm {
  @extend .pl-sm;
  @extend .pr-sm;
}

// Padding - responsive views
@media only screen and (max-width: $mq-desktop) {
  .no-p-h-tablet {
    padding-left: 0;
    padding-right: 0;
  }
}

// Border
// --------------------

.b0 {
  border-width: 0 !important;
}

.bl0 {
  border-left-width: 0 !important;
}

.br0 {
  border-right-width: 0 !important;
}

.bt0 {
  border-top-width: 0 !important;
}

.bb0 {
  border-bottom-width: 0 !important;
}

.br {
  border-right: 1px solid $border-color !important;
}

.bl {
  border-left: 1px solid $border-color !important;
}

.bt {
  border-top: 1px solid $border-color !important;
}

.bb {
  border-bottom: 1px solid $border-color !important;
}

.b,
.ba {
  @extend .br;
  @extend .bl;
  @extend .bt;
  @extend .bb;
}

// all borders

// Border Radius Clear

.radius-clear {
  border-radius: 0 !important;
}

.b-rad {
  border-radius: 4px;
}

// Box Shadow Clear

.shadow-clear {
  box-shadow: 0 0 0 #000 !important;
}

// Text helpers

.text-white {
  color: $dmp-white-inverse;
}

.text-inverse {
  color: $brand-inverse;
}

.text-dmp-blue {
  color: $state-primary;
}

.text-alpha {
  color: $text-alpha;
}

.text-pink {
  color: $brand-pink;
}

.text-purple {
  color: $brand-purple;
}

.text-dark {
  color: $brand-dark;
}

.text-alpha-inverse {
  color: $text-alpha-inverse;
}

.text-green {
  color: $brand-green;
}

.text-yellow {
  color: $brand-yellow;
}

.text-gray-darker {
  color: $gray-darker;
}

.text-gray-dark {
  color: $gray-dark;
}

.text-gray {
  color: $gray;
}

.text-gray-light {
  color: $gray-light;
}

.text-gray-lighter {
  color: $gray-lighter;
}

.text-normal {
  color: $dmp-text-standard !important;
}

.text-dmp-green {
  color: $brand-green-dmp;
}

.text-dmp-danger {
  color: $brand-danger;
}

// Text sizing

.text-sm {
  font-size: $text-sm;
}

.text-md {
  font-size: $text-md;
}

.text-lg {
  font-size: $text-lg;
}

// Text others

.text-nowrap {
  white-space: nowrap;
}

.text-thin {
  font-weight: 100 !important;
}

.text-normal {
  font-weight: normal !important;
}

.text-bold {
  font-weight: bold !important;
}

.inline {
  display: inline-block !important;
}

.block-center {
  margin: 0 auto;
}

// Background helpers
//  Generates classes for
//    .brand
//    .brand-light
//    .brand-dark

.bg-primary {
  @include bgcolor-variant("primary");
}

.bg-success {
  @include bgcolor-variant("success");
}
.bg-success-modal {
  background-color: var(--color-success-400) !important;
}
.bg-danger-modal {
  background-color: var(--color-danger-500) !important;
}

.bg-info-modal {
  background-color: var(--color-info-300);
}

.bg-info {
  @include bgcolor-variant("info");
}

.bg-warning {
  @include bgcolor-variant("warning");
}

.bg-danger {
  @include bgcolor-variant("danger");
}

.bg-green {
  @include bgcolor-variant("green");
}

.bg-pink {
  @include bgcolor-variant("pink");
}

.bg-purple {
  @include bgcolor-variant("purple");
}

.bg-inverse {
  @include bgcolor-variant("inverse");
}

.bg-yellow {
  @include bgcolor-variant("yellow");
}

.bg-white {
  @include make-bg($dmp-white-inverse, inherit);
}

.bg-gray-darker {
  @include make-bg($gray-darker);
}

.bg-gray-dark {
  @include make-bg($gray-dark);
}

.bg-gray {
  @include make-bg($gray, $text-color);
}

.bg-gray-light {
  @include make-bg($gray-light, $text-color);
}

.bg-gray-lighter {
  @include make-bg($gray-lighter, $text-color);
}

.bg-gray-sidebar {
  @include make-bg($gray-sidebar, $text-color);
}

.bg-cover {
  background-size: cover;
}

// Common thumbnail sizes (ideal for images)

.thumb8 {
  @include thumnbail-size();
}

.thumb16 {
  @include thumnbail-size(16px);
}

.thumb24 {
  @include thumnbail-size(24px);
}

.thumb32 {
  @include thumnbail-size(32px);
}

.thumb48 {
  @include thumnbail-size(48px);
}

.thumb64 {
  @include thumnbail-size(64px);
}

.thumb80 {
  @include thumnbail-size(80px);
}

.thumb96 {
  @include thumnbail-size(96px);
}

.thumb128 {
  @include thumnbail-size(128px);
}

.thumb-16x8-responsive {
  @include thumbnail-responsive(128px);
}
// Vertical align helpers

.align-middle {
  vertical-align: middle;
}

.align-top {
  vertical-align: top;
}

.align-bottom {
  vertical-align: bottom;
}

// Background image
.bg-center {
  background-position: center center;
  background-size: cover;
}

// List
.list-icon {
  em {
    font-size: 14px;
    width: 40px;
    vertical-align: middle;
    margin: 0;
    display: inline-block;
    text-align: center;
    @include transition(all 0.2s);
    line-height: 30px;
  }
  div:hover em {
    @include scale(3, 3);
  }
}

// Components size

.wd-xxs {
  width: $wd-xxs;
}

.wd-xs {
  width: $wd-xs;
}

.wd-sm {
  width: $wd-sm;
}

.wd-sd {
  width: $wd-sd;
}

.wd-md {
  width: $wd-md;
}

.wd-lg {
  width: $wd-lg;
}

.wd-xl {
  width: $wd-xl;
}

.wd-xxl {
  width: $wd-xxl;
}

.wd-wide {
  width: $wd-wide;
}

.wd-auto {
  width: $wd-auto;
}

.wd-zero {
  width: $wd-zero;
}

.wd-3xl {
  width: $wd-3xl;
}

// Extra labels

.label-inverse {
  @include label-variant($brand-inverse);
}

.label-green {
  @include label-variant($brand-green);
}

.label-pink {
  @include label-variant($brand-pink);
}

.label-purple {
  @include label-variant($brand-purple);
}

// Extra alert

.alert-purple {
  @include alert-variant($brand-purple, $brand-purple, $dmp-white-inverse);
}

.alert-green {
  @include alert-variant($brand-green, $brand-green, $dmp-white-inverse);
}

.alert-pink {
  @include alert-variant($brand-pink, $brand-pink, $dmp-white-inverse);
}

.alert-inverse {
  @include alert-variant($brand-inverse, $brand-inverse, $dmp-white-inverse);
}

// Simulate clickable element
.clickable {
  cursor: pointer;
}

// Absolute center element
// -----------------------------------

.abs-center-container {
  position: relative;
}

.abs-center {
  // width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.abs-fixed {
    position: fixed;
    z-index: 999;
  }

  &.abs-right {
    left: auto;
    right: 20px;
    text-align: right;
  }

  &.abs-left {
    right: auto;
    left: 20px;
    text-align: left;
  }
}

@media (max-height: 720px) {
  .abs-center {
    position: relative;
  }
}

.link-unstyled {
  text-decoration: none !important;
  outline: none !important;
}

.no-resize {
  resize: none;
  max-width: 100%;
  min-width: 100%;
}

/** 
 * Displays hidden content on hover. Add this class to the parent element.
 * Markup
 *   <div class="hover-visible">
 *    <div class="hover-hidden"></div>
 *   </div>
 */

.hover-visible {
  .hover-hidden {
    display: none;
  }

  &:hover .hover-hidden {
    display: block;
  }
  &:hover .hover-inline {
    display: inline-block;
  }
}

//
// MEDIA BOX CLASSIC
//
// since BS 3.3.x it doesn't support fullwidth form-controls so we use
// a copy to make possible the use of the classic component
// -----------------------------------

// Media objects
// Source: http://stubbornella.org/content/?p=497
// --------------------------------------------------

// Common styles
// -------------------------

// Clear the floats
.media-box,
.media-box-body {
  overflow: hidden;
  zoom: 1;
  font-size: 1em;
}

// Proper spacing between instances of .media-box
.media-box,
.media-box .media-box {
  margin-top: 15px;
}

.media-box:first-child {
  margin-top: 0;
}

// For images and videos, set to block
.media-box-object {
  display: block;
}

// Reset margins on headings for tighter default spacing
.media-box-heading {
  margin: 0 0 5px;
}

// Media image alignment
// -------------------------

.media-box {
  > .pull-left {
    margin-right: 10px;
  }
  > .pull-right {
    margin-left: 10px;
  }
}

// Media list variation
// -------------------------

// Undo default ul/ol styles
.media-box-list {
  padding-left: 0;
  list-style: none;
}

@media only screen and (max-width: $mq-tablet) {
  // Margins
  // --------------------mobile

  .m0-mobile {
    margin: 0 !important;
  }
  .ml0-mobile {
    margin-left: 0 !important;
  }
  .mr0-mobile {
    margin-right: 0 !important;
  }
  .mt0-mobile {
    margin-top: 0 !important;
  }
  .mb0-mobile {
    margin-bottom: 0 !important;
  }

  .m-mobile {
    margin: $margin-base !important;
  }
  .ml-mobile {
    margin-left: $margin-base !important;
  }
  .mr-mobile {
    margin-right: $margin-base !important;
  }
  .mt-mobile {
    margin-top: $margin-base !important;
  }
  .mb-mobile {
    margin-bottom: $margin-base !important;
  }

  .m-sm-mobile {
    margin: $margin-sm !important;
  }
  .ml-sm-mobile {
    margin-left: $margin-sm !important;
  }
  .mr-sm-mobile {
    margin-right: $margin-sm !important;
  }
  .mt-sm-mobile {
    margin-top: $margin-sm !important;
  }
  .mb-sm-mobile {
    margin-bottom: $margin-sm !important;
  }

  .m-lg-mobile {
    margin: $margin-lg !important;
  }
  .ml-lg-mobile {
    margin-left: $margin-lg !important;
  }
  .mr-lg-mobile {
    margin-right: $margin-lg !important;
  }
  .mt-lg-mobile {
    margin-top: $margin-lg !important;
  }
  .mb-lg-mobile {
    margin-bottom: $margin-lg !important;
  }

  .m-xl-mobile {
    margin: $margin-xl !important;
  }
  .ml-xl-mobile {
    margin-left: $margin-xl !important;
  }
  .mr-xl-mobile {
    margin-right: $margin-xl !important;
  }
  .mt-xl-mobile {
    margin-top: $margin-xl !important;
  }
  .mb-xl-mobile {
    margin-bottom: $margin-xl !important;
  }

  // Paddings
  // ---------------------mobile

  .p0-mobile {
    padding: 0 !important;
  }
  .pl0-mobile {
    padding-left: 0 !important;
  }
  .pr0-mobile {
    padding-right: 0 !important;
  }
  .pt0-mobile {
    padding-top: 0 !important;
  }
  .pb0-mobile {
    padding-bottom: 0 !important;
  }

  .p-mobile {
    padding: $padding-base !important;
  }
  .pl-mobile {
    padding-left: $padding-base !important;
  }
  .pr-mobile {
    padding-right: $padding-base !important;
  }
  .pt-mobile {
    padding-top: $padding-base !important;
  }
  .pb-mobile {
    padding-bottom: $padding-base !important;
  }

  .p-sm-mobile {
    padding: $padding-sm !important;
  }
  .pl-sm-mobile {
    padding-left: $padding-sm !important;
  }
  .pr-sm-mobile {
    padding-right: $padding-sm !important;
  }
  .pt-sm-mobile {
    padding-top: $padding-sm !important;
  }
  .pb-sm-mobile {
    padding-bottom: $padding-sm !important;
  }

  .p-lg-mobile {
    padding: $padding-lg !important;
  }
  .pl-lg-mobile {
    padding-left: $padding-lg !important;
  }
  .pr-lg-mobile {
    padding-right: $padding-lg !important;
  }
  .pt-lg-mobile {
    padding-top: $padding-lg !important;
  }
  .pb-lg-mobile {
    padding-bottom: $padding-lg !important;
  }

  .p-xl-mobile {
    padding: $padding-xl !important;
  }
  .pl-xl-mobile {
    padding-left: $padding-xl !important;
  }
  .pr-xl-mobile {
    padding-right: $padding-xl !important;
  }
  .pt-xl-mobile {
    padding-top: $padding-xl !important;
  }
  .pb-xl-mobile {
    padding-bottom: $padding-xl !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  // Margins
  // --------------------tablet

  .m0-tablet {
    margin: 0 !important;
  }
  .ml0-tablet {
    margin-left: 0 !important;
  }
  .mr0-tablet {
    margin-right: 0 !important;
  }
  .mt0-tablet {
    margin-top: 0 !important;
  }
  .mb0-tablet {
    margin-bottom: 0 !important;
  }

  .m-tablet {
    margin: $margin-base !important;
  }
  .ml-tablet {
    margin-left: $margin-base !important;
  }
  .mr-tablet {
    margin-right: $margin-base !important;
  }
  .mt-tablet {
    margin-top: $margin-base !important;
  }
  .mb-tablet {
    margin-bottom: $margin-base !important;
  }

  .m-sm-tablet {
    margin: $margin-sm !important;
  }
  .ml-sm-tablet {
    margin-left: $margin-sm !important;
  }
  .mr-sm-tablet {
    margin-right: $margin-sm !important;
  }
  .mt-sm-tablet {
    margin-top: $margin-sm !important;
  }
  .mb-sm-tablet {
    margin-bottom: $margin-sm !important;
  }

  .m-lg-tablet {
    margin: $margin-lg !important;
  }
  .ml-lg-tablet {
    margin-left: $margin-lg !important;
  }
  .mr-lg-tablet {
    margin-right: $margin-lg !important;
  }
  .mt-lg-tablet {
    margin-top: $margin-lg !important;
  }
  .mb-lg-tablet {
    margin-bottom: $margin-lg !important;
  }

  .m-xl-tablet {
    margin: $margin-xl !important;
  }
  .ml-xl-tablet {
    margin-left: $margin-xl !important;
  }
  .mr-xl-tablet {
    margin-right: $margin-xl !important;
  }
  .mt-xl-tablet {
    margin-top: $margin-xl !important;
  }
  .mb-xl-tablet {
    margin-bottom: $margin-xl !important;
  }

  // Paddings
  // ---------------------tablet

  .p0-tablet {
    padding: 0 !important;
  }
  .pl0-tablet {
    padding-left: 0 !important;
  }
  .pr0-tablet {
    padding-right: 0 !important;
  }
  .pt0-tablet {
    padding-top: 0 !important;
  }
  .pb0-tablet {
    padding-bottom: 0 !important;
  }

  .p-tablet {
    padding: $padding-base !important;
  }
  .pl-tablet {
    padding-left: $padding-base !important;
  }
  .pr-tablet {
    padding-right: $padding-base !important;
  }
  .pt-tablet {
    padding-top: $padding-base !important;
  }
  .pb-tablet {
    padding-bottom: $padding-base !important;
  }

  .p-sm-tablet {
    padding: $padding-sm !important;
  }
  .pl-sm-tablet {
    padding-left: $padding-sm !important;
  }
  .pr-sm-tablet {
    padding-right: $padding-sm !important;
  }
  .pt-sm-tablet {
    padding-top: $padding-sm !important;
  }
  .pb-sm-tablet {
    padding-bottom: $padding-sm !important;
  }

  .p-lg-tablet {
    padding: $padding-lg !important;
  }
  .pl-lg-tablet {
    padding-left: $padding-lg !important;
  }
  .pr-lg-tablet {
    padding-right: $padding-lg !important;
  }
  .pt-lg-tablet {
    padding-top: $padding-lg !important;
  }
  .pb-lg-tablet {
    padding-bottom: $padding-lg !important;
  }

  .p-xl-tablet {
    padding: $padding-xl !important;
  }
  .pl-xl-tablet {
    padding-left: $padding-xl !important;
  }
  .pr-xl-tablet {
    padding-right: $padding-xl !important;
  }
  .pt-xl-tablet {
    padding-top: $padding-xl !important;
  }
  .pb-xl-tablet {
    padding-bottom: $padding-xl !important;
  }
}

@media only screen and (min-width: 1199px) {
  .p-sm-desktop {
    padding: $padding-sm !important;
  }
  .pl-sm-desktop {
    padding-left: $padding-sm !important;
  }
}

.content {
  width: 300%;
  height: 100%;
}

.no-wrap {
  white-space: nowrap !important;
}

.table > thead > tr > th.vat {
  vertical-align: top !important;
}

.lh-sm {
  line-height: 1em !important;
}

/*
 * Row with equal height columns
 * --------------------------------------------------
 */
.row.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
