//alert animation -- ng-show

.alert-animation {
  position: absolute;
  &:after {
    content: "";
    width: 40px;
    height: 60px;
  }
}

.alert-animation-ngshow-pulse.ng-hide-add {
  transform: rotateZ(0);
  transform-origin: right;
  transition: all 0.5s ease-in-out;
}

.alert-animation-ngshow-pulse.ng-hide-add.ng-hide-add-active {
  transform: rotateZ(-135deg);
}

.alert-animation-ngshow-pulse.ng-hide-remove {
  transform: rotateY(90deg);
  transform-origin: left;
  transition: all 0.5s ease;
}

.alert-animation-ngshow-pulse.ng-hide-remove.ng-hide-remove-active {
  transform: rotateY(0);
}
