@mixin base-news-grid {
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content min-content;
  grid-column-gap: 1.8rem;
  grid-template-areas: "pic" "title" "body";
  background: white;
  border: 1px solid $global-border;
  border-radius: 0.8rem;
  padding: var(--measure-2x);
  margin-bottom: var(--measure-2x);
}

.news {
  &__list-item {
    &__mail-chimp {
      @include base-news-grid();
      @media (min-width: $screen-sm) {
        grid-template-columns: minmax(12rem, 32rem) 1fr;
        grid-template-rows: min-content min-content;
        grid-template-areas: "pic title" "pic body";
      }
      &__news-admin {
        @include base-news-grid();
        @media (min-width: $screen-lg) {
          //grid-template-rows: minmax(8rem, min-content) min-content min-content;
        }
        &__pic {
          grid-area: pic;
          align-self: stretch;
          justify-self: center;

          width: 100%;

          > img {
            object-fit: cover;
            width: 100%;
            max-height: 100%;
            transition: all 0.2s;
            cursor: pointer;
            border: 1px solid $global-border;
            @media (min-width: $screen-lg) {
              width: 80%;
              max-height: auto;
            }
            &:hover {
              transform: scale(1.01);
              border: 1px solid $global-border;
            }
          }
        }
      }

      &__closer {
        position: absolute;
        top: var(--measure-2x);
        right: var(--measure-1x);
        width: var(--measure-3x);
        height: var(--measure-3x);
        z-index: 4;
        /*center icon in the closer*/
        > a {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $text-color;
          text-decoration: none;
          @media (min-width: $screen-sm) {
            color: $bleached-white;
          }

          &:hover,
          &:active {
            text-decoration: none;
          }
        }
      }

      &__pic {
        grid-area: pic;
        align-self: center;
        justify-self: center;

        width: 100%;

        > img {
          object-fit: cover;
          width: 100%;
          max-height: 100%;
          transition: all 0.2s;
          cursor: pointer;
          border: 1px solid $global-border;

          &:hover {
            transform: scale(1.03);
            border: 1px solid $global-border;
          }
        }
      }

      &__title {
        grid-area: title;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: top;
        font-size: var(--measure-font-16);
        padding: 0;
        line-height: 1.2;

        &__extras {
          display: flex;
          flex-flow: row nowrap;
          justify-items: flex-end;
          align-items: flex-start;
          > button {
            height: 3rem;
            margin-left: var(--measure-1x);
          }
        }
        @media (min-width: $screen-sm) {
          font-size: var(--measure-font-20);
        }
      }

      &__body {
        grid-area: body;
        position: relative;
        font-size: var(--measure-font-14);
        line-height: 1.714285714;
        max-height: 6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        content: "";
        @media (min-width: $screen-lg) {
          max-height: 8rem;
        }
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: rgb(255, 255, 255);
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 80%,
            rgba(255, 255, 255, 1) 100%
          );
        }
      }
    }
  }
  &__sticky-editor {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 9.6rem;
  }
}

.display-date {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  height: 3rem;
  background: white;
  border: 1px solid $color-primary;
  border-radius: 0.4rem;
  //box-shadow: 0 0 1.4rem rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
  margin-left: var(--measure-2x);
  &__max-width {
    max-width: 6.8rem;
  }

  &__day {
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    min-height: 3rem;
    padding: 0 0.8rem;
  }

  &__month {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-primary;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    border-radius: 0 0.4rem 0.4rem 0;
    padding: 0 0.8rem;
    height: 3rem;
  }
}
