$system-slider-width: 28rem;
$tech-map-slider-width: 32rem;

@mixin sliding-menu-base {
  @include backface-visibility(hidden);
  height: 100%;
  padding: 0.8rem 0.8rem 0.8rem 0;
  background-color: $sidebar-bg;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  -webkit-box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in;
}

.sliding-menu {
  @include sliding-menu-base;
  width: $system-slider-width;
  margin-left: -$system-slider-width; //hides the menu

  &--tech-map {
    width: $tech-map-slider-width - (rem(40px));
    margin-left: -$tech-map-slider-width + (rem(40px)); // - (rem(40px)); //hides the menu
    @media (min-width: $screen-sm) {
      width: $tech-map-slider-width;
      margin-left: -$tech-map-slider-width; //hides the menu
    }
  }
  &__control-tab {
    width: 4rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    vertical-align: middle;
    padding: 1.2rem 0.3rem 1.2rem 0;
    position: absolute;
    background-color: $sidebar-bg;
    top: 44%;
    right: -4rem;
    cursor: pointer;
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    font-size: 1.5rem;
    -webkit-box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.2);
    &:before {
      content: "";
      position: absolute;
      left: -0.2rem;
      top: 0;
      width: 0.5rem;
      height: 100%;
      max-height: 0.8rem;
      background-color: white;
      z-index: 3;
    }

    &--text {
      position: relative;
      top: 0;
      right: 0;
      transform-origin: center;
      //transform: translate(-50%, -50%);
      font-size: 1rem;
      text-transform: uppercase;
      writing-mode: vertical-rl;
    }
  }
  &__title {
    font-size: 1.4rem;
    font-weight: 400;
    padding: $padding-base 0 0 0.8rem;
  }

  &.toggled {
    margin-left: 0;
    transition: all 0.25s ease-out;
  }
  &__control-icon {
    display: inline-flex;
    position: relative;
    top: 0;
    transform-origin: center;
    // padding: 0 var(--measure-1x);
    //transform: translateY(50%);
    //right: - .6rem;

    &.rotated:after {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  &__nav {
  }
  &__nav-item {
    list-style: none;
  }
}

.checkbox {
  &__description {
    font-style: normal;
    font-size: 1rem;
    font-weight: 500;
    &-detail {
      display: inline-block;
      font-size: 0.8em;
      font-style: italic;
      font-weight: 400;
      padding-left: 0.8rem;
      position: relative;
      top: -0.8rem;
    }
  }
}
