.sticky-footer {
  display: none;
  @media (min-width: $screen-lg) {
    display: block;
    animation: to-end-me 0.4s 0.2s cubic-bezier(0.075, 0.82, 0.165, 1.02)
      forwards;
    opacity: 0;
    background-color: var(--color-primary-300);
    border-color: var(--color-primary-300);
    //border-top: 1px solid;
    bottom: -0.1rem;
    right: 0;
    left: -0.1rem;
    color: var(--color-primary-700);
    height: auto;

    padding: var(--measure-2x);
    position: fixed;
    transform-origin: bottom;
    transform: translateY(5.2rem) scaleY(0.9);
    width: 100%;
    z-index: 110;
  }

  @media (min-width: $screen-lg) {
    width: calc(100% - 23.5rem);
  }

  &__grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: min-content 1fr max-content min-content;
    grid-template-areas: "icon items total actions";
    grid-gap: var(--measure-2x);
    align-items: center;

    &__icon {
      grid-area: icon;
      font-size: var(--measure-font-18);
    }

    &__included-items {
      grid-area: items;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      &__feature {
        font-weight: var(--font-weight-semibold);
        line-height: 1.8;
      }
    }

    &__total {
      grid-area: total;
      font-size: var(--measure-font-18);
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      font-weight: var(--font-weight-semibold);

      &__cost {
        font-weight: var(--font-weight-semibold);
        font-size: var(--measure-font-18);
      }
    }

    &__action-area {
      grid-area: actions;
    }
  }
}

@keyframes to-end-me {
  100% {
    opacity: 1;
    transform: none;
  }
}
//added to the pricing grid to provide adequate space
.mar-b-responsive--new-control-system {
  margin-bottom: 13.2rem;
}
