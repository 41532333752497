.status-text {
  &__green {
    color: green;
  }
  &__red {
    color: red;
  }
  &__yellow {
    color: #d6c410;
  }
}

.status-row {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__row-child {
    max-width: 20rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.phone-row {
  display: flex;
  flex-direction: row;

  &__pre-activate {
    padding-left: 1.5rem;
    margin-top: 0.7rem;
  }
}

.carrier-container {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  padding: 0 0.8rem;
}
