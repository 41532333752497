/* ========================================================================
   Component: ngdialog
  ========================================================================== */

.ngdialog {
  &.ngdialog-theme-default {
    .ngdialog-content {
      background-color: $dmp-white-inverse !important;
      border-radius: $border-radius-base !important;
      font-family: inherit !important;
      font-size: inherit !important;
      line-height: inherit !important;
    }
  }
}
