.video-display-grid {
  width: 100%;

  display: grid;
  grid-row-gap: rem(32px);
  grid-column-gap: rem(16px);
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(min-content, 100%));
  padding-bottom: var(--measure-3x);
  //padding:               var(--measure-1x);

  /* @media screen and (min-width: $screen-lg) {
        grid-template-columns: repeat(auto-fill, minmax(max-content, 48%));
        grid-template-rows: max-content;
        padding:               var(--measure-1x);
        }
*/
}
