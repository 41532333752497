@mixin dropdown-select-link-styles {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0;
  font-size: 14px;
  line-height: 1.52857;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $primary-color;
}

@mixin dropdown-select-hover-animation {
  -webkit-animation-name: hvr-icon-hang-sink, hvr-icon-hang;
  animation-name: hvr-icon-hang-sink, hvr-icon-hang;
  -webkit-animation-duration: 0.3s, 1.5s;
  animation-duration: 0.3s, 1.5s;
  -webkit-animation-delay: 0s, 0.3s;
  animation-delay: 0s, 0.3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

@mixin dropdown-select-delete-hover {
  background-color: $dmp-red !important;
  color: white !important;

  i,
  span {
    color: white !important;
  }
}

.dropdown-select {
  &--tech-track {
    @include dropdown-select-link-styles;
  }

  &__link {
    @include dropdown-select-link-styles;

    &--chart-control {
      @include dropdown-select-link-styles;
      font-size: 16px;
      padding: var(--measure-2x);
    }

    &:link {
    }

    &:hover {
      color: red;

      & .dropdown-select__control,
      &.dropdown-select__control--svg {
        @include dropdown-select-hover-animation;
      }
    }

    &--delete {
      &:hover {
        @include dropdown-select-delete-hover;
      }
    }
  }

  &__control,
  &__control--svg {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    padding-right: 2.2em;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;

    &:before {
      position: absolute;
      left: 1rem;
      top: -0.6rem;
      padding: 0 0.1rem;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      font-size: 1.2rem;
    }
  }

  &__container {
  }

  &__item {
    padding-left: 1.5rem;

    label {
      font-weight: normal;
      font-size: 1.2rem;
      padding: 0 var(--measure-2x);
    }

    & li {
      & a {
        color: red;
      }
    }

    &:not(:last-child) {
      padding-bottom: 0.4rem;
    }
  }

  &__checkbox {
    margin-left: 1rem;
  }
}

li.dropdown-select__item {
  padding-left: 0;
}

@mixin dropdown-select-link-base {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0;
  font-size: 14px;
  line-height: 1.52857;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: black;

  &:hover {
    color: $accent-blue-link-hover;

    & .dropdown-select__control,
    & .dropdown-select__control--svg {
      @include dropdown-select-hover-animation;
    }
  }
}

@mixin dropdown-arrow-base($top-offset: -0.6rem, $left-offset: 1rem) {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  padding-right: 2.2em;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;

  &:before {
    position: absolute;
    left: $left-offset;
    top: $top-offset;
    padding: 0 0.1rem;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    font-size: 1.2rem;
  }
}

.dropdown-select {
  &__link {
    @include dropdown-select-link-base;

    &--jump-menu {
      @include dropdown-select-link-base;
      padding: var(--measure-half) var(--measure-1x);
      border-radius: 0.4rem;
      border: 1px solid $primary-color;
    }

    &--flex {
      display: flex;
      align-items: center;
    }
  }

  &__control,
  &__control--svg {
    @include dropdown-arrow-base();

    &--jump-menu {
      @include dropdown-arrow-base(-0.6rem, 3rem);
    }
  }

  &__control--svg {
    width: 4.5rem;
    height: 2rem;
    margin-left: 0.8rem;
  }

  &__container {
  }

  &__item {
    padding-left: 1.5rem;

    label {
      font-weight: normal;
      font-size: 1.2rem;
      padding: 0 var(--measure-2x);
    }

    & li {
      & a {
        color: $dmp-red;
      }
    }

    &:not(:last-child) {
      padding-bottom: 0.4rem;
    }
  }

  &__checkbox {
    margin-left: 1rem;
  }
}

li.dropdown-select__item {
  padding-left: 0;
}
