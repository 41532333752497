.flex-list {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;

  &__nested-list {
  }

  &__item {
    width: 100%;
    display: grid;
    //padding: var(--measure-1x);
    grid-template-columns: 50% 50%;
    grid-auto-rows: max-content;
    grid-column-gap: var(--measure-2x);
    grid-row-gap: var(--measure-2x);
    margin-bottom: var(--measure-1x);
    /*&:nth-of-type(odd) {
            background-color: #f5f7f9 ;
        }
*/

    &--header {
      width: 100%;
      display: grid;
      // padding: var(--measure-1x);
      grid-template-columns: 50% 50%;
      grid-auto-rows: max-content;
      grid-column-gap: var(--measure-2x);
      //grid-row-gap: var(--measure-2x);
      margin-bottom: var(--measure-1x);
      border-bottom: 1px solid $global-border;

      background-color: white !important;
    }

    &__header-text {
      font-size: var(--measure-font-14);
      font-weight: var(--font-weight-thin);
    }

    &__body-text {
      font-size: var(--measure-font-14);
      font-weight: var(--font-weight-regular);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
