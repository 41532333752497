/*
 * Define the widths: play around with these to get a best fit.
 */
$text-width: 180px;
$num-width: 80px;

// Main container: initialize the flex, direction is row
.table-row {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

// Text based cells: grow, truncate and show ellipsis when there is not enough space
.text {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 20px;
}

// Fix the minimum width of the leaf level cells
.text {
  width: $text-width;
}

.num {
  width: $num-width;
}

/*
 * General good-look styles, not mandatory.
 */

.table-row {
  border-bottom: 2px solid #e0e0e0;
  border-collapse: collapse;
  padding-top: 5px;
}

.table-row.header {
  font-weight: 400;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
