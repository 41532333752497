.mobile-wallet-panel {
  max-width: 100%;
  background-color: white;
  border-radius: 0.4rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content max-content 1fr;
  grid-template-areas: "title title" "detail detail" "form form";
  @media (min-width: 768px) {
    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 6.6rem max-content;
    grid-template-areas:
      "title form"
      "detail form";
    margin-bottom: 3.3rem;
    gap: 0.8rem;
  }

  &__title {
    grid-area: title;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--measure-font-16);
    font-weight: var(--font-weight-semibold);
    margin-bottom: 1.6rem;
    @media (min-width: 768px) {
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__detail {
    grid-area: detail;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: var(--measure-font-14);
    margin-bottom: 1rem;
  }

  &__form {
    grid-area: form;
    justify-items: start;
    grid-row: span 2;
  }
}
