$image-dimension-full: rem(52px);
$image-dimension-compact: rem(32px);

@mixin il-blk-vert-center {
  display: inline-block;
  vertical-align: middle;
}

@mixin my-menu-transition {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.user-block {
  border-bottom: 0.1rem solid rgba(204, 204, 204, 0.2);
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  padding: var(--measure-1x);
  margin: 0 auto;
  cursor: pointer;

  &:link {
    text-decoration: none;
  }
  &:hover {
    img {
      transform: scale(1);
      -webkit-box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.75);

      @include my-menu-transition();
    }
    .user-block__usr-info--id {
      color: $link-hover-color;

      @include my-menu-transition();
    }
  }

  &__usr {
    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      padding-left: rem(8px);
      &--id {
        font-size: 1.2rem;
      }

      &--role {
        font-size: 1rem;
        color: $text-color;
      }
      &--name {
        font-size: 1.5rem;
        font-weight: 700;
        color: $dmp-blue;
      }
    }

    &-img {
      width: $image-dimension-compact;
      height: auto;
      position: relative;
      margin-left: 0;
      margin-right: 0;
      flex: 0 1 auto;
      white-space: nowrap;

      > img {
        position: relative;
        display: block;
        background-position: center;
        background-size: $image-dimension-compact $image-dimension-compact;
        width: $image-dimension-compact;
        height: $image-dimension-compact;
        margin: rem(-1px);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        vertical-align: middle;
        white-space: nowrap;
        overflow: hidden;
        -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 3px #999,
          0 2px 5px 4px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 0 2px #fff, 0 0 0 3px #999,
          0 2px 5px 4px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 0 2px #fff, 0 0 0 3px #999,
          0 2px 5px 4px rgba(0, 0, 0, 0.2);

        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      }
    }
    &-hover {
      &:hover {
        img {
          transform: scale(1);
          -webkit-box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.75);
          -moz-box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.75);

          @include my-menu-transition();
        }
        .user-block__usr-info--id {
          color: $link-hover-color;

          @include my-menu-transition();
        }
      }
    }
  }
  &__carrot {
    font-size: 1rem;
    display: none;
  }
  &__dropdown-menu {
    & .dropdown {
      width: 28rem !important;
    }
  }
}

.dropdown-menu {
  &.user-block__dropdown-menu {
    width: 28rem !important;
  }
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  background-color: rgba($tab-active-bg-color, 0.5);
  color: $bleached-white;
  @include my-menu-transition();
}

@media screen and (min-width: $screen-lg-min) {
  .user-block {
    border-bottom: none;

    opacity: 0;
    -webkit-transform: translateX(8rem);
    -moz-transform: translateX(8rem);
    -ms-transform: translateX(8rem);
    -o-transform: translateX(8rem);
    transform: translateX(8rem);
    -webkit-animation: to-end-state 0.6s cubic-bezier(0.33, 0.84, 0.49, 1.18)
      forwards;
    -o-animation: to-end-state 0.6s cubic-bezier(0.33, 0.84, 0.49, 1.18)
      forwards;
    animation: to-end-state 0.6s cubic-bezier(0.33, 0.84, 0.49, 1.18) forwards;

    &:link {
      text-decoration: none;
    }
    &:hover {
      img {
        transform: scale(1.01);
        -webkit-box-shadow: 0px 10px 20px -4px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 10px 20px -4px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 10px 20px -4px rgba(0, 0, 0, 0.75);
        @include my-menu-transition();
      }
      .user-block__usr-info--id {
        color: $link-hover-color;

        @include my-menu-transition();
      }
    }

    &__usr {
      &-info {
        &--id {
          font-size: 1.4rem;
        }

        &--role {
          font-size: 1.2rem;
          color: $text-color;
        }
      }

      &-img {
        @include il-blk-vert-center();
        width: $image-dimension-full;
        height: $image-dimension-full;
        position: relative;

        > img {
          display: block;
          background-size: $image-dimension-full $image-dimension-full;
          width: $image-dimension-full;
          height: $image-dimension-full;
          margin: rem(-1px);
          margin-right: rem(10px);
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          transform: scale3d(0.8, 0.8, 0.8);
          -webkit-box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999,
            0 2px 5px 4px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999,
            0 2px 5px 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 0 3px #fff, 0 0 0 4px #999,
            0 2px 5px 4px rgba(0, 0, 0, 0.2);
        }
      }
    }
    &__carrot {
      font-size: 1rem;
    }
    &__dropdown-menu {
      & .dropdown {
        width: 28rem !important;
      }
    }
  }
}

.caret-nav {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  position: absolute;
  right: 20px;
  top: 30px;
  border-top-color: black;
  z-index: 999;
}

.click-out-blocker {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: " ";
  background: transparent;
}
.link-wrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cccccc;
}

.dropdown-menu {
  &.user-block__dropdown-menu {
    width: 100% !important;
  }
  &.user-block__dropdown-menu-fit-content {
    width: fit-content !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 1rem;
  }
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  text-decoration: none;
  background-color: rgba($tab-active-bg-color, 0.5);

  color: $bleached-white;
  @include my-menu-transition();
}

@keyframes to-end-state {
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    opacity: 1;
  }
}
