/**
* An element with .dndPlaceholder class will be
* added to the dnd-list while the user is dragging
* over it.
*/
ul[dnd-list] .dndPlaceholder {
  background-color: $dmp-light-gray;
  display: block;
  border: 3px dashed #003870;
  border-radius: $border-radius-base;
}

/**
 * The dnd-list should always have a ,
 * otherwise you can't drop to it once it's empty
 */
ul[dnd-list] {
  padding-left: $padding-base;
  padding-right: $padding-base;
  list-style: none;
}

/**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */
ul[dnd-list] .dndDraggingSource {
  display: none;
}

.backupPath {
  padding-left: 10px;
}

@media screen and (min-width: 468px) {
  .backupPath {
    padding-left: 50px;
  }
}
li[dnd-list] {
  border: 3px dashed #003870;
}
