label.light {
  //removed bc it looked like disabled text
  color: rgba($text-muted, 0.5);
}

.checkbox-revealer {
  padding: 0.5rem 0;
  transition: all 0.3s ease-out;
}
.panel-title {
  &--with-more-content {
    display: inline-block;
    vertical-align: top;
  }
}

.instructions {
  display: inline-block;
  font-size: $text-sm;
  font-weight: 400;
  padding-left: 1rem;
  vertical-align: top;
  line-height: 100%;
  color: rgba($dmp-text-standard, 0.8);
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}

.checkbox-revealer-animation.ng-hide-add,
.checkbox-revealer-animation.ng-hide-remove {
  transition: all 0.5s ease-out;
}

.checkbox-revealer-animation.ng-hide-add {
}
.checkbox-revealer-animation.ng-hide-add.ng-hide-add-active {
}
.checkbox-revealer-animation.ng-hide-remove {
  opacity: 0;
}
.checkbox-revealer-animation.ng-hide-remove.ng-hide-remove-active {
}

.quick-report {
  &__link {
    display: block;
    transition: all 0.2s;
    text-decoration: none;
    font-size: 1.4rem;
    line-height: 1.8;
    margin-left: var(--measure-4x);

    &:hover {
      text-decoration: none;
      color: darken($primary-color, 20%);
    }
  }
}
