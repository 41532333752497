.small-alert-notice {
  width: 80%;
  padding: 0.5em;
  border-radius: 4px;
  i[class*="fa"] {
    color: $dmp-white-inverse;
    font-size: 1.5em;
  }
}

.info-alert {
  font-size: inherit;
  color: var(--color-neutral-700);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  *:first-child {
    margin-right: var(--measure-1x);
  }
}
