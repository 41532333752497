.cron-select-wrap {
  display: inline-block;
  .cron-select {
    height: 3.4rem;
    padding: 0.6rem 1.2rem;
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.42857143;
    color: $gray-dark;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dde6e9;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  select[multiple] {
    height: 140px;
  }
}

.select-options {
  display: inline-block;
}
