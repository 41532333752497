.flex-feedback-notification {
  display: flex;
  flex-flow: row nowrap;
  justify-items: flex-start;
  align-items: center;
  width: 100%;
  padding: var(--measure-1x) var(--measure-2x);
  border: 0.1rem solid;
  color: white;
  margin-bottom: var(--measure-3x);
  border-radius: 0.4rem;
  font-size: var(--measure-font-16);
  font-weight: var(--font-weight-semibold);
  opacity: 0;
  transform-origin: top;
  transform: scaleY(0);
  animation: 400ms to-end-state cubic-bezier(0.26, 0, 0.71, 1.27) forwards;

  @include box-shadow(4);

  &--info {
    background-color: var(--color-primary-300);
    border-color: var(--color-primary-500);
    color: white;
    text-shadow: 0px 0px 2px var(--color-primary-800);
  }

  &--warning {
    background-color: var(--color-warning-500);
    border-color: var(--color-warning-900);
  }

  &--danger {
    background-color: var(--color-danger-500);
    border-color: var(--color-danger-900);
  }
}
