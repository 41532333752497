/* Sink */
@mixin sink {
  @include hacks();
  @include prefixed(transition-duration, $mediumDuration);
  @include prefixed(transition-property, transform);
  @include prefixed(transition-timing-function, ease-out);

  &:hover,
  &:focus,
  &:active {
    @include prefixed(transform, translateY(8px));
  }
}
