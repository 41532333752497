@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transition-hover() {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

// Alerts

@mixin alert-variant-da($background, $border, $text-color) {
  background-color: $background;
  border-color: $border;
  color: $text-color;

  hr {
    border-top-color: darken($border, 5%);
  }
  .alert-link {
    color: darken($text-color, 10%);
  }
}

@mixin collapse-animation-transition {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@import "rem";

/**
 * A mixin which helps you to add depth to elements according to the Google Material Design spec:
 * http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality
 *
 * Please note that the values given in the specification cannot be used as is. To create the same visual experience
 * the blur parameter has to be doubled.
 *
 * Adapted from a LESS version at https://medium.com/@Florian/freebie-google-material-design-shadow-helper-2a0501295a2d
 *
 * Original Author: Florian Kutschera (@gefangenimnetz), Conceptboard GmbH (@conceptboardapp)
 *
 * Example usage:
 *
 * .card {
 *     width: 95px;
 *     height: 95px;
 *     background: #f4f4f4;
 *     -webkit-transition: all 250ms;
 *     -moz-transition: all 250ms;
 *     transition: all 250ms;
 *     @include box_shadow(1);
 *     &:hover {
 *         @include box_shadow(3);
 *         -webkit-transform: translateY(-5px);
 *         -moz-transform: translateY(-5px);
 *         transform: translateY(-5px);
 *     }
 * }
 *
 */

@mixin box_shadow($level) {
  @if $level == 0 {
    box-shadow: none;
  } @else if $level == 1 {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  } @else if $level == 2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  } @else if $level == 5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
}

@mixin n-columns($min-width, $gutter, $last-equal: false, $max-cols: 5) {
  .items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;
    margin-top: -$gutter;

    .item {
      flex: 1 0 $min-width;
      margin-left: $gutter;
      margin-top: $gutter;

      @if $last-equal {
        @for $i from 2 through $max-cols {
          $screen-width: ($min-width * $i)+ ($gutter * $i);
          $column-width: (100% / $i);
          @media (min-width: $screen-width) {
            max-width: calc(#{$column-width} - #{$gutter});
          }
        }

        $column-width: (100% / $max-cols);
        @media (min-width: $min-width*$max-cols) {
          min-width: calc(#{$column-width} - #{$gutter});
        }
      }
    }
  }
}

@mixin flex-row_spc-btwn-cntr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-col-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@mixin ellipsis-cell($width: 90%, $max: 90%) {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: $width;
  max-width: $max;
}

@mixin ellipsis-cell($width: 90%, $max: 90%) {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: $width;
  max-width: $max;
}

@mixin spacing-classes {
  @for $i from 0 through 100 {
    @if $i % 2 == 0 {
      .height-#{$i} {
        height: $i * 0.1rem !important;
      }
      .mar-#{$i} {
        margin: $i * 0.1rem !important;
        &--xs {
          @media (min-width: $screen-xs) {
            margin: $i * 0.1rem !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            margin: $i * 0.1rem !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            margin: $i * 0.1rem !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            margin: $i * 0.1rem !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            margin: $i * 0.1rem !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            margin: $i * 0.1rem !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            margin: $i * 0.1rem !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            margin: $i * 0.1rem !important;
          }
        }
      }
      .mar-l-#{$i} {
        margin-left: $i * 0.1rem !important;

        &--xs {
          @media (min-width: $screen-xs) {
            margin-left: $i * 0.1rem !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            margin-left: $i * 0.1rem !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            margin-left: $i * 0.1rem !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            margin-left: $i * 0.1rem !important;
          }
        }

        &--xs {
          @media (min-width: $screen-xs) {
            margin-left: $i * 0.1rem !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            margin-left: $i * 0.1rem !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            margin-left: $i * 0.1rem !important;
          }
        }

        &--lg-max {
          @media (min-width: $screen-lg) {
            margin-left: $i * 0.1rem !important;
          }
        }
      }
      .mar-r-#{$i} {
        margin-right: $i * 0.1rem !important;

        &--xs-max {
          @media (max-width: $screen-xs) {
            margin-right: $i * 0.1rem !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            margin-right: $i * 0.1rem !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            margin-right: $i * 0.1rem !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            margin-right: $i * 0.1rem !important;
          }
        }
      }
      .mar-t-#{$i} {
        margin-top: $i * 0.1rem !important;

        &--xs {
          @media (min-width: $screen-xs) {
            margin-top: $i * 0.1rem !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            margin-top: $i * 0.1rem !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            margin-top: $i * 0.1rem !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            margin-top: $i * 0.1rem !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            margin-top: $i * 0.1rem !important;
          }
        }

        &--sm-max {
          @media (min-width: $screen-sm) {
            margin-top: $i * 0.1rem !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            margin-top: $i * 0.1rem !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            margin-top: $i * 0.1rem !important;
          }
        }
      }
      .mar-b-#{$i} {
        margin-bottom: $i * 0.1rem !important;

        &--xs {
          @media (min-width: $screen-xs) {
            margin-bottom: $i * 0.1rem !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            margin-bottom: $i * 0.1rem !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            margin-bottom: $i * 0.1rem !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            margin-bottom: $i * 0.1rem !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            margin-bottom: $i * 0.1rem !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            margin-bottom: $i * 0.1rem !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            margin-bottom: $i * 0.1rem !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            margin-bottom: $i * 0.1rem !important;
          }
        }
      }
      .mar-h-#{$i} {
        margin: 0 ($i * 0.1rem) !important;

        &--xs {
          @media (min-width: $screen-xs) {
            margin: 0 ($i * 0.1rem) !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            margin: 0 ($i * 0.1rem) !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            margin: 0 ($i * 0.1rem) !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            margin: 0 ($i * 0.1rem) !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            margin: 0 ($i * 0.1rem) !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            margin: 0 ($i * 0.1rem) !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            margin: 0 ($i * 0.1rem) !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            margin: 0 ($i * 0.1rem) !important;
          }
        }
      }
      .mar-v-#{$i} {
        margin: ($i * 0.1rem) 0 !important;

        &--xs {
          @media (min-width: $screen-xs) {
            margin: ($i * 0.1rem) 0 !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            margin: ($i * 0.1rem) 0 !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            margin: ($i * 0.1rem) 0 !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            margin: ($i * 0.1rem) 0 !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            margin: ($i * 0.1rem) 0 !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            margin: ($i * 0.1rem) 0 !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            margin: ($i * 0.1rem) 0 !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            margin: ($i * 0.1rem) 0 !important;
          }
        }
      }
      .mar-north-#{$i} {
        margin: ($i * 0.1rem) ($i * 0.1rem) 0 !important;

        &--xs {
          @media (min-width: $screen-xs) {
            margin: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            margin: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            margin: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            margin: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            margin: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            margin: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            margin: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            margin: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }
      }
      .mar-south-#{$i} {
        margin: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;

        &--xs {
          @media (min-width: $screen-xs) {
            margin: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            margin: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            margin: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            margin: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            margin: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            margin: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            margin: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            margin: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }
      }

      //padding
      .pad-#{$i} {
        padding: $i * 0.1rem !important;

        &--xs {
          @media (min-width: $screen-xs) {
            padding: $i * 0.1rem !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            padding: $i * 0.1rem !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            padding: $i * 0.1rem !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            padding: $i * 0.1rem !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            padding: $i * 0.1rem !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            padding: $i * 0.1rem !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            padding: $i * 0.1rem !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            padding: $i * 0.1rem !important;
          }
        }
      }
      .pad-l-#{$i} {
        padding-left: $i * 0.1rem !important;

        &--xs {
          @media (min-width: $screen-xs) {
            padding-left: $i * 0.1rem !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            padding-left: $i * 0.1rem !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            padding-left: $i * 0.1rem !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            padding-left: $i * 0.1rem !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            padding-left: $i * 0.1rem !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            padding-left: $i * 0.1rem !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            padding-left: $i * 0.1rem !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            padding-left: $i * 0.1rem !important;
          }
        }
      }
      .pad-r-#{$i} {
        padding-right: $i * 0.1rem !important;

        &--xs {
          @media (min-width: $screen-xs) {
            padding-right: $i * 0.1rem !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            padding-right: $i * 0.1rem !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            padding-right: $i * 0.1rem !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            padding-right: $i * 0.1rem !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            padding-right: $i * 0.1rem !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            padding-right: $i * 0.1rem !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            padding-right: $i * 0.1rem !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            padding-right: $i * 0.1rem !important;
          }
        }
      }
      .pad-t-#{$i} {
        padding-top: $i * 0.1rem !important;

        &--xs {
          @media (min-width: $screen-xs) {
            padding-top: $i * 0.1rem !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            padding-top: $i * 0.1rem !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            padding-top: $i * 0.1rem !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            padding-top: $i * 0.1rem !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            padding-top: $i * 0.1rem !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            padding-top: $i * 0.1rem !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            padding-top: $i * 0.1rem !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            padding-top: $i * 0.1rem !important;
          }
        }
      }
      .pad-b-#{$i} {
        padding-bottom: $i * 0.1rem !important;

        &--xs {
          @media (min-width: $screen-xs) {
            padding-bottom: $i * 0.1rem !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            padding-bottom: $i * 0.1rem !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            padding-bottom: $i * 0.1rem !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            padding-bottom: $i * 0.1rem !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            padding-top: $i * 0.1rem !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            padding-top: $i * 0.1rem !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            padding-top: $i * 0.1rem !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            padding-top: $i * 0.1rem !important;
          }
        }
      }
      .pad-h-#{$i} {
        padding: 0 ($i * 0.1rem) !important;

        &--xs {
          @media (min-width: $screen-xs) {
            padding: 0 ($i * 0.1rem) !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            padding: 0 ($i * 0.1rem) !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            padding: 0 ($i * 0.1rem) !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            padding: 0 ($i * 0.1rem) !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            padding: 0 ($i * 0.1rem) !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            padding: 0 ($i * 0.1rem) !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            padding: 0 ($i * 0.1rem) !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            padding: 0 ($i * 0.1rem) !important;
          }
        }
      }
      .pad-v-#{$i} {
        padding: ($i * 0.1rem) 0px !important;

        &--xs {
          @media (min-width: $screen-xs) {
            padding: ($i * 0.1rem) 0px !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            padding: ($i * 0.1rem) 0px !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            padding: ($i * 0.1rem) 0px !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            padding: ($i * 0.1rem) 0px !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            padding: ($i * 0.1rem) 0px !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            padding: ($i * 0.1rem) 0px !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            padding: ($i * 0.1rem) 0px !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            padding: ($i * 0.1rem) 0px !important;
          }
        }
      }
      .pad-north-#{$i} {
        padding: ($i * 0.1rem) ($i * 0.1rem) 0 !important;

        &--xs {
          @media (min-width: $screen-xs) {
            padding: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            padding: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            padding: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            padding: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            padding: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            padding: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            padding: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            padding: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
          }
        }
      }
      .pad-south-#{$i} {
        padding: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;

        &--xs {
          @media (min-width: $screen-xs) {
            padding: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }

        &--sm {
          @media (min-width: $screen-sm) {
            padding: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }

        &--md {
          @media (min-width: $screen-md) {
            padding: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }

        &--lg {
          @media (min-width: $screen-lg) {
            padding: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }

        &--xs-max {
          @media (max-width: $screen-xs) {
            padding: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }

        &--sm-max {
          @media (max-width: $screen-sm) {
            padding: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }

        &--md-max {
          @media (max-width: $screen-md) {
            padding: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }

        &--lg-max {
          @media (max-width: $screen-lg) {
            padding: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
          }
        }
      }

      //font-size
      .font-sz-#{$i} {
        font-size: $i * 0.1rem !important;
      }

      //widths
      .w-#{$i} {
        width: $i * 0.1rem !important;
      }
      .w-#{$i * 10} {
        width: $i * 1rem !important;
      }

      //padding
      .pad-#{$i} {
        padding: $i * 0.1rem !important;
      }
      .pad-l-#{$i} {
        padding-left: $i * 0.1rem !important;
      }
      .pad-r-#{$i} {
        padding-right: $i * 0.1rem !important;
      }
      .pad-t-#{$i} {
        padding-top: $i * 0.1rem !important;
      }
      .pad-b-#{$i} {
        padding-bottom: $i * 0.1rem !important;
      }
      .pad-h-#{$i} {
        padding: 0 ($i * 0.1rem) !important;
      }
      .pad-v-#{$i} {
        padding: ($i * 0.1rem) 0px !important;
      }
      .pad-north-#{$i} {
        padding: ($i * 0.1rem) ($i * 0.1rem) 0 !important;
      }
      .pad-south-#{$i} {
        padding: 0 ($i * 0.1rem) ($i * 0.1rem) ($i * 0.1rem) !important;
      }

      //font-size
      .font-sz-#{$i} {
        font-size: $i * 0.1rem !important;
      }
      //widths
      .w-#{$i} {
        width: $i * 0.1rem !important;
      }
      .w-#{$i * 10} {
        width: $i * 1rem !important;
      }

      //max-widths
      .max-w-percent-#{$i} {
        max-width: $i * 1% !important;
      }
      .max-w-#{$i * 10} {
        max-width: $i * 1rem !important;
      }
    }
  }
}

@mixin percent-widths {
  @for $z from 1 through 100 {
    //width-percent
    .w-percent-#{$z} {
      width: $z * 1% !important;
    }
  }
}

@mixin ellipsis-cell($width: 90%, $max: 90%) {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: $width;
  max-width: $max;
}

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin BoxShadow($dp) {
  @if $dp==0 {
    box-shadow: none;
  } @else if $dp==1 {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  } @else if $dp==2 {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  } @else if $dp==3 {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
      0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  } @else if $dp==4 {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  } @else if $dp==6 {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  } @else if $dp==8 {
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  } @else if $dp==9 {
    box-shadow: 0 9px 12px 1px rgba(0, 0, 0, 0.14),
      0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 5px 6px -3px rgba(0, 0, 0, 0.2);
  } @else if $dp==12 {
    box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14),
      0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
  } @else if $dp==16 {
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
      0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  } @else if $dp==24 {
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  }
}

@mixin create-colors-classes {
  @each $name, $tint in $primary-colors {
    .color-#{"" + $name} {
      color: $tint !important;
    }
  }

  @each $name, $tint in $neutral-colors {
    .color-#{"" + $name} {
      color: $tint !important;
    }
  }

  @each $name, $tint in $secondary-colors {
    .color-#{"" + $name} {
      color: $tint !important;
    }
  }

  @each $name, $tint in $warning-colors {
    .color-#{"" + $name} {
      color: $tint !important;
    }
  }

  @each $name, $tint in $danger-colors {
    .color-#{"" + $name} {
      color: $tint !important;
    }
  }

  @each $name, $tint in $info-colors {
    .color-#{"" + $name} {
      color: $tint !important;
    }
  }
}

@mixin global-border {
  border: 1px solid $global-border;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}
