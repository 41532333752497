//
// Labels
// --------------------------------------------------
@mixin label-base {
  display: inline;
  padding: 0.6rem 1rem;
  font-size: var(--measure-font-12);
  font-weight: var(--font-weight-regular);
  line-height: 1;
  color: $label-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--measure-4x);
}

.label {
  @include label-base;

  // [converter] extracted a& to a.label

  // Empty labels collapse automatically (not available in IE8)
  &:empty {
    display: none;

    + i[class*="icon-radial_info"] {
      font-size: 1.4rem;
    }
  }

  // Quick fix for labels in buttons
  .btn & {
    position: relative;
    top: -1px;
  }
}

// Add hover effects, but only for links
a.label {
  &:hover,
  &:focus {
    color: $label-link-hover-color;
    text-decoration: none;
    cursor: pointer;
  }
}

// Colors
// Contextual variations (linked labels get darker on :hover)

.label-default {
  @include label-variant($label-default-bg);
}

.label-primary {
  @include label-variant($color-primary-400);
}

.label-success {
  @include label-variant($color-success-400);
}

.label-info {
  @include label-variant($color-info-400);
}

.label-warning {
  @include label-variant($color-warning-400);
}

.label-danger {
  @include label-variant($color-danger-400);
}

.label__check--read-only {
  font-size: 14px;
  padding-left: var(--measure-2x);
}
