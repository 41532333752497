.aside-collapser {
  display: none;
}

@media screen and(min-width:$mq-tablet) {
  .aside-collapser {
    display: block;
    width: 100%;
    height: 4rem;
    padding: $padding-base;
    border-top: 1px dotted rgba($global-border, 0.5);
    border-bottom: 1px solid $global-border;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: pointer;

    &__icon {
      transform: rotate(90deg);
    }
  }
}
