.password-revealer {
  position: absolute;
  cursor: hand;
  -webkit-transition: color 0.4s;
  -moz-transition: color 0.4s;
  -ms-transition: color 0.4s;
  -o-transition: color 0.4s;
  transition: color 0.4s;

  top: 30px;
  right: 20px;
  &--login {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
  &--change {
    position: absolute;
    top: 1rem;
    right: 2rem;
    cursor: pointer;
  }
  &--user-change {
    position: absolute;
    top: 0.5rem;
    right: 2rem;
    cursor: pointer;
  }
  &__icon {
    color: $primary-color;
    cursor: hand;
    -webkit-transition: color 0.4s;
    -moz-transition: color 0.4s;
    -ms-transition: color 0.4s;
    -o-transition: color 0.4s;
    transition: color 0.4s;
  }
}
