/*Subtable Component*/
.sub-table {
  font-size: 1.2rem;
  &-ext-info-block {
    display: inline-block;
  }
  &__thead {
  }

  &__th {
    padding: 0.5rem;
    text-align: center;
  }

  &__tbody {
  }

  &__tr {
  }

  &__td {
    padding: 1rem;
  }
}
