@mixin filter-key-row {
  display: flex;
  flex-flow: row nowrap;
  font-size: 1.4rem;
  margin-bottom: 0.8rem;
  width: 100%;
  margin-bottom: var(--measure-half);
}
.form-section {
  &__closer {
    position: absolute;
    top: var(--measure-2x);
    right: var(--measure-1x);
    width: var(--measure-3x);
    height: var(--measure-3x);
    z-index: 4;
    /*center icon in the closer*/
    > a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: inherit;
      text-decoration: none;
      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }

  &__multi-field-group {
    margin-bottom: var(--measure-2x);

    &__title {
      font-size: var(--measure-font-16);

      display: flex;
      flex-flow: row nowrap;
      border-bottom: 0.1rem solid #e5e5e5;
      line-height: 1.2;
      margin-bottom: var(--measure-2x);
      justify-content: flex-start;
      align-items: baseline;

      &--space-between {
        justify-content: space-between;
      }

      > .title-subtext,
      .title-subtext {
        font-size: var(--measure-font-12);
        color: var(--color-neutral-800);
        margin-left: var(--measure-2x);
        font-style: italic;
      }

      &--no-border {
        border-bottom: none;
      }

      &.required:after {
        @include required-marker();
      }

      &--keys {
        font-size: 1.4rem;
      }
    }

    &__checkbox-columns {
      max-width: 100%;
      column-width: 25rem;
      margin-bottom: 24px;
    }
    &--feature-key-item {
      @include filter-key-row();
      + span {
        @include filter-key-row();
      }
    }
  }
}
