.init-connect-modal {
  &__info-table {
    padding: 0.5rem 0;
    background-color: rgba($astral, 0.4);
    border-top: 1px solid $astral;
    border-bottom: 1px solid $astral;
    color: $astral;
  }
}

/*$border, $heading-text-color, $heading-bg-color, $heading-border*/

.panel-step {
  @include panel-variant(
    $color-neutral-700,
    $color-neutral-700,
    $color-neutral-200,
    $color-neutral-200
  );

  &.active {
    @include panel-variant(
      $color-primary-800,
      $color-primary-800,
      $color-primary-200,
      $color-primary-800
    );
  }

  &.success {
    @include panel-variant(
      $color-success-800,
      $color-success-800,
      $color-success-100!important,
      $color-success-800
    );
  }

  &.fail {
    @include panel-variant(
      $color-danger-800,
      $color-danger-800,
      $color-danger-200,
      $color-danger-800
    );
  }
}

.panel.panel-step {
  padding: 0;
  > .panel-body {
    padding: 1rem;
  }
  .panel-heading {
    //padding: 1rem;

    h4 {
      font-weight: 100;
      font-size: 1.5em;
    }
  }
}
