.actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-left: var(--measure-12);
}

.actions > * {
  margin-top: var(--measure-12);
}

.actions > *:first-child {
  margin-top: 0;
}

@media (min-width: 550px) {
  .actions {
    flex-direction: row;
    padding-left: 0;
  }

  .actions > * {
    margin-top: 0;
    margin-left: var(--measure-12);
  }

  .actions > *:first-child {
    margin-left: 0;
  }
}

.form-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

.form-error {
  margin-top: var(--measure-12);
}
