@mixin header-base {
  font-size: 1.4rem;
  display: grid;
  grid-template-columns: 1fr 3fr 2fr;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > * {
    align-self: center;
  }
}

.accordion-programming {
  &-title {
    @include header-base;
    &--cards {
      grid-template-columns: 1fr !important;
    }
  }

  &__header {
    padding: 0.8rem;

    &-title {
      @include header-base;

      .button-group {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      &--cards {
        @include header-base;
        grid-template-columns: 1fr;
      }
    }
  }

  &__toggle {
    text-decoration: none;

    &:link,
    &:hover,
    &active {
      text-decoration: none;
    }
  }
}

.accordion-heading {
  &__two-item-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and(min-width: $screen-sm) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      &-item {
        word-break: break-word;
        //min-width: 25%;
        margin-right: var(--measure-2x);
        &:nth-child(1) {
          min-width: 50%;
        }
      }
    }
  }
}
