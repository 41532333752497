//
// Popovers
// --------------------------------------------------

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-popover;
  display: none;
  max-width: $popover-max-width;
  padding: 1px !important;
  // Reset font and text propertes given new insertion method
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: normal;
  line-height: $line-height-base;
  text-align: left;
  background-color: $popover-bg;
  background-clip: padding-box;
  border: 1px solid $popover-fallback-border-color;
  border: 1px solid $popover-border-color;
  border-radius: $border-radius-large;
  @include box-shadow(0 5px 10px rgba(0, 0, 0, 0.2));

  // Overrides for proper insertion
  white-space: normal;

  // Offset the popover to account for the popover arrow
  &.top {
    margin-top: -$popover-arrow-width;
  }
  &.right {
    margin-left: $popover-arrow-width;
  }
  &.bottom {
    margin-top: $popover-arrow-width;
  }
  &.left {
    margin-left: -$popover-arrow-width;
  }

  &__customer-score {
    width: 600px;
    max-width: 600px;
    & .popover-title {
      margin: 0; // reset heading margin
      display: flex;
      justify-content: center;
      align-items: center;
      padding: var(--measure-2x) 14px;
      font-size: var(--measure-font-18);
      font-weight: var(--font-weight-regular);
      background-color: white;
      border-bottom: 1px solid darken($popover-title-bg, 5%);
      border-radius: ($border-radius-large - 1) ($border-radius-large - 1) 0 0;
    }
  }
}

.popover-title {
  margin: 0; // reset heading margin
  padding: 8px 14px;
  font-size: $font-size-base;
  background-color: $popover-title-bg;
  border-bottom: 1px solid darken($popover-title-bg, 5%);
  border-radius: ($border-radius-large - 1) ($border-radius-large - 1) 0 0;
  //For the beacon notifier popup
  &--beacon {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: var(--measure-1x) var(--measure-2x);
    margin: 0 !important;
    font-weight: var(--font-weight-thin);
    font-size: var(--measure-font-20);
    background: var(--color-primary-400);
    color: white;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    &:before {
      font-family: var(--font-family-icon);
      content: "\e901";
      margin-right: 0.8rem;
    }

    &--info {
      background: var(--color-info-400);

      &:before {
        font-family: var(--font-family-icon);
        content: "\e955";
        margin-right: 0.8rem;
      }
    }

    &--success {
      background: var(--color-success-400);
      &:before {
        font-family: var(--font-family-icon);
        content: "\e978";
        margin-right: 0.8rem;
      }
    }
    &--warning {
      background: var(--color-warning-800);
      &:before {
        font-family: var(--font-family-icon);
        content: "\e926";
        margin-right: 0.8rem;
      }
    }
    &--danger {
      background: var(--color-danger-400);
      &:before {
        font-family: var(--font-family-icon);
        content: "\e926";
        margin-right: 0.8rem;
      }
    }

    &-closer {
      &:before {
        font-family: $font-family-icon;

        content: "\e979";
        padding: 0.2rem;
        color: inherit;
        font-size: var(--measure-font-14);
        cursor: pointer;
      }
    }
  }
}

.popover-content {
  padding: 9px 14px;
  color: $text-global;
}

// Arrows
//
// .arrow is outer, .arrow:after is inner

.popover > .arrow {
  &,
  &:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
}
.popover > .arrow {
  border-width: $popover-arrow-outer-width;
}
.popover > .arrow:after {
  border-width: $popover-arrow-width;
  content: "";
}

.popover {
  &.top > .arrow {
    left: 50%;
    margin-left: -$popover-arrow-outer-width;
    border-bottom-width: 0;
    border-top-color: $popover-arrow-outer-fallback-color; // IE8 fallback
    border-top-color: $popover-arrow-outer-color;
    bottom: 20px;
    display: none;
    &:after {
      content: " ";
      bottom: 20px;
      margin-left: -$popover-arrow-width;
      border-bottom-width: 0;
      border-top-color: $popover-arrow-color;
      display: none;
    }
  }
  &.right > .arrow {
    top: 50%;
    left: -$popover-arrow-outer-width;
    margin-top: -$popover-arrow-outer-width;
    border-left-width: 0;
    border-right-color: $popover-arrow-outer-fallback-color; // IE8 fallback
    border-right-color: $popover-arrow-outer-color;
    &:after {
      content: " ";
      left: 1px;
      bottom: -$popover-arrow-width;
      border-left-width: 0;
      border-right-color: $popover-arrow-color;
    }
  }
  &.bottom > .arrow {
    left: 50%;
    margin-left: -$popover-arrow-outer-width;
    border-top-width: 0;
    border-bottom-color: $popover-arrow-outer-fallback-color; // IE8 fallback
    border-bottom-color: $popover-arrow-outer-color;
    top: -$popover-arrow-outer-width;
    &:after {
      content: " ";
      top: 1px;
      margin-left: -$popover-arrow-width;
      border-top-width: 0;
      border-bottom-color: $popover-arrow-color;
    }
  }

  &.left > .arrow {
    top: 50%;
    right: -$popover-arrow-outer-width;
    margin-top: -$popover-arrow-outer-width;
    border-right-width: 0;
    border-left-color: $popover-arrow-outer-fallback-color; // IE8 fallback
    border-left-color: $popover-arrow-outer-color;
    &:after {
      content: " ";
      right: 1px;
      border-right-width: 0;
      border-left-color: $popover-arrow-color;
      bottom: -$popover-arrow-width;
    }
  }
}

.emphasis-box {
  .popover {
    color: $gray-dark;
  }
}
