/* Bubble Float Left */
@mixin bubble-float-left {
  @include hacks();
  position: relative;
  @include prefixed(transition-duration, $mediumDuration);
  @include prefixed(transition-property, transform);

  &:before {
    position: absolute;
    z-index: -1;
    content: "";
    top: calc(50% - #{$tipHeight});
    left: 0;
    border-style: solid;
    border-width: $tipHeight $tipWidth $tipHeight 0;
    border-color: transparent $tipColor transparent transparent;
    @include prefixed(transition-duration, $mediumDuration);
    @include prefixed(transition-property, transform);
  }

  &:hover,
  &:focus,
  &:active {
    @include prefixed(transform, translateX($tipWidth));

    &:before {
      @include prefixed(transform, translateX(-($tipWidth)));
    }
  }
}
