@mixin bullet($width: var(--measure-1x), $state: "success") {
  width: $width;
  height: $width;

  border-radius: 50%;
  @if ($state == "danger") {
    background-color: var(--color-state-danger);
  } @else if($state == "warning") {
    background-color: var(--color-secondary);
  } @else {
    background-color: var(--color-state-success);
  }
}

.bullet-state-indicator {
  &--success {
    @include bullet(var(--measure-1x), "success");
  }

  &--warning {
    @include bullet(var(--measure-1x), "warning");
  }

  &--danger {
    @include bullet(var(--measure-1x), "danger");
  }

  &__app-user {
    position: absolute;
    transform-origin: center;
    transform: translate(-1.2rem, 0.8rem);
  }
  &__tech-track {
    position: relative;
    transform-origin: center;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
}
