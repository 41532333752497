.switch-overrides {
  line-height: 1;
}

.switch-overrides input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media (max-width: 767px) {
  /* Using inversed to implicity default back to previous display */
  .switch-overrides {
    display: block;
  }
}

@media (min-width: 768px) {
  .switch-overrides {
    line-height: 1;
    display: block;
  }

  .inline-label {
    text-align: right;
    padding-top: 0.54em;
    white-space: pre;
  }

  .field {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
  }

  .label {
    padding-top: 0.54em;
    padding-left: var(--measure-1x);
  }

  .full-width-field {
    width: 100%;
  }

  .offset-field {
    width: 100%;
    padding-left: 38%;
  }

  .offset-field > :nth-child(1) {
    padding: 0 var(--measure-20);
  }

  .default-field > :nth-child(1) {
    flex: 1 0 38%;
    max-width: 38%;
    text-align: right;
    padding-left: var(--measure-20);
    padding-right: var(--measure-20);
  }

  .featured-field > :nth-child(1) {
    flex: 1 0 25%;
    max-width: 25%;
    text-align: right;
    padding-left: var(--measure-20);
    padding-right: var(--measure-20);
  }
}
