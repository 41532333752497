svg {
  &.icon-button {
    position: relative;
    height: 3.2rem;
    width: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
  }

  &.icon-feedback {
    position: absolute;
    height: 1.6rem;
    width: 1.6rem;
    right: 1rem;
  }

  &.icon-8 {
    position: absolute;
    height: 0.8rem;
    width: 0.8rem;
  }

  &.icon-14 {
    position: relative;
    height: 1.4rem;
    width: 1.4rem;
  }

  &.icon-1x,
  &.icon-16 {
    position: relative;
    height: 1.6rem;
    width: 1.6rem;
  }

  &.icon-18 {
    position: relative;
    height: 1.8rem;
    width: 1.8rem;
  }

  &.icon-20 {
    position: relative;
    height: 2rem;
    width: 2rem;
  }

  &.icon-22 {
    position: relative;
    height: 2.2rem;
    width: 2.2rem;
  }

  &.icon-dropdown-select,
  &.icon-24 {
    position: relative;
    height: 2.4rem;
    width: 2.4rem;
  }

  &.icon-26 {
    position: relative;
    height: 2.6rem;
    width: 2.6rem;
  }

  &.dealer-dashboard-subtitle--svg,
  &.icon-30 {
    height: 3rem;
    width: 3rem;
  }

  &.icon-32 {
    position: relative;
    height: 3.2rem;
    width: 3.2rem;
  }

  &.icon-40 {
    position: relative;
    height: 4rem;
    width: 4rem;
  }

  &.icon-44 {
    position: relative;
    height: 1.4rem;
    width: 5rem;
    margin-top: .5rem;
  }

  &.icon-46 {
    max-height: 2.0rem;
    width: 6rem;
    position: relative;
    top: 5px;
    left: 3px;
  }
}
