.grid-layout {
  display: grid;
  @media screen and (min-width: $screen-sm) {
  }
  &__col-1 {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(0, max-content);
    grid-gap: var(--measure-2x);
    padding-left: -(var(--measure-1x));
    margin-bottom: var(--measure-2x);
  }
  &__col-2 {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(0, max-content);
    grid-gap: var(--measure-2x);
    padding-left: -(var(--measure-1x));
    margin-bottom: var(--measure-2x);
    @media screen and (min-width: $screen-sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: 1fr;

      &--max-660 {
        grid-template-columns: minmax(38rem, 0.5fr) 1fr;
      }
    }
  }

  &__cell {
    &--send-to-top {
      order: -1;

      @media screen and (min-width: $screen-sm) {
        order: 2;
      }
    }

    &--send-to-bottom {
      order: 2;

      @media screen and (min-width: $screen-sm) {
        order: 1;
      }
    }
  }
}
