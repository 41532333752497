@mixin colored-bg($color: $dmp-light-gray) {
  background: none repeat scroll 0% 0% $color;
}
@mixin dmp-border($color: $dmp-border-color) {
  border: 1px solid $color;
}

@mixin dmp-border-bottom($color: $dmp-border-color) {
  border-bottom: 1px solid $color;
}

@mixin no-padding-lr {
  padding-left: 0;
  padding-right: 0;
}

//Mixin for vendor prefixes
@mixin prefix($map, $vendors: webkit moz ms o) {
  @each $prop, $value in $map {
    @if $vendors {
      @each $vendor in $vendors {
        #{"-" + $vendor + "-" + $prop}: #{$value};
      }
    }
    // Dump regular property anyway
    #{$prop}: #{$value};
  }
}
