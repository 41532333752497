.button--round {
  display: inline-block;
  border-radius: 99999px;
  background-color: var(--color-primary-500);
  color: white;
  padding: var(--measure-1x) var(--measure-2x);
  font-weight: bold;
  white-space: nowrap;

  &:hover {
    background-color: var(--color-primary-600);
    color: white;
    text-decoration: none;
  }
}

.button--round__icon {
  font-size: var(--measure-font-10);
}
