$feature-margin-last-item: 2.4rem;
$col-1-height: 15.5rem;
$col-2-height: 18rem;
$col-3-height: 14rem;
$col-4-height: 12rem;
$col-5-height: 10rem;
.control-system-edit-view {
  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-template-areas: "basic-info" "virtual-keypad" "features";
    grid-auto-flow: dense;
    grid-row-gap: var(--measure-4x);

    &__row-1 {
      grid-area: basic-info;

      &__panel-content {
        display: grid;
        grid-template-columns: [start-left] 1fr [end-left];
        grid-template-rows: max-content [start-top] max-content [end-top start-middle] max-content [end-middle start-bottom] max-content [end-bottom];
        grid-template-areas: "top" "left-content" "right-content" "middle-content" "left-bottom" "right-bottom";
        grid-column-gap: var(--measure-5x);
        //border: 4px dashed cornflowerblue;
        @media (min-width: $screen-sm) {
          // border-color: mediumvioletred;

          grid-template-areas: "top top" "left-content right-content" "middle-content middle-content" "left-bottom right-bottom";
          grid-template-columns: [start-left] 1fr [end-left start-right] 1fr [end-right];
          grid-template-rows: [start-very-top] max-content [end-very-top start-top] max-content [end-top start-middle] max-content [end-middle start-bottom] max-content [end-bottom];
        }
        @media (min-width: $screen-md) {
          // border-color: greenyellow;
        }
        @media (min-width: $screen-lg) {
          // border-color: orangered;
        }
        &-top {
          grid-area: top;
        }

        &-lt {
          grid-area: left-content;
        }

        &-rt {
          grid-area: right-content;
        }

        &-md {
          grid-area: middle-content;
          padding: var(--measure-1x) 0;
        }

        &-lt-bottom {
          grid-area: left-bottom;
          padding-top: var(--measure-1x);
        }

        &-rt-bottom {
          grid-area: right-bottom;
          padding-top: var(--measure-1x);
        }
      }
    }

    &__row-2 {
      width: 100%;
      //grid-area: virtual-keypad;

      &__vk-packages {
        //  margin-bottom: var(--measure-3x);
        display: grid;
        height: min-content;
        grid-template-rows: repeat(3, minmax(min-content, max-content));
        grid-template-columns: 1fr;
        grid-template-areas: "left" "center" "right";
        grid-row-gap: var(--measure-1x);

        justify-content: center;

        @media (min-width: $screen-sm) {
          /*  $width-content-area : calc(25% - 240px );
                      $card-side-width: (($width-content-area) * .25) + 0vh;
                      $card-primary-width: (($width-content-area) * .5) + 0vh;*/

          grid-template-columns: 0.9fr 1fr 0.9fr;
          grid-auto-rows: minmax(0, 1fr);
          grid-template-areas: "left center right";
          grid-column-gap: var - (--measure-2x);
          grid-row-gap: 0;
          // border: 3px solid blue;
        }
        @media (min-width: $screen-md) {
          //; border: 3px solid orange;
        }

        @media (min-width: $screen-lg-min) {
          /*  $width-content-area : calc(25% - 240px );
                      $card-side-width: (($width-content-area) * .25) + 0vh;
                      $card-primary-width: (($width-content-area) * .5) + 0vh;*/
          grid-template-columns: minmax(25rem, 35rem) minmax(38rem, 45rem) minmax(
              25rem,
              35rem
            );
          grid-auto-rows: minmax(0, 1fr);
          grid-template-areas: "left center right";
          grid-column-gap: var - (--measure-2x);
          //border: 3px solid red;
        }

        &--basic {
          grid-area: left;
        }

        &--standard {
          grid-area: center;
        }

        &--premium {
          grid-area: right;
        }
      }
    }

    &__row-3 {
      grid-area: features;
    }
  }
}

.plans-table {
  &__plan {
    background: white;
    border: 1px solid $global-border;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: var(--measure-3x);

    &--primary {
      @media (min-width: $screen-sm) {
        @include BoxShadow(6);
      }
    }

    &--not-primary {
      margin-top: 0;
      @media (min-width: $screen-sm) {
        margin-top: var(--measure-2x);
      }
    }

    &--position-1 {
      @media (min-width: $screen-sm) {
        position: relative;
        top: var(--measure-1x);
        left: var(--measure-1x);
        z-index: 1;
        @include BoxShadow(1);
      }
    }

    &--position-2 {
      @media (min-width: $screen-sm) {
        position: relative;
        z-index: 2;
      }
    }

    &--position-3 {
      @media (min-width: $screen-sm) {
        position: relative;
        top: var(--measure-1x);
        right: var(--measure-1x);
        z-index: 1;
        @include BoxShadow(1);
      }
    }

    .btn--choose-me {
      position: relative;
      text-align: center;
      font-size: var(--measure-font-14);
      width: 80%;
      align-self: center;
      border-radius: 30px;
      transition: all 0.2s ease-out;

      @media (min-width: $screen-sm) {
        font-size: var(--measure-font-14);
      }

      &:hover {
        background-color: rgba(#428dae, 0.2);
        color: $color-primary;
        border-color: $color-primary;
        transform: translateY(-0.2rem);
        @include box-shadow(2);
      }

      &.btn--choose-me--selected {
        background-color: var(--color-primary-500);
        color: white;

        &:before {
          position: absolute;
          left: var(--measure-1x);

          font-family: dmp_icon_font;
          content: "\e952";
          top: 50%;
          font-size: var(--measure-font-18);
          transform-origin: center;
          transform: translateY(-50%);
          @media (min-width: $screen-sm) {
            font-size: var(--measure-font-16);
          }
        }
      }
    }
  }

  &__tab-callout {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: var(--measure-4x);
    font-size: var(--measure-font-16);
    background: var(--color-primary-300);
    color: white;
    line-height: 0;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    justify-content: center;
    align-items: center;
  }

  &__inner-box {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__header {
    width: 100%;
    /*height: var(--measure-10x);*/
    font-size: var(--measure-font-16);
    display: flex;
    font-weight: var(--font-weight-thin);
    background-color: var(--color-neutral-200);
    justify-content: center;
    align-items: center;
    padding: var(--measure-1x);
    color: var(--color-primary-500);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    @media (min-width: $screen-sm) {
      font-size: var(--measure-font-18);
      padding: var(--measure-1x);
    }

    &--primary {
      color: white;
      background-color: var(--color-primary-500);
    }
  }

  &__nutshell {
    &-line {
      width: 100%;
      font-size: var(--measure-font-18);
      line-height: 1.2;
      font-weight: var(--font-weight-semibold);
      display: block;
      text-align: center;
      align-items: center;
      //color: var(--color-neutral-500);
      padding: var(--measure-1x) var(--measure-1x);
      // margin-bottom: var(--measure-2x);
      @media (min-width: $screen-sm) {
        font-size: var(--measure-font-16);
        min-height: 9.4rem;
      }
    }

    &-icon {
      display: none;
      width: 100%;
      padding: var(--measure-3x);

      justify-content: center;
      align-items: center;
      font-size: var(--measure-font-24);
      color: var(--color-primary-400);

      @media (min-width: $screen-sm) {
        display: flex;
        font-size: var(--measure-font-16);
        padding: var(--measure-1x);
      }

      > * {
        margin-right: var(--measure-1x);

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__content {
  }

  &__subheader {
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding-left: 0;
    width: 100%;
    margin-bottom: var(--measure-3x);

    list-style: none none inside;

    @media (min-width: $screen-sm) {
      min-height: 28rem;
    }
    @media (min-width: $screen-md) {
      min-height: 28rem;
    }

    &--primary {
      @media (min-width: $screen-sm) {
        min-height: 29 + 3.2rem;
      }
    }

    > li {
      width: 100%;
      // border-bottom: 1px solid var(--color-neutral-200);
      padding: var(--measure-1x) var(--measure-3x);
      font-size: var(--measure-font-12);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 0;
      @media (min-width: $screen-sm) {
        font-size: var(--measure-font-12);
      }

      &.emphasized {
        font-weight: var(--font-weight-bold);
        font-style: italic;

        .details {
          font-weight: var(--font-weight-thin);
          font-style: italic;
        }
      }

      > i {
        font-size: var(--measure-font-24);
        color: var(--color-state-success);
        @media (min-width: $screen-sm) {
          font-size: var(--measure-font-16);
        }
      }
    }
  }

  &__highlight {
    &__icon {
    }
  }

  &__price {
    width: 100%;
    text-align: center;
    font-size: var(--measure-font-14);
    margin-bottom: var(--measure-2x);
    font-weight: var(--font-weight-thin);
    color: var(--color-primary-600);
    @media (min-width: $screen-sm) {
      font-size: var(--measure-font-18);
    }
  }
}

.feature {
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: 4.2rem 1fr 1fr;
  grid-template-rows: min-content min-content min-content min-content;
  grid-template-areas:
    "icon title title"
    "desc desc desc"
    "price price price"
    "controls controls controls";
  grid-column-gap: var(--measure-1x);

  background-color: white;
  border: 0.1rem solid $global-border;
  border-radius: 0.4rem;
  margin-bottom: var(--measure-2x);
  padding: var(--measure-2x) var(--measure-2x);
  @media (min-width: $screen-iphone-x) {
    grid-template-areas:
      "icon title price"
      "desc desc desc"
      "controls controls controls";
  }

  &.last-item-bottom-margin {
    margin-bottom: $feature-margin-last-item;

    @media (min-width: 530px) {
      margin-bottom: $col-1-height + $feature-margin-last-item !important;
    }
    @media (min-width: 768px) {
      margin-bottom: $col-2-height !important;
    }

    @media (min-width: 1003px) {
      margin-bottom: $col-3-height !important;
    }

    @media (min-width: 1450px) {
      margin-bottom: $col-4-height !important;
    }
  }

  &__top-section {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__bottom-section {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  &__list {
    > .feature:last-child {
      margin-bottom: 0;
    }
  }

  /*&__icon-and-main {
        display: flex;

    }*/

  &__icon {
    grid-area: icon;
    align-self: center;

    > i {
      font-size: var(--measure-font-32);
      color: var(--color-primary-400);
      @media (min-width: $screen-sm) {
        font-size: var(--measure-font-36);
      }
    }

    &--extra-controls {
      @media (min-width: $screen-md) {
        grid-column: icon-start / icon-end;
        grid-row: top-row-start / top-row-end;
      }
    }
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: var(--measure-1x);
  }

  &__rt-side {
    grid-area: price;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &--bottom {
      grid-column: price-start / price-end;
      grid-row: bottom-row-start / bottom-row-end;
      justify-self: end;
    }

    &--extra-controls {
      @media (min-width: $screen-md) {
        // grid-row: top-row-start / top-row-end;
      }
    }
  }

  &__title {
    grid-area: title;
    font-size: var(--measure-font-14);
    font-weight: var(--font-weight-semibold);
    align-self: center;
    //display: flex;
    //flex-direction: row;
    // justify-content: flex-start;
    //word-break: break-all;
    @media (min-width: $screen-sm) {
      font-size: var(--measure-font-16);
    }
  }

  &__short-description {
    grid-area: desc;
    font-size: var(--measure-font-14);
    @media (min-width: $screen-sm) {
    }
    @media (min-width: $screen-md) {
    }
    @media (min-width: $screen-lg) {
    }

    &--extra-controls {
      // grid-column: icon-start / price-start;
      @media (min-width: $screen-sm) {
        //   grid-column: icon-start /title-end;
        // align-self: start;
      }
      @media (min-width: $screen-md) {
        // grid-column: title-start /title-end;
        // align-self: start;
      }
    }
  }

  &__price {
    font-size: var(--measure-font-14);
    margin-right: var(--measure-2x);
    color: var(--color-primary-600);
  }

  &__selection-checkbox {
  }

  &__option-area {
  }

  &__checkbox {
    transform-origin: center;
    transform: scale(1.5);
    margin-right: 0;
  }

  &__read-only-data {
    //padding: var(--measure-2x);
    //border-top: .1rem solid #eee;
    grid-area: controls;
    @media (min-width: $screen-md) {
    }
  }

  &__edit-link {
    position: relative;
    //top: calc(var(--measure-half) * -1) ;
    right: calc(var(--measure-half) * -1);
    font-size: var(--measure-font-12);
    font-weight: var(--font-weight-semibold);
  }
}

.pre-programmed:after {
  content: " (Pre-Program)";
  color: $dmp-blue;
}

label.tiny-label {
  font-size: var(--measure-font-10);
  font-weight: var(--font-weight-thin);
  line-height: 1;
}

//sensor list modal
.sensor-list {
  &__item {
    label {
      font-size: var(--measure-font-14);
    }
  }
}
