.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.icon {
  flex: 0 0 45px;
}

.description {
  flex: 1 0 auto;
  max-width: calc(100% - 50px);
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.4em;
  align-items: center;
}

.action {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1 0 auto;
  padding-left: 1em;
}

.checkbox {
  min-height: 27px;
  height: 30px;
  width: 30px;
  border: 1px solid #ccc;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.checkbox-checked {
  border-color: #49b6d7;
  background-color: rgba(73, 182, 215, 0.2);
}

.checkmark {
  margin: 0;
  opacity: 0;
  color: #227d98;
}

.checkmark-checked {
  opacity: 1;
}

.section {
  padding: 14px 0 0 0;
}
