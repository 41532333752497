.#{$icon-css-prefix} {
  display: inline-block;
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class*=" icon-"] {
  &.icon {
    &_1x {
      font-size: 1.2rem;
    }

    &_3x {
      font-size: 1.4rem;
    }

    &_4x {
      font-size: 1.8rem;
    }
  }
}
