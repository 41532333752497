/* Wobble Vertical */
@include keyframes(#{$nameSpace}-wobble-vertical) {
  16.65% {
    @include prefixed(transform, translateY(8px));
  }

  33.3% {
    @include prefixed(transform, translateY(-6px));
  }

  49.95% {
    @include prefixed(transform, translateY(4px));
  }

  66.6% {
    @include prefixed(transform, translateY(-2px));
  }

  83.25% {
    @include prefixed(transform, translateY(1px));
  }

  100% {
    @include prefixed(transform, translateY(0));
  }
}

@mixin wobble-vertical {
  @include hacks();

  &:hover,
  &:focus,
  &:active {
    @include prefixed(animation-name, #{$nameSpace}-wobble-vertical);
    @include prefixed(animation-duration, 1s);
    @include prefixed(animation-timing-function, ease-in-out);
    @include prefixed(animation-iteration-count, 1);
  }
}
