$color-flex-item-hover: #eaeef2;

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  padding: var(--measure-1x);
  margin: -0.8rem;

  &__item {
    flex: 1 0 20rem;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000000;
    padding: var(--measure-1x);
    margin-left: var(--measure-1x);
    margin-top: var(--measure-1x);
    //background-color: #FAFBFC;
    //border:           1px solid #EEEEEE;
    font-weight: 600;

    //&:nth-child(odd) {
    //
    //
    //    }

    @media (min-width: 410px) {
      max-width: calc(50% - 8px);
    }
    @media (min-width: 620px) {
      max-width: calc(33.33333% - 8px);
    }
    @media (min-width: 830px) {
      max-width: calc(25% - 8px);
    }
    @media (min-width: 1040px) {
      max-width: calc(20% - 8px);
    }
    @media (min-width: 1250px) {
      max-width: calc(16.66667% - 8px);
    }
    @media (min-width: 1460px) {
      max-width: calc(14.28571% - 8px);
    }
    @media (min-width: 1670px) {
      min-width: calc(12.5% - 8px);
    }
    &:hover {
      //background-color: $color-flex-item-hover;
    }
  }

  &__area {
    padding: var(--measure-1x);
    margin-bottom: var(--measure-3x);
    &-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 2rem;
      margin-bottom: var(--measure-3x);

      > *:first-child {
        font-size: inherit;
        margin-right: var(--measure-1x);
      }
    }
  }
}
