/* Bubble Right */
@mixin bubble-right {
  @include hacks();
  position: relative;

  &:before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: "";
    border-style: solid;
    @include prefixed(transition-duration, $mediumDuration);
    @include prefixed(transition-property, transform);
    top: calc(50% - #{$tipHeight});
    right: 0;
    border-width: $tipHeight 0 $tipHeight $tipWidth;
    border-color: transparent transparent transparent $tipColor;
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    @include prefixed(transform, translateX($tipWidth));
  }
}
