.feedback-notifier {
  //padding: 1px 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  //border-radius: 3rem;
  font-size: 1.4rem;
  //border: .1rem solid;
  //width: auto;
  &--positive {
    color: $alert-success-bg;
    // border-color: $alert-success-bg;
  }
  &--negative {
    color: $alert-danger-bg;
    // border-color: $alert-danger-bg;
  }
  &__icon {
    position: relative;
    margin-right: 0.4rem;
    font-family: "dmp_icon_font";
    font-size: 1.6rem;

    &--positive:before {
      font-size: inherit;
      color: $alert-success-bg;
      content: "\e952";
    }

    &--negative:before {
      font-size: inherit;
      color: $alert-danger-bg;
      content: "\e95c";
    }
  }

  &__text {
    font-size: inherit;
    font-weight: 500;
    line-height: 0;
  }
}
