@mixin animation-wobble-fix() {
  -webkit-filter: blur(0);
}
/*
* ng-show/hide animation
*/
.fade-show-hide {
  transition: all linear 0.3s;
  opacity: 1;
  -webkit-transform: translateX(0);
}

.fade-show-hide.ng-hide {
  opacity: 0;
  -webkit-transform: translateY(0.4rem);
  transform: translateX(0.4rem);
}

/* ngIf animation */
.fade-animation-if.ng-enter,
.fade-animation-if.ng-leave {
  -webkit-transition: all ease-out 0.3s;
  -moz-transition: all ease-out 0.3s;
  -ms-transition: all ease-out 0.3s;
  -o-transition: all ease-out 0.3s;
  transition: all ease-out 0.3s;
}

.fade-animation-if.ng-enter,
.fade-animation-if.ng-leave.ng-leave-active {
  opacity: 0;
  -webkit-transform: translate3d(-2rem, 0, -2rem);
  -moz-transform: translate3d(-2rem, 0, -2rem);
  -ms-transform: translate3d(-2rem, 0, -2rem);
  -o-transform: translate3d(-2rem, 0, -2rem);
  transform: translate3d(-2rem, 0, -2rem);
}

.fade-animation-if.ng-leave,
.fade-animation-if.ng-enter.ng-enter-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* ngIf animation */
.drop-in-and-expand-ngif.ng-enter,
.drop-in-and-expand-ngif.ng-leave {
  -webkit-transition: all ease-out 0.3s;
  -moz-transition: all ease-out 0.3s;
  -ms-transition: all ease-out 0.3s;
  -o-transition: all ease-out 0.3s;
  transition: all ease-out 0.3s;
  transform-origin: top;
}

.drop-in-and-expand-ngif.ng-enter,
.drop-in-and-expand-ngif.ng-leave.ng-leave-active {
  opacity: 0;
  -webkit-transform: translate3d(0, -4rem, -2rem);
  -moz-transform: translate3d(0, -4rem, -2rem);
  -ms-transform: translate3d(0, -4rem, -2rem);
  -o-transform: translate3d(0, -4rem, -2rem);
  transform: translate3d(0, -4rem, -2rem);
  max-height: 0;
}

.drop-in-and-expand-ngif.ng-leave,
.drop-in-and-expand-ngif.ng-enter.ng-enter-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  max-height: 100%;
}

/* ngIf animation change-opacity-and-rotate*/
.change-opacity-and-rotate-ngif.ng-enter,
.change-opacity-and-rotate-ngif.ng-leave {
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  transform-origin: center;
}

.change-opacity-and-rotate-ngif.ng-enter,
.change-opacity-and-rotate-ngif.ng-leave.ng-leave-active {
  opacity: 0;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.change-opacity-and-rotate-ngif.ng-leave,
.change-opacity-and-rotate-ngif.ng-enter.ng-enter-active {
  opacity: 1;
  -webkit-transform: rotate(720deg) scale(0.5);
  -moz-transform: rotate(720deg) scale(0.5);
  -ms-transform: rotate(720deg) scale(0.5);
  -o-transform: rotate(720deg) scale(0.5);
  transform: rotate(720deg) scale(0.5);
}

/* ngIf animation expand-down-and-right-ng-if*/

.expand-down-and-right-ng-if.ng-enter,
.expand-down-and-right-ng-if.ng-leave {
  -moz-transition: all 5s forwards;
  -ms-transition: all 5s forwards;
  -o-transition: all 5s forwards;
  transition: all 5s forwards;
  transform-origin: top right;
}

.expand-down-and-right-ng-if.ng-enter,
.expand-down-and-right-ng-if.ng-leave {
  -webkit-transition: all 5s forwards;
}

.expand-down-and-right-ng-if.ng-enter,
.expand-down-and-right-ng-if.ng-leave.ng-leave-active {
  opacity: 1;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.expand-down-and-right-ng-if.ng-leave,
.expand-down-and-right-ng-if.ng-enter.ng-enter-active {
  opacity: 1;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
}

/* ngIf animation change-opacity-and-flip*/
.change-opacity-and-flip-ngif.ng-enter {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  transform-origin: bottom;
  -webkit-transform: scaleY(0.1);
  -moz-transform: scaleY(0.1);
  -ms-transform: scaleY(0.1);
  -o-transform: scaleY(0.1);
  transform: scaleY(0.1);
}
.change-opacity-and-flip-ngif.ng-leave {
  opacity: 0;
}

.change-opacity-and-flip-ngif.ng-enter,
.change-opacity-and-flip-ngif.ng-leave.ng-leave-active {
  opacity: 0;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.change-opacity-and-flip-ngif.ng-enter.ng-enter-active {
  opacity: 1;
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
}

.change-opacity-and-flip-ngif.ng-leave {
  opacity: 0;
}

@keyframes justfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Icon Hang */
@-webkit-keyframes hvr-icon-hang {
  0% {
    -webkit-transform: translateY(0.4rem);
    transform: translateY(0.4rem);
  }
  50% {
    -webkit-transform: translateY(0.1rem);
    transform: translateY(0.1rem);
  }
  100% {
    -webkit-transform: translateY(0.4rem);
    transform: translateY(0.4rem);
  }
}

@keyframes hvr-icon-hang {
  0% {
    -webkit-transform: translateY(0.4rem);
    transform: translateY(0.4rem);
  }
  50% {
    -webkit-transform: translateY(0.1rem);
    transform: translateY(0.1rem);
  }
  100% {
    -webkit-transform: translateY(0.4rem);
    transform: translateY(0.4rem);
  }
}

@-webkit-keyframes hvr-icon-hang-sink {
  100% {
    -webkit-transform: translateY(0.4rem);
    transform: translateY(0.4rem);
  }
}

@keyframes hvr-icon-hang-sink {
  100% {
    -webkit-transform: translateY(0.4rem);
    transform: translateY(0.4rem);
  }
}

.slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;

  animation-duration: 1s;
  -webkit-animation-duration: 1s;

  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;

  visibility: visible !important;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  25% {
    transform: translateY(8%);
  }
  50% {
    transform: translateY(0%);
  }
  60% {
    transform: translateY(4%);
  }
  70% {
    transform: translateY(-4%);
  }
  80% {
    transform: translateY(4%);
  }
  95% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  25% {
    transform: translateY(8%);
  }
  50% {
    transform: translateY(0%);
  }
  60% {
    transform: translateY(4%);
  }
  70% {
    transform: translateY(-4%);
  }
  80% {
    transform: translateY(4%);
  }
  95% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateY(0%);
  }
}

//icon - spin animation

.icon__animation {
  &--spin {
    &-forward {
      @include animation-wobble-fix();
      -webkit-animation: icon-spin-fw 2s infinite linear;
      animation: icon-spin-fw 2s infinite linear;
    }

    &-reverse {
      @include animation-wobble-fix();
      -webkit-animation: icon-spin-rv 2s infinite linear;
      animation: icon-spin-rv 2s infinite linear;
    }
  }
}

@-webkit-keyframes icon-spin-fw {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin-fw {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes icon-spin-rv {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

@keyframes icon-spin-rv {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}

.animate-list-if.ng-enter,
.animate-list-if.ng-leave {
  transition: all 0.6s ease-in-out;
}

.animate-list-if.ng-enter,
.animate-list-if.ng-leave.ng-leave-active {
  opacity: 0;
  max-height: 0;

  -webkit-transform: translate3d(0, -1rem, 0);
  transform: translate3d(0, -1rem, 0);
}

.animate-list-if.ng-leave,
.animate-list-if.ng-enter.ng-enter-active {
  opacity: 1;
  max-height: 70rem;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/**/
.animate-div-if.ng-enter,
.animate-div-if.ng-leave {
}

.animate-div-if.ng-enter,
.animate-div-if.ng-leave.ng-leave-active {
  transform-origin: top;
  transition: all 300ms cubic-bezier(0.23, -0.32, 0.51, -0.18);
  opacity: 0;
  max-height: 0;
  -webkit-transform: translate3d(0, -1rem, 0) scaleY(0.1);
  transform: translate3d(0, -1rem, 0) scaleY(0);
}

.animate-div-if.ng-leave,
.animate-div-if.ng-enter.ng-enter-active {
  transform-origin: top;
  transition: all 200ms cubic-bezier(0.45, 1.2, 1, 1.12);
  opacity: 1;
  max-height: 70rem;
  -webkit-transform: translate3d(0, 0, 0) scaleY(1);
  transform: translate3d(0, 0, 0) scaleY(1);
}

/**/

/* ngIf animation */
.animationSizeIf.ng-enter,
.animationSizeIf.ng-leave {
  -webkit-transition: opacity cubic-bezier(0.43, 0.94, 0.76, 1.17) 1s;
  -moz-transition: opacity cubic-bezier(0.43, 0.94, 0.76, 1.17) 1s;
  -ms-transition: opacity cubic-bezier(0.43, 0.94, 0.76, 1.17) 1s;
  -o-transition: opacity cubic-bezier(0.43, 0.94, 0.76, 1.17) 1s;
  transition: opacity cubic-bezier(0.43, 0.94, 0.76, 1.17) 1s;
}

.animationSizeIf.ng-enter,
.animationSizeIf.ng-leave.ng-leave-active {
  height: 0;
}

.animationSizeIf.ng-leave,
.animationSizeIf.ng-enter.ng-enter-active {
  height: 100%;
}

@keyframes justFadeInAndMove {
  0% {
    transform: translateY(-40px);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.bounceTop.ng-hide-remove {
  -webkit-animation: bounce-top 0.9s both;
  animation: bounce-top 0.9s both;
}

.bounceTop.ng-hide-add {
  -webkit-animation: slide-out-top 0.4s both;
  animation: slide-out-top 0.4s both;
}

/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-4rem);
    transform: translateY(-4rem);
    opacity: 0;
  }
}

@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-4rem);
    transform: translateY(-4rem);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition-property(height, visibility);
  @include transition-duration(0.35s);
  @include transition-timing-function(ease);
}

.accordion-body-animation.ng-enter,
.accordion-body-animation.ng-leave {
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  transform-origin: top center;
}

.accordion-body-animation.ng-enter {
  opacity: 0;
  transform: scaleY(0);
}

.accordion-body-animation.ng-leave,
.accordion-body-animation.ng-enter.ng-enter-active {
  opacity: 1;
  transform: scaleY(1);
}

pulse {
  margin: 100px;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #cca92c;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

@keyframes pulse {
  0%,
  50% {
    transform: none;
    opacity: 1;
  }
  25%,
  100% {
    -webkit-transform: scale(3);
    -moz-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(95, 195, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(95, 195, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(95, 195, 255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(95, 195, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(95, 195, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(95, 195, 255, 0);
    box-shadow: 0 0 0 10px rgba(95, 195, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(95, 195, 255, 0);
    box-shadow: 0 0 0 0 rgba(95, 195, 255, 0);
  }
}

.animate-my-height-if.ng-enter,
.animate-my-height-if.ng-leave {
  transition: all 1s;
}

.animate-my-height-if.ng-enter,
.animate-my-height-if.ng-leave.ng-leave-active {
  height: 0;
}

.animate-my-height-if.ng-leave,
.animate-my-height-if.ng-enter.ng-enter-active {
  height: auto;
}
.animate-div-height-if {
  transition: all 0.4s cubic-bezier(0.52, 0.7, 1, 1.34);
}

.animate-div-height-if.ng-enter,
.animate-div-height-if.ng-leave.ng-leave-active {
  transform-origin: top;

  opacity: 0;
  max-height: 0;
  -webkit-transform: translate3d(0, 0, 0) scaleY(0.1);
  transform: translate3d(0, 0, 0) scaleY(0);
}

.animate-div-height-if.ng-leave,
.animate-div-height-if.ng-enter.ng-enter-active {
  transform-origin: top;

  opacity: 1;
  max-height: 70rem;
  -webkit-transform: translate3d(0, 0, 0) scaleY(1);
  transform: translate3d(0, 0, 0) scaleY(1);
}

.table-col-animate-width-ng-if {
  background: transparent;
}

.table-col-animate-width-ng-if.ng-enter,
.table-col-animate-width-ng-if.ng-leave {
  transform-origin: left;
  transition: all 0.5s 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.table-col-animate-width-ng-if.ng-enter,
.table-col-animate-width-ng-if.ng-leave.ng-leave-active {
  width: 0;
}

.table-col-animate-width-ng-if.ng-leave,
.table-col-animate-width-ng-if.ng-enter.ng-enter-active {
  width: 120px;
}

.animate-show-hide-form-element.ng-hide {
  opacity: 0;
  transform: translateY(-1rem);
}

.animate-form-element-ngif.ng-enter,
.animate-form-element-ngif.ng-leave {
  transition: all ease-out 0.5s;
}

.animate-form-element-ngif.ng-enter,
.animate-form-element-ngif.ng-leave.ng-leave-active {
  opacity: 0;
  transform: translateY(-1rem);
}

.animate-form-element-ngif.ng-leave,
.animate-form-element-ngif.ng-enter.ng-enter-active {
  opacity: 1;
  transform: none;
}

.input-field-button-show-animate.ng-hide-add {
}
.input-field-button-show-animate.ng-hide-add.ng-hide-add-active {
}

.input-field-button-show-animate.ng-hide-remove {
  opacity: 0;
  transform: rotateZ(1080deg) scale(0.5);
  transform-origin: center;
  transition: all 0.5s ease;
}

.input-field-button-show-animate.ng-hide-remove.ng-hide-remove-active {
  transform: rotateZ(0);
  opacity: 1;
}

.slide-in-from-below-if.ng-enter,
.slide-in-from-below-if.ng-leave {
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.slide-in-from-below-if.ng-enter,
.slide-in-from-below-if.ng-leave.ng-leave-active {
  opacity: 0;
  transform: translateY(5rem);
}

.slide-in-from-below-if.ng-leave,
.slide-in-from-below-if.ng-enter.ng-enter-active {
  opacity: 1;
  transform: none;
}

.swivel-in-if.ng-enter,
.swivel-in-if.ng-leave {
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

//--------------------
.slide-in-from-right-side-if.ng-enter,
.slide-in-from-right-side-if.ng-leave {
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.slide-in-from-right-side-if.ng-enter,
.slide-in-from-right-side-if.ng-leave.ng-leave-active {
  opacity: 0;
  transform: translateX(5rem) rotate(360deg);
}

.slide-in-from-right-side-if.ng-leave,
.slide-in-from-right-side-if.ng-enter.ng-enter-active {
  opacity: 1;
  transform: none;
}

.swivel-in-if.ng-enter,
.swivel-in-if.ng-leave {
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.swivel-in-if.ng-enter,
.swivel-in-if.ng-leave.ng-leave-active {
  -webkit-transform: rotateX(80deg);
  transform: rotateX(80deg);
  opacity: 0;
}

.swivel-in-if.ng-leave,
.swivel-in-if.ng-enter.ng-enter-active {
  opacity: 1;
  transform: none;
}

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

.disable-animations {
  &.ng-enter,
  &.ng-leave,
  &.ng-animate {
    -webkit-transition: none !important;
    transition: none !important;
  }

  &.ng-animate {
    -webkit-animation: none 0s;
    animation: none 0s;
  }
}
