.test-connection-modal {
  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-title {
    font-size: rem(20px);
  }

  &.modal-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: rem(144px);
  }

  &__status {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    position: relative;

    &__icon-wrapper {
      width: 64px;
      height: 64px;
      margin-bottom: 18px;
    }
    &__message-wrapper {
      position: relative;
      font-size: rem(24px);
      text-align: center;
      width: 100vw;
    }
  }
  > .icon-window {
    height: 64px;
    width: 64px;
    overflow: hidden;
    position: relative;
  }
  &__status-icon {
    width: 64px;
    display: inline-block;
    margin-bottom: rem(16px);
    position: absolute;
  }

  &__status-message {
    position: absolute;
    font-size: rem(24px);
    text-align: center;
    width: 100vw;
  }
  &__status-message {
    position: absolute;
    font-size: rem(24px);
    text-align: center;
    width: 100vw;
  }
}

@keyframes flipX-appear {
  0% {
    opacity: 0;
    transform-origin: right center;
    transform: scale(0, 0) rotateX(180deg);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

/**
 * ----------------------------------------
 * ANIMATIONS FOR NG_IF SVG'S
 * ----------------------------------------
 */

.status-msg-animate-busy.ng-leave {
  animation: rotateMeXNgLeave 800ms $easeOutCubic;
}

.status-msg-animate-positive.ng-enter {
  animation: rotateMeXNgEnter 800ms $easeOutCubic;
}

.status-msg-animate-negative.ng-enter {
  animation: rotateMeXNgEnter 800ms $easeOutCubic;
}

@keyframes rotateMeXNgEnter {
  from {
    transform: rotateX(-90deg);
  }
  80% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  to {
    transform: rotateX(0);
  }
}

@keyframes rotateMeXNgLeave {
  from {
    opacity: 0.2;
    transform: rotateX(70deg);
  }
  50% {
  }
  to {
    opacity: 0;
    transform: rotateX(90deg);
  }
}

/**
*
* ngIf Animation bounceFromTop-ngif
***************************************************/
.bounceFromTop-ngif.ng-enter {
  animation: 1s bounceInDown;
}

.bounceFromTop-ngif.ng-leave {
  animation: 0.4s bounceOutUp;
}

/**
*
* ngIf Animation bounceFromBottom-ngif
***************************************************/

.bounceFromBottom-ngif.ng-enter {
  animation: 1s bounceInUp;
}

.bounceFromBottom-ngif.ng-leave {
  animation: 0.4s bounceOutDown;
}

/**
*
* ngIf Animation flipX-ngif
***************************************************/
.flipX-ngif.ng-enter {
  opacity: 0;
  -webkit-animation: flipX 2s cubic-bezier(0.2, 0.3, 0.25, 0.9) forwards;
  animation: flipX 2s cubic-bezier(0.2, 0.3, 0.25, 0.9) forwards;
}

.flipX-ngif.ng-leave {
  opacity: 1;
  -webkit-animation: flipX 2s cubic-bezier(0.2, 0.3, 0.25, 0.9) forwards;
  animation: flipX 2s cubic-bezier(0.2, 0.3, 0.25, 0.9) forwards;
}

/**
*
* ngIf Animation fade-ngif
***************************************************/
.fade-ngif.ng-enter,
.fade-ngif.ng-enter-active {
  opacity: 1;
}

.fade-ngif.ng-leave,
.fade-ngif.ng-leave-active {
  opacity: 0;
}

/**
*
* Animation bounceInDown
***************************************************/
@-webkit-keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -4rem, 0);
    transform: translate3d(0, -4rem, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 2.5rem, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -1rem, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 0.5rem, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -4rem, 0);
    transform: translate3d(0, -4rem, 0) rotateX(90deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 2.5rem, 0);
    transform: translate3d(0, 2.5rem, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -1rem, 0);
    transform: translate3d(0, -1rem, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 0.5rem, 0);
    transform: translate3d(0, 0.5rem, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0) rotateX(90deg);
  }
}

/**
*
* Animation bounceOutUp
***************************************************/

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -1rem, 0) scale(1);
    transform: translate3d(0, -1rem, 0) scale(1);
  }

  40%,
  45% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2rem, 0) scale3d(0);
    transform: translate3d(0, 2rem, 0) scale3d(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2rem, 0) rotateX(90deg) scale(0);
    transform: translate3d(0, -2rem, 0) rotateX(90deg) scale(0);
  }
}

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -1rem, 0) scale(1);
    transform: translate3d(0, -1rem, 0) scale(1);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 2rem, 0);
    transform: translate3d(0, 2rem, 0) scale3d(0, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2rem, 0) rotateX(90deg) scale(0);
    transform: translate3d(0, -2rem, 0) rotateX(90deg) scale(0);
  }
}

/**
*
* Animation flipInX
***************************************************/
@-webkit-keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) translateZ(0) rotateX(0) scale(1);
    transform: perspective(400px) translateZ(0) rotateX(0) scale(1);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) translateZ(0) rotateX(180deg) scale(1);
    transform: perspective(400px) translateZ(0) rotateX(90deg) scale(1);
  }
}

@keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) translateZ(0) rotateX(0) scale(1);
    transform: perspective(400px) translateZ(0) rotateX(0) scale(1);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) translateZ(0) rotateX(180deg) scale(1);
    transform: perspective(400px) translateZ(0) rotateX(90deg) scale(1);
  }
}

/**
*
* Animation flipOutX
***************************************************/
@-webkit-keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) translateZ(0) rotateX(0) scale(1);
    transform: perspective(400px) translateZ(0) rotateX(0) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) translateZ(0) rotateX(180deg) scale(1);
    transform: perspective(400px) translateZ(0) rotateX(90deg) scale(1);
  }
}

@keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) translateZ(0) rotateX(0) scale(1);
    transform: perspective(400px) translateZ(0) rotateX(0) scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) translateZ(0) rotateX(180deg) scale(1);
    transform: perspective(400px) translateZ(0) rotateX(90deg) scale(1);
  }
}

.flipX {
  opacity: 0;
  -webkit-animation: flipX 2s cubic-bezier(0.2, 0.3, 0.25, 0.9) forwards;
  animation: flipX 2s cubic-bezier(0.2, 0.3, 0.25, 0.9) forwards;
}

/**
*
* Animation bounceInUp
***************************************************/

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 4rem, 0) scale(0);
    transform: translate3d(0, 4rem, 0) scale(0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -2rem, 0);
    transform: translate3d(0, -2rem, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 1rem, 0);
    transform: translate3d(0, 1rem, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -0.5rem, 0);
    transform: translate3d(0, -0.5rem, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 4rem, 0) scale(0);
    transform: translate3d(0, 4rem, 0) scale(0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -2rem, 0);
    transform: translate3d(0, -2rem, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 1rem, 0);
    transform: translate3d(0, 1rem, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -0.5rem, 0);
    transform: translate3d(0, -0.5rem, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
}

/**
*
* Animation bounceOutDown
***************************************************/

@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  20% {
    -webkit-transform: translate3d(0, 1rem, 0);
    transform: translate3d(0, 1rem, 0);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -2rem, 0);
    transform: translate3d(0, -2rem, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2rem, 0) scale(0);
    transform: translate3d(0, 2rem, 0) scale(0);
  }
}

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 1rem, 0);
    transform: translate3d(0, 1rem, 0);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -2rem, 0);
    transform: translate3d(0, -2rem, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2rem, 0) scale(0);
    transform: translate3d(0, 2rem, 0) scale(0);
  }
}
