$shield-width: 6.5rem;
$shield-height: 0.8rem;
$border-width: 0.4rem;

.dmp-spinner {
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 999999999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: HSLA(198, 0%, 40%, 0.5);
  font-size: 1.8rem;
  color: $gray-dark;
  font-weight: var(--font-weight-semibold);
  //opacity: 0;
  //animation: fade-in-loader .1s ease-in-out forwards;

  &__icon {
    z-index: 99999999999999999;
    width: 32px;
    height: 32px;
    opacity: 1;
  }
}

@keyframes fade-in-loader {
  100% {
    transform: none;
    opacity: 1;
  }
}
