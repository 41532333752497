.panel {
  width: 100%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
}

.panel + .panel {
  margin-top: var(--measure-3x);
}

.panel-header {
  padding: var(--measure-20);
  width: 100%;
}

.panel-content {
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  padding: var(--measure-20);
}
.panel-footer {
  width: 100%;
  background-color: white;
}
