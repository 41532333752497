.billing-charges-grid,
.autopay-grid,
.form-group-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, minmax(0, min-content));

  &__metric {
    padding: var(--measure-1x) 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-size: var(--measure-font-12);
      line-height: 1.2;
      margin-right: var(--measure-3x);
    }

    &__data {
      font-size: var(--measure-font-16);
      font-weight: var(--font-weight-medium);
    }
  }
}

.autopay-grid,
.form-group-grid {
  grid-template-columns: max-content 1fr;
  grid-template-rows: repeat(2, minmax(0, min-content));
  justify-items: start;
  align-items: center;
}

.billing-charges-modal {
  &__info {
    font-size: var(--measure-font-14);
    font-weight: var(--font-weight-medium);
  }
}
.autopay-grid,
.form-group-grid {
  grid-row-gap: var(--measure-2x);
}
