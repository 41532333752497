.accordion {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  position: relative;
}

.accordion + .accordion {
  margin-top: var(--measure-3x);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
}

.header-content-container {
  flex: 1 0 auto;
  overflow: hidden;
  max-width: calc(100% - 50px);
}

.toggle {
  padding: 0 var(--measure-2x);
  border: 0;
  border-radius: 0;
  background: transparent;
  color: #3d85a3;
  margin: 0;
  line-height: 1;
  cursor: pointer;
  align-self: stretch;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--measure-font-14);
  flex: 1;
  min-width: 0;
}

.toggleable {
  display: block;
  width: 100%;
  padding: var(--measure-12);
  background-color: transparent;
  border: 0;
  margin: 0;
  border-radius: 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
