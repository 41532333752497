@mixin round-button-dimensions {
  min-width: 5rem;
  max-width: 10rem;
  min-height: 5rem;
  max-height: 10rem;
}
@mixin checkbox-shadow($switch: "not-none") {
  @if $switch == "none" {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  } @else {
    -webkit-box-shadow: inset 0px 2px 1px 0px hsla(198, 45%, 84%, 0.3);
    -moz-box-shadow: inset 0px 2px 1px 0px hsla(198, 45%, 84%, 0.3);
    box-shadow: inset 0px 2px 1px 0px hsla(198, 45%, 84%, 0.3);
  }
}
.button-checkbox {
  &__flex-grid {
    display: flex;
    width: 100%;

    &--row {
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
    }

    &--column {
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;

      overflow: hidden;
    }
  }

  &__round-button {
    @include round-button-dimensions;
    text-decoration: none;
    display: inline-block;
    position: relative;
    cursor: pointer;
    color: var(--color-neutral-600);
    background-color: var(--color-neutral-100);
    border: 0.1rem solid var(--color-neutral-500);
    border-radius: 100%;
    overflow: hidden;
    text-align: center;
    padding: 0;
    margin-right: var(--measure-2x);
    transition: all 0.2s ease-in-out;
    @include checkbox-shadow();
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 3px var(--color-primary-200);
    }

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      padding-top: 100%;
      margin-left: -0.2rem;
    }

    &.is-selected {
      background-color: var(--color-primary);
      border: 0.1rem solid var(--color-primary);
      color: white;
      @include checkbox-shadow(none);
    }

    > span {
      display: inline-block;
      vertical-align: middle;
      max-width: 90%;
      font-size: var(--measure-font-16);
    }

    .visually-hidden {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      height: 40px;
      width: 40px;
      opacity: 0;
    }
  }

  &__rectangle-button {
    min-width: 100%;

    height: 40px;
    max-height: 100px;
    text-decoration: none;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
    outline: none;
    cursor: pointer;
    color: var(--color-neutral-600);
    background-color: var(--color-neutral-100);
    border: 0.1rem solid var(--color-neutral-500);
    border-radius: 0.4rem;
    overflow: hidden;
    text-align: center;
    padding: 0;
    margin-right: var(--measure-2x);
    @include checkbox-shadow();
    transition: all 0.2s ease-in-out;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 3px var(--color-primary-200);
    }

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      padding-top: 100%;
    }

    &.is-selected {
      background-color: var(--color-primary);
      border: 0.1rem solid var(--color-primary);
      color: white;
      @include checkbox-shadow(none);
    }

    > span {
      display: inline-block;
      vertical-align: middle;
      max-width: 90%;
      font-size: var(--measure-font-16);
    }

    .visually-hidden {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      height: 40px;
      width: 40px;
    }
  }

  label.button-checkbox__rectangle-button {
    display: flex;
  }
}
