// allows justified tabsets to have a bottom border in the mobile view
@media screen and (max-width: $mq-up-to-tablet) {
  .nav-tabs.nav-justified > .active > a,
  .nav-tabs.nav-justified > .active > a:hover,
  .nav-tabs.nav-justified > .active > a:focus {
    border-bottom: 1px solid $dmp-layout-border-darker;
  }
  .tab-content {
    border-top-width: 1px;
    border-radius: 12px;
    border-top-color: $bleached-white;
  }
}

.tab-content {
  background: $bleached-white;
  height: max-content;
}

.tab-content-wrapper {
  height: 100%;
}

.nav-tabs > li > a {
  font-weight: bold;
  color: $text-color;
  //background-color: $nav-tabs-inactive;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 0;
  padding: 10px 20px;
}

/* line 86, /Users/kweigand/workspace/virtuallink/prep/sass/angle-template/_bootstrap-reset.scss */
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: black;

  border-bottom: 1px solid #fff !important;
}

@media screen and (min-width: $mq-tablet) {
  .nav-tabs li:first-child {
    padding-left: 0;
  }
}

@media screen and (max-width: $mq-mobile) {
  .nav-tabs li:first-child {
    margin-left: 10px;
  }
}
