/* Rotate */
@mixin hvr-rotate {
  @include hacks();
  @include prefixed(transition-duration, $mediumDuration);
  @include prefixed(transition-property, transform);

  &:hover,
  &:focus,
  &:active {
    @include prefixed(transform, rotate(4deg));
  }
}
