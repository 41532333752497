svg [id^="btn-"] {
  fill: rgba(102, 134, 169, 0);
  cursor: pointer;
}

svg [id^="btn-"] {
  transition: fill 0.4s ease;
}

svg [id^="btn-"]:hover,
svg [id^="btn-"].active {
  fill: rgba(102, 134, 169, 0.8);
}

svg .selected {
  animation: mymove 0.3s;
  fill: #ffbb33;
}

@-webkit-keyframes mymove {
  0% {
    fill: #dedede;
  }
  100% {
    fill: #ffbb33;
  }
}

svg.keyfob {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.keyfob-svg-wrapper {
  margin: 0 35%;
  display: inline-block;
  position: relative;
  width: 150px;
  padding-bottom: 17em;
  text-align: center;
  vertical-align: top;
}

@media screen and (min-width: 768px) {
  svg.keyfob {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
  }
  .keyfob-svg-wrapper {
    margin: 0 2.5%;
    display: inline-block;
    position: relative;
    width: 95%;
    max-width: 180px;
    padding-bottom: 21em;

    vertical-align: bottom;
  }
}

.svg-btn-img {
  width: 1.5em;
  height: 1.5em;
  fill: $text-global;

  &--subheader {
    width: 1.5rem;
    height: 1.5rem;
    fill: $text-global;
  }

  &--table {
    width: 1.4rem;
    height: 1.4rem;
    fill: var(--color-state-success);
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
    transform: scale(1.4);
  }
}

a {
  &.dropdown-select__link {
    .svg-icon {
      display: inline-block;
      height: 2.4rem;
      width: 2.4rem;
      background-image: url("/assets/img/move_system--gray.svg");
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 16px;
    }

    &:hover {
      .svg-icon {
        display: inline-block;
        height: 2.4rem;
        width: 2.4rem;
        background-image: url("/assets/img/move_system--white.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
}
