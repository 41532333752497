.sub-header {
  &--tabset {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: var(--measure-2x);
    border-bottom: 1px solid rgba($text-color, 0.2);
    @media (min-width: $screen-sm) {
      justify-content: flex-start;
      > * {
        //margin-right: var(--measure-2x);
      }
    }
    &__tab {
      font-size: 1.8rem;
      line-height: 1.5;
      padding: var(--measure-1x) var(--measure-2x);
      -webkit-box-shadow: inset 0px 0px 0px 0px rgba(73, 182, 215, 0);
      -moz-box-shadow: inset 0px 0px 0px 0px rgba(73, 182, 215, 0);
      box-shadow: inset 0px 0px 0px 0px rgba(73, 182, 215, 0);
      transition: all 0.2s ease-out;

      &:first-of-type {
        padding-left: 0;
      }

      color: var(--color-state-default);
      &:link {
        text-decoration: none;
      }
      &:visited {
      }
      &:hover {
        text-decoration: none;
        -webkit-box-shadow: inset 0px -3px 0px 0px darken($state-primary, 10%);
        -moz-box-shadow: inset 0px -3px 0px 0px darken($state-primary, 10%);
        box-shadow: inset 0px -3px 0px 0px darken($state-primary, 10%);
        transform-origin: bottom center;
        -webkit-transform: scaleY(1.02);
        -moz-transform: scaleY(1.02);
        -ms-transform: scaleY(1.02);
        -o-transform: scaleY(1.02);
        transform: scaleY(1.02);
      }
      &.is-active {
        color: $state-primary;
        -webkit-box-shadow: inset 0px -3px 0px 0px rgba(73, 182, 215, 1);
        -moz-box-shadow: inset 0px -3px 0px 0px rgba(73, 182, 215, 1);
        box-shadow: inset 0px -3px 0px 0px rgba(73, 182, 215, 1);
      }
    }
  }
}
