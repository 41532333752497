.table-pic-laucher {
  width: 12.8rem;
  height: 6.4rem;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.17, 0.67, 0.47, 1.49);
  &:hover {
    -webkit-transform: scale(1.04);
    -moz-transform: scale(1.04);
    -ms-transform: scale(1.04);
    -o-transform: scale(1.04);
    transform: scale(1.04);
    transform-origin: center;

    box-shadow: 2px 2px 20px -10px rgba(0, 0, 0, 0.4),
      0px 4px 5px 0px rgba(0, 0, 0, 0.6);
  }

  &__image {
    width: 100%;
    height: auto;
    max-height: 165px;
    //margin:           8px;
    box-shadow: 2px 2px 45px -10px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    object-fit: cover;
    background-color: #404040;
  }
}
