$avatar-size: rem(50px);
$avatar-bg-size: $avatar-size + rem(10px);

#markerLayer {
  img:not([src="assets/img/pushpin.png"]):not([src="/assets/img/googlemaps-markers/dmp-map-marker.png"]):not([src="assets/img/pin-start.png"]):not([src="assets/img/pin-stop-flag.png"]) {
    width: $avatar-size !important;
    height: $avatar-size !important;
    display: block;
    border: 0.2rem solid white !important;
    border-radius: 50%;
    //margin: var(--measure-5x);
    background-color: white;
    object-fit: cover;
    padding: 0.1rem !important;
    //clip-path: circle(100% at 50% 50%);
    -webkit-box-shadow: 0px 8px 10px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 8px 10px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 8px 10px -4px rgba(0, 0, 0, 0.75);
    object-position: center top;
  }
}
.techIcon {
  width: 50px;
  height: 20px;

  border: 1px solid #eb3a44;
  border-radius: 5px;
  background: #fee1d7;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  font-size: 14px;
  color: #eb3a44;
}
