//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

$screen-iphone-x: 375px;
// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

/* ========================================================================
     Component: media-queries
 ========================================================================== */

// Taken from bootstrap variables
// to match all components media queries

$mq-desktop-lg: $screen-lg-desktop; // Usually 1200px
$mq-desktop: $screen-desktop; // Usually 992px
$mq-tablet: $screen-sm-min; // Usually 768px
$mq-mobile: $screen-phone; // Usually 480px

// Inverse version media queries (for use with max-width)
// Some components needs to be strictly defined only for mobile

$mq-up-to-desktop-lg: ($screen-lg-desktop - 1); // Usually 1199px
$mq-up-to-desktop: ($screen-desktop - 1); // Usually 991px
$mq-up-to-tablet: ($screen-sm-min - 1); // Usually 767px
$mq-up-to-mobile: ($screen-phone - 1); // Usually 479px

@mixin respond-to($media) {
  @if $media == up-to-mobile {
    @media only screen and (max-width: $mq-up-to-mobile) {
      @content;
    }
  } @else if $media == tablet-only {
    @media only screen and (min-width: $mq-tablet) and (max-width: $mq-up-to-desktop) {
      @content;
    }
  } @else if $media == tablet-and-up {
    @media only screen and (min-width: $mq-tablet) {
      @content;
    }
  } @else if $media == desktop-only {
    @media only screen and (min-width: $mq-desktop) and (max-width: $mq-up-to-desktop-lg) {
      @content;
    }
  } @else if $media == desktop-and-up {
    @media only screen and (min-width: $mq-desktop) {
      @content;
    }
  } @else if $media == up-to-wide {
    @media only screen and (max-width: $mq-up-to-desktop-lg) {
      @content;
    }
  } @else if $media == wide-desktop-only {
    @media only screen and (min-width: $mq-desktop-lg) {
      @content;
    }
  }
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: var(--measure-3x);
  padding-right: var(--measure-3x);
  @media (min-width: $screen-sm) {
    margin-right: auto;
    margin-left: auto;
    padding-left: var(--measure-2x);
    padding-right: var(--measure-2x);
  }
}
.content-wrapper {
  padding: 15px;
  width: 100%;
  margin-top: 0px;
}
.wrapper {
  &.container {
    &.container-mobile {
      padding: 15px 2px !important;
    }
  }
}
.layout-fixed .wrapper > section {
  margin-top: 75px;
}

.pad-horizontal-break-desktop-wide {
  @include respond-to(up-to-wide) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  @include respond-to(up-to-mobile) {
    padding-left: $padding-base !important;
    padding-right: $padding-base !important;
  }
}

.camera-settings-container {
  display: flex;
  flex-flow: row wrap;
  align-items: space evenly;

  .camera-settings-child{
    padding: 1.6rem 2.2rem;
    width: 28rem;
    display: flex;
    justify-content: center;
  }
}
