/* Bounce To Left */
@mixin bounce-to-left {
  @include hacks();
  position: relative;
  @include prefixed(transition-property, color);
  @include prefixed(transition-duration, $slowDuration);

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $activeColor;
    @include prefixed(transform, scaleX(0));
    @include prefixed(transform-origin, 100% 50%);
    @include prefixed(transition-property, transform);
    @include prefixed(transition-duration, $slowDuration);
    @include prefixed(transition-timing-function, ease-out);
  }

  &:hover,
  &:focus,
  &:active {
    color: white;

    &:before {
      @include prefixed(transform, scaleX(1));
      @include prefixed(
        transition-timing-function,
        cubic-bezier(0.52, 1.64, 0.37, 0.66)
      );
    }
  }
}
