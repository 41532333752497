.btn-vertical-layout {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: var(--measure-font-16);
  padding: var(--measure-3x);
  font-weight: var(--font-weight-medium);
  background-color: rgba($color-primary-200, 0.1) !important;
  width: auto;
  transition: all 1s;
  &--clear {
    background-color: white !important;
    padding: 0.8rem;
  }
  > i {
    font-size: 6.4rem;
  }

  &:hover {
    transform: scale(1.05);
    z-index: 2;
  }
}

.btn-flex-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
  > * {
    flex: 1;
  }
  > *:not(:last-child) {
    margin-right: 4px;
  }
}

.armed-status {
  color: #999999;
  font-size: rem(16px);
}

.animate-show-hide-btn-vertical-layout.ng-hide {
  width: 0;
}

.animate-show-hide-btn-vertical-layout.ng-hide-add,
.animate-show-hide-btn-vertical-layout.ng-hide-remove {
  transition: all linear 0.2s;
}

.animate-show-hide-btn-vertical-layout.ng-enter,
.animate-show-hide-btn-vertical-layout.ng-leave {
  transition: all;
}

.animate-show-hide-btn-vertical-layout.ng-enter,
.animate-show-hide-btn-vertical-layout.ng-leave.ng-leave-active {
  width: 0;
  opacity: 0;
}

.animate-show-hide-btn-vertical-layout.ng-leave,
.animate-show-hide-btn-vertical-layout.ng-enter.ng-enter-active {
  width: 100%;
  opacity: 1;
}
