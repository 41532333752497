/* ========================================================================
     Component: print
 ========================================================================== */

//
// Put here all rules required to style the print view
//

@media print {
  .sidebar,
  .topnavbar,
  .offsidebar,
  .btn {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }

  .wrapper,
  .wrapper > section,
  .content-wrapper {
    margin: 0 !important; /* remove margin used for sidebar and expand the content */
    padding: 0 !important;
    width: 100% !important;
  }

  .content-wrapper {
    overflow: hidden !important;
  }
}
