.third-party-device {
  //border-top:         1px solid $global-border;
  //border-bottom:      1px solid $global-border;
  text-decoration: none;
  color: $dmp-text-standard !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 8px;
  margin: 0 0 0 0;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -ms-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  &:hover {
    text-decoration: none;
  }
  &__control-area {
    align-self: center;
  }

  &__buttons {
    align-self: center;

    &--icon[class^="icon-"] {
      font-size: 1.4rem;
      position: relative;
      top: 2px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    &--name {
      font-size: var(--measure-font-16);
      font-weight: bold;
      color: $astral;
    }
    &__serial {
    }
  }
}

.panel-body {
  &.third-party-devices-panel-body {
    max-height: 20rem;
    overflow-y: scroll;

    &.editing-device {
      max-height: 30rem;
    }
  }
}

@keyframes expand-for-editing {
  from {
    max-height: 20rem;
  }
  to {
    max-height: 40rem;
  }
}
