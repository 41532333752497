.grid {
  width: 100%;
  display: flex;
  margin-top: var(--measure-4x);
  flex-direction: column;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sheetFull {
  width: 100%;
}

.section {
  margin-bottom: var(--measure-4x);
}

.buttonSheet {
  text-align: left;
}
