//sass for custom icon resizing

span[class*="icon-"].media-box-object.pull-left {
  font-size: 3em;
}

i[class*="fa-"].media-box-object.pull-left {
  font-size: 3em;
}

li i[class^="icon-"] {
  position: relative;
  bottom: -3px;
}

span[class*="icon-"].icon2x {
  font-size: 2em;
}

span[class*="icon-"].icon2-5x {
  font-size: 2.5em;
}

span[class*="icon-"].icon3x {
  font-size: 3em;
}

span[class*="icon-"].icon4x {
  font-size: 4em;
}

span[class*="icon-"].icon5x {
  font-size: 5em;
}

span[class*="icon-"].icon6x {
  font-size: 6em;
}

.fa-1_5x {
  font-size: 1.5em;
}

.icon2x {
  font-size: 2em;
}

.fa-stack-3em {
  position: relative;
  display: inline-block;
  width: 3em;
  height: 3em;
  line-height: 3em;
  vertical-align: middle;
}

.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.fa-stack-1x {
  line-height: inherit;
}

.icon-1-5x {
  font-size: 1.5em;
}

.fa-stack-3x {
  font-size: 3em;
}

.color-invert {
  color: #fff;
}

em[class^="fa"] {
  &.fa-accordion-control {
    font-size: 1.5em;
    color: $collapse-icon;
  }
}

*[class^="icon-"] {
  &.icon-radial_alert-programming {
    font-size: 1.2em;
  }

  &.icon-1x {
    font-size: 1em;
  }

  &.icon-2x {
    font-size: 2em;
  }

  &.icon-3x {
    font-size: 3em;
  }

  &.icon-4x {
    font-size: 4em;
  }
}

.icon-spin {
  -webkit-filter: blur(0);
}

.flex-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-circled {
  position: relative;
  background-color: $alert-info-bg;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  color: $bleached-white;
  margin-right: var(--measure-1x);

  &:before {
    position: absolute;
    top: 20%;
    left: 10%;
    width: 1.6rem;
    height: 1.6rem;
    font-size: 1.2rem;
  }
}

.icon-link-hover {
  transform-origin: center;
  transform: scale(1);
  transition: all 0.1s;
  &:hover {
    transform: scale(1.12);
  }
}

.icon-close-btn {
  height: 2.2rem;
  width: 2.2rem;
  font-size: 1rem;
  position: absolute;
  right: 1rem;
  top: 0.6rem;
  z-index: 2;
  cursor: pointer;
  padding: 0.6rem;
  background: var(--color-neutral-500);
  border-radius: 30px;
  color: white;
  @media (min-width: $screen-sm) {
    position: absolute;
    left: 26.8rem;
    top: 0.6rem;
  }
}

.icon-delete-btn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 8rem;
  width: 8rem;
  line-height: 0;
  font-size: 5rem;
  z-index: 2;
  cursor: pointer;
  padding: 0.2rem;
  background: rgba($color-danger-500, 0.4);
  border-radius: 50%;
  color: white;
  border: 4px solid white;
  transition: all 0.2s;
  transform-origin: center;
  @include BoxShadow(1);
  &:hover {
    @include BoxShadow(3);
  }
  @media (min-width: $screen-sm) {
  }
}

// Spinning Icons
// --------------------------

span.icon-cycle.spin {
  -webkit-animation: spin-me 0.2s infinite linear;
  -moz-animation: spin-me 0.2s infinite linear;
  -o-animation: spin-me 0.2s infinite linear;
  animation: spin-me 0.2s infinite linear;
  -webkit-transform-origin: 50% 58%;
  transform-origin: 50% 58%;
  -ms-transform-origin: 50% 58%; /* IE 9 */
}

span.icon-cycle.spin-me {
  -webkit-animation: spin-me 0.2s infinite linear;
  -moz-animation: spin-me 0.2s infinite linear;
  -o-animation: spin-me 0.2s infinite linear;
  animation: spin-me 0.2s infinite linear;
  -webkit-transform-origin: 50% 58%;
  transform-origin: 50% 58%;
  -ms-transform-origin: 50% 58%; /* IE 9 */
}

@-moz-keyframes spin-me {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(1020deg);
  }
}

@-webkit-keyframes spin-me {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1020deg);
  }
}

@keyframes spin-me {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1020deg);
  }
}
