.animate-fadeInList.ng-enter,
.animate-fadeInList.ng-leave {
  -webkit-transition: 150ms ease-out all;
  -moz-transition: 150ms ease-out all;
  -ms-transition: 150ms ease-out all;
  -o-transition: 150ms ease-out all;
  transition: 150ms ease-out all;
}

.animate-fadeInList.ng-enter,
.animate-fadeInList.ng-leave.ng-leave-active {
  opacity: 0;
}

.animate-fadeInList.ng-leave,
.animate-fadeInList.ng-enter.ng-enter-active {
  opacity: 1;
}

/*.animate-fadeInList.ng-enter-stagger {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.repeat-animation {
  -webkit-transition: 0.2s linear all;
  transition: 0.2s linear all;
  position: relative;
  left: 10px;
}


/!*repeat-animation class*!/

.repeat-animation.ng-enter-stagger,
.repeat-animation.ng-leave-stagger,
.repeat-animation.ng-move-stagger {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;

}

.repeat-animation.ng-enter,
.repeat-animation.ng-leave.ng-leave-active{
  opacity: 0;
}

.repeat-animation.left.ng-enter {
  left: 0px;
}

.repeat-animation.right.ng-enter {
  left: 10px;
}

.repeat-animation.ng-enter-active {
  opacity: 1;
  left: 10px;
}
.repeat-animation.ng-leave-active {
  opacity: 0;
  left: 40px;*/

.animate-fadeInList.ng-enter-stagger {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.table-row-animate.ng-enter,
.table-row-animate.ng-leave {
  -webkit-transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  -moz-transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  -ms-transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  -o-transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  position: relative;
  display: block;
}

.table-row-animate.ng-enter.table-row-animate.ng-enter-active,
.table-row-animate-leave {
  opacity: 1;
  top: 0;
  height: 30px;
}

.table-row-animate.ng-leave.table-row-animate.ng-leave-leave-active,
.table-row-animate-enter {
  opacity: 0;
  top: -50px;
  height: 0px;
}
.table-row-animate.ng-enter-stagger {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.table-row-animate.ng-enter,
.table-row-animate.ng-leave {
  -webkit-transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  -moz-transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  -ms-transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  -o-transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  transition: 400ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
  position: relative;
  display: block;
}

.table-row-animate.ng-enter.table-row-animate.ng-enter-active,
.table-row-animate-leave {
  opacity: 1;
  top: 0;
  height: 30px;
}

.table-row-animate.ng-leave.table-row-animate.ng-leave-leave-active,
.table-row-animate-enter {
  opacity: 0;
  top: -50px;
  height: 0px;
}
.table-row-animate.ng-enter-stagger {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.animate-button {
  opacity: 1;
}

.animate-button.ng-hide-add.ng-hide-add-active,
.animate-button.ng-hide-remove.ng-hide-remove-active {
  -webkit-transition: all linear 0s;
  transition: all linear 0s;
}

.animate-button.ng-hide {
  opacity: 0;
  display: none !important;
}

.animate-button {
  opacity: 1;
}

.animate-button.ng-hide-add.ng-hide-add-active,
.animate-button.ng-hide-remove.ng-hide-remove-active {
  -webkit-transition: all linear 0s;
  transition: all linear 0s;
}

.animate-button.ng-hide {
  opacity: 0;
  display: none !important;
}

/* when hiding the picture */
.el-camino.ng-show-add {
  animation: 0.3s fadeInUp;
}

/* when showing the picture */
.el-camino.ng-show-remove {
  animation: 0.3s flipOutDown;
}
