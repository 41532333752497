/* Bubble Left */
@mixin bubble-left {
  @include hacks();
  position: relative;

  &:before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: "";
    border-style: solid;
    @include prefixed(transition-duration, $mediumDuration);
    @include prefixed(transition-property, transform);
    top: calc(50% - #{$tipHeight});
    left: 0;
    border-width: $tipHeight $tipWidth $tipHeight 0;
    border-color: transparent $tipColor transparent transparent;
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    @include prefixed(transform, translateX(-($tipWidth)));
  }
}
