.modal-grid {
  display: grid;
  padding: 2.8rem;
  grid-template-columns: 1fr;
  grid-template-rows: max-content min-content max-content 5rem;
  grid-template-areas: "pic" "title" "content" "actions";
  grid-gap: 2.8rem;
  position: relative;

  &__closer {
    padding: var(--measure-1x);
    position: absolute;

    color: var(--color-primary);
    z-index: 4;
    /*center icon in the closer*/
    > a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $text-color;
      text-decoration: none;
      cursor: pointer;
      padding: var(--measure-1x);
      @media (min-width: $screen-sm) {
        color: $bleached-white;
      }

      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }

  &__pic-area {
    grid-area: pic;
    //align-self: center;
    //justify-self: center;
    border: 1px solid lightgrey;

    width: 100%;
    overflow: hidden;
    //padding: var(--measure-1x)
    > img {
      object-fit: contain;
      width: 100%;
      height: auto;
      cursor: hand;
      transition: all 0.2s;
    }
  }

  &__title-area {
    grid-area: title;
    font-size: var(--measure-font-24);
    font-weight: var(--font-weight-medium);
    display: flex;
    flex-flow: row / nowrap;
    justify-content: flex-start;
    align-items: top;
    line-height: 1.2;
  }

  &__content-area {
    grid-area: content;
    padding: 0 0.8rem;
  }

  &__action-area {
    grid-area: actions;
    display: flex;
    flex-flow: row-reverse nowrap;
    align-items: center;
  }

  &__padding-bottom {
    padding-bottom: 0;
  }

  &__max-width {
    max-width: 500px;
  }
}
