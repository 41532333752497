.flexbox-container {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;

  > .flex-col {
    width: 50%;
    padding: 0.8rem;
  }
}

.flex-column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.flex-center-column {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center-column--mobile {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: $screen-sm) {
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
}
.box {
  width: 100%;

  @media (min-width: $screen-sm) {
    max-width: $screen-sm;
  }
}

.col {
  &--centered-flex-panel {
    width: 100%;
    @media (min-width: $screen-sm) {
      max-width: 90%;
    }
    @media (min-width: $screen-md) {
      max-width: 80%;
    }
    @media (min-width: $screen-lg) {
      max-width: 70%;
    }
  }
}
