/* Backward */
@mixin backward {
  @include hacks();
  @include prefixed(transition-duration, $mediumDuration);
  @include prefixed(transition-property, transform);

  &:hover,
  &:focus,
  &:active {
    @include prefixed(transform, translateX(-8px));
  }
}
