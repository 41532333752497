.news-item {
  &__panel {
    height: calc(100vh - 32.5rem);
    min-height: 50rem;
    overflow-y: auto;
    &--no-content {
      height: 6.2rem;
      min-height: 6.2rem;
      overflow-y: hidden;
    }
  }

  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 8rem) 1fr;
  grid-template-rows: repeat(3, min-content);
  grid-template-areas: "pic title" "pic summary" "actions actions";

  border-bottom: 1px solid $gray-light;
  min-height: 12rem;
  grid-column-gap: var(--measure-1x);

  &:first-of-type {
    border-top: 1px solid $gray-light;
  }

  @media screen and (min-width: $screen-sm) {
    grid-template-columns: minmax(0, 12rem) 1fr 12rem;
    grid-template-rows: repeat(2, min-content);
    grid-template-areas: "pic title actions" "pic summary actions";
  }

  &__pic {
    grid-area: pic;
    display: grid; /* new; nested grid container */
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    background-color: var(--color-neutral-200);
    border-radius: 0.4rem;

    > img {
      grid-column: 1 / -1;
      grid-row: 1 / -1; /* from first line at start, to first line at end */
      align-self: center; /* vertical centering */
      max-width: 90%;
      margin: 0 auto;
      border-radius: 0.4rem;
      padding: var(--measure-half);
    }
  }

  &__title {
    grid-area: title;
    font-size: var(--measure-font-18);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    padding-top: var(--measure-2x);
  }

  &__actions {
    grid-area: actions;
    display: flex;
    justify-content: space-between;
    padding: var(--measure-2x) var(--measure-1x);

    flex-flow: row-reverse nowrap;
    align-items: center;
    @media screen and (min-width: $screen-sm) {
      flex-flow: column nowrap;
      align-items: flex-end;
      justify-content: space-between;
      min-height: 12rem;
      height: 100%;
    }

    &-date {
      margin-top: 0.6rem;
    }
  }

  &__summary {
    grid-area: summary;
    padding-bottom: var(--measure-2x);

    ul {
      list-style-type: disc;
    }
  }
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(1, 1fr);
  grid-template-areas: "news-item-list  news-edit";

  &__item-list-area {
  }

  &__item-edit-area {
  }
}

.grid-layout {
}

ta-editor.ta-html ul,
.ta-scroll-window.form-control ul {
  list-style-type: disc;
}
