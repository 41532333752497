.emphasis-box {
  padding: $alert-padding;
  margin-bottom: $line-height-computed;
  border: 1px solid $state-primary;
  border-radius: $alert-border-radius;
  background: $bleached-white;

  // Headings for larger alerts
  h4 {
    margin-top: 0;
    // Specified for the h4 to prevent conflicts of changing $headings-color
  }
  // Provide class for links that match alerts
  .alert-link {
    font-weight: normal;
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    margin-bottom: 0;
  }
  > p + p {
    margin-top: 5px;
  }
}
.emphasis-box.emphasis-box-dmp {
  @include alert-variant($bleached-white, $alert-info-bg, $alert-info-bg);
}
