/*.animate-if {
  background:white;

  padding:10px;
}

.animate-if.ng-enter, .animate-if.ng-leave {
  -webkit-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
  transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
}

.animate-if.ng-enter,
.animate-if.ng-leave.ng-leave-active {
  opacity:0;
}

.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
  opacity:1;
}*/

.animate-fadeInList.ng-enter,
.animate-fadeInList.ng-leave {
  -webkit-transition: 300ms ease-out all;
  -moz-transition: 300ms ease-out all;
  -ms-transition: 300ms ease-out all;
  -o-transition: 300ms ease-out all;
  transition: 300ms ease-out all;
}

.animate-fadeInList {
  &.ng-enter,
  ng-leave.ng-leave-active {
    -webkit-transition: translateX(-4rem);
    -moz-transition: translateX(-4rem);
    -ms-transition: translateX(-4rem);
    -o-transition: translateX(-4rem);
    transition: translateX(-4rem);
    opacity: 0;
  }
}

.animate-fadeInList,
.animate-fadeInList {
  &.ng-leave,
  .ng-enter.ng-enter-active {
    -webkit-transition: translateX(0rem);
    -moz-transition: translateX(0rem);
    -ms-transition: translateX(0rem);
    -o-transition: translateX(0rem);
    transition: translateX(0rem);
    opacity: 1;
  }
}
.animate-fadeInList.ng-enter-stagger {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

// Use for larger lists

.animate-fadeInBigList.ng-enter,
.animate-fadeInBigList.ng-leave {
  -webkit-transition: 10ms cubic-bezier(0.42, 0, 1, 1) all;
  -moz-transition: 10ms cubic-bezier(0.42, 0, 1, 1) all;
  -ms-transition: 10ms cubic-bezier(0.42, 0, 1, 1) all;
  -o-transition: 10ms cubic-bezier(0.42, 0, 1, 1) all;
  transition: 10ms cubic-bezier(0.42, 0, 1, 1) all;
}

.animate-fadeInBigList.ng-enter,
.animate-fadeInBigList.ng-leave.ng-leave-active {
  opacity: 0;
}

.animate-fadeInBigList.ng-leave,
.animate-fadeInBigList.ng-enter.ng-enter-active {
  opacity: 1;
}
.animate-fadeInBigList.ng-enter-stagger {
  -webkit-transition-delay: 10ms;
  transition-delay: 10ms;
}

/*

.fade-show-hide {
  -webkit-transition:all linear 0.5s;
  transition:all linear 0.5s;
}

.fade-show-hide.ng-hide {
  opacity:0;
}
*/

/**

template for ng-animate css

//starting style
.my-class.ng-enter {

  transition: 0.5s linear all;
  transform: scale(0.1);
}

// destiantion style
.my-class.ng-enter.ng-enter-active {

}


*/

.animate-enter {
  -webkit-transition: 10s linear all;
  -mos-transition: 10s linear all;
  -o-transition: 10s linear all;
  -ms-transition: 10s linear all;
  transition: 10s linear all;
  opacity: 0;
}

.animate-enter.animate-enter-active {
  opacity: 1;
}
//Used for schedule view on the rotate-flip icon
[da-rotate-flip] {
  -webkit-transition: transform ease 250ms;
  -moz-transition: transform ease 250ms;
  -o-transition: transform ease 250ms;
  -ms-transition: transform ease 250ms;
  transition: transform ease 250ms;
}

//Used for schedule view on the rotate-flip icon
[da-rotate-flip].rotated {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.animate-if.ng-enter,
.animate-if.ng-leave {
  -webkit-transition: all linear 0.5s; /* cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s; */

  transition: all linear 0.5s; /* cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s; */
}

.animate-if.ng-enter,
.animate-if.ng-leave.ng-leave-active {
  opacity: 0;
  height: 0;
}

.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
  opacity: 1;
  height: auto;
}
.item-listing {
  line-height: 40px;
  list-style: none;
  box-sizing: border-box;
  display: block;
}

.item-listing.ng-enter,
.item-listing.ng-leave,
.item-listing.ng-move {
  -webkit-transition: 0.5s linear all;
  -moz-transition: 0.5s linear all;
  -o-transition: 0.5s linear all;
  transition: 0.5s linear all;
}

.item-listing.ng-enter,
.item-listing.ng-move {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.item-listing.ng-move.ng-move-active,
.item-listing.ng-enter.ng-enter-active {
  opacity: 1;
  height: 120px;
}

.item-listing.ng-leave {
  opacity: 1;
  overflow: hidden;
}

.item-listing.ng-leave.ng-leave-active {
  opacity: 0;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

//
//working example of ng-show and ng-hide animation
//
.my-element.ng-hide-add,
.my-element.ng-hide-remove {
  /* this is required as of 1.3x to properly
     apply all styling in a show/hide animation */
  transition: 0s linear all;
}

.animate-show {
  line-height: 20px;
  opacity: 1;
}

.animate-show.ng-hide-add,
.animate-show.ng-hide-remove {
  transition: all linear 0.5s;
}

.animate-show.ng-hide {
  line-height: 0;
  opacity: 0;
  padding: 0 10px;
}

.toggler.fa.fa-minus-square.open {
  display: inline-block;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.toggler.fa.fa-plus-square.closed {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}
