.temp-user-modal {
  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: var(--measure-2x) var(--measure-2x) var(--measure-1x)
      var(--measure-2x);
    font-size: 1.8rem;
    border-bottom: 1px solid #e6e6e6;

    > svg {
      &.vk-logo {
        fill: var(--color-brand-vk);
        display: none;
        @media (min-width: $screen-sm) {
          display: block;
          height: 3.2rem;
          width: 3.2rem;
          min-height: 3.2rem;
          margin-right: var(--measure-half);
          flex-shrink: 0;
        }
      }
    }
  }

  &__body {
    padding: var(--measure-1x) var(--measure-2x) 0 2rem;
  }

  &__error {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.6rem;
    color: $dmp-red;

    span {
      transform: scale(0) translate3d(0, 0, 4rem);
      animation: to-normal 0.2s 0.1s ease-out forwards;
    }

    > svg {
      &.error-svg {
        opacity: 0;
        fill: $dmp-red;
        height: 2.4rem;
        width: 2.4rem;
        margin-right: var(--measure-1x);
        flex-shrink: 0;
        transform: scale(0) rotate(-275deg);
        -webkit-animation: to-normal 600ms
          cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
        -o-animation: to-normal 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55)
          forwards;
        animation: to-normal 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55)
          forwards;
      }
    }
  }

  &__success {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.8rem;
    font-weight: 400;
    transform: translateY(-2rem);
    opacity: 0;
    animation: to-normal 300ms cubic-bezier(0.67, 0.65, 0.82, 1.4) forwards;

    > span {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      svg {
        &.success-svg {
          opacity: 0;
          fill: $brand-success;
          height: 2.4rem;
          width: 2.4rem;
          margin-right: var(--measure-1x);
          flex-shrink: 0;
          transform: scale(0) rotate(-275deg);
          -webkit-animation: to-normal 600ms
            cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
          -o-animation: to-normal 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55)
            forwards;
          animation: to-normal 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55)
            forwards;
        }
      }
    }

    &-expiration {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 1.4rem;
      color: $text-color;
    }
  }
}

@keyframes to-normal {
  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}
