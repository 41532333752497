/* ========================================================================
     Component: sidebar
 ========================================================================== */

$sidebar-bg: $bleached-white;

$sidebar-item-color: black;
$sidebar-item-color-active: $nav-top-bg;
$sidebar-item-bg-active: darken($sidebar-bg, 1%);

$sidebar-icon-color: inherits;
$sidebar-icon-color-active: $nav-top-bg;

$sidebar-bullet-color: rgba(0, 0, 0, 0.25);
$sidebar-bullet-color-active: $sidebar-icon-color-active;

$sidebar-heading-color: #919da8;
$sidebar-label-color: $sidebar-item-color;

$submenu-header-hg: 40px;
// Submenu header when hover collapse sidebar
$sidebar-item-border-wd: 3px;
$scrollbar-wd: 17px;

.sidebar {
  height: 100%;
  padding-bottom: 20px;
  background-color: $sidebar-bg;

  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  // draw a divider for the sidebar
  &:after {
    content: "";
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    -webkit-transform: translateZ(0px);
  }

  .nav-heading {
    //padding: 12px 15px;
    //color: $sidebar-heading-color;
    //font-size: 13px;
    //letter-spacing: .035em;
    pointer-events: none;
    cursor: default;
  }

  // Items
  // First main level
  > .nav {
    position: relative;
    padding-right: $scrollbar-wd;

    // Only the first item in sidebar
    > .nav-heading:first-child {
      padding-top: 20px;
    }

    > li {
      //border-left: $sidebar-item-border-wd solid transparent;
      @include transition(border-left-color 0.4s ease);
      padding: 0;

      > a,
      > .nav-item {
        padding: 12px 24px;
        color: $sidebar-item-color;
        letter-spacing: 0.025em;
        font-weight: var(--font-weight-bold);

        &:focus {
          text-decoration: none;
          outline: none;
          color: $sidebar-item-color-active;
        }
        // Item icon
        /*> i {
          width: 1.8em;
          display: inline-block;
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          color: $sidebar-icon-color;
          padding-right: 1em;
        }*/
      }

      // Active item state
      &.active,
      &.open {
        &,
        > a,
        .nav {
          background-color: $sidebar-item-bg-active;
          color: $sidebar-item-color-active;
        }
        > a > em {
          color: $sidebar-icon-color-active;
        }
      }

      &.active {
        border-left-color: initial;
      }
    }
    .label {
      display: block;
      margin-right: 20px;
    }
  }
}

// Sub Levels
.sidebar-subnav {
  background-color: $sidebar-bg;

  > .sidebar-subnav-header {
    color: $sidebar-item-color;
    display: none;
    padding: 10px 20px;
    //font-weight: bold;
  }

  > li {
    border-left: 0 !important;
    > a,
    > .nav-item {
      display: block;
      position: relative;
      padding: 10px 20px;
      padding-left: 53px;
      font-weight: normal;
      background-color: $dmp-light-gray;
      color: $sidebar-item-color;
      &:focus,
      &:hover {
        color: $sidebar-item-color-active;
      }
    }

    &.active {
      > a,
      > .nav-item {
        color: $sidebar-icon-color-active;
        &:after {
          border-color: $sidebar-bullet-color-active;
          background-color: $sidebar-bullet-color-active;
        }
      }
    }

    // Nested levels
    .nav > li {
      padding-left: 5px;
    }
  }
  // li

  // This only happend when sidebar is collapsed and the submenu
  // visibility is toggled via js
  &.nav-floating {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-left: -1px;
    > .sidebar-subnav-header {
      display: block;
    }
    // reduce padding when item is on sidebar collapsed
    li > a {
      padding-left: 20px;
    }
  }
}

// Desktop styles
// ---------------------------------

@media only screen and (min-width: $mq-tablet) {
  .sidebar > .nav {
    padding-right: 0;
    padding-top: 1em;

    .label {
      margin: 2px 0 0 0;
    }
  }

  // Collapsed state styles
  .aside-collapsed {
    .sidebar {
      overflow-x: hidden;
      // Applies to first level items
      > .nav {
        > .nav-heading,
        > li > a > span {
          display: none;
        }

        > li {
          width: $aside-wd-collapsed - 1;
          > a,
          > .nav-item {
            text-indent: -$sidebar-item-border-wd;
            padding: 20px 0;
            text-align: center;
            > em {
              font-size: 1.6em; // increase icons size
              width: auto;
            }
            &:focus {
              // accessibility
              background-color: transparent;
              > em {
                color: inherit;
              }
            }
          }
        }
      }

      // labels in menu items
      .nav {
        .label {
          position: absolute;
          top: 10px;
          right: 5px;
          text-indent: 0;
        }
      }
    }
    // .sidebar
  }
  // .aside-collapsed
}

.sidebar.nav {
  > li {
    > a {
      color: $text-color;
      font-size: 1em;
      padding: 0.3em 0.5em;
      width: $aside-wd;
      font-weight: normal;
      text-align: left;
    }
  }
}
