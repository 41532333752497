/* When the element is about to enter DOM*/
.item-swapout-ngSwitch.ng-enter {
  -webkit-transform: translateY(4rem);
  -moz-transform: translateY(4rem);
  -ms-transform: translateY(4rem);
  -o-transform: translateY(4rem);
  transform: translateY(4rem);
}

/* When the element completes the enter transition */
.item-swapout-ngSwitch.ng-enter-active {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  opacity: 1;
}
/* When the element is about to leave DOM*/
.item-swapout-ngSwitch.ng-leave {
  opacity: 0.5;
}
/*When the element end the leave transition*/
.item-swapout-ngSwitch.ng-leave-active {
  opacity: 0;
}

/*animate-switch */

.animate-switch.ng-animate {
  -webkit-transition: all linear 2s;
  transition: all linear 2s;
}
.animate-switch.ng-leave,
.animate-switch.ng-leave.ng-leave-active {
  display: none;
}
.animate-switch.ng-enter {
  opacity: 0;
  transform: translateY(-4rem);
}
.animate-switch.ng-enter.ng-enter-active {
  opacity: 1;
}
