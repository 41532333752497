.report-btn {
  &__label {
    color: darken($dmp-blue, 20%);
    font-size: 1.4rem;
  }
  &__icon {
    font-size: 1.2rem;
  }

  &__edit-button {
  }

  &__custom {
    &:after {
      content: "\e9c0";
    }
  }
}
