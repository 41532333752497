table.sys-info-table {
  > tbody {
    tr {
      td {
        color: $text-global;
        &:not(.sys-info-table__title) {
          font-weight: 500;
          color: black;
        }
      }
    }
  }
}

.system-info-list {
  &__subsection {
    min-width: 240px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: var(--measure-3x);
  }
  &__subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--measure-2x);
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: var(--measure-2x);
  }
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--measure-1x);
  }
  &title {
    min-width: 50%;
  }
  &__data {
    font-weight: 700;
  }
}
