$label-font-size: 1.2rem;
.mass-programming {
  &__checkbox-label {
    font-size: $label-font-size;

    &--customer {
      font-size: 1.4rem;
      font-weight: var(--font-weight-bold) !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--system {
      font-weight: var(--font-weight-regular);
    }
  }

  &__detail-group {
    display: flex;
    flex-flow: column nowrap;
    padding: var(--measure-1x) 0;
    margin-bottom: var(--measure-1x);

    &--numerical {
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__detail-view {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "job-info system-info";
    grid-column-gap: var(--measure-2x);

    &__job-info {
      grid-area: job-info;
      border-right: 1px solid $global-border;
    }

    &__system-info {
      grid-area: system-info;
    }

    &-label {
      font-weight: var(--font-weight-regular);
      font-size: var(--measure-font-12);
    }

    &-data {
      font-weight: var(--font-weight-semibold);
      font-size: var(--measure-font-14);

      &--subdued {
        font-weight: var(--font-weight-regular);
        color: var(--color-neutral-800);
      }

      &--numerical {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        font-size: var(--measure-font-16);
        font-weight: var(--font-weight-semibold);
      }
    }
  }
}

.upgrade-sunset {
  &__customer-list {
    padding-left: 0;
    li {
      padding: var(--measure-1x);
      &:nth-child(odd) {
        background-color: var(--color-neutral-300);
      }
    }
  }

  &__checkbox-label {
    font-size: $label-font-size;

    &--customer {
      font-size: 1.4rem;
      font-weight: var(--font-weight-regular) !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--system {
      font-weight: var(--font-weight-regular);
    }
  }

  &__detail-group {
    display: flex;
    flex-flow: column nowrap;
    padding: var(--measure-1x) 0;
    margin-bottom: var(--measure-1x);

    &--numerical {
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__detail-view {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "job-info system-info";
    grid-column-gap: var(--measure-2x);

    &__job-info {
      grid-area: job-info;
      border-right: 1px solid $global-border;
    }

    &__system-info {
      grid-area: system-info;
    }

    &-label {
      font-weight: var(--font-weight-regular);
      font-size: var(--measure-font-12);
    }

    &-data {
      font-weight: var(--font-weight-semibold);
      font-size: var(--measure-font-14);

      &--subdued {
        font-weight: var(--font-weight-regular);
        color: var(--color-neutral-800);
      }

      &--numerical {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        font-size: var(--measure-font-16);
        font-weight: var(--font-weight-semibold);
      }
    }
  }
}
.manual-icon-collapse {
  position: absolute;
  font-size: var(--measure-font-12);
  top: 50%;
  right: var(--measure-2x);
  transform: translateY(-50%);
  line-height: 0;
}

.checkbox {
  &.mass-programming__customer-checkbox {
    background-color: var(--color-neutral-300);
    padding: var(--measure-1x);
  }

  &.upgrade-sunset__customer-checkbox {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.c-checkbox__mass-pr span {
  position: absolute !important;
  display: flex !important;
  transform: scale(1.6) !important;
  margin-bottom: 0.6rem !important;
}
