.panel-top-tabs-container {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 1rem 2.2rem;
  height: 6rem;
  -webkit-box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 0);
  -moz-box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 0);
  box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 0);
  &:not(:first-child) {
    margin-left: -0.5rem;
  }
  &:link {
    color: inherit;
    text-decoration: none;
    transition: all 0.5s ease;
    transform-origin: 50% 50%;
  }

  &:hover {
    color: $accent-blue-link;
    transition: all 0.3s ease;
    -webkit-box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 1);
    -moz-box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 1);
    box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 1);
  }
  &.focused {
    color: $accent-blue-link;
    -webkit-box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 1);
    -moz-box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 1);
    box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 1);
  }

  &__title {
    display: block;
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
  }
  &__metric {
    text-align: center;
    position: relative;
    top: -2rem;
  }

  &--system-info {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    padding: var(--measure-1x);
  }
}

.panel-tabs {
  display: flex;
  justify-content: flex-start;
  &__selector {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 500;
    padding: var(--measure-1x) var(--measure-2x);
    -webkit-box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 0);
    -moz-box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 0);
    box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 0);
    &:not(:first-child) {
      margin-left: -0.5rem;
    }

    &:hover {
      transform-origin: top center;
      color: $accent-blue-link;
      transition: all 0.3s ease;
      -webkit-box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 1);
      -moz-box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 1);
      box-shadow: inset 0px 4px 0px 0px rgba($accent-blue-link, 1);
    }
  }
}
