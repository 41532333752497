/* ========================================================================
     Component: table-extras
 ========================================================================== */

//
// Add support to components inside tables
//

.table {
  > thead > tr > th {
    padding: 4px 8px 8px 8px;
    color: black;
  }
  > tbody > tr > td {
    vertical-align: middle;
    > .media {
      img {
        width: 36px;
        height: 36px;
        margin: 0 auto;
      }
    }
  }
  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td {
    border-top: 1px solid #eeeeee;
    color: black;
    font-size: 14px;
    line-height: 1.52857143;
    padding: 8px;
    vertical-align: top;
  }

  .checkbox {
    margin: 0 auto;
    width: 20px;
  }

  .progress {
    margin-bottom: 0;
  }

  .radial-bar {
    margin-bottom: 0;
    margin: 0 auto;
  }
}

.jqstooltip {
  box-sizing: content-box;
}

// Extended table demos

#table-ext-1 {
  th {
    &:nth-child(1) {
      width: 3%;
    }
    &:nth-child(2) {
      width: 5%;
    }
    &:nth-child(7) {
      width: 5%;
    }
    &:nth-child(9) {
      width: 5%;
    }
  }
}

#table-ext-2 {
  th {
    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(3) {
      width: 10%;
    }
  }
}

td.span-50 {
  width: 50%;
}
table.span-full {
  width: 100%;
}
