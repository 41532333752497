$chart-neutral: hsla(202, 46%, 96%, 1);
$font-size-label: 1.8rem;
$font-size-data: 1.8rem;
$font-color-label: hsla(0, 0%, 60%, 1);
$font-color-data: hsla(0, 0%, 45%, 1);
$font-weight-label: 400;
$font-weight-data: 300;
$font-text-decoration-link: underline;
$color-zebra: hsl(204, 50%, 96%);
$padding-header-cell: 0.8rem 1.6rem;
$padding-table-cell: 0.8rem 1.6rem;

@mixin base-analytic-table {
  font-family: $font-family-base;
  font-size: 10pt;
  cursor: default;
  margin: 0;
  background: transparent !important;
  z-index: 1 !important;
}

//disabled for now
@mixin table-row-hover {
  -webkit-box-shadow: inset 0px 0px 2px 1px rgba(53, 158, 196, 1);
  -moz-box-shadow: inset 0px 0px 2px 1px rgba(53, 158, 196, 1);
  box-shadow: inset 0px 0px 2px 1px rgba(53, 158, 196, 1);
}

@mixin header-styles {
  background-color: $color-zebra; //white;
  border-bottom-color: hsla(240, 100%, 50%, 1); //hsla(201, 47%, 86%, 1.00);
  border-width: 0 0 2px 0 !important;
  border-right-color: $color-zebra !important;
}

@mixin table-cell-global {
  color: $text-global;
  font-size: $font-size-data !important;
  padding: $padding-table-cell !important;
  max-height: 3rem;
  text-align: right;

  border-color: transparent !important;
  font-weight: $font-weight-data;
}

@mixin table-cell-global--overview-1st-child {
  color: $text-global;
  font-size: $font-size-data !important;
  padding: $padding-table-cell !important;
  max-height: 3rem;
  text-align: left;

  border-color: transparent !important;
  font-weight: $font-weight-data;
}

.chart {
  /* &__header-row {}*/

  &__table-row {
    border-left: 8px solid transparent;
  }

  /* &__headerRow{}*/

  &__oddTableRow {
    background-color: rgba($color-zebra, 0.6);
  }

  &__selectedTableRow {
    // @include table-row-hover;
    &--overview {
    }
  }

  &__hoverTableRow {
    //  @include table-row-hover;
    &--overview {
    }
  }

  &__headerCell {
    padding: $padding-header-cell !important;
    font-weight: $font-weight-label;
    font-size: $font-size-label;
    color: $font-color-label;
    text-align: right;
    @include header-styles;

    &:first-of-type {
      text-align: left;
      width: 33.33333333%;
    }

    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(7) {
      display: none;
    }

    &--overview {
      padding: 1.4rem !important;
      font-size: $font-size-label;
      text-align: right;
      width: 25%;
      background-color: $chart-neutral;
      color: $font-color-label;
      font-weight: $font-weight-label;
      @include header-styles;

      &:first-of-type {
        &.sort-ascending,
        &.sort-descending {
          > span.google-visualization-table-sortind:after {
            content: "";
          }
        }
      }

      &:first-of-type,
      &:nth-child(2),
      &:nth-child(3) {
        width: 25%;
      }
    }
  }

  &__tableCell {
    @include table-cell-global;
    //descriptor column
    &:nth-child(1) {
      cursor: pointer;

      text-align: left;
      font-size: $font-size-label;
      font-weight: $font-weight-label;
      text-decoration: $font-text-decoration-link;
    }

    &:nth-child(2) {
      //background: rgba(#A0CDDE, .2);
    }

    &:nth-child(4) {
      //background: rgba(#25627A, .2);
    }

    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(7) {
      display: none;
    }

    &--overview {
      @include table-cell-global--overview-1st-child;
      text-align: right;

      &:nth-child(1) {
        font-size: $font-size-label;
        color: $font-color-data;
        font-weight: $font-weight-label;
        text-decoration: underline;
        text-align: left !important;
        cursor: pointer;
      }
    }
  }

  &__rowNumberCell {
  }
}

.sub-header__title-chart {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > i {
    padding-right: var(--measure-1x);
  }

  .sub-header-svg {
    margin-right: var(--measure-1x);
    width: 2.4rem;
    height: 2.4rem;
    fill: #656565;
  }
}

.control-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.table-height {
  &__2-row {
    min-height: 1px !important;
    max-height: 120px !important;
  }

  &__3-row {
    min-height: 1px !important;
    max-height: 200px !important;
  }

  &__4-row {
    min-height: 1px !important;
    max-height: 240px !important;
  }

  &__full {
    min-height: 475px !important;
    height: auto !important;
    width: 100% !important;
    max-height: 100% !important;
  }
}

.google-visualization-table-table {
  @include base-analytic-table();
}
.table {
  &--dealer-analytics,
  &--site-diagnostics {
    @include base-analytic-table();
    width: 100%;
    &__header-cell {
      @include header-styles;
      padding: $padding-header-cell !important;
      font-weight: $font-weight-label;
      font-size: $font-size-label !important;
      color: $font-color-label;
      text-align: right;
      @include header-styles;
    }
    &__data-cell {
      @include table-cell-global;
      font-weight: var(--font-weight-regular);
      &:nth-child(1) {
        text-align: left;
      }
    }
    tr {
      &:nth-child(odd) {
        background-color: rgba($color-zebra, 0.6);
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .table-responsive--dealer-analytics,
  .table-responsive--site-diagnostics {
    border: 1px solid #eeeeee;
    margin-bottom: 15.75px;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    transition: all 0.4s ease-out;

    /*     &:hover:before {
            position: absolute;
            top: 48%;
            left: var(--measure-2x);
            font-family: dmp_icon_font;
            content: '\e97c';
            font-size: var(--measure-font-60);
            opacity: .5;
            //transform: translateY(-50%);
        }*/
  }

  .table-responsive--dealer-analytics > .table--dealer-analytics,
  .table-responsive--site-diagnostics > .table--site-diagnostics {
    margin-bottom: 0;
  }

  .table-responsive > .table--dealer-analytics,
  .table-responsive--site-diagnostics
    > .table--site-diagnostics
    > thead
    > tr
    > th,
  .table-responsive > .table--dealer-analytics,
  .table-responsive--site-diagnostics
    > .table--site-diagnostics
    > tbody
    > tr
    > th,
  .table-responsive > .table--dealer-analytics,
  .table-responsive--site-diagnostics
    > .table--site-diagnostics
    > tfoot
    > tr
    > th,
  .table-responsive > .table--dealer-analytics,
  .table-responsive--site-diagnostics
    > .table--site-diagnostics
    > thead
    > tr
    > td,
  .table-responsive > .table--dealer-analytics,
  .table-responsive--site-diagnostics
    > .table--site-diagnostics
    > tbody
    > tr
    > td,
  .table-responsive > .table--dealer-analytics,
  .table-responsive--site-diagnostics
    > .table--site-diagnostics
    > tfoot
    > tr
    > td {
    white-space: nowrap;
  }
}
