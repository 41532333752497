.y-axis-selector {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  &__col-1 {
    width: 40%;
    min-width: rem(320px);
    flex: 1 0 32rem;
    border: 1px solid $global-border;
  }

  &__col-2 {
    width: 60%;
    flex: 2 0 1;
  }
}
