/* ========================================================================
     Component: notify
 ========================================================================== */

// Name:            Notify
// Description:     Defines styles for toggleable notifications
//
// Component:       `uk-notify`
//
// Sub-objects:     `uk-notify-message`
//
// Modifiers:       `uk-notify-top-center`
//                  `uk-notify-top-right`
//                  `uk-notify-bottom-left`
//                  `uk-notify-bottom-center`
//                  `uk-notify-bottom-right`
//                  `uk-notify-message-primary`
//                  `uk-notify-message-success`
//                  `uk-notify-message-warning`
//                  `uk-notify-message-danger`
//
// Uses:            Close: `uk-close`
//
// ========================================================================

// Variables
// ========================================================================

$notify-position: 50px;
$notify-z-index: 1040;
$notify-width: 350px;

$notify-message-margin-bottom: 10px;
$notify-message-padding: 15px;
$notify-message-background: #444;
$notify-message-color: $dmp-white-inverse;
$notify-message-font-size: 16px;
$notify-message-line-height: 22px;

$notify-message-primary-background: #ebf7fd;
$notify-message-primary-color: #2d7091;

$notify-message-success-background: #f2fae3;
$notify-message-success-color: #659f13;

$notify-message-warning-background: #fffceb;
$notify-message-warning-color: #e28327;

$notify-message-danger-background: #fff1f0;
$notify-message-danger-color: #d85030;

// Message container for positioning

.uk-notify {
  position: fixed;
  top: $notify-position;
  left: $notify-position;
  z-index: $notify-z-index;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: $notify-width;
}

// Position modifiers

.uk-notify-top-right,
.uk-notify-bottom-right {
  left: auto;
  right: $notify-position;
}

.uk-notify-top-center,
.uk-notify-bottom-center {
  left: 50%;
  margin-left: ($notify-width / -2);
}

.uk-notify-bottom-left,
.uk-notify-bottom-right,
.uk-notify-bottom-center {
  top: auto;
  bottom: $notify-position;
}

// Responsiveness

// Phones portrait and smaller */
@media (max-width: $mq-mobile) {
  // Fit in small screen

  .uk-notify {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0;
  }
}

// Sub-object: `uk-notify-message`

.uk-notify-message {
  position: relative;
  margin-bottom: $notify-message-margin-bottom;
  padding: $notify-message-padding;
  font-size: $notify-message-font-size;
  line-height: $notify-message-line-height;
  border-radius: $border-radius-small;
  padding-right: 35px;
  cursor: pointer;

  // $geedmo: define a normal status to use with default styling
  &.alert.alert-normal {
    background: $notify-message-background;
    color: $notify-message-color;
  }
}

// Close in notify

.uk-notify-message > .close {
  visibility: hidden;
  // float: right;
}

.uk-notify-message:hover > .close {
  visibility: visible;
}
