.line-item-button {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border-left: 0;
  border-right: 0;
  padding: 1.4rem 1.6rem;
  font-size: var(--measure-font-16);
  border-bottom: 0;
  border-top: 0.1rem solid #e3e3e3;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: var(--color-neutral-100);
  }

  &:after {
    font-family: "dmp_icon_font";
    content: "\e97e";
  }
}
