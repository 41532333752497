/* Icon Wobble Horizontal */
@include keyframes(#{$nameSpace}-icon-wobble-horizontal) {
  16.65% {
    @include prefixed(transform, translateX(6px));
  }

  33.3% {
    @include prefixed(transform, translateX(-5px));
  }

  49.95% {
    @include prefixed(transform, translateX(4px));
  }

  66.6% {
    @include prefixed(transform, translateX(-2px));
  }

  83.25% {
    @include prefixed(transform, translateX(1px));
  }

  100% {
    @include prefixed(transform, translateX(0));
  }
}

@mixin icon-wobble-horizontal {
  @include hacks();
  position: relative;
  padding-right: 2.2em;
  @include prefixed(transition-duration, $mediumDuration);

  &:before {
    content: "\f061";
    position: absolute;
    right: 1em;
    padding: 0 1px;
    font-family: FontAwesome;
    @include prefixed(transform, translateZ(0));
  }

  &:hover,
  &:focus,
  &:active {
    &:before {
      @include prefixed(animation-name, #{$nameSpace}-icon-wobble-horizontal);
      @include prefixed(animation-duration, 1s);
      @include prefixed(animation-timing-function, ease-in-out);
      @include prefixed(animation-iteration-count, 1);
    }
  }
}
