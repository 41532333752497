/* Bob */
@include keyframes(#{$nameSpace}-bob) {
  0% {
    @include prefixed(transform, translateY(-8px));
  }
  50% {
    @include prefixed(transform, translateY(-4px));
  }
  100% {
    @include prefixed(transform, translateY(-8px));
  }
}

@include keyframes(#{$nameSpace}-bob-float) {
  100% {
    @include prefixed(transform, translateY(-8px));
  }
}

@mixin bob {
  @include hacks();

  &:hover,
  &:focus,
  &:active {
    @include prefixed(
      animation-name,
      "#{$nameSpace}-bob-float, #{$nameSpace}-bob"
    );
    @include prefixed(animation-duration, ".3s, 1.5s");
    @include prefixed(animation-delay, "0s, .3s");
    @include prefixed(animation-timing-function, "ease-out, ease-in-out");
    @include prefixed(animation-iteration-count, "1, infinite");
    @include prefixed(animation-fill-mode, "forwards");
    @include prefixed(animation-direction, "normal, alternate");
  }
}
